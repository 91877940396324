import { createSlice } from '@reduxjs/toolkit';
import type {
  SaveWebhookReducerActions,
  GetWebhooksReducerActions,
  DeleteWebhookReducerActions,
  ActiveWebhookReducerActions,
} from './types';
import { generateId, upsertInArray } from '@utils/helpers';
import { ActiveWebhook } from '../../webhooks/types/types';
import { Webhook } from '../../webhooks/ducks/backTypes/types';

export interface WebhooksState {
  loaded: boolean;
  isFetching?: boolean;
  formShouldBeClosed?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  page?: number;
  limit?: number;
  total?: number;
  webhooks: Webhook[];
  webhook?: Webhook;
  activeWebhook: ActiveWebhook;
}
export const initialActiveWebhook = {
  id: generateId(),
  ipAddressesWhitelist: [],
  headersWhitelist: [],
  methodsWhitelist: [],
};
export const INITIAL_WEBHOOKS_STATE: WebhooksState = {
  loaded: false,
  isFetching: false,
  webhooks: [],
  activeWebhook: initialActiveWebhook,
};

const webhooksSlice = createSlice({
  name: 'webhooks',
  initialState: INITIAL_WEBHOOKS_STATE,

  reducers: {
    getWebhooks: (state) => {
      state.isFetching = true;
    },
    getWebhooksSuccess: (state, { payload }: GetWebhooksReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.webhooks = payload.webhooks;
    },
    getWebhooksFailed: (state, { payload }: GetWebhooksReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getWebhook: (state) => {
      state.isFetching = true;
    },
    getWebhookSuccess: (state, { payload }: SaveWebhookReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.webhook = payload.webhook;
    },
    getWebhookFailed: (state, { payload }: SaveWebhookReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    createWebhook: (state) => {
      state.isFetching = true;
      state.formShouldBeClosed = false;
    },
    createWebhookSuccess: (state, { payload }: SaveWebhookReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.formShouldBeClosed = true;
      state.webhook = payload.webhook;
      state.webhooks = upsertInArray(payload.webhook, state.webhooks, (webhook) => webhook.id);
      state.activeWebhook = initialActiveWebhook;
    },
    createWebhookFailed: (state, { payload }: SaveWebhookReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    updateWebhook: (state) => {
      state.isFetching = true;
      state.formShouldBeClosed = false;
    },
    updateWebhookSuccess: (state, { payload }: SaveWebhookReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.formShouldBeClosed = true;
      state.webhook = payload.webhook;
      state.webhooks = upsertInArray(payload.webhook, state.webhooks, (webhook) => webhook.id);
      state.activeWebhook = {
        id: generateId(),
        methodsWhitelist: [],
      };
    },
    updateWebhookFailed: (state, { payload }: SaveWebhookReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    deleteWebhook: (state) => {
      state.isFetching = true;
      state.formShouldBeClosed = false;
    },
    deleteWebhookSuccess: (state, { payload }: DeleteWebhookReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.formShouldBeClosed = true;
      state.webhooks = state.webhooks.filter((webhook) => webhook.id !== payload.webhook.id);
    },
    deleteWebhookFailed: (state, { payload }: DeleteWebhookReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    updateActiveWebhook: (state, { payload }: ActiveWebhookReducerActions) => {
      state.activeWebhook = { ...payload.activeWebhook };
    },
    cleanActiveWebhook: (state) => {
      state.activeWebhook = { ...INITIAL_WEBHOOKS_STATE.activeWebhook };
    },
  },
});
export default webhooksSlice;
