import { allRights } from '@features/profiles/profilesHelpers';
import { Profile, ProfileType } from '@features/profiles/types';
import { generateId } from '@utils/helpers';

export const profilesList: Profile[] = [
  {
    id: generateId(),
    name: 'admin',
    type: ProfileType.admin,
    rights: {
      billing: allRights,
      dashboard: allRights,
      connections: allRights,
      syncs: allRights,
      audiences: allRights,
      datasets: allRights,
      destinations: allRights,
      users: allRights,
      accountManagement: allRights,
    },
  },
  {
    id: generateId(),
    name: 'marketing',
    type: ProfileType.marketing,
    rights: {
      billing: allRights,
      dashboard: allRights,
      connections: allRights,
      audiences: allRights,
      datasets: allRights,
      syncs: allRights,
      destinations: [],
      users: [],
      accountManagement: [],
    },
  },
  {
    id: generateId(),
    name: 'data',
    type: ProfileType.data,
    rights: {
      billing: allRights,
      dashboard: allRights,
      connections: allRights,
      audiences: allRights,
      datasets: allRights,
      syncs: allRights,
      destinations: [],
      users: [],
      accountManagement: [],
    },
  },
];
