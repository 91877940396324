import { Audience } from '../../audienceBackTypes/audience';
import { AudienceSpecification } from '../../audienceBackTypes/audienceSpecification';
import { ActiveAudience } from '@features/audiences/types/ActiveAudience/ActiveAudience';
import { toBackType } from '@features/audiences/ducks/api/mappingAudienceTypes/toBackType/toBackType';
import { BusinessCategoryEnum } from '@features/objects/types';
import { shouldCalculateMetrics } from './shouldCalculateMetrics';
export type CreateAudienceCommand = {
  accountId: string;
  dataWarehouseId: string;
  name: string;
  longName: string;
  businessCategory: BusinessCategoryEnum;
  specification: AudienceSpecification;
  postDeploymentOptions: {
    execute: boolean;
    calculateMetrics: boolean;
  };
};
export const getCreateAudienceCommand = (
  audience: ActiveAudience,
  accountId: string,
  dataWarehouseId: string
): CreateAudienceCommand => {
  // Mapping front type to the back type
  const backType: Audience = toBackType({
    audience,
    accountId,
    dataWarehouseId,
  }); // Back type

  // @@@TODO: this will be removed when we finish the helpers
  // Hard code some audience properties

  // Prepare CreateAudienceCommand
  const createAudienceCommand: CreateAudienceCommand = {
    accountId,
    dataWarehouseId,
    name: backType.name,
    longName: backType.longName,
    businessCategory: backType.businessCategory,
    specification: backType.specification,
    postDeploymentOptions: {
      execute: true,
      calculateMetrics: shouldCalculateMetrics(accountId),
    },
  };
  return createAudienceCommand;
};
