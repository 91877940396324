import { ApmBase, init as initApm } from '@elastic/apm-rum';
import React, { useContext } from 'react';

export interface ApmContextModel {
  setUser(user: Readonly<{ id: string }>): void;
}

export const ApmContext = React.createContext<ApmContextModel>({ setUser() {} });
export const useApm = () => useContext(ApmContext);

export type ApmProviderProps = Readonly<{
  children: React.ReactNode;
}>;

let apm: ApmBase | undefined;

const setUser = (user: { id: string }) => {
  apm?.setUserContext(user);
};

export const ApmProvider = ({ children }: ApmProviderProps) => {
  const { REACT_APP_APM_SERVICE_NAME, REACT_APP_APM_URL, REACT_APP_APM_ENVIRONMENT, REACT_APP_APM_SERVICE_VERSION } = process.env;
  if (!REACT_APP_APM_SERVICE_NAME || !REACT_APP_APM_URL || !REACT_APP_APM_ENVIRONMENT) {
    console.info(`APM is disabled because configuration is incomplete.`, {
      REACT_APP_APM_SERVICE_NAME,
      REACT_APP_APM_URL,
      REACT_APP_APM_ENVIRONMENT,
      REACT_APP_APM_SERVICE_VERSION,
    });
  } else if (!apm) {
    apm = initApm({
      serviceName: REACT_APP_APM_SERVICE_NAME,
      serverUrl: REACT_APP_APM_URL,
      environment: REACT_APP_APM_ENVIRONMENT,
      serviceVersion: REACT_APP_APM_SERVICE_VERSION ?? '',
    });
  }

  return <ApmContext.Provider value={{ setUser }}>{children}</ApmContext.Provider>;
};
