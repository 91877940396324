import { call, put, select, takeLatest } from 'redux-saga/effects';
// api
import * as API from './api';
// webhooksSlice

import webhooksSlice from './webhooksSlice';
import { activeAccountIdStateSelector, activeDataWarehouseIdStateSelector } from '@redux/selectors';
import {
  ApiDeleteWebhookPayload,
  ApiGetWebhookPayload,
  ApiGetWebhooksPayload,
  ApiSaveWebhookPayload,
  CreateWebhookSaga,
  DeleteWebhookSaga,
  GetWebhookSaga,
  UpdateWebhookSaga,
} from '../../webhooks/ducks/types';

export function* getWebhooksSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const getWebhooksPayload: ApiGetWebhooksPayload = yield call(API.getWebhooks, accountId);
    yield put({
      type: webhooksSlice.actions.getWebhooksSuccess.type,
      payload: getWebhooksPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: webhooksSlice.actions.getWebhooksFailed.type,
        payload: { err, errorDetails: err.message },
      });
    } else {
      console.log(err);
    }
  }
}
function* getWebhookSaga({ payload }: GetWebhookSaga) {
  try {
    const { id } = payload;
    const getWebhookPayload: ApiGetWebhookPayload = yield call(API.getWebhook, id);
    yield put({
      type: webhooksSlice.actions.getWebhookSuccess.type,
      payload: getWebhookPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: webhooksSlice.actions.getWebhookFailed.type,
        payload: { err, errorDetails: err.message },
      });
    } else {
      console.log(err);
    }
  }
}
function* createWebhookSaga({ payload }: CreateWebhookSaga) {
  try {
    const { activeWebhook } = payload;
    const accountId: string = yield select(activeAccountIdStateSelector);
    const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);
    const updateWebhookPayload: ApiSaveWebhookPayload = yield call(
      API.createWebhook,
      activeWebhook,
      accountId,
      dataWarehouseId
    );
    yield put({
      type: webhooksSlice.actions.createWebhookSuccess.type,
      payload: updateWebhookPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: webhooksSlice.actions.createWebhookFailed.type,
        payload: { err, errorDetails: err.message },
      });
    } else {
      console.log(err);
    }
  }
}
function* updateWebhookSaga({ payload }: UpdateWebhookSaga) {
  try {
    const { activeWebhook } = payload;
    const updateWebhookPayload: ApiSaveWebhookPayload = yield call(
      API.updateWebhook,
      activeWebhook
    );
    yield put({
      type: webhooksSlice.actions.updateWebhookSuccess.type,
      payload: updateWebhookPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: webhooksSlice.actions.updateWebhookFailed.type,
        payload: { err, errorDetails: err.message },
      });
    } else {
      console.log(err);
    }
  }
}

function* deleteWebhookSaga({ payload }: DeleteWebhookSaga) {
  try {
    const { webhook } = payload;
    const deleteWebhookPayload: ApiDeleteWebhookPayload = yield call(API.deleteWebhook, webhook);
    yield put({
      type: webhooksSlice.actions.deleteWebhookSuccess.type,
      payload: { webhook },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: webhooksSlice.actions.deleteWebhookFailed.type,
        payload: { err, errorDetails: err.message },
      });
    } else {
      console.log(err);
    }
  }
}

export const webhooksSagas = [
  takeLatest(webhooksSlice.actions.getWebhooks.type, getWebhooksSaga),
  takeLatest(webhooksSlice.actions.getWebhook.type, getWebhookSaga),
  takeLatest(webhooksSlice.actions.createWebhook.type, createWebhookSaga),
  takeLatest(webhooksSlice.actions.updateWebhook.type, updateWebhookSaga),
  takeLatest(webhooksSlice.actions.deleteWebhook.type, deleteWebhookSaga),
];
