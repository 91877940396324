import { FilterGroupType, FilterOperatorTypes, OperatorDetails } from '@features/filter/types';
import { columnTypesEnum } from '@features/objects/types';
import { SyncSegmentation } from '@features/syncs/types/SyncSegmentation';
import {
  SegmentColumnOperator,
  SegmentColumnOperatorType,
} from '@features/syncs/types/backTypes/SegmentColumnOperators';
import { SyncSegmentationSpecification } from '@features/syncs/types/backTypes/SyncSegmentationSpecification';
import { caseNever } from '@utils/case-never';
import { generateId } from '@utils/helpers';
import { AudienceColumn } from '../../../../audiences/types/AudienceColumn';
import { JSONValue } from '@contracts/JSONValue';

export const mappingSegmentationToFront = (
  audienceColumns: AudienceColumn[],
  segmentationSpecification?: SyncSegmentationSpecification
): SyncSegmentation | undefined => {
  return segmentationSpecification
    ? {
        dataFilter: {
          key: generateId(),
          combinationOperator: 'or',
          groupFilters: (segmentationSpecification?.condition.items || []).reduce(
            (result: FilterGroupType[], elem) => {
              const filterGroup: FilterGroupType = {
                id: generateId(),
                combinationOperator: 'and',
                type: 'property',
                conditions: elem.items.map((item) => {
                  const columnDetails = audienceColumns.find((col) => col.id === item.columnId);
                  if (!columnDetails) {
                    throw new Error('Failed to find audience column');
                  }
                  return {
                    id: generateId(),
                    fieldId: item.columnId,
                    fieldName: columnDetails.name,
                    fieldType: columnDetails.type,
                    operator: mappingConditionOperator(item.operator, columnDetails),
                  };
                }),
              };
              result.push(filterGroup);
              return result;
            },
            []
          ),
        },
        recordsLeavingStrategy: segmentationSpecification?.recordsLeavingStrategy,
      }
    : undefined;
};
const mappingConditionOperator = (
  operator: SegmentColumnOperator,
  columnDetails: AudienceColumn
): OperatorDetails => {
  const operatorType = operator.type;
  switch (operatorType) {
    case SegmentColumnOperatorType.is:
      let isValue = operator.value;
      if (columnDetails.type === columnTypesEnum.BOOLEAN && typeof isValue === 'boolean') {
        isValue = parseBooleanToStringValue(isValue);
      }
      return { type: FilterOperatorTypes.is, value: isValue };
    case SegmentColumnOperatorType.isNot:
      let isNotValue = operator.value;
      if (columnDetails.type === columnTypesEnum.BOOLEAN && typeof isNotValue === 'boolean') {
        isNotValue = parseBooleanToStringValue(isNotValue);
      }
      return { type: FilterOperatorTypes.isNot, value: isNotValue };
    case SegmentColumnOperatorType.contains:
      return { type: FilterOperatorTypes.contains, value: operator.value };
    case SegmentColumnOperatorType.isEmpty:
      return { type: FilterOperatorTypes.isEmpty };
    case SegmentColumnOperatorType.isNotEmpty:
      return { type: FilterOperatorTypes.isNotEmpty };
    case SegmentColumnOperatorType.isIn:
    case SegmentColumnOperatorType.isNotIn:
      return mappingArrayOperatorByColumnType(operatorType, operator.values, columnDetails);
    case SegmentColumnOperatorType.matchesRegExp:
      return {
        type: FilterOperatorTypes.regex,
        value: operator.expression,
        isCaseSensitive: operator.isCaseSensitive,
      };
    default:
      return caseNever(operatorType);
  }
};
const mappingArrayOperatorByColumnType = (
  arrayOperator: SegmentColumnOperatorType.isIn | SegmentColumnOperatorType.isNotIn,
  operatorValues: JSONValue[],
  columnDetails: AudienceColumn
): OperatorDetails => {
  switch (columnDetails.type) {
    case columnTypesEnum.SYSTEM_ID:
      return {
        type:
          arrayOperator === SegmentColumnOperatorType.isIn
            ? FilterOperatorTypes.isInListOfSystemIds
            : FilterOperatorTypes.isNotInListOfSystemIds,
        value: operatorValues,
      };
    case columnTypesEnum.STRING:
      return {
        type:
          arrayOperator === SegmentColumnOperatorType.isIn
            ? FilterOperatorTypes.isInListOfStrings
            : FilterOperatorTypes.isNotInListOfStrings,
        value: operatorValues,
      };
    case columnTypesEnum.BOOLEAN:
      return {
        type:
          arrayOperator === SegmentColumnOperatorType.isIn
            ? FilterOperatorTypes.isInListOfBooleans
            : FilterOperatorTypes.isNotInListOfBooleans,
        value: operatorValues.map((x) => parseBooleanToStringValue(x as boolean)),
      };
    case columnTypesEnum.FLOAT:
      return {
        type:
          arrayOperator === SegmentColumnOperatorType.isIn
            ? FilterOperatorTypes.isInListOfFloats
            : FilterOperatorTypes.isNotInListOfFloats,
        value: parseNumberValuesToStrings(operatorValues as number[]),
      };
    case columnTypesEnum.INTEGER:
      return {
        type:
          arrayOperator === SegmentColumnOperatorType.isIn
            ? FilterOperatorTypes.isInListOfIntegers
            : FilterOperatorTypes.isNotInListOfIntegers,
        value: parseNumberValuesToStrings(operatorValues as number[]),
      };

    case columnTypesEnum.TIMESTAMP:
      return {
        type:
          arrayOperator === SegmentColumnOperatorType.isIn
            ? FilterOperatorTypes.isInListOfDates
            : FilterOperatorTypes.isNotInListOfDates,
        value: operatorValues,
      };
    case columnTypesEnum.STRING_ARRAY:
    case columnTypesEnum.JSON_ARRAY:
    case columnTypesEnum.FLOAT_ARRAY:
    case columnTypesEnum.BOOLEAN_ARRAY:
    case columnTypesEnum.INTEGER_ARRAY:
    case columnTypesEnum.SYSTEM_ID_ARRAY:
    case columnTypesEnum.TIMESTAMP_ARRAY:
    case columnTypesEnum.JSON:
      throw new Error(`Array operators doesn't support ${columnDetails.type}`);
    default:
      return caseNever(columnDetails.type);
  }
};
const parseBooleanToStringValue = (value: boolean) => {
  return value.toString();
};
const parseNumberValuesToStrings = (values: number[]) => {
  return values.map((n: number) => n.toString());
};
