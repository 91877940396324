export function trim(s: string, chars: string[]) {
  const charList = chars.join('').replace(/[\]\\]/g, '\\$&');
  const exp = new RegExp(`[${charList}]+$`);
  return s.replace(exp, '');
}
export function trimStartBy(s: string, chars: string[]) {
  const charList = chars.join('').replace(/[\]\\]/g, '\\$&');
  const exp = new RegExp(`^[${charList}]+`);
  return s.replace(exp, '');
}
export function trimBy(s: string, chars: string[]) {
  const charList = chars.join('').replace(/[\]\\]/g, '\\$&');
  const exp = new RegExp(`^[${charList}]+|[${charList}]+$`, 'g');
  return s.replaceAll(exp, '');
}
