import {
  ActiveSourceConfiguration,
  ActiveSQLSourceConfiguration,
  AggregateDetails,
  AggregateMappingRow,
  AudienceDeduplicationKey,
  Column,
  columnDisplayEnum,
  columnShapeTypeEnum,
  dataTypesEnum,
  MappedColumn,
  MappedColumns,
  MappingRow,
  mappingRowTypesEnum,
  MasterIdMappingRow,
  ModifiedAtMappingRow,
  RenameColumnPreparationAction,
  sftpModifiedAtColumnSource,
  sourceTypesEnum,
} from '@features/audiences/types';
import { isDefaultMappingRow } from '@features/audiences/types/typesPredicates';
import { ConnectorType } from '@features/connections/types';
import { columnTypesEnum } from '@features/objects/types';
import { generateId, limitTextDisplay } from '@utils/helpers';
import { toSnakeCase } from '@utils/to-snake-case';
import { emptyMappedColumnValues } from './constants';
import { omit } from 'lodash';
import {
  JoinedAudienceSourceColumnConditionType,
  JoinedAudienceSourceConditionType,
} from '@features/audiences/ducks/api/audienceBackTypes/audienceSpecification';
import { MASTER_ID_COLUMN_ID_FOR_JOIN } from '@features/audiences/helpers/constants/joinSourceMapping';
import { getSourceColumnType } from '@features/audiences/helpers/castValue';
import {
  MASTER_ID_COLUMN_NAME,
  MODIFIED_AT_COLUMN_NAME,
} from '@features/audiences/helpers/constants/constants';

interface IMappingHelpers {
  // Used to generate new row depending on the row type check enum: mappingRowTypesEnum
  generateNewMappingRow: (
    rowType: mappingRowTypesEnum,
    mappedColumns: MappedColumns,
    canDelete: boolean,
    key?: string,
    audienceColumnName?: string,
    audienceColumnType?: columnTypesEnum,
    primaryKey?: boolean,
    updatedAtKey?: boolean
  ) => MappingRow;
  // Used to update or add column to the mappedColumns object
  saveMappingRowColumn: (
    oldMappingRows: MappingRow[],
    rowKey: string,
    sourceKey: string,
    column: MappedColumn
  ) => MappingRow[];
  // Used to delete mapping row
  deleteMappingRows: (oldMappingRows: MappingRow[], rowKey: string) => MappingRow[];
  addSystemMappingRows: (
    currentMappingRows: MappingRow[],
    sourcesConfig: ActiveSourceConfiguration[]
  ) => MappingRow[];
  updateAggregateMappingRow: (
    aggregateMappingRow: AggregateMappingRow,
    activeSourceId: string
  ) => AggregateMappingRow;
  // Used for audience columns names
  prepareAudienceColumnName: (audienceColumnName: string) => string;
  prepareAggregateColumnName: (columnName: string, aggregate?: AggregateDetails) => string;
  // Functions used for the mapped columns display
  // Used to hide old unsigned columns from the previous active sources for the new row
  hideMappedColumnsHandler: (
    sourcesConfig: ActiveSourceConfiguration[],
    activeSourceCombinationLevel: number
  ) => MappedColumns;
  deleteSourceMappingColumnFromMappingRows: (
    mappingRows: MappingRow[],
    targetSourceKey: string,
    colName: string
  ) => MappingRow[];
  deleteSourceMappingFromMappingRows: (mapping: MappingRow[], sourceKey: string) => MappingRow[];
  updateMappingRowsNamesWithSourcePreparation: (
    activeMappingRows: MappingRow[],
    renameSteps: RenameColumnPreparationAction[],
    activeSourceConfigurationKey: string
  ) => MappingRow[];
  updateAudienceColumnsPrimaryKey: (
    oldMappingRows: MappingRow[],
    deduplicationKeys: AudienceDeduplicationKey[]
  ) => MappingRow[];
  prepareDefaultDeduplicationKeysFromMappingRows: (
    mappingRows: MappingRow[],
    activeSourceConfigurationKey: string
  ) => AudienceDeduplicationKey[];
  // Check if other source columns with/without the active source if they contain values in the mapping row object or not
  // If activeSourceKey is undefined we will consider to check all sources including the active source
  checkIfSourceColumnsContainValuesInMappingRow: (
    row: MappingRow,
    activeSourceKey?: string
  ) => boolean;
  checkIfPreviousMappedColumnHidden: (
    row: MappingRow,
    sources: ActiveSourceConfiguration[],
    activeSourceCombinationLevel: number
  ) => boolean;
  setColumnDisplayForMasterIdMappingRow: (row: MasterIdMappingRow) => MasterIdMappingRow;
  cleanRemovedSourceColumnsFromMappingRows: (
    mappingRows: MappingRow[],
    activeSourceConfiguration: ActiveSQLSourceConfiguration | ActiveSourceConfiguration
  ) => { cleanedMappingRows: MappingRow[]; cleanedSelectedColumns: Column[] };
}

const mappingHelpers: IMappingHelpers = {
  generateNewMappingRow: (
    rowType,
    mappedColumns,
    canDelete,
    key,
    audienceColumnName,
    audienceColumnType,
    primaryKey,
    updatedAtKey
  ) => {
    switch (rowType) {
      case mappingRowTypesEnum.MASTER_ID_ROW:
        return {
          mappedColumns,
          rowType,
          key: key ? key : generateId(),
          audienceColumnName: audienceColumnName || MASTER_ID_COLUMN_NAME,

          audienceColumnType: columnTypesEnum.SYSTEM_ID,
        };
      case mappingRowTypesEnum.MODIFIED_AT_ROW:
        return {
          mappedColumns,
          rowType,
          key: key ? key : generateId(),
          audienceColumnName: audienceColumnName || MODIFIED_AT_COLUMN_NAME,
          audienceColumnType: audienceColumnType || columnTypesEnum.STRING,
          updatedAtKey: true,
        };
      case mappingRowTypesEnum.DEFAULT:
        return {
          canDelete,
          primaryKey,
          updatedAtKey,
          mappedColumns,
          rowType,
          key: key ? key : generateId(),
          audienceColumnName: audienceColumnName || '',
          audienceColumnType: audienceColumnType || columnTypesEnum.STRING,
        };
      case mappingRowTypesEnum.AGGREGATE_ROW:
        return {
          canDelete,
          primaryKey,
          updatedAtKey,
          mappedColumns,
          rowType,
          key: key ? key : generateId(),
          audienceColumnName: audienceColumnName || '',
          audienceColumnType: audienceColumnType || columnTypesEnum.STRING,
        };
      case mappingRowTypesEnum.SOURCE_ID_LIST:
        return {
          canDelete,
          primaryKey,
          updatedAtKey,
          mappedColumns,
          rowType,
          key: key ? key : generateId(),
          sourceId: '',
          audienceColumnName: audienceColumnName || '',
          audienceColumnType: audienceColumnType || columnTypesEnum.STRING,
        };
    }
  },
  saveMappingRowColumn: (oldMappingRows, rowKey, sourceKey, column) => {
    const newMappingRows: MappingRow[] = oldMappingRows.reduce((result: MappingRow[], row) => {
      if (row.key === rowKey) {
        const newMappedColumnsValues: MappedColumns = {
          ...row.mappedColumns,
          [sourceKey]: {
            ...row.mappedColumns[sourceKey],
            ...column,
          },
        };
        result.push({ ...row, mappedColumns: newMappedColumnsValues });
      } else {
        result.push(row);
      }
      return result;
    }, []);
    return newMappingRows;
  },
  deleteMappingRows: (oldMappingRows, rowKey) => {
    const newMappingRow: MappingRow[] = oldMappingRows.filter((row) => row.key !== rowKey);
    return newMappingRow;
  },
  // TODO @@@@koralex refactor/split to 2 functions and call them inside
  addSystemMappingRows: (currentMappingRows, sourcesConfig) => {
    const masterIdColumnName: string = MASTER_ID_COLUMN_NAME;
    const modifiedAtColumnName: string = MODIFIED_AT_COLUMN_NAME;
    const masterIdMappedColumns: MappedColumns = sourcesConfig.reduce(
      (result: MappedColumns, sourceConfig) => {
        if (
          sourceConfig.dataType === dataTypesEnum.UNION ||
          (sourceConfig.dataType === dataTypesEnum.JOIN &&
            sourceConfig.condition.type !== JoinedAudienceSourceConditionType.regular)
        ) {
          return {
            ...result,
            [sourceConfig.key]: {
              columnId: '',
              columnName: '',
              primaryKey: false,
              updatedAtKey: false,
              columnDisplay: columnDisplayEnum.HIDDEN,
            },
          };
        }

        const masterIdJoinCondition =
          sourceConfig.condition.type === JoinedAudienceSourceConditionType.regular
            ? sourceConfig.condition.columns.find(
                ({ type }) => type === JoinedAudienceSourceColumnConditionType.master_id
              )
            : undefined;
        const masterIdSourceColumnForJoin = sourceConfig.selectedColumns.find(
          ({ id }) => id === masterIdJoinCondition?.sourceColumnId
        );

        return {
          ...result,
          [sourceConfig.key]: {
            columnId: masterIdSourceColumnForJoin?.id || '',
            columnName: masterIdSourceColumnForJoin?.name || '',
            columnType: masterIdSourceColumnForJoin
              ? getSourceColumnType(masterIdSourceColumnForJoin)
              : undefined,
            columnDisplay: columnDisplayEnum.HIDDEN,
            primaryKey: false,
            updatedAtKey: false,
          },
        };
      },
      {}
    );
    const modifiedAtMappedColumns: MappedColumns = sourcesConfig.reduce(
      (result: MappedColumns, sourceConfig) => {
        const updatedAtColumn: Column | undefined = sourceConfig.selectedColumns.find(
          (column) => column.id === sourceConfig.dataSource.updatedAtColumnId
        );
        const regexColumnName =
          (sourceConfig.dataSource.type === sourceTypesEnum.CONNECTION &&
            sourceConfig.dataSource.sourceSettings?.type === ConnectorType.sftp &&
            sourceConfig.dataSource?.sourceSettings?.modifiedAtColumn?.type ===
              sftpModifiedAtColumnSource.fileName &&
            sourceConfig.dataSource?.sourceSettings?.modifiedAtColumn.columnName) ||
          '';
        return {
          ...result,
          [sourceConfig.key]:
            sourceConfig.dataType === dataTypesEnum.UNION && updatedAtColumn
              ? {
                  columnId: updatedAtColumn.id,
                  columnName: updatedAtColumn.name,
                  primaryKey: false,
                  updatedAtKey: true,
                  columnType: getSourceColumnType(updatedAtColumn),
                  columnDisplay: columnDisplayEnum.DISABLED,
                }
              : sourceConfig.dataType === dataTypesEnum.UNION && regexColumnName
              ? {
                  columnId: '',
                  columnName: regexColumnName,
                  primaryKey: false,
                  updatedAtKey: true,
                  columnType: columnTypesEnum.TIMESTAMP,
                  columnDisplay: columnDisplayEnum.DISABLED,
                }
              : {
                  columnId: '',
                  columnName: '',
                  primaryKey: false,
                  updatedAtKey: true,
                  columnType: columnTypesEnum.TIMESTAMP,
                  columnDisplay: columnDisplayEnum.LONG_LINE,
                },
        };
      },
      {}
    );
    const masterIdMappingRow: MasterIdMappingRow = mappingHelpers.generateNewMappingRow(
      mappingRowTypesEnum.MASTER_ID_ROW,
      masterIdMappedColumns,
      false,
      MASTER_ID_COLUMN_ID_FOR_JOIN,
      masterIdColumnName,
      columnTypesEnum.SYSTEM_ID
    ) as MasterIdMappingRow;
    const modifiedAtMappingRow: ModifiedAtMappingRow = mappingHelpers.generateNewMappingRow(
      mappingRowTypesEnum.MODIFIED_AT_ROW,
      modifiedAtMappedColumns,
      false,
      undefined,
      modifiedAtColumnName,
      columnTypesEnum.TIMESTAMP,
      undefined,
      true
    ) as ModifiedAtMappingRow;
    return [
      masterIdMappingRow,
      modifiedAtMappingRow,
      ...currentMappingRows.filter(
        (x) =>
          x.rowType !== mappingRowTypesEnum.MASTER_ID_ROW &&
          x.rowType !== mappingRowTypesEnum.MODIFIED_AT_ROW
      ),
    ];
  },
  updateAggregateMappingRow: (aggregateMappingRow, activeSourceId) => {
    const mappedColumns: MappedColumns = {
      ...aggregateMappingRow.mappedColumns,
      [activeSourceId]: {
        columnId: '',
        columnName: '',
        primaryKey: false,
        updatedAtKey: false,
        columnDisplay:
          aggregateMappingRow.mappedColumns[activeSourceId]?.columnDisplay !==
          columnDisplayEnum.HIDDEN
            ? columnDisplayEnum.LONG_LINE
            : columnDisplayEnum.HIDDEN,
      },
    };
    return { ...aggregateMappingRow, mappedColumns };
  },
  prepareAudienceColumnName: (audienceColumnName) => {
    return toSnakeCase(audienceColumnName);
  },
  prepareAggregateColumnName: (columnName, aggregate) => {
    let str = limitTextDisplay(columnName, aggregate?.excludeNullValues ? 6 : 9);
    if (aggregate?.function) {
      str += `_${aggregate.function}`;
    }
    if (aggregate?.datePeriod) {
      str += `_${aggregate.datePeriod}`;
    }
    if (aggregate?.excludeNullValues) {
      str += `_nn`;
    }
    str = toSnakeCase(str);
    return str;
  },
  hideMappedColumnsHandler: (sourcesConfig, activeSourceCombinationLevel) => {
    const sourcesKeys: { afterActiveSource: boolean; key: string }[] = sourcesConfig.map(
      (sourceConfig) => ({
        afterActiveSource: activeSourceCombinationLevel < sourceConfig.combinationLevel,
        key: sourceConfig.key,
      })
    );
    return sourcesKeys.reduce((result: MappedColumns, source) => {
      const { key, afterActiveSource } = source;
      return {
        ...result,
        [key]: {
          ...emptyMappedColumnValues,
          columnDisplay: afterActiveSource ? columnDisplayEnum.LONG_LINE : columnDisplayEnum.HIDDEN,
        },
      };
    }, {});
  },
  checkIfPreviousMappedColumnHidden: (
    mappingRow: MappingRow,
    sources,
    activeSourceCombinationLevel: number
  ): boolean => {
    if (activeSourceCombinationLevel === 0) {
      return true;
    }

    return sources.some(
      (sourceConfig) =>
        sourceConfig.combinationLevel === activeSourceCombinationLevel - 1 &&
        mappingRow.mappedColumns[sourceConfig.key]?.columnDisplay === columnDisplayEnum.HIDDEN
    );
  },
  deleteSourceMappingColumnFromMappingRows: (
    mappingRows: MappingRow[],
    targetSourceKey: string,
    colName: string
  ) => {
    const newMappingRows: MappingRow[] = mappingRows.reduce((result: MappingRow[], row) => {
      const sourceKeys = Object.keys(row.mappedColumns);

      let newMappedColumnsValues: MappedColumns = {};
      sourceKeys.forEach((sourceKey) => {
        if (
          !(
            sourceKey === targetSourceKey &&
            row.mappedColumns[targetSourceKey].columnName === colName
          )
        ) {
          newMappedColumnsValues = {
            ...newMappedColumnsValues,
            [sourceKey]: row.mappedColumns[sourceKey],
          };
        }
      });
      result.push({ ...row, mappedColumns: newMappedColumnsValues });

      return result;
    }, []);
    return newMappingRows;
  },
  deleteSourceMappingFromMappingRows: (mapping: MappingRow[], sourceKey: string) => {
    return mapping.reduce((mappingRows: MappingRow[], mappingRow) => {
      if (
        mappingRow.rowType === mappingRowTypesEnum.AGGREGATE_ROW &&
        !!mappingRow.mappedColumns[sourceKey]?.aggregate
      ) {
        return mappingRows;
      }

      mappingRows.push({
        ...mappingRow,
        mappedColumns: omit(mappingRow.mappedColumns, [sourceKey]),
      });

      return mappingRows;
    }, []);
  },
  updateMappingRowsNamesWithSourcePreparation: (
    activeMappingRows: MappingRow[],
    renameSteps: RenameColumnPreparationAction[],
    activeSourceConfigurationKey: string
  ) => {
    const mappingRows: MappingRow[] = [...activeMappingRows];
    renameSteps.forEach((preparationAction) => {
      const mappingRow = activeMappingRows.find(
        (mappingRow) =>
          preparationAction.column.originalColumnId ===
          mappingRow.mappedColumns[activeSourceConfigurationKey].columnId
      );
      if (!mappingRow) {
        return;
      }

      const index = mappingRows.indexOf(mappingRow);
      if (index === -1) {
        return;
      }
      mappingRows.splice(index, 1);

      const updatedMappingRow = {
        ...mappingRow,
        audienceColumnName: preparationAction.column.targetColumnName,
      };
      mappingRows.push(updatedMappingRow);
    });

    return mappingRows;
  },
  updateAudienceColumnsPrimaryKey: (oldMappingRows, deduplicationKeys) =>
    oldMappingRows.map((row: MappingRow) => {
      const isPrimaryKeyRow: boolean = deduplicationKeys.some((keys) =>
        keys.columnIds.includes(row.key)
      );
      if (isPrimaryKeyRow && isDefaultMappingRow(row)) {
        return { ...row, primaryKey: true, canDelete: false };
      }
      if (isDefaultMappingRow(row)) {
        return { ...row, primaryKey: false, canDelete: true };
      }
      return row;
    }),
  prepareDefaultDeduplicationKeysFromMappingRows: (mappingRows, activeSourceConfigurationKey) => {
    const columnIds: string[] = mappingRows
      .filter((row) => row.mappedColumns[activeSourceConfigurationKey].primaryKey)
      .map((row) => row.key);
    return [{ columnIds, key: generateId() }];
  },
  checkIfSourceColumnsContainValuesInMappingRow: (row, activeSourceKey): boolean => {
    return Object.keys(row.mappedColumns).some(
      (sourceKey) => sourceKey !== activeSourceKey && !!row.mappedColumns[sourceKey].columnId
    );
  },
  setColumnDisplayForMasterIdMappingRow: (row: MasterIdMappingRow): MasterIdMappingRow => {
    const sourcesKeysArray: string[] = Object.keys(row.mappedColumns);

    const mappedColumns = sourcesKeysArray.reduce(
      (result: MappedColumns, sourceKey, currentIndex) => {
        // Init column values
        let column = row.mappedColumns[sourceKey];

        const columnName: string = column.columnName;
        const previousColumnValue = result[sourcesKeysArray[currentIndex - 1]];
        // Case normal column (for disabled display)
        const isDisabledColumnDisplay: boolean = !!columnName;

        const isLongLineDisplay: boolean = !!(
          !columnName &&
          previousColumnValue &&
          (previousColumnValue.columnName ||
            (!previousColumnValue.columnName &&
              previousColumnValue.columnDisplay === columnDisplayEnum.LONG_LINE))
        );

        const isHiddenDisplay: boolean =
          !columnName &&
          ((previousColumnValue &&
            previousColumnValue.columnName &&
            previousColumnValue.columnDisplay === columnDisplayEnum.HIDDEN) ||
            previousColumnValue === undefined);

        if (isDisabledColumnDisplay) {
          column = { ...column, columnDisplay: columnDisplayEnum.DISABLED };
        }
        if (isLongLineDisplay) {
          column = { ...column, columnDisplay: columnDisplayEnum.LONG_LINE };
        }
        if (isHiddenDisplay) {
          column = { ...column, columnDisplay: columnDisplayEnum.HIDDEN };
        }

        return { ...result, [sourceKey]: column };
      },
      {}
    );

    return { ...row, mappedColumns };
  },
  cleanRemovedSourceColumnsFromMappingRows: (
    mappingRows: MappingRow[],
    activeSourceConfiguration: ActiveSQLSourceConfiguration | ActiveSourceConfiguration
  ) => {
    let cleanedMappingRows: MappingRow[] = mappingRows;
    let cleanedSelectedColumns: Column[] = [...activeSourceConfiguration.selectedColumns];
    activeSourceConfiguration.dataSource.sourceDescription?.newShapeDetails.deletedColumns.forEach(
      (deletedCol) => {
        cleanedMappingRows = mappingHelpers.deleteSourceMappingColumnFromMappingRows(
          cleanedMappingRows,
          activeSourceConfiguration.key,
          deletedCol.name
        );
        cleanedSelectedColumns = cleanedSelectedColumns.filter((col) => col.id !== deletedCol.id);
      }
    );
    return { cleanedMappingRows, cleanedSelectedColumns };
  },
};

export default mappingHelpers;
