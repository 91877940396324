import { StrictlySchedule } from '@contracts/schedule';
import { ISyncRecordsScope } from './ISyncRecordsScope';

export enum DestinationTriggerType {
  /**
   * Triggered in reaction to another component producing a data payload.
   */
  realTime = 'realTime',

  /**
   * Triggered manually by a user
   */
  manual = 'manual',

  scheduled = 'scheduled',
}

export type IRealTimeSyncExecutionTriggerSpecification = {
  type: DestinationTriggerType.realTime;
  isThrottled?: boolean;
};
export type IManualSyncExecutionTriggerSpecification = {
  type: DestinationTriggerType.manual;
};
export type IScheduledSyncExecutionTriggerSpecification = {
  type: DestinationTriggerType.scheduled;
  recordsScope: ISyncRecordsScope;
  schedule: StrictlySchedule;
};
export type ISyncExecutionTriggerSpecification =
  | IRealTimeSyncExecutionTriggerSpecification
  | IManualSyncExecutionTriggerSpecification
  | IScheduledSyncExecutionTriggerSpecification;
