import { DeployedAudience } from '@features/audiences/types/DeployedAudience';
import { BackDeployedAudience } from '../../audienceBackTypes/deployedAudience';
import { mappingAudienceDataShape } from './mappingAudienceDataShape';
import { mappingDeployedAudienceSources } from './mappingDeployedAudienceSources';

export const toFrontDeployedAudienceType = (
  backDeployedAudience: BackDeployedAudience
): DeployedAudience => {
  return {
    id: backDeployedAudience.id,
    version: backDeployedAudience.version,
    audienceVersion: backDeployedAudience.audienceVersion,
    createdAt: backDeployedAudience.createdAt,
    updatedAt: backDeployedAudience.updatedAt,
    accountId: backDeployedAudience.accountId,
    parentAudienceId: backDeployedAudience.parentAudienceId,
    dataWarehouseId: backDeployedAudience.dataWarehouseId,
    name: backDeployedAudience.name,
    identifier: backDeployedAudience.identifier,
    businessCategory: backDeployedAudience.businessCategory,
    sources: mappingDeployedAudienceSources(backDeployedAudience.sources),
    dataShape: mappingAudienceDataShape(backDeployedAudience.dataShape),
    numberOfRecords: backDeployedAudience.numberOfRecords,
  };
};
