// Business category
export enum BusinessCategoryEnum {
  contacts = 'contacts',
  orders = 'orders',
  consents = 'consents',
  stores = 'stores',
  products = 'products',
  ordersitems = 'ordersitems',
  events = 'events',
  tickets = 'tickets',
  companies = 'companies',
  payments = 'payments',
  others = 'others',
}

export const businessCategoryDisplayValues: { [key in BusinessCategoryEnum]: string } = {
  contacts: 'Contacts',
  orders: 'Orders',
  consents: 'Consents',
  stores: 'Stores',
  products: 'Products',
  ordersitems: 'Orders items',
  events: 'Events',
  tickets: 'Tickets',
  companies: 'Companies',
  payments: 'Payments',
  others: 'Others',
};

export const businessCategorySelectInputOptions: {
  value: BusinessCategoryEnum;
  label: string;
}[] = [
  {
    value: BusinessCategoryEnum.contacts,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.contacts],
  },
  {
    value: BusinessCategoryEnum.orders,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.orders],
  },
  {
    value: BusinessCategoryEnum.consents,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.consents],
  },
  {
    value: BusinessCategoryEnum.stores,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.stores],
  },
  {
    value: BusinessCategoryEnum.products,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.products],
  },
  {
    value: BusinessCategoryEnum.ordersitems,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.ordersitems],
  },
  {
    value: BusinessCategoryEnum.events,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.events],
  },
  {
    value: BusinessCategoryEnum.tickets,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.tickets],
  },
  {
    value: BusinessCategoryEnum.companies,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.companies],
  },
  {
    value: BusinessCategoryEnum.payments,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.payments],
  },
  {
    value: BusinessCategoryEnum.others,
    label: businessCategoryDisplayValues[BusinessCategoryEnum.others],
  },
];

export interface SchemaColumn {
  id: string;
  columnName: string;
  columnType: columnTypesEnum;
  required: boolean;
  description: string;
  category: string;
  externalReference?: string;
  position: number;
  isExternalId?: boolean;
  staticValue?: string;
}

export enum columnTypesEnum {
  STRING = 'string',
  TIMESTAMP = 'timestamp',
  INTEGER = 'integer',
  FLOAT = 'float',
  BOOLEAN = 'boolean',
  JSON = 'json',
  SYSTEM_ID = 'system-id',
  STRING_ARRAY = 'string[]',
  TIMESTAMP_ARRAY = 'timestamp[]',
  INTEGER_ARRAY = 'integer[]',
  FLOAT_ARRAY = 'float[]',
  BOOLEAN_ARRAY = 'boolean[]',
  SYSTEM_ID_ARRAY = 'system-id[]',
  JSON_ARRAY = 'json[]',
}
