import { mappingColumnTypePropertyToFront } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mappingColumnTypePropertyToFront';
import {
  GetApiDestinationSchemaOutput,
  GetApiDestinationSchemaTypename,
} from '@features/syncs/types/backTypes/GetApiDestinationSchemaOutput';
import { DestinationSchema, DestinationSchemaType } from '@features/syncs/types/DestinationSchema';
import { caseNever } from '@utils/case-never';

export const mapApiDestinationSchemaToFront = (
  backApiDestinationSchema: GetApiDestinationSchemaOutput
): DestinationSchema => {
  switch (backApiDestinationSchema.__typename) {
    case GetApiDestinationSchemaTypename.ApiConnectionEntityDestinationSchema:
      return {
        type: DestinationSchemaType.API_SCHEMA,
        fields: backApiDestinationSchema.fields.map((elem) => {
          return { ...elem, type: mappingColumnTypePropertyToFront(elem.type) };
        }),
        id: backApiDestinationSchema.id,
        label: backApiDestinationSchema.label,
      };
    case GetApiDestinationSchemaTypename.ConnectionNotFound:
      throw new Error('Connection not found');
    case GetApiDestinationSchemaTypename.EntityTypeNotFound:
      throw new Error('Entity type not found');
    default:
      return caseNever(backApiDestinationSchema);
  }
};
