import { gql } from '@apollo/client';

const CREATE_AUDIENCE = gql`
  mutation CreateAudience($command: CreateAudienceCommand!) {
    createAudience(command: $command) {
      __typename
      id
      version
      createdAt
      updatedAt
      dataWarehouseId
      specification
      name
      longName
      businessCategory
      deployment {
        status
        subjectId
        subjectVersion
        subjectType
        version
        commitError
        rollBackError
        executionError
      }
    }
  }
`;
const UPDATE_AUDIENCE = gql`
  mutation ($command: UpdateAudienceCommand!) {
    updateAudience(command: $command) {
      __typename
      ... on Audience {
        id
        version
        createdAt
        updatedAt
        dataWarehouseId
        specification
        name
        longName
        businessCategory
        deployment {
          status
          subjectId
          subjectVersion
          subjectType
          version
          commitError
          rollBackError
          executionError
        }
      }
    }
  }
`;
const DELETE_AUDIENCE = gql`
  mutation ($command: DeleteAudienceCommand!) {
    deleteAudience(command: $command) {
      __typename
      ... on AudienceDeleted {
        id
      }
      ... on AudienceNotFound {
        id
      }
    }
  }
`;
const REQUEST_AUDIENCE_PREVIEW = gql`
  mutation ($command: RequestAudiencePreviewCommand!) {
    requestAudiencePreview(command: $command) {
      __typename
      audiencePreviewId
    }
  }
`;

const DESCRIBE_SQL_AUDIENCE_SOURCES_SHAPES = gql`
  mutation ($command: DescribeSqlAudienceSourcesShapesCommand!) {
    describeSqlAudienceSourcesShapes(command: $command) {
      sources {
        name
        shape
        source
      }
    }
  }
`;

const REQUEST_SQL_AUDIENCE_QUERY_DESCRIPTOR = gql`
  mutation ($command: RequestSqlAudienceQueryDescriptorCommand!) {
    requestSqlAudienceQueryDescriptor(command: $command) {
      __typename
      id
    }
  }
`;

const REQUEST_DRAFT_SOURCE = gql`
  mutation requestDraftSource($command: RequestDraftSourceCommand!) {
    requestDraftSource(command: $command) {
      draftSourceId
      __typename
    }
  }
`;
const REQUEST_AUDIENCE_EXECUTION = gql`
  mutation requestAudienceExecution($command: RequestAudienceExecutionCommand!) {
    requestAudienceExecution(command: $command) {
      __typename

      ... on AudienceExecutionRequestAccepted {
        __typename
        executionId
      }
      ... on AudienceExecutionAudienceNotFound {
        __typename
        audienceId
      }
      ... on AudienceExecutionInvalidRequest {
        __typename
        audienceId
        error
      }
      ... on AudienceRecordsScopeIsEmpty {
        __typename
        audienceId
      }
      ... on AudienceExecutionAudienceNotDeployed {
        __typename
        audienceId
      }
      ... on AudienceExecutionRequestNotAcceptedDueSubjectAlreadyTriggered {
        __typename
      }
    }
  }
`;
export {
  CREATE_AUDIENCE,
  UPDATE_AUDIENCE,
  DELETE_AUDIENCE,
  REQUEST_AUDIENCE_PREVIEW,
  REQUEST_DRAFT_SOURCE,
  DESCRIBE_SQL_AUDIENCE_SOURCES_SHAPES,
  REQUEST_SQL_AUDIENCE_QUERY_DESCRIPTOR,
  REQUEST_AUDIENCE_EXECUTION,
};
