import {
  DateBoundaryType,
  DatePeriodFilter,
  DatePeriodFilterSourceColumnType,
  DateRange,
} from '@features/audiences/ducks/api/audienceBackTypes/columnAggregations';
import { AggregateDetails, DatePeriodTypes } from '@features/audiences/types';

export const mappingDatePeriodProperty = (
  aggregation: AggregateDetails
): DatePeriodFilter | undefined => {
  const { datePeriod, filterByOtherDateColumn } = aggregation;
  if (datePeriod === undefined || datePeriod === DatePeriodTypes.all_time) {
    // all_time means no filtering at all
    return undefined;
  }
  return {
    // sourceColumnId: filterByOtherDateColumn.sourceFilterDateColumnId || modifiedAtColumnId,
    dateRange: mappingDateRangeProperty(datePeriod),
    sourceColumn: filterByOtherDateColumn.sourceFilterDateColumnId //  modifiedAtColumn id
      ? {
          columnId: filterByOtherDateColumn.sourceFilterDateColumnId,
          type: DatePeriodFilterSourceColumnType.data,
        }
      : {
          type: DatePeriodFilterSourceColumnType.modified_at,
        },
  };
};

const mappingDateRangeProperty = (
  datePeriod: Exclude<DatePeriodTypes, DatePeriodTypes.all_time>
): DateRange => {
  switch (datePeriod) {
    case DatePeriodTypes.this_year:
      return {
        start: { type: DateBoundaryType.relative, year: 0 },
        end: { type: DateBoundaryType.relative, year: 0 },
      };
    case DatePeriodTypes.last_year:
      return {
        start: { type: DateBoundaryType.relative, year: -1 },
        end: { type: DateBoundaryType.relative, year: -1 },
      };
    case DatePeriodTypes.thirty_days:
      return {
        start: { type: DateBoundaryType.relative, year: 0, month: -1, day: 0 },
        end: { type: DateBoundaryType.relative, year: 0, month: 0, day: 0 },
      };
    case DatePeriodTypes.sixty_days:
      return {
        start: { type: DateBoundaryType.relative, year: 0, month: -2, day: 0 },
        end: { type: DateBoundaryType.relative, year: 0, month: 0, day: 0 },
      };
    case DatePeriodTypes.ninety_days:
      return {
        start: { type: DateBoundaryType.relative, year: 0, month: -3, day: 0 },
        end: { type: DateBoundaryType.relative, year: 0, month: 0, day: 0 },
      };
    case DatePeriodTypes.one_twenty_days:
      return {
        start: { type: DateBoundaryType.relative, year: 0, month: -4, day: 0 },
        end: { type: DateBoundaryType.relative, year: 0, month: 0, day: 0 },
      };
    case DatePeriodTypes.one_fifty_days:
      return {
        start: { type: DateBoundaryType.relative, year: 0, month: -5, day: 0 },
        end: { type: DateBoundaryType.relative, year: 0, month: 0, day: 0 },
      };
    case DatePeriodTypes.one_eighty_days:
      return {
        start: { type: DateBoundaryType.relative, year: 0, month: -6, day: 0 },
        end: { type: DateBoundaryType.relative, year: 0, month: 0, day: 0 },
      };

    case DatePeriodTypes.three_sixty_five_days:
      return {
        start: { type: DateBoundaryType.relative, year: -1, month: 0, day: 0 },
        end: { type: DateBoundaryType.relative, year: 0, month: 0, day: 0 },
      };
  }
};
