import { ConnectorType } from '@features/connections/types';
import { DestinationEnum, IDestination } from '@features/syncs/types/Destination';
import { IDatabaseDestinationSchemaSettings } from '@features/syncs/types/DestinationSchema';
import { caseNever } from '@utils/case-never';

export const entityToDestinationSchemaSettings = (
  destination: IDestination
): IDatabaseDestinationSchemaSettings => {
  switch (destination.type) {
    case DestinationEnum.fs:
    case DestinationEnum.api:
    case DestinationEnum.webhook:
      throw new Error('Destination type is not supported');
    case DestinationEnum.db:
      switch (destination.settings.type) {
        case ConnectorType.bigQuery:
          return {
            type: ConnectorType.bigQuery,
            datasetId: destination.settings.schemaName,
            tableId: destination.settings.tableName,
            location: destination.settings.location,
          };

        case ConnectorType.postgres:
          return {
            type: ConnectorType.postgres,
            schemaName: destination.settings.schemaName,
            tableName: destination.settings.tableName,
          };

        case ConnectorType.mysql:
          return {
            type: ConnectorType.mysql,
            schemaName: destination.settings.schemaName,
            tableName: destination.settings.tableName,
          };

        default:
          return caseNever(destination.settings);
      }

    default:
      return caseNever(destination);
  }
};
