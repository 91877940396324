import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getTokenSilently } from '@providers/Auth0Provider';
import {
  getFrontendTestingSignInRequest,
  hasFrontendTestingSignInRequest,
} from '@features/frontend-e2e-testing/frontend-testing';

const apolloClientInstance = async ({
  abortSignal,
  traceparent,
}: { abortSignal?: AbortSignal; traceparent?: string } = {}) => {
  let accessToken: string | null;
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
    fetchOptions: { signal: abortSignal },
    headers: traceparent
      ? {
          traceparent: traceparent,
        }
      : undefined,
  });

  if (hasFrontendTestingSignInRequest()) {
    const request = getFrontendTestingSignInRequest();
    if (request) {
      accessToken = request.accessToken;
    }
  } else {
    try {
      accessToken = await getTokenSilently();
    } catch (error) {
      console.log('Unable to get an access token silently', error);
    }
  }

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return apolloClient;
};
export default apolloClientInstance;
