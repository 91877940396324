import { call, put, select, takeLatest } from 'redux-saga/effects';
// api
import * as API from './api/api';
// connectionsSlice
import {
  ApiDeleteConnectionPayload,
  ApiSaveConnectionPayload,
  ApiTestConnectionPayload,
  DeleteConnectionSaga,
  GetConnectionPointersSaga,
  GetConnectionSaga,
  SaveConnectionSaga,
  TestSettingsSaga,
  UpdateConnectionSaga,
} from '../../connections/ducks/types';
import connectionsSlice from '../../connections/ducks/connectionsSlice';
import { activeAccountIdStateSelector, activeConnectionSelector } from '@redux/selectors';
import { ActiveConnection, Connection } from '../../connections/types';
import { isEqual } from 'lodash';

export function* getConnectionsSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const connectionsResult: Connection[] = yield call(API.getConnections, accountId);
    yield put({
      type: connectionsSlice.actions.getConnectionsSuccess.type,
      payload: { connections: connectionsResult },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: connectionsSlice.actions.getConnectionsFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}
function* getConnectionSaga({ payload }: GetConnectionSaga) {
  try {
    const { id } = payload;
    const connectionResult: Connection = yield call(API.getConnection, id);
    yield put({
      type: connectionsSlice.actions.getConnectionSuccess.type,
      payload: { connection: connectionResult },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: connectionsSlice.actions.getConnectionFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}
function* updateConnectionSaga({ payload }: UpdateConnectionSaga) {
  try {
    const { connection } = payload;
    const result: ApiSaveConnectionPayload = yield call(API.updateConnection, connection);
    yield put({
      type: connectionsSlice.actions.updateConnectionSuccess.type,
      payload: result,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: connectionsSlice.actions.updateConnectionFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}

function* deleteConnectionSaga({ payload }: DeleteConnectionSaga) {
  const { connection } = payload;
  const result: ApiDeleteConnectionPayload = yield call(API.deleteConnection, connection);
  if (result.error) {
    yield put({
      type: connectionsSlice.actions.deleteConnectionFailed.type,
      payload: result,
    });
  } else {
    yield put({
      type: connectionsSlice.actions.deleteConnectionSuccess.type,
      payload: result,
    });
  }
}
function* testSettingsSaga({ payload }: TestSettingsSaga) {
  try {
    const { settings } = payload;
    const result: ApiTestConnectionPayload = yield call(API.testSettings, settings);
    yield put({
      type: connectionsSlice.actions.testSettingsSuccess.type,
      payload: result,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: connectionsSlice.actions.testSettingsFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}
function* saveSourceConnectionSaga({ payload }: SaveConnectionSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const { connection } = payload;
    const result: ApiSaveConnectionPayload = yield call(API.saveConnection, connection, accountId);
    yield put({
      type: connectionsSlice.actions.saveConnectionSuccess.type,
      payload: result,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: connectionsSlice.actions.saveConnectionFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}

function* getConnectionPointersSaga({ payload }: GetConnectionPointersSaga) {
  try {
    const { connection } = payload;
    const result: ApiSaveConnectionPayload = yield call(API.getConnectionPointer, connection);
    yield put({
      type: connectionsSlice.actions.getConnectionPointersSuccess.type,
      payload: result,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: connectionsSlice.actions.getConnectionPointersFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}
function* updateActiveConnectionSaga({ payload }: GetConnectionPointersSaga) {
  try {
    const activeConnection: ActiveConnection = yield select(activeConnectionSelector);

    const { connection } = payload;
    yield put({
      type: connectionsSlice.actions.updateActiveConnectionPayload.type,
      payload: {
        connection: {
          ...connection,
          status: !isEqual(connection.settings, activeConnection.settings)
            ? undefined
            : connection.status,
        },
      },
    });
  } catch (e) {
    yield put({
      type: connectionsSlice.actions.updateActiveConnectionError.type,
      payload: { errorDetails: e },
    });
  }
}

export const connectionsSagas = [
  takeLatest(connectionsSlice.actions.getConnections.type, getConnectionsSaga),
  takeLatest(connectionsSlice.actions.getConnection.type, getConnectionSaga),
  takeLatest(connectionsSlice.actions.updateConnection.type, updateConnectionSaga),
  takeLatest(connectionsSlice.actions.updateActiveConnection.type, updateActiveConnectionSaga),
  takeLatest(connectionsSlice.actions.saveSourceConnection.type, saveSourceConnectionSaga),
  takeLatest(connectionsSlice.actions.deleteConnection.type, deleteConnectionSaga),
  takeLatest(connectionsSlice.actions.testSettings.type, testSettingsSaga),
  takeLatest(connectionsSlice.actions.getConnectionPointers.type, getConnectionPointersSaga),
];
