import { ConnectorType } from '../../../../connections/types';

export enum SFTPProtocolType {
  ftp = 'ftp',
  ftps = 'ftps',
  sftp = 'sftp',
}
export interface SFTPConnectionSettings {
  type: ConnectorType.sftp;
  host: string;
  port: number;
  user: string;
  password: string;
  protocol: SFTPProtocolType;
  sshKey: string;
}

export interface ActiveSFTPConnectionSettings {
  type: ConnectorType.sftp;
  host?: string;
  port?: number;
  user?: string;
  password?: string;
  protocol?: SFTPProtocolType;
  sshKey?: string;
}
