export type ParentAudience = {
  audienceId: string;
  join: ParentAudienceJoin;
  conditions: ParentAudienceCondition[];
  draftSourceId?: string;
};

export type ParentAudienceJoin = {
  childMatchColumnId: string;
  childAllMatchesColumnId: string;
  outputColumnName: string;
  parentColumn: ParentAudienceJoinParentColumnInputType;
};

export enum ParentAudienceJoinParentColumnType {
  masterid = 'masterid', // Joining master-id
  custom = 'custom', // Joining a custom column
}
export type ParentAudienceJoinParentColumnMasterIdInputType = {
  type: ParentAudienceJoinParentColumnType.masterid;
};

export type ParentAudienceJoinParentColumnCustomInputType = {
  type: ParentAudienceJoinParentColumnType.custom;

  columnId: string;
};

export type ParentAudienceJoinParentColumnInputType =
  | ParentAudienceJoinParentColumnMasterIdInputType
  | ParentAudienceJoinParentColumnCustomInputType;

export type ParentAudienceRegularConditionColumnMappingInputType = {
  childColumnId: string;

  parentColumnId: string;
};

export enum ParentAudienceConditionType {
  regular = 'regular',
  source_id_list = 'source_id_list',
}

export type ParentAudienceRegularConditionInputType = {
  type: ParentAudienceConditionType.regular;
  columns: ParentAudienceRegularConditionColumnMappingInputType[];
};

// Source-Id-List Condition

export type ParentAudienceSourceIdListConditionInputType = {
  type: ParentAudienceConditionType.source_id_list;

  childColumnId: string;

  parentSourceId: string;

  parentSourceIdColumnId: string;
};

// Combined Condition

export type ParentAudienceCondition =
  | ParentAudienceRegularConditionInputType
  | ParentAudienceSourceIdListConditionInputType;