import { ActiveDefaultAudience } from '@features/audiences/types';
import {
  AudienceSpecificationType,
  DefaultAudienceSpecification,
  SqlAudienceSpecification,
} from '../../../audienceBackTypes/audienceSpecification';
import { mappingAudienceColumnsProperty } from './mappingColumns';
import { mappingSourcesMappedColumns } from './mappingColumns/mappingSourcesMappedColumns';
import { mapBaseSourceConfigToDataSource, mappingSourcesProperty } from './mappingSourcesProperty';
import mappingPreparationStepsToBack from '../mappingPreparationStepsToBack';
import { ActiveSQLAudience } from '@features/audiences/types/ActiveAudience/ActiveAudience';
import { mappingColumnTypePropertyToBack } from '@features/audiences/ducks/api/mappingAudienceTypes/toBackType/mappingAudienceSpecificationProperty/mappingColumnTypePropertyToBack';
import { mappingParentAudienceToBack } from '@features/audiences/ducks/api/mappingAudienceTypes/toBackType/mappingAudienceSpecificationProperty/mappingParentAudienceToBack';
import { mappingDeduplicationKeysToBack } from '@features/audiences/ducks/api/mappingAudienceTypes/toBackType/mappingAudienceSpecificationProperty/mappingDeduplicationKeysToBack';
import { mappingDataApiPreparationToBack } from './mappingDataApiPreparationToBack';

export const mappingDefaultAudienceSpecificationProperty = (
  audience: ActiveDefaultAudience
): DefaultAudienceSpecification => {
  const sourcesConfig = mappingSourcesProperty(audience);
  const frontPreparationConfigs = audience.preparationSteps.map(
    (frontPreparation) => frontPreparation.configuration
  );

  let audienceSpecification: DefaultAudienceSpecification = {
    type: AudienceSpecificationType.default,
    columns: mappingAudienceColumnsProperty(audience.mappingRows),
    parent: mappingParentAudienceToBack(audience.parent),
    sources: {
      joined: sourcesConfig.joined,
      unioned: sourcesConfig.unioned,
    }, // Mapping sources values (joined and unioned)
    deduplicationSettings: {
      idsOfColumnValuesToBePreserved: audience.deduplicationSettings.preservedValuesKeys,
      keys: mappingDeduplicationKeysToBack(audience.deduplicationSettings.deduplicationKeys),
    },
    immutable: audience.immutable,
    preparationSteps: mappingPreparationStepsToBack(frontPreparationConfigs),
    dataApiEnabled: audience.dataApiEnabled,
    dataApiProtectedColumns: audience.dataApiProtectedColumns,
    dataApiMandatoryColumns: audience.dataApiMandatoryColumns,
    dataApiPreparations: mappingDataApiPreparationToBack(audience.dataApiPreparations),
    schedule: audience.schedule,
    businessModel: audience.businessModel,
  };
  // Mapping unioned/joined/aggregated sources MappedColumns
  audienceSpecification = {
    ...audienceSpecification,
    sources: mappingSourcesMappedColumns(
      audienceSpecification.sources,
      audience,
      sourcesConfig.sourceConfigurationIdsToSourceIds
    ),
  };
  return audienceSpecification;
};

export const mappingSqlAudienceSpecificationProperty = (
  audience: ActiveSQLAudience
): SqlAudienceSpecification => {
  const sourcesConfig = audience.sources.map((source) => ({
    source: mapBaseSourceConfigToDataSource(source),
  }));

  const audienceSpecification: SqlAudienceSpecification = {
    type: AudienceSpecificationType.sql,
    sources: sourcesConfig,
    deduplicationSettings: {
      idsOfColumnValuesToBePreserved: audience.deduplicationSettings.preservedValuesKeys,
      keys: mappingDeduplicationKeysToBack(audience.deduplicationSettings.deduplicationKeys),
    },
    immutable: audience.immutable,

    columns: audience.columns.map((col) => ({
      id: col.id,
      name: col.name,
      type: mappingColumnTypePropertyToBack(col.type),
    })),
    modifiedAtColumnId: audience.modifiedAtColumnId || '',
    sqlMode: audience.mode,
    sqlQuery: audience.sqlQuery || '',
    dataApiEnabled: audience.dataApiEnabled,
    schedule: audience.schedule,
  };

  return audienceSpecification;
};
