import { SourceJoinCondition } from '@features/audiences/types';
import { generateId } from '@utils/helpers';
import {
  JoinedAudienceSourceColumnConditionType,
  JoinedAudienceSourceCondition,
  JoinedAudienceSourceConditionType,
} from '../../audienceBackTypes/audienceSpecification';
import { caseNever } from '@utils/case-never';
import { MASTER_ID_COLUMN_ID_FOR_JOIN } from '@features/audiences/helpers/constants/joinSourceMapping';

// Mapping joinCondition type from back type to front
export const mappingJoinConditionProperty = (
  condition: JoinedAudienceSourceCondition
): SourceJoinCondition => {
  switch (condition.type) {
    case JoinedAudienceSourceConditionType.regular:
      return {
        type: JoinedAudienceSourceConditionType.regular,
        columns: condition.columns.map((condition) => ({
          key: generateId(),
          ...condition,
          audienceColumnId: condition.type === JoinedAudienceSourceColumnConditionType.master_id ? MASTER_ID_COLUMN_ID_FOR_JOIN : condition.audienceColumnId,
        })),
      };
    case JoinedAudienceSourceConditionType.source_id_list:
      return {
        type: JoinedAudienceSourceConditionType.source_id_list,
        sourceColumnId: condition.sourceColumnId,
        sourceId: condition.sourceId,
        sourceIdColumnId: condition.sourceIdColumnId,
      };
    default:
      return caseNever(condition);
  }
};
