import {
    ModifiedAtNullValuesStrategy,
    ModifiedAtOnlyChangeStrategy,
    ModifiedAtOptions
} from '@features/audiences/types';

export const getDefaultModifiedAtOptions = (): ModifiedAtOptions =>
    ({
        nullValues: ModifiedAtNullValuesStrategy.set_current_time,
        onlyChange: ModifiedAtOnlyChangeStrategy.silent_update,
    });