import { ReducerStates } from './reducers';

const activeAccountIdStateSelector = (state: ReducerStates) => state.account.activeAccountId;
const activeAccountDetailsStateSelector = (state: ReducerStates) => state.account.accountsDetails;

const activeDataWarehouseIdStateSelector = (state: ReducerStates) =>
  state.dataWarehouses.activeDataWarehouseId;

const audienceSelector = (state: ReducerStates) => state.audiences.activeAudience;
const deployedAudiencesSelector = (state: ReducerStates) => state.audiences.deployedAudiences;
const audiencesSelector = (state: ReducerStates) => state.audiences.audiences;
const connectionsSelector = (state: ReducerStates) => state.connections.connections;
const activeSourceConfigurationAudienceSelector = (state: ReducerStates) =>
  state.audiences.activeSourceConfiguration;
const activeSqlSourceConfigurationAudienceSelector = (state: ReducerStates) =>
  state.audiences.activeSQLSourceConfiguration;
const activeAudienceSelector = (state: ReducerStates) => state.audiences.activeAudience;
const activeSqlAudienceSelector = (state: ReducerStates) => state.audiences.activeSQLAudience;
const sqlAudienceStepsSelector = (state: ReducerStates) => state.audiences.sqlAudienceSteps;
const sqlAudienceQueryDescriptorSelector = (state: ReducerStates) =>
  state.audiences.sqlAudienceQueryDescriptor;
const audiencePreviewSelector = (state: ReducerStates) => state.audiences.audiencePreview;
const organizationByDomainSelector = (state: ReducerStates) =>
  state.organizations.organizationByDomain?.organization;
const activeConnectionSelector = (state: ReducerStates) => state.connections.activeConnection;

const appContextSelector = (state: ReducerStates) => state.appContext.appContext;
const businessTermsSelector = (state: ReducerStates) =>
  state.audiences.listBusinessTerms.businessTerms;

export {
  activeAccountIdStateSelector,
  activeDataWarehouseIdStateSelector,
  audiencesSelector,
  connectionsSelector,
  deployedAudiencesSelector,
  activeAudienceSelector,
  audienceSelector,
  activeSourceConfigurationAudienceSelector,
  activeSqlAudienceSelector,
  sqlAudienceStepsSelector,
  sqlAudienceQueryDescriptorSelector,
  audiencePreviewSelector,
  activeSqlSourceConfigurationAudienceSelector,
  organizationByDomainSelector,
  appContextSelector,
  activeAccountDetailsStateSelector,
  activeConnectionSelector,
  businessTermsSelector,
};
