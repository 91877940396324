import { columnTypesEnum } from '@features/objects/types';
import { DataType, IColumnType } from '../../audienceBackTypes/columnType';

export const mappingColumnTypePropertyToFront = (columnType: IColumnType) => {
  const columnTypeDataType = columnType.dataType;
  switch (columnTypeDataType) {
    case DataType.String:
      return columnTypesEnum.STRING;
    case DataType.Timestamp:
      return columnTypesEnum.TIMESTAMP;
    case DataType.Boolean:
      return columnTypesEnum.BOOLEAN;
    case DataType.Integer:
      return columnTypesEnum.INTEGER;
    case DataType.Float:
      return columnTypesEnum.FLOAT;
    case DataType.Json:
      return columnTypesEnum.JSON;
    case DataType.SystemId:
      return columnTypesEnum.SYSTEM_ID;
    case DataType.Array:
      if (columnType.itemType.dataType === DataType.Timestamp) {
        return columnTypesEnum.TIMESTAMP_ARRAY;
      }
      if (columnType.itemType.dataType === DataType.Integer) {
        return columnTypesEnum.INTEGER_ARRAY;
      }
      if (columnType.itemType.dataType === DataType.Float) {
        return columnTypesEnum.FLOAT_ARRAY;
      }
      if (columnType.itemType.dataType === DataType.Boolean) {
        return columnTypesEnum.BOOLEAN_ARRAY;
      }
      if (columnType.itemType.dataType === DataType.String) {
        return columnTypesEnum.STRING_ARRAY;
      }
      if (columnType.itemType.dataType === DataType.SystemId) {
        return columnTypesEnum.SYSTEM_ID_ARRAY;
      }
      if (columnType.itemType.dataType === DataType.Json) {
        return columnTypesEnum.JSON_ARRAY;
      }
      throw new Error(
        `item type: ${columnType.itemType.dataType} of an array doesn't exist in the mapping`
      );

    default: {
      throw new Error(`column type: ${columnTypeDataType} doesn't exist in the mapping`);
    }
  }
};
