import { call, put, select, takeLatest } from 'redux-saga/effects';
// api
import * as API from './api';
// connectionsSlice
// types
import * as types from './types';
import accountSlice from '@features/account/ducks/accountSlice';
import { activeAccountDetailsStateSelector } from '@redux/selectors';
import { AccountDetails } from '@features/account/types';
import { getAccessibleFeatures, getVisibleFeatures } from '@features/rightsManagement';

function* getAccountSaga({ payload }: types.GetAccountSaga) {
  try {
    const { name } = payload;

    const getAccountPayload: types.AccountState = yield call(API.getAccount, name);
    yield put({ type: accountSlice.actions.getAccountSuccess.type, payload: getAccountPayload });
  } catch (error) {
    yield put({ type: accountSlice.actions.getAccountFailed.type, payload: error });
  }
}

function* saveAccountSaga({ payload }: types.SaveAccountSaga) {
  try {
    const { account } = payload;
    const saveAccountPayload: types.AccountState = yield call(API.saveAccount, account);
    yield put({ type: accountSlice.actions.saveAccountSuccess.type, payload: saveAccountPayload });
  } catch (error) {
    yield put({ type: accountSlice.actions.saveAccountFailed.type, payload: error });
  }
}

export function* getAccountsSaga() {
  try {
    const saveAccountPayload: types.AccountDetailsState = yield call(API.getAccounts);
    yield put({ type: accountSlice.actions.getAccountsSuccess.type, payload: saveAccountPayload });
  } catch (error) {
    yield put({ type: accountSlice.actions.getAccountsFailed.type, payload: error });
  }
}
export function* updateActiveAccountSaga({ payload }: types.UpdateActiveAccountSaga) {
  const accountsDetails: AccountDetails[] = yield select(activeAccountDetailsStateSelector);
  const accountDetails = accountsDetails.find((account) => account.id === payload.activeAccountId);
  try {
    const visibleFeatures = getVisibleFeatures(accountDetails);
    const accessibleFeatures = getAccessibleFeatures(accountDetails);

    yield put({
      payload: {
        activeAccountId: payload.activeAccountId,
        visibleFeatures: visibleFeatures,
        accessibleFeatures: accessibleFeatures,
      },
      type: accountSlice.actions.updateActiveAccountSuccess.type,
    });
  } catch (error) {
    yield put({ type: accountSlice.actions.updateActiveAccountFailed.type, payload: error });
  }
}

export const accountsSagas = [
  takeLatest(accountSlice.actions.getAccount.type, getAccountSaga),
  takeLatest(accountSlice.actions.saveAccount.type, saveAccountSaga),
  takeLatest(accountSlice.actions.getAccounts.type, getAccountsSaga),
  takeLatest(accountSlice.actions.updateActiveAccount.type, updateActiveAccountSaga),
];
