import React, { createContext, useMemo } from 'react';
import { Profile, RightsType } from '@features/profiles/types';
import { ReducerStates } from '@redux/reducers';
import { connect } from 'react-redux';
import { extractObjectKeys } from '@utils/helpers';
import { Features } from '@features/app/types';

type ProfileContextType = {
  type: 'admin' | 'marketing' | 'data' | 'user';
  rights: RightsType;
  visibleFeatures: Features[];
  accessibleFeatures: Features[];
};

export const ProfileContext = createContext<ProfileContextType>({
  type: 'user',
  rights: {
    connections: [],
    billing: [],
    dashboard: [],
    audiences: [],
    datasets: [],
    syncs: [],
    destinations: [],
    users: [],
    accountManagement: [],
  },
  visibleFeatures: [],
  accessibleFeatures: [],
});

const mapRightsStateToProps = (state: ReducerStates) => {
  return {
    profile: state.profiles.profile,
    accountVisibleFeatures: state.account.account.visibleFeatures,
    accountAccessibleFeatures: state.account.account.accessibleFeatures,
    AppAccessibleFeatures: state.appContext.appContext.accessibleFeatures,
    AppVisibleFeatures: state.appContext.appContext.visibleFeatures,
  };
};

interface RightsContextProps {
  children: JSX.Element;
  profile: Profile;
  accountVisibleFeatures: Features[];
  accountAccessibleFeatures: Features[];
  AppAccessibleFeatures: Features[];
  AppVisibleFeatures: Features[];
}

const RightsContainer = ({
  children,
  profile,
  accountVisibleFeatures,
  accountAccessibleFeatures,
  AppAccessibleFeatures,
  AppVisibleFeatures,
}: RightsContextProps) => {
  const appRights: RightsType = useMemo(() => {
    const profileRights = profile.rights;
    const profileFeaturesRights = extractObjectKeys(profile.rights);
    const accessibleFeatures = accountAccessibleFeatures
      ? accountAccessibleFeatures
      : AppAccessibleFeatures;
    const visibleFeatures = accountVisibleFeatures ? accountVisibleFeatures : AppVisibleFeatures;
    return profileFeaturesRights.reduce(
      (result: RightsType, feature) => {
        if (
          profileRights[feature] &&
          accessibleFeatures.includes(feature) &&
          visibleFeatures.includes(feature)
        ) {
          return { ...result, [feature]: profileRights[feature] };
        }
        return result;
      },
      {
        connections: [],
        billing: [],
        dashboard: [],
        audiences: [],
        datasets: [],
        syncs: [],
        destinations: [],
        users: [],
        accountManagement: [],
      }
    );
  }, [
    profile,
    accountVisibleFeatures,
    accountAccessibleFeatures,
    AppAccessibleFeatures,
    AppVisibleFeatures,
  ]);

  return (
    <ProfileContext.Provider
      value={{
        type: profile.type,
        rights: appRights,
        visibleFeatures: accountVisibleFeatures,
        accessibleFeatures: accountAccessibleFeatures,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
export const ProfileContextProvider = connect(mapRightsStateToProps)(RightsContainer);
