import { gql } from '@apollo/client';

const GET_AUDIENCES = gql`
  query ($accountId: String!) {
    audiences(accountId: $accountId) {
      id
      version
      createdAt
      updatedAt
      dataWarehouseId
      specification
      name
      longName
      businessCategory
      deployment {
        status
        subjectId
        subjectVersion
        subjectType
        version
        commitError
        rollBackError
        executionError
      }
      lastExecution {
        execution {
          id
          createdAt
          startedAt
          endedAt
          status
          abortedBy
        }
      }
    }
  }
`;
const GET_AUDIENCE_TABLE_LIST = gql`
  query ($accountId: String!) {
    audiences(accountId: $accountId) {
      id
      version
      createdAt
      updatedAt
      dataWarehouseId
      specification
      name
      longName
      businessCategory
      deployment {
        status
        subjectId
        subjectVersion
        subjectType
        version
        commitError
        rollBackError
        executionError
      }
      lastExecution {
        execution {
          id
          createdAt
          startedAt
          endedAt
          status
          abortedBy
        }
      }
    }
    deployedAudiences(accountId: $accountId) {
      id
      name
      numberOfRecords
    }
  }
`;
const GET_AUDIENCE = gql`
  query audience($id: String!) {
    audience(id: $id) {
      id
      name
      longName
      businessCategory
      version
      createdAt
      updatedAt
      dataWarehouseId
      accountId
      specification
      deployment {
        status
        subjectId
        subjectVersion
        subjectType
        version
        commitError
        rollBackError
        executionError
      }
      lastExecution {
        execution {
          id
          createdAt
          startedAt
          endedAt
          status
          abortedBy
        }
      }
    }
  }
`;
const GET_DEPLOYED_AUDIENCES = gql`
  query ($accountId: String!) {
    deployedAudiences(accountId: $accountId) {
      id
      version
      accountId
      createdAt
      updatedAt
      dataWarehouseId
      name
      businessCategory
      numberOfRecords
      parentAudienceId
      sources {
        ... on DeployedAudienceDataSource {
          type
          audienceId
        }
        ... on DeployedConnectionDataSource {
          type
          id
          connectionId
        }
      }
      dataShape
    }
  }
`;
const GET_DEPLOYED_AUDIENCE = gql`
  query ($id: String!) {
    deployedAudience(id: $id) {
      id
      version
      accountId
      createdAt
      updatedAt
      dataWarehouseId
      name
      businessCategory
      numberOfRecords
      sources {
        ... on DeployedAudienceDataSource {
          type
          audienceId
        }
        ... on DeployedConnectionDataSource {
          type
          id
          connectionId
        }
      }
      dataShape
    }
  }
`;
const GET_AUDIENCE_DATA = gql`
  query ($audienceId: String!) {
    audienceData(id: $audienceId) {
      id
      accountId
      shape
      count
      data
    }
  }
`;
const GET_AUDIENCE_DATA_V2 = gql`
  query ($query: GetAudienceDataByIdQuery!) {
    audienceDataV2(query: $query) {
      __typename
      ... on AudienceData {
        id
        accountId
        shape
        count
        data
      }
      ... on AudienceDataAudienceNotFoundOrNotDeployed {
        audienceId
      }
    }
  }
`;
const GET_AUDIENCE_EXECUTIONS = gql`
  query audienceExecutions($audienceId: String!, $query: ListAudienceExecutionsQuery!) {
    audience(id: $audienceId) {
      executions(query: $query) {
        audienceId
        audienceVersion
        subject {
          __typename

          ... on AudienceConnectionSourceExecutionSubject {
            sourceId
            type
          }
          ... on AudienceAudienceSourceExecutionSubject {
            audienceId
            type
          }

          ... on EntireAudienceExecutionSubject {
            type
          }
        }
        trigger {
          __typename
          ... on ManualAudienceExecutionTrigger {
            user
            description
          }
        }
        execution {
          id
          createdAt
          startedAt
          endedAt
          status
          abortedBy
          steps {
            nodeId
            status
            error
            startedAt
            endedAt
            consumedDataPayload
            producedDataPayload
          }
        }
      }
    }
  }
`;

const AUDIENCE_PREVIEW = gql`
  query AudiencePreview($id: String!, $skip: Int!, $take: Int!) {
    audiencePreview(id: $id) {
      __typename
      id
      status
      error
      shape
      shapes
      sample(skip: $skip, take: $take)
      counter
      #draftAudienceId
    }
  }
`;

const GET_AUDIENCE_PREVIEW_SAMPLE = gql`
  query AudiencePreview($id: String!, $skip: Int!, $take: Int!) {
    audiencePreview(id: $id) {
      __typename
      sample(skip: $skip, take: $take)
    }
  }
`;

const DRAFT_SOURCE = gql`
  query draftSource($id: String!) {
    draftSource(id: $id) {
      id
      accountId
      version
      createdAt
      updatedAt
      status
      error
      request
      shape
      counter
      sample
      sampleTable
      dataWarehouseId
    }
  }
`;

const DRAFT_AUDIENCE_SOURCE = gql`
  query draftAudienceSource($draftSourceId: String!, $audienceId: String!) {
    draftSource(id: $draftSourceId) {
      id
      accountId
      version
      createdAt
      updatedAt
      status
      error
      request
      shape
      counter
      sample
      sampleTable
      dataWarehouseId
    }
    audience(id: $audienceId) {
      id
      specification
    }
    deployedAudience(id: $audienceId) {
      id
      version
      name
      identifier
      businessCategory
      sources {
        ... on DeployedAudienceDataSource {
          type
          audienceId
        }
        ... on DeployedConnectionDataSource {
          type
          id
          connectionId
        }
      }
      dataShape
    }
  }
`;
const DRAFT_SYNC_SOURCE = gql`
  query draftSyncSource($draftSourceId: String!, $syncId: String!) {
    draftSource(id: $draftSourceId) {
      id
      accountId
      version
      createdAt
      updatedAt
      status
      error
      request
      shape
      counter
      sample
      sampleTable
      dataWarehouseId
    }
    sync(id: $syncId) {
      id
      name
    }
    deployedSync(id: $syncId) {
      id
      version
      name
      destination
      audienceId
    }
  }
`;
const GET_SOURCE_ENTITIES = gql`
  query getSourceEntities($accountId: String!, $settings: JSON!) {
    getSourceEntities(accountId: $accountId, settings: $settings) {
      __typename
      ... on SftpSourceEntities {
        type
        entities {
          __typename
          ... on FileEntity {
            type
            key
            name
          }
          ... on FolderEntity {
            type
            key
            name
            children
          }
        }
      }
      ... on AudienceSourceEntities {
        type
        entities {
          __typename
          key
          name
          audienceId
        }
      }
      ... on DatabaseSourceEntities {
        type

        entities {
          __typename
          key
          name
          type
          meta
          views {
            type
            key
            name
            meta
          }
          tables {
            type
            key
            name
            meta
          }
        }
      }
      ... on SyncSourceEntities {
        type
        entities {
          type
          key
          name
          syncId
        }
      }
    }
  }
`;

const SQL_AUDIENCE_QUERY_DESCRIPTOR = gql`
  query SqlAudienceQueryDescriptor($id: String!) {
    sqlAudienceQueryDescriptor(id: $id) {
      __typename
      id
      status
      state
      error
      shape
    }
  }
`;

const LIST_BUSINESS_TERMS = gql`
  query ($query: ListBusinessTermsQuery!) {
    businessTerms(query: $query) {
      name
      version
      createdAt
      updatedAt
      label
      businessCategories
      columnConstraints {
        types
      }
    }
  }
`;

export {
  GET_AUDIENCES,
  GET_AUDIENCE_TABLE_LIST,
  GET_AUDIENCE,
  GET_AUDIENCE_EXECUTIONS,
  AUDIENCE_PREVIEW,
  GET_DEPLOYED_AUDIENCES,
  GET_DEPLOYED_AUDIENCE,
  GET_AUDIENCE_DATA,
  GET_AUDIENCE_DATA_V2,
  GET_AUDIENCE_PREVIEW_SAMPLE,
  SQL_AUDIENCE_QUERY_DESCRIPTOR,
  GET_SOURCE_ENTITIES,
  DRAFT_AUDIENCE_SOURCE,
  DRAFT_SYNC_SOURCE,
  DRAFT_SOURCE,
  LIST_BUSINESS_TERMS,
};
