export enum OperationType {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
}
export enum ProfileType {
  admin = 'admin',
  marketing = 'marketing',
  data = 'data',
  user = 'user',
}
export type Profile = {
  id: string;
  name: string;
  type: ProfileType;
  rights: RightsType;
};

export type RightsType = {
  connections: OperationType[];
  billing: OperationType[];
  dashboard: OperationType[];
  audiences: OperationType[];
  datasets: OperationType[];
  syncs: OperationType[];
  destinations: OperationType[];
  users: OperationType[];
  accountManagement: OperationType[];
};

export const PROFILE_TYPES = Object.freeze({
  ADMIN: 'admin',
  MARKETING: 'marketing',
  DATA: 'data',
  USER: 'user',
});
