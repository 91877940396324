import { CreateSyncCommand } from '@features/syncs/ducks/types/CreateSyncCommand';
import { Sync } from '@features/syncs/types';
import { SyncModel } from '@features/syncs/types/backTypes/SyncModel';
import { syncToBack } from '../syncToBack';

export const getCreateSyncCommand = (
  sync: Sync,
  accountId: string,
  dataWarehouseId: string
): CreateSyncCommand => {
  // Mapping front type to the back type
  const backSyncType: SyncModel = syncToBack(sync, accountId, dataWarehouseId); // Back type

  // Prepare CreateSyncCommand
  const createSyncCommand: CreateSyncCommand = {
    accountId,
    dataWarehouseId,
    name: backSyncType.name,
    specification: backSyncType.specification,
    isEnabled: backSyncType.isEnabled,
  };
  return createSyncCommand;
};
