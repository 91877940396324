import { Mapping } from './Mapping';
import { SyncRecordsEachTimeStrategy } from './SyncRecordsEachTimeStrategy';
import { SyncSchedule } from './SyncSchedule';

import {
  DestinationEnum,
  IApiDestinationV2,
  IDatabaseDestinationV2,
  IDestination,
  IFileSystemDestinationV2,
} from './Destination';
import { SyncSegmentation } from './SyncSegmentation';
import { ConnectorType } from '@features/connections/types';

export type ActiveSync<DestinationType extends IDestination = IDestination> = {
  key: string;
  name?: string;
  recordSyncType?: string;
  audienceId?: string;
  syncSegmentation?: SyncSegmentation;
  destinationConnectionId?: string;
  destinationConnectionType?: ConnectorType;
  syncEachTimeStrategy: SyncRecordsEachTimeStrategy;
  destination?: DestinationType;
  mapping: Mapping[];
  schedule?: SyncSchedule;
  active?: boolean;
  version?: number;
  createdAt?: Date | undefined;
  updatedAt?: Date | undefined;
};

export const isDBSync = (
  activeSync: ActiveSync
): activeSync is ActiveSync<IDatabaseDestinationV2> =>
  activeSync.destination?.type === DestinationEnum.db;

export const isFsSync = (
  activeSync: ActiveSync
): activeSync is ActiveSync<IFileSystemDestinationV2> =>
  activeSync.destination?.type === DestinationEnum.fs;

export const isAPISync = (activeSync: ActiveSync): activeSync is ActiveSync<IApiDestinationV2> =>
  activeSync.destination?.type === DestinationEnum.api;
