import { Audience } from '../../audienceBackTypes/audience';
import { AudienceSpecification } from '../../audienceBackTypes/audienceSpecification';
import { toBackType } from '../toBackType/toBackType';
import { ActiveAudience } from '@features/audiences/types/ActiveAudience/ActiveAudience';
import { BusinessCategoryEnum } from '@features/objects/types';
import { shouldCalculateMetrics } from './shouldCalculateMetrics';

export type UpdateAudienceCommand = {
  id: string;
  longName: string;
  businessCategory: BusinessCategoryEnum;
  specification: AudienceSpecification;
  postDeploymentOptions: {
    execute: boolean;
    calculateMetrics: boolean;
  };
};
export const getUpdateAudienceCommand = (
  audience: ActiveAudience,
  accountId: string,
  dataWarehouseId: string
): UpdateAudienceCommand => {
  // Mapping front type to the back type
  const backType: Audience = toBackType({
    audience,
    accountId,
    dataWarehouseId,
    version: audience.version,
  }); // Back type

  // Prepare CreateAudienceCommand
  const updateAudienceCommand: UpdateAudienceCommand = {
    id: backType.id,
    longName: backType.longName,
    businessCategory: backType.businessCategory,
    specification: backType.specification,
    postDeploymentOptions: {
      execute: true,
      calculateMetrics: shouldCalculateMetrics(accountId),
    },
  };
  return updateAudienceCommand;
};
