import {
  ColumnAggregation,
  ColumnAggregationTypeBack,
} from '@features/audiences/ducks/api/audienceBackTypes/columnAggregations';

const canBackAggregateBeSorted = (aggregate: ColumnAggregation) => {
  return (
    (aggregate.type === ColumnAggregationTypeBack.first_value ||
      aggregate.type === ColumnAggregationTypeBack.array ||
      aggregate.type === ColumnAggregationTypeBack.average_difference) &&
    aggregate.sort
  );
};

export default canBackAggregateBeSorted;
