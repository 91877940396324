import { OperationType } from '@features/profiles/types';
import { v4 as uuid } from 'uuid';
import { AbortError } from './abort-error';
import { debounce } from 'lodash';

export const sum = (a: number, b: number): number => a + b;

export const capitalizeString = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const generateId = () => uuid();

export const CompareDates = (a: string, b: string) => {
  const dateA: Date = new Date(a);
  const dateB: Date = new Date(b);

  return +dateA - +dateB;
};
export const CompareStrings = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const sleep = (ms: number, abortSignal?: AbortSignal) =>
  new Promise<void>((resolve, reject) => {
    const timer = setTimeout(() => {
      clear();
      resolve();
    }, ms);
    const clear = () => {
      clearTimeout(timer);
      abortSignal?.removeEventListener('abort', eventListener);
    };
    const eventListener = () => {
      clear();
      reject(new AbortError());
    };
    abortSignal?.addEventListener('abort', eventListener);
  });

export const poll = (
  fn: (signal: AbortSignal) => any,
  intervalInMs: number,
  signal: AbortSignal
) => {
  let isRunning = false;

  const finish = () => (isRunning = false);

  const doPoll = () => {
    if (!isRunning) {
      isRunning = true;
      Promise.resolve(fn(signal)).then(finish, finish);
    }
  };

  doPoll();
  const handle = setInterval(doPoll, intervalInMs);

  const clear = () => {
    clearInterval(handle);
    signal.removeEventListener('abort', clear);
  };

  signal.addEventListener('abort', clear);
};

export const getStatusColor = (status: string): string | undefined => {
  switch (status) {
    case 'active':
      return 'green';
    case 'broken':
      return 'volcano';
    case 'pending':
      return 'yellow';
    case 'enabled':
      return 'green';
    case 'disabled':
      return 'volcano';
    default:
      return undefined;
  }
};
export const formatOperationToRightsArray = (operation: OperationType) => {
  switch (operation) {
    case OperationType.read:
      return [OperationType.read];
    case OperationType.create:
      return [OperationType.read, OperationType.create];
    case OperationType.update:
      return [OperationType.read, OperationType.create, OperationType.update];
    case OperationType.delete:
      return [OperationType.read, OperationType.create, OperationType.update, OperationType.delete];
  }
};

export const extractObjectKeys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];
export const extractObjectValues = Object.keys as <T>(o: T) => T[keyof T][];

export const Debounce = (func: any, timeout = 1000) => {
  let handle: any = null;

  return (...args: any) => {
    if (handle !== null) {
      clearTimeout(handle);
    }

    handle = setTimeout(() => func(...args), timeout);
  };
};

export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(func: F, wait?: number) {
  const resolveSet = new Set<(p: any) => void>();
  const rejectSet = new Set<(p: any) => void>();

  const debounced = debounce((args: Parameters<F>) => {
    func(...args)
      .then((...res) => {
        resolveSet.forEach((resolve) => resolve(...res));
        resolveSet.clear();
      })
      .catch((...res) => {
        rejectSet.forEach((reject) => reject(...res));
        rejectSet.clear();
      });
  }, wait);

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      resolveSet.add(resolve);
      rejectSet.add(reject);
      debounced(args);
    }) as ReturnType<F>;
}

export const upsertInArray = <Type, ID>(
  element: Type,
  arrayOfElement: ReadonlyArray<Type>,
  identity: (item: Type) => ID
): Type[] => {
  const elementExists = arrayOfElement.some((item) => identity(item) === identity(element));

  if (elementExists) {
    return arrayOfElement.map((item) => {
      if (identity(item) === identity(element)) {
        return element;
      }

      return item;
    });
  } else {
    return [...arrayOfElement, element];
  }
};

export const searchStrings = (array: any[], searchText: string, key?: string) => {
  if (key) {
    return array.filter((element) => element[key].includes(searchText));
  }
  return array.filter((element) => element.includes(searchText));
};
export const generateRandomInteger = (min: number, max: number) => {
  return Math.floor(min + Math.random() * (max + 1 - min));
};
export const trimmedString = (length: number, stringValue?: string) => {
  return stringValue && stringValue.length > length
    ? `${stringValue.substring(0, length - 3)}...`
    : stringValue;
};

export const generateRandomBoolean = () => {
  return Math.random() < 0.5;
};

export const limitTextDisplay = (text: string | number, maxLength: number) => {
  if (text) {
    return text.toString().length > maxLength
      ? text.toString().substring(0, maxLength) + ' ...'
      : text.toString();
  }
  return '';
};

export const limitArrayDisplayDetails = (
  array: string[],
  maxCharsLength: number
): { limitIndex: number; limitElem: string } => {
  let valueToTest: string = '';
  let limitElem: string = array[0] || '';
  let limitIndex: number = 0;
  for (let i = 0; i < array.length; i += 1) {
    valueToTest += array[i];
    if (valueToTest.length <= maxCharsLength) {
      limitIndex = i;
      limitElem = array[i];
    } else {
      break;
    }
  }
  return { limitElem, limitIndex };
};

export const removeExtensionFromString = (stringWithExtension: string): string =>
  stringWithExtension.includes('.')
    ? stringWithExtension.substring(0, stringWithExtension.lastIndexOf('.'))
    : stringWithExtension;

export const getExtensionFromString = (stringWithExtension: string): string =>
  `.${stringWithExtension.split('.').pop()}`;
