import { ConnectorType } from '@features/connections/types';
import { AudienceDataSource, SyncDataSource, ISourceSettings } from '../../audienceBackTypes/source';
import {
  AudienceEntity,
  EntityFactory,
  GetSourceEntitiesTypes,
  SelectableEntity,
  SourceSettingsType, SyncEntity,
} from '../../types';
import { SftpSourceType } from '@features/audiences/types';
import { caseNever } from '@utils/case-never';
import { trimBy } from '@utils/trim';

export const mapSelectedEntity = (
  sourceSettings: ISourceSettings
): SelectableEntity | undefined => {
  switch (sourceSettings.type) {
    case ConnectorType.bigQuery:
      return EntityFactory.databaseTable({
        key: sourceSettings.tableId,
        name: sourceSettings.tableId,
        schema: sourceSettings.datasetId, // TODO @@@@koralex is it correct?
        location: sourceSettings.location,
      });

    case SourceSettingsType.api: {
      // TODO @@@@koralex [all values are the same, fix it]
      return EntityFactory.api({
        key: sourceSettings.entityTypeId,
        name: sourceSettings.entityTypeId,
      });
    }

    case ConnectorType.postgres:
      return EntityFactory.databaseTable({
        key: sourceSettings.tableName,
        name: sourceSettings.tableName,
        schema: sourceSettings.schemaName,
      });

    case ConnectorType.mysql:
      return EntityFactory.databaseTable({
        key: sourceSettings.objectName,
        name: sourceSettings.objectName,
        schema: sourceSettings.schemaName,
      });

    case ConnectorType.sftp: {
      switch (sourceSettings.sourceType) {
        case SftpSourceType.file:
          return EntityFactory.file({
            key: `${trimBy(sourceSettings.directoryPath, ['/'])}/${
              sourceSettings.filesMatchPattern
            }`,
            name: sourceSettings.filesMatchPattern,
          });
        case SftpSourceType.pattern:
          return EntityFactory.folder({
            key: sourceSettings.directoryPath,
            name: sourceSettings.directoryPath.split('/').filter(Boolean).pop() || '',
            // TODO @@@@koralex should provide real children?
            // TODO @@@@koralex should be a separate property which will hold the key of the selected entity?
            children: [],
          });
        default:
          return caseNever(sourceSettings.sourceType);
      }
    }
    case ConnectorType.snowflake:
      return EntityFactory.databaseTable({
        key: sourceSettings.tableName,
        name: sourceSettings.tableName,
        schema: sourceSettings.schemaName,
      });
  }
};

export function mapSelectedEntityOfAudienceAsSource(
  backSourceConfig: AudienceDataSource
): AudienceEntity {
  return {
    key: backSourceConfig.audienceId,
    audienceId: backSourceConfig.audienceId,
    type: GetSourceEntitiesTypes.audience,
    name: '',
  };
}

export function mapSelectedEntityOfSyncAsSource(
  backSourceConfig: SyncDataSource
): SyncEntity {
  return {
    key: backSourceConfig.syncId,
    syncId: backSourceConfig.syncId,
    type: GetSourceEntitiesTypes.sync,
    name: '',
  };
}
