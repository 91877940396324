import { gql } from '@apollo/client';

const CREATE_CONNECTION = gql`
  mutation CreateConnection($command: CreateConnectionCommand!) {
    createConnection(command: $command) {
      __typename
      id
      type
      version
      name
      status
      canBeUsedAs
      settings
    }
  }
`;

const UPDATE_CONNECTION = gql`
  mutation UpdateConnection($command: UpdateConnectionCommand!) {
    updateConnection(command: $command) {
      __typename
      ... on Connection {
        id
        type
        version
        createdAt
        name
        status
        canBeUsedAs
        settings
      }
      ... on ConnectionNotFound {
        id
        __typename
      }
      ... on ConnectionVersionOutdated {
        providedVersion
        expectedVersion
      }
    }
  }
`;
const DELETE_CONNECTION = gql`
  mutation DeleteConnection($command: DeleteConnectionCommand!) {
    deleteConnection(command: $command) {
      __typename
    }
  }
`;
const TEST_SETTINGS = gql`
  mutation TestSettings($command: TestSettingsCommand!) {
    testSettings(command: $command)
  }
`;
export { CREATE_CONNECTION, UPDATE_CONNECTION, DELETE_CONNECTION, TEST_SETTINGS };
