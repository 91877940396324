// Type Definitions

export enum DataType {
  // Scalar
  Integer = 'integer',
  Float = 'float',
  String = 'string',
  Boolean = 'boolean',
  Timestamp = 'timestamp',
  Json = 'json',
  SystemId = 'system-id', // UUID (Generated and provided by the system)

  // Array
  Array = 'array',
}

// Scalar Types

// Notes:
//   - Using "I" prefix to avoid name-collisions with AWS
//   - Using "dataType" property-name to differentiate
//       1) Types of columns (IColumn -> IColumnType)
//       2) Data-Types of Types (IColumnType -> DataType)

export type IIntegerType = {
  dataType: DataType.Integer;
};

export type IFloatType = {
  dataType: DataType.Float;
};

export type IStringType = {
  dataType: DataType.String;
};

export type IBooleanType = {
  dataType: DataType.Boolean;
};

export type ITimestampType = {
  dataType: DataType.Timestamp;
  format?: string;
};

export type IJsonType = {
  dataType: DataType.Json;
};

export type ISystemIdType = {
  dataType: DataType.SystemId;
};

// Array

export type IArrayType = {
  dataType: DataType.Array;
  itemType: IColumnType;
};

// Combined / Common Column-Type
// type IColumnType = { dataType: columnTypesEnum };
export type IColumnType =
  | IIntegerType
  | IFloatType
  | IStringType
  | IBooleanType
  | ITimestampType
  | IJsonType
  | ISystemIdType
  | IArrayType;

// Column-Type Factory

export const ColumnTypes = {
  // Scalar Types
  get integer(): IIntegerType {
    return { dataType: DataType.Integer };
  },
  get float(): IFloatType {
    return { dataType: DataType.Float };
  },
  get string(): IStringType {
    return { dataType: DataType.String };
  },
  get boolean(): IBooleanType {
    return { dataType: DataType.Boolean };
  },
  get timestamp(): ITimestampType {
    return { dataType: DataType.Timestamp };
  },
  get json(): IJsonType {
    return { dataType: DataType.Json };
  },
  get systemId(): ISystemIdType {
    return { dataType: DataType.SystemId };
  },

  // Array Type
  array(itemType: IColumnType): IArrayType {
    return {
      dataType: DataType.Array,
      itemType,
    };
  },
};

// Checking Equality
/*
export const checkForAllowedColumnType = (
  allowedTypes: IColumnType[],
  inputType: IColumnType
): boolean => allowedTypes.some((type: IColumnType) => compareColumnTypes(type, inputType));

export const compareColumnTypes = (type1: IColumnType, type2: IColumnType): boolean => {
  if (type1.dataType !== type2.dataType) {
    return false;
  }

  if (type1.dataType !== DataType.Array || type2.dataType !== DataType.Array) {
    return true;
  }

  return compareColumnTypes(type1.itemType, type2.itemType);
};
*/
