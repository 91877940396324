import {
  ColumnFilteringStrategy,
  ColumnFilteringStrategyType,
} from '@features/audiences/ducks/api/audienceBackTypes/columnAggregations';
import { operator } from '@features/filter/types';

export const mappingStrategyProperty = (
  value: string,
  operator?: operator
): ColumnFilteringStrategy => {
  const operatorTypes: { [keyValue: string]: ColumnFilteringStrategyType } = {
    is: ColumnFilteringStrategyType.match_value,
    'is not': ColumnFilteringStrategyType.mismatch_value,
    regex: ColumnFilteringStrategyType.match_regex,
  };
  return {
    value,
    type:
      operator && operatorTypes[operator.value]
        ? operatorTypes[operator?.value]
        : ColumnFilteringStrategyType.match_value,
  };
};
