// Importing type
import * as apiTypes from './types';
import { AppContextType } from '@features/app/types';
import { sleep } from '@utils/helpers';
import {
  getApplicationConfiguration,
} from '@data/app';

const getAppContext: apiTypes.APIGetAppContext = async (name: string) => {
  const appContext: AppContextType = getApplicationConfiguration(name);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!appContext) {
    error = true;
    errorDetails = 'No appContext found.';
  }

  return {
    error,
    appContext,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveAppContext: apiTypes.APISaveAppContext = async (appContext: AppContextType) => {
  await sleep(1000);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!appContext) {
    error = true;
    errorDetails = 'No appContext found.';
  }
  return {
    appContext,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getAppContext, saveAppContext };
