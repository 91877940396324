import { columnShapeTypeEnum, ColumnWithNoID } from '@features/audiences/types';
import { toSnakeCase } from '@utils/to-snake-case';
import { BackColumn } from '../../types';
import { mappingColumnTypePropertyToFront } from '../toFrontType/mappingColumnTypePropertyToFront';
import { shortenColumnName } from '@features/audiences/utils/columns';

export const mappingBackColumnToColumn = (col: BackColumn): ColumnWithNoID => ({
  ...col,
  type: mappingColumnTypePropertyToFront(col.type),
  shapeType: columnShapeTypeEnum.DEFAULT,
  externalName: col.name,
  name: toSnakeCase(col.name),
});

export const mappingDraftSourceBackColumnToFront = (col: BackColumn): ColumnWithNoID => {
  const mappedColumn = mappingBackColumnToColumn(col);
  return ({
    ...mappedColumn,
    name: shortenColumnName(mappedColumn.name)
  });
};
