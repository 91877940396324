import { takeLatest, call, put, select, all } from 'redux-saga/effects';
// api
import * as API from './api';
// connectionsSlice
import appContextSlice, { AppContextState } from '@features/app/ducks/appContextSlice';
// types
import * as types from './types';
import i18n from '@configs/i18n';
import { OrganizationByDomain, OrganizationType } from '@features/organizations/types';
import { caseNever } from '@utils/case-never';
import { getOrganizationByDomainSaga } from '@features/organizations/ducks/sagas';
import {
  activeAccountIdStateSelector,
  activeDataWarehouseIdStateSelector,
  organizationByDomainSelector,
} from '@redux/selectors';
import organizationsSlice from '@features/organizations/ducks/organizationsSlice';
import { getProfilesSaga } from '@features/profiles/ducks/sagas';
import profilesSlice from '@features/profiles/ducks/profilesSlice';
import { getAccountsSaga, updateActiveAccountSaga } from '@features/account/ducks/sagas';
import accountSlice from '@features/account/ducks/accountSlice';
import { getUserSaga } from '@features/users/ducks/sagas';
import usersSlice from '@features/users/ducks/usersSlice';
import { getConnectorsSaga } from '@features/connectors/ducks/sagas';
import { getDatawarehousesSaga } from '@features/dataWarehouse/ducks/sagas';
import { redirectToDataWarehouseSettingsSaga } from '@features/redirections/ducks/sagas';

function* getAppContextSaga({ payload }: types.GetAppContextSaga) {
  try {
    const { organization } = payload;
    let appContextName: string | undefined = undefined;
    switch (organization.type) {
      case OrganizationType.octolis:
        appContextName = OrganizationType.octolis;
        break;
      case OrganizationType.partner:
        appContextName = organization.partnerId;
        break;
      case OrganizationType.customer:
        appContextName = organization.partnerId ? organization.partnerId : OrganizationType.octolis;
        break;
      default:
        caseNever(organization);
    }
    if (appContextName) {
      const getAppContextPayload: AppContextState = yield call(API.getAppContext, appContextName);
      yield put({
        type: appContextSlice.actions.getAppContextSuccess.type,
        payload: { appContext: getAppContextPayload.appContext },
      });
      const appContext = getAppContextPayload.appContext;
      const appName = appContext.labels.appName;
      const appIconURL = appContext.imagesUrls.appIcon;
      if (appName && appIconURL) {
        yield call(setAppIconAndNameSaga, { payload: { appName, appIconURL } });
      }
      if (appContext.language) {
        const language = appContext.language;
        yield call(setAppLanguageSaga, { payload: { language } });
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put({
        type: appContextSlice.actions.getAppContextFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
      console.log(err);
    }
  }
}

function* saveAppContextSaga({ payload }: types.SaveAppContextSaga) {
  try {
    const { appContext } = payload;
    const saveAppContextPayload: AppContextState = yield call(API.saveAppContext, appContext);
    yield put({
      type: appContextSlice.actions.saveAppContextSuccess.type,
      payload: saveAppContextPayload,
    });
  } catch (err) {
    if (err instanceof Error) {
      yield put({
        type: appContextSlice.actions.saveAppContextFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
      console.log(err);
    }
  }
}

function* setAppLanguageSaga({ payload }: types.SetAppLanguageSaga) {
  try {
    const { language } = payload;
    yield i18n.changeLanguage(language);

    yield put({ type: appContextSlice.actions.setAppLanguageSuccess.type, payload: { language } });
  } catch (err) {
    if (err instanceof Error) {
      yield put({
        type: appContextSlice.actions.setAppLanguageFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
      console.log(err);
    }
  }
}

export function* setAppIconAndNameSaga({ payload }: types.SetAppIconAndNameSaga) {
  try {
    // @@@Amine maybe here we will add a url property to http favicon
    const { appName, appIconURL } = payload;
    let link: HTMLLinkElement;
    const faviconLink: HTMLLinkElement =
      document.querySelectorAll<HTMLLinkElement>('link[rel~="icon"]')[0];
    yield (document.title = `${appName.charAt(0).toUpperCase()}${appName.slice(1)}`);

    if (faviconLink) {
      yield (faviconLink.href = appIconURL);
    } else {
      link = document.createElement('link');
      link.rel = 'shortcut icon';
      link.href = appIconURL;
      yield document.getElementsByTagName('head')[0].appendChild(link);
    }
  } catch (err) {
    if (err instanceof Error) {
      console.log(err);
    }
  }
}

export function* initPlatformSaga({ payload }: types.InitPlatformSaga) {
  const { domain } = payload;
  yield call(getOrganizationByDomainSaga, {
    type: organizationsSlice.actions.getOrganizationByDomain.type,
    payload: { domain },
  });
  const organization: OrganizationByDomain = yield select(organizationByDomainSelector);
  if (organization) {
    yield call(getAppContextSaga, {
      payload: { organization },
      type: appContextSlice.actions.getAppContext.type,
    });
  }
}
export function* initAppLayoutSaga({ payload }: types.InitAppLayoutSaga) {
  const { activeAccountId } = payload;
  if (activeAccountId) {
    yield call(getAccountsSaga);

    yield all([
      call(updateActiveAccountSaga, {
        type: accountSlice.actions.updateActiveAccount.type,
        payload: { activeAccountId },
      }),
      call(getProfilesSaga),
      call(getUserSaga, {
        type: usersSlice.actions.getUser.type,
        payload: { userId: 'data', organization: 'octolis' },
      }),
      call(getConnectorsSaga),
      call(getDatawarehousesSaga),
    ]);
    const accountId: string = yield select(activeAccountIdStateSelector);
    const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);
    if (accountId && !dataWarehouseId) {
      yield call(redirectToDataWarehouseSettingsSaga);
    }
  }
}
export const appContextSagas = [
  takeLatest(appContextSlice.actions.getAppContext.type, getAppContextSaga),
  takeLatest(appContextSlice.actions.saveAppContext.type, saveAppContextSaga),
  takeLatest(appContextSlice.actions.initPlatform.type, initPlatformSaga),
  takeLatest(appContextSlice.actions.initAppLayout.type, initAppLayoutSaga),
];
