// Importing type
import * as apiTypes from './types';
import { RightsType, Profile, ProfileType } from '@features/profiles/types';
import { sleep } from '@utils/helpers';
import { allRights } from '@features/profiles/profilesHelpers';
import { profilesList } from '@data/profiles';

const getProfiles: apiTypes.APIGetProfiles = async () => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  if (!profilesList.length) {
    error = true;
    errorDetails = 'No profile found.';
  }
  return {
    error,
    profiles: profilesList,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getProfile: apiTypes.APIGetProfile = async (profileType: ProfileType) => {
  let profile: Profile;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  profile = profilesList.find((x) => x.type === profileType) as Profile;
  if (!profile) {
    error = true;
    errorDetails = 'No profile found.';
  }

  return {
    error,
    profile,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const saveProfiles: apiTypes.APISaveProfiles = async (profiles: Profile[]) => {
  await sleep(1000);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!profiles) {
    error = true;
    errorDetails = 'no profiles to save';
  }
  return {
    error,
    profiles,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getProfile, getProfiles, saveProfiles };
