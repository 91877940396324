import { AudienceColumn } from './audienceColumn';
import { IColumnType } from './columnType';
import {
  FormattingPreparationActionErrorHandlingStrategy,
  StringCaseChangeType,
} from '@features/audiences/types';
export enum PreparationStepType {
  filter_by_flags = 'filter_by_flags',

  format_as_string = 'format_as_string',
  format_as_boolean = 'format_as_boolean',

  extract_json_by_path = 'extract_json_by_path',

  round_numbers = 'round_numbers',

  combine_values = 'combine_values',
  concat_values_as_string = 'concat_values_as_string',
  hash_values = 'hash_values',

  conditional_statement_sql = 'conditional_statement_sql',
  custom_function = 'custom_function',
  custom_sql = 'custom_sql',

  copy_columns = 'copy_columns',
  create_columns = 'create_columns',
  delete_columns = 'delete_columns',
  keep_columns = 'keep_columns',
  rename_columns = 'rename_columns',

  change_strings_cases = 'change_strings_cases',
  check_emails_validity = 'check_emails_validity',
  find_replace_in_strings = 'find_replace_in_strings',
  format_phone_numbers = 'format_phone_numbers',
  replace_string_column_values_with_null = 'replace_string_column_values_with_null',
  split_emails_into_parts = 'split_emails_into_parts',
  split_string_column_values_to_array_columns = 'split_string_column_values_to_array_columns',
  test_string_column_values_with_regex = 'test_string_column_values_with_regex',
  trim_string_column_values = 'trim_string_column_values',

  extract_parts_from_timestamps = 'extract_parts_from_timestamps',
  format_timestamps_as_strings = 'format_timestamps_as_strings',
  generate_current_timestamp_columns = 'generate_current_timestamp_columns',
  truncate_timestamps = 'truncate_timestamps',
}
export type PreparationStep =
  | GenerateCurrentTimestampColumnsPreparationStep
  | FilterByFlagsPreparationStep
  | FormatAsStringPreparationStep
  | FormatAsBooleanPreparationStep
  | CombineValuesPreparationStep
  | ConcatValuesAsStringPreparationStep
  | HashValuesPreparationStep
  | CustomSqlPreparationStep
  | CustomFunctionPreparationAction
  | ConditionalStatementSqlPreparationStep
  | CopyColumnsPreparationStep
  | KeepColumnsPreparationStep
  | DeleteColumnsPreparationStep
  | RenameColumnsPreparationStep
  | ChangeStringsCasesPreparationStep
  | FindReplaceInStringsPreparationStep
  | ReplaceStringColumnValuesWithNullPreparationStep
  | SplitStringColumnValuesToArrayColumnsPreparationStep
  | TestStringColumnValuesWithRegexPreparationStep
  | TrimStringColumnValuesPreparationStep
  | RoundNumbersPreparationStep
  | FormatTimestampsAsStringsPreparationStep
  | FormatPhoneNumbersPreparationStep
  | ExtractJsonByPathPreparationAction
  | CheckEmailsValidityPreparationAction;

// GenerateCurrentTimestampColumnsPreparationStep
export type GenerateCurrentTimestampColumnsPreparationStepColumnConfig = Readonly<{
  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;

export type GenerateCurrentTimestampColumnsPreparationStep = Readonly<{
  type: PreparationStepType.generate_current_timestamp_columns;

  columns: GenerateCurrentTimestampColumnsPreparationStepColumnConfig[];
}>;

// FilterByFlagsPreparationStepColumnConfig
export type FilterByFlagsPreparationStepColumnConfig = Readonly<{
  flagColumnId: AudienceColumn['id'];

  expectedValue: boolean;
}>;

export type FilterByFlagsPreparationStep = Readonly<{
  type: PreparationStepType.filter_by_flags;

  columns: FilterByFlagsPreparationStepColumnConfig[];
}>;

// FormatAsStringPreparationStep
export type FormattingPreparationStepColumnConfig = AudienceColumn['id'];
export type FormatAsStringPreparationStep = Readonly<{
  type: PreparationStepType.format_as_string;

  columns: FormattingPreparationStepColumnConfig[];
  // No unsupported types for string-formatting
}>;
// FormatAsBooleanPreparationStep
export enum FormattingPreparationStepErrorHandlingStrategy {
  throwError = 'throw-error',
  returnNull = 'return-null',
}
export type FormatAsBooleanPreparationStep = Readonly<{
  type: PreparationStepType.format_as_boolean;

  columns: FormattingPreparationStepColumnConfig[];

  onError: FormattingPreparationActionErrorHandlingStrategy;
}>;
// CombineValuesPreparationStep
export type CombineValuesPreparationStepColumnConfig = Readonly<{
  originalColumnIds: AudienceColumn['id'][];
  expectedType: IColumnType; // Output-Type MUST be specified for evaluation
  //   -> Determines output shape

  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;

export type CombineValuesPreparationStep = Readonly<{
  type: PreparationStepType.combine_values;

  columns: CombineValuesPreparationStepColumnConfig[];
}>;
// ConcatValuesAsStringPreparationStep
export type ConcatValuesAsStringPreparationStepColumnConfig = Readonly<{
  originalColumnIds: AudienceColumn['id'][];
  delimiter: string;

  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;
export type ConcatValuesAsStringPreparationStep = Readonly<{
  type: PreparationStepType.concat_values_as_string;

  columns: ConcatValuesAsStringPreparationStepColumnConfig[];
}>;
export type CustomFunctionPreparationAction = Readonly<{
  type: PreparationStepType.custom_function;

  function: {
    name: string;
    expectedType: AudienceColumn['type'];
  };

  columns: AudienceColumn['id'][]; // Audience column ids
}>;
// HashValuesPreparationStep
export type HashValuesPreparationStepColumnConfig = Readonly<{
  originalColumnIds: AudienceColumn['id'][];

  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;

export enum PreparingHashAlgorithm {
  md5 = 'md5',
  sha256 = 'sha256',
}
export type HashValuesPreparationStep = Readonly<{
  type: PreparationStepType.hash_values;

  algorithm?: PreparingHashAlgorithm;

  lowercase?: boolean;
  columns: HashValuesPreparationStepColumnConfig[];
}>;

// CustomSqlPreparationStep
export type CustomSqlPreparationStepColumnConfig = Readonly<{
  sqlExpression: string;

  expectedType: AudienceColumn['type'];

  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;

export type CustomSqlPreparationStep = Readonly<{
  type: PreparationStepType.custom_sql;

  columns: CustomSqlPreparationStepColumnConfig[];
}>;
// ConditionalStatementSqlPreparationStep
export type ConditionalStatementSqlPreparationStepColumnConfig = Readonly<{
  conditionSqlExpression: string;
  onTrueSqlExpression: string;
  onFalseSqlExpression: string;

  expectedType: AudienceColumn['type'];

  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;

export type ConditionalStatementSqlPreparationStep = Readonly<{
  type: PreparationStepType.conditional_statement_sql;

  columns: ConditionalStatementSqlPreparationStepColumnConfig[];
}>;
// CopyColumnsPreparationStep
export type CopyColumnsPreparationStepColumnConfig = Readonly<{
  originalColumnId: AudienceColumn['id'];

  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;

export type CopyColumnsPreparationStep = Readonly<{
  type: PreparationStepType.copy_columns;

  columns: CopyColumnsPreparationStepColumnConfig[];
}>;
// KeepColumnsPreparationStep
export type KeepColumnsPreparationStepColumnConfig = AudienceColumn['id'];

export type KeepColumnsPreparationStep = Readonly<{
  type: PreparationStepType.keep_columns;

  columns: KeepColumnsPreparationStepColumnConfig[];
}>;
// DeleteColumnsPreparationStep
export type DeleteColumnsPreparationStepColumnConfig = AudienceColumn['id'];

export type DeleteColumnsPreparationStep = Readonly<{
  type: PreparationStepType.delete_columns;

  columns: DeleteColumnsPreparationStepColumnConfig[];
}>;
// RenameColumnsPreparationStep

export type RenameColumnsPreparationStepColumnConfig = Readonly<{
  originalColumnId: AudienceColumn['id'];

  targetColumnName: AudienceColumn['name'];
}>;

export type RenameColumnsPreparationStep = Readonly<{
  type: PreparationStepType.rename_columns;

  columns: RenameColumnsPreparationStepColumnConfig[];
}>;
// ChangeStringsCasesPreparationStep

export type ChangeStringsCasesPreparationStepColumnConfig = Readonly<{
  columnId: AudienceColumn['id'];

  stringCase: StringCaseChangeType;
}>;

export type ChangeStringsCasesPreparationStep = Readonly<{
  type: PreparationStepType.change_strings_cases;

  columns: ChangeStringsCasesPreparationStepColumnConfig[];
}>;
// FindReplaceInStringsPreparationStep
export type FindReplaceInStringsPreparationStepColumnConfig = Readonly<{
  columnId: AudienceColumn['id'];

  find: string;
  replace: string;

  isCaseSensitive: boolean;
}>;

export type FindReplaceInStringsPreparationStep = Readonly<{
  type: PreparationStepType.find_replace_in_strings;

  columns: FindReplaceInStringsPreparationStepColumnConfig[];
}>;
// ReplaceStringColumnValuesWithNullPreparationStep
export type ReplaceStringColumnValuesWithNullPreparationStepColumnConfig = AudienceColumn['id'];

export type ReplaceStringColumnValuesWithNullPreparationStep = Readonly<{
  type: PreparationStepType.replace_string_column_values_with_null;

  columns: ReplaceStringColumnValuesWithNullPreparationStepColumnConfig[];
}>;
// SplitStringColumnValuesToArrayColumnsPreparationStep
export type SplitStringColumnValuesToArrayColumnsPreparationStepColumnConfig = Readonly<{
  originalColumnId: AudienceColumn['id'];
  delimiter: string;

  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;

export type SplitStringColumnValuesToArrayColumnsPreparationStep = Readonly<{
  type: PreparationStepType.split_string_column_values_to_array_columns;

  columns: SplitStringColumnValuesToArrayColumnsPreparationStepColumnConfig[];
}>;
// TestStringColumnValuesWithRegexPreparationStep
export type TestStringColumnValuesWithRegexPreparationStepColumnConfig = Readonly<{
  originalColumnId: AudienceColumn['id'];
  regex: string;

  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;

export type TestStringColumnValuesWithRegexPreparationStep = Readonly<{
  type: PreparationStepType.test_string_column_values_with_regex;

  columns: TestStringColumnValuesWithRegexPreparationStepColumnConfig[];
}>;
// TrimStringColumnValuesPreparationStepColumnConfig
export type TrimStringColumnValuesPreparationStepColumnConfig = AudienceColumn['id'];

export type TrimStringColumnValuesPreparationStep = Readonly<{
  type: PreparationStepType.trim_string_column_values;

  columns: TrimStringColumnValuesPreparationStepColumnConfig[];
}>;

// format timestamps as string
export type IComplexTimestampFormat = Readonly<{
  date?: string;
  time?: string;
  delimiter?: string;
}>;
export type IFullTimestampFormat = string | IComplexTimestampFormat;

export type FormatTimestampsAsStringsPreparationActionColumnConfig = Readonly<{
  originalColumnId: AudienceColumn['id'];

  format: IFullTimestampFormat;

  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;

// Operating on multiple column
export type FormatTimestampsAsStringsPreparationStep = Readonly<{
  type: PreparationStepType.format_timestamps_as_strings;

  columns: FormatTimestampsAsStringsPreparationActionColumnConfig[];
}>;

// Operating on multiple column
export type RoundNumbersPreparationActionColumnConfig = Readonly<{
  columnId: AudienceColumn['id'];

  decimals: number;
}>;
export type RoundNumbersPreparationStep = Readonly<{
  type: PreparationStepType.round_numbers;

  columns: RoundNumbersPreparationActionColumnConfig[];
}>;
// Truncate timestamps
export enum TimestampPart {
  year = 'year',
  month = 'month',
  day = 'day',
  hour = 'hour',
  minute = 'minute',
  second = 'second',
}
export type TruncateTimestampsPreparationActionColumnConfig = Readonly<{
  columnId: AudienceColumn['id'];

  part: TimestampPart;
}>;
export type TruncateTimestampsPreparationAction = Readonly<{
  type: PreparationStepType.truncate_timestamps;

  columns: TruncateTimestampsPreparationActionColumnConfig[];
}>;

// format phone number

// Prefixes: Either "+33..." or "0033"

export const PHONE_FORMATTING_OUTPUT_PREFIXES = ['+', '00'];

export type PhoneFormattingOutputPrefix = '+' | '00';

// Invalid Output -> Either a NULL or the Raw-Phone

export enum PhoneFormattingInvalidOutput {
  null_value = 'null_value',
  raw = 'raw',
}

// Actions

export type FormatPhoneNumbersPreparationActionColumnConfig = AudienceColumn['id'];

// Operating on multiple column
export type FormatPhoneNumbersPreparationStep = Readonly<{
  type: PreparationStepType.format_phone_numbers;

  prefix: PhoneFormattingOutputPrefix;

  output: PhoneFormattingInvalidOutput;

  columns: FormatPhoneNumbersPreparationActionColumnConfig[];
}>;

export type ExtractJsonByPathPreparationActionColumnConfig = Readonly<{
  originalColumnId: AudienceColumn['id'];
  path: string;
  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
  outputType?: AudienceColumn['type'];
}>;

export type ExtractJsonByPathPreparationAction = Readonly<{
  type: PreparationStepType.extract_json_by_path;
  columns: ExtractJsonByPathPreparationActionColumnConfig[];
}>;

export type CheckEmailsValidityPreparationActionColumnConfig = Readonly<{
  originalColumnId: AudienceColumn['id'];

  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];
}>;

export type CheckEmailsValidityPreparationAction = Readonly<{
  type: PreparationStepType.check_emails_validity;

  columns: CheckEmailsValidityPreparationActionColumnConfig[];
}>;
