import { columnTypesEnum } from '@features/objects/types';
import { TimestampPart } from './Preparation';
import { PreparationActionType } from './PreparationActionType';

export type SourcePreparation =
  | FormatAsStringColumnPreparationAction // Enabled for All column types ??
  | FormatAsBooleanColumnPreparationAction // Enabled for string / int / float ??
  | CustomFunctionColumnPreparationAction // Enabled for All column types ??
  | RenameColumnPreparationAction // Enabled for All column types
  | ChangeStringsCasesColumnPreparationAction // Enabled for column type STRING
  | FindReplaceInStringsColumnPreparationAction // Enabled for column type STRING
  | ReplaceStringColumnValuesWithNullColumnPreparationAction // Enabled for column type STRING
  | TrimStringColumnValuesColumnPreparationAction // Enabled for column type STRING
  | FormatPhoneNumberPreparationAction // Enabled for column type string  ??
  | RoundNumberPreparationAction // Enabled for column type FLOAT ??
  | TruncateTimestampPreparationAction; // Enabled for column type TIMESTAMP ??
export type DataApiPreparation =
  | CustomFunctionColumnPreparationAction
  | ChangeStringsCasesColumnPreparationAction
  | FindReplaceInStringsColumnPreparationAction
  | ReplaceStringColumnValuesWithNullColumnPreparationAction
  | TrimStringColumnValuesColumnPreparationAction
  | FormatPhoneNumberPreparationAction
  | RoundNumberPreparationAction
  | TruncateTimestampPreparationAction;

export type FormatAsStringColumnPreparationAction = Readonly<{
  type: PreparationActionType.format_as_string;
  column: string; // Source Id
}>;

export type CustomFunctionColumnPreparationAction = Readonly<{
  type: PreparationActionType.custom_function;
  function: {
    name: string;
    expectedType: columnTypesEnum;
  };
  column: string;
}>;

export type RenameColumnPreparationAction = Readonly<{
  type: PreparationActionType.rename_columns;
  column: {
    originalColumnId: string; // Source Id
    targetColumnName: string; // Source name
  };
}>;

export type ChangeStringsCasesColumnPreparationAction = Readonly<{
  type: PreparationActionType.change_strings_cases;
  column: {
    columnId: string; // Source Id
    stringCase: StringCaseChangeType;
  };
}>;
export enum StringCaseChangeType {
  lowercase = 'lowercase',
  uppercase = 'uppercase',
  initcase = 'initcase',
  initcase_first_only = 'initcase_first_only',
}

export type FindReplaceInStringsColumnPreparationAction = Readonly<{
  type: PreparationActionType.find_replace_in_strings;
  column: {
    columnId: string;
    find: string;
    replace: string;
    isCaseSensitive: boolean;
  };
}>;

export type ReplaceStringColumnValuesWithNullColumnPreparationAction = Readonly<{
  type: PreparationActionType.replace_string_column_values_with_null;
  column: string; // Source Id
}>;

export type TrimStringColumnValuesColumnPreparationAction = Readonly<{
  type: PreparationActionType.trim_string_column_values;
  column: string; // Source Id
}>;

export type PhoneFormattingOutputPrefix = '+' | '00';
export enum PhoneFormattingInvalidOutput {
  null_value = 'null_value',
  raw = 'raw',
}
export type FormatPhoneNumberPreparationAction = Readonly<{
  type: PreparationActionType.format_phone_numbers;

  prefix: PhoneFormattingOutputPrefix;

  output: PhoneFormattingInvalidOutput;

  column: string;
}>;
export enum FormattingPreparationActionErrorHandlingStrategy {
  throwError = 'throw-error',
  returnNull = 'return-null',
}

export type FormatAsBooleanColumnPreparationAction = Readonly<{
  type: PreparationActionType.format_as_boolean;

  column: string;

  onError: FormattingPreparationActionErrorHandlingStrategy;
}>;

export type RoundNumbersPreparationActionColumnConfig = Readonly<{
  columnId: string;
  decimals: number;
}>;
export type RoundNumberPreparationAction = Readonly<{
  type: PreparationActionType.round_numbers;
  column: RoundNumbersPreparationActionColumnConfig;
}>;

export type TruncateTimestampsPreparationActionColumnConfig = {
  columnId: string;

  part: TimestampPart;
};
export type TruncateTimestampPreparationAction = {
  type: PreparationActionType.truncate_timestamps;

  column: TruncateTimestampsPreparationActionColumnConfig;
};
