import { ScheduleType } from '@contracts/schedule';
import {
  FieldMapItemType,
  IFrontSyncRecordsScope,
  Mapping,
  Sync,
  SyncSchedule,
  SyncScheduleTypes,
} from '@features/syncs/types';
import {
  IBackDestination,
  SftpDestinationColumn,
} from '@features/syncs/types/backTypes/IBackDestinationSettings';
import { IFieldMapItem } from '@features/syncs/types/backTypes/IFieldMapItem';
import {
  DestinationTriggerType,
  ISyncExecutionTriggerSpecification,
} from '@features/syncs/types/backTypes/ISyncExecutionTriggerSpecification';
import { SyncModel, SyncSpecification } from '@features/syncs/types/backTypes/SyncModel';
import { caseNever } from '@utils/case-never';
import { DestinationEnum } from '../../../types/Destination';
import { ConnectorType } from '@features/connections/types';
import { mappingSegmentationToBack } from './mappingSegmentationToBack';
import { ISyncRecordsScope, SyncRecordsScope } from '../../../types/backTypes/ISyncRecordsScope';
import {
  IRecordsScopeDateFilter,
  IRecordsScopeFilter,
  RecordsScopeDateFilter,
  RecordsScopeFilter,
} from '../../../types/backTypes/ISyncRecordsScopeFilter';

export const syncToBack = (
  frontSync: Sync,
  accountId: string,
  dataWarehouseId: string
): SyncModel => {
  const backSync: SyncModel = {
    accountId,
    dataWarehouseId,
    id: frontSync.key,
    version: frontSync.version,
    name: frontSync.name,
    isEnabled: frontSync.active,
    createdAt: frontSync.createdAt,
    updatedAt: frontSync.updatedAt,
    specification: mappingSyncSpecification(frontSync),
  };
  return backSync;
};
const mappingSyncSpecification = (frontSync: Sync): SyncSpecification => {
  return {
    audienceId: frontSync.audienceId,
    destination: mappingDestinationProperty(frontSync),
    executionTrigger: mappingExecutionTrigger(frontSync.schedule),
    segmentation: mappingSegmentationToBack(frontSync.syncSegmentation),
  };
};

const mapSyncRecordsScope = (recordsScope: IFrontSyncRecordsScope): ISyncRecordsScope => {
  switch (recordsScope.recordsScope) {
    case SyncRecordsScope.allRecords:
      return {
        type: SyncRecordsScope.allRecords,
      };
    case SyncRecordsScope.unprocessedRecords: {
      return {
        type: SyncRecordsScope.unprocessedRecords,
      };
    }
    case SyncRecordsScope.recordsInTimePeriod: {
      return {
        type: SyncRecordsScope.recordsInTimePeriod,
        filter: mapSyncRecordsScopeFilter(recordsScope),
      };
    }
    default:
      return caseNever(recordsScope.recordsScope);
  }
};

const mapSyncRecordsScopeFilter = (recordsScope: IFrontSyncRecordsScope): IRecordsScopeFilter => {
  const filter = mapSyncRecordsScopeDateFilter(recordsScope);

  switch (recordsScope.dateFilterType) {
    case RecordsScopeFilter.createdAt:
      return {
        type: RecordsScopeFilter.createdAt,
        createdAt: filter,
      };
    case RecordsScopeFilter.updatedAt:
      return {
        type: RecordsScopeFilter.updatedAt,
        updatedAt: filter,
      };
    case RecordsScopeFilter.createdOrUpdatedAt:
      return {
        type: RecordsScopeFilter.createdOrUpdatedAt,
        createdAt: filter,
        updatedAt: filter,
      };
    default:
      throw new Error(`Unknown date filter type: ${recordsScope.dateFilterType}`);
  }
};

const mapSyncRecordsScopeDateFilter = (
  recordsScope: IFrontSyncRecordsScope
): IRecordsScopeDateFilter => {
  if (recordsScope.fromMoment && recordsScope.toMoment) {
    return {
      type: RecordsScopeDateFilter.range,
      after: recordsScope.fromMoment?.toDate() ?? new Date(),
      before: recordsScope.toMoment?.toDate() ?? new Date(),
    };
  }

  if (recordsScope.fromMoment && !recordsScope.toMoment) {
    return {
      type: RecordsScopeDateFilter.after,
      after: recordsScope.fromMoment?.toDate() ?? new Date(),
    };
  }

  if (recordsScope.toMoment && !recordsScope.fromMoment) {
    return {
      type: RecordsScopeDateFilter.before,
      before: recordsScope.toMoment?.toDate() ?? new Date(),
    };
  }

  throw new Error(`Unsupported case for mapping date filter: ${JSON.stringify(recordsScope)}`);
};

const mappingExecutionTrigger = (schedule: SyncSchedule): ISyncExecutionTriggerSpecification => {
  switch (schedule.type) {
    case SyncScheduleTypes.MANUAL:
      return { type: DestinationTriggerType.manual };
    case SyncScheduleTypes.REAL_TIME:
      return { type: DestinationTriggerType.realTime, isThrottled: schedule.isThrottled };
    case SyncScheduleTypes.SCHEDULED:
      return {
        type: DestinationTriggerType.scheduled,
        recordsScope: mapSyncRecordsScope(schedule),
        schedule: {
          type: ScheduleType.cron,
          value: schedule.cron,
        },
      };
    default:
      return caseNever(schedule);
  }
};
// TODO @@@@koralex [handle destinationSettings correctly]
const mappingDestinationProperty = (frontSync: Sync): IBackDestination => {
  switch (frontSync.destination.type) {
    case DestinationEnum.api:
      return {
        type: DestinationEnum.api,
        connectionId: frontSync.destinationConnectionId,
        settings: {
          // TODO @@@@koralex [map destinationSettings?]
          entitySettings: frontSync.destination.settings.entitySettings,
          entityTypeId: frontSync.destination.settings.entityTypeId,
          keyFieldId: frontSync.destination.settings.keyFieldId,
          fieldMap: transformFieldMapsToMappings(frontSync.mapping),
          destinationSettings: frontSync.destination.settings.destinationSettings ?? {},
        },
      };
    case DestinationEnum.fs:
      switch (frontSync.destination.settings.type) {
        case ConnectorType.sftp: {
          const { columns, dataSettings, directoryPath, fileName, fileNameExtension, type } =
            frontSync.destination.settings;
          return {
            type: DestinationEnum.fs,
            connectionId: frontSync.destinationConnectionId,
            settings: {
              type,
              dataSettings,
              directoryPath,
              columns: columns.reduce((result: SftpDestinationColumn[], elem) => {
                if (elem.sync) {
                  elem.name === elem.externalName
                    ? result.push({ name: elem.name })
                    : result.push({ name: elem.name, externalName: elem.externalName });
                }
                return result;
              }, []),
              fileName: `${fileName}${
                fileNameExtension.includes('.') ? fileNameExtension : `.${fileNameExtension}`
              }`,
            },
          };
        }
        default:
          throw new Error('Unknown FS destination type');
      }

    case DestinationEnum.db:
      return {
        type: DestinationEnum.db,
        connectionId: frontSync.destinationConnectionId,
        settings: frontSync.destination.settings,
      };
    case DestinationEnum.webhook:
      return {
        type: DestinationEnum.webhook,
        settings: frontSync.destination.settings,
      };
    default:
      return caseNever(frontSync.destination);
  }
};

const transformFieldMapsToMappings = (frontFieldMaps: Mapping[]): IFieldMapItem[] => {
  const mappings: IFieldMapItem[] = frontFieldMaps.reduce((result: IFieldMapItem[], fieldMap) => {
    if (
      fieldMap.type === FieldMapItemType.audience_field &&
      fieldMap.audienceColumnId &&
      fieldMap.audienceColumnName &&
      fieldMap.destinationFieldId &&
      fieldMap.syncStrategy
    ) {
      const backField: IFieldMapItem = {
        type: FieldMapItemType.audience_field,
        audienceColumnId: fieldMap.audienceColumnId,
        audienceColumnName: fieldMap.audienceColumnName,
        destinationFieldId: fieldMap.destinationFieldId,
        syncStrategy: fieldMap.syncStrategy,
      };
      result.push(backField);
    }
    if (
      fieldMap.type === FieldMapItemType.static_value &&
      fieldMap.staticValue !== undefined && // it could be false in the boolean fields
      fieldMap.destinationFieldId &&
      fieldMap.syncStrategy
    ) {
      const backField: IFieldMapItem = {
        type: FieldMapItemType.static_value,
        destinationFieldId: fieldMap.destinationFieldId,
        syncStrategy: fieldMap.syncStrategy,
        staticValue: fieldMap.staticValue,
      };
      result.push(backField);
    }
    return result;
  }, []);
  return mappings;
};
