import { ConnectorType } from '@features/connections/types';
import { columnTypesEnum } from '@features/objects/types';
import { IColumnType } from './audienceBackTypes/columnType';
import {
  AudiencePreviewState,
  AudiencePreviewStatus,
} from '../../types/AudienceExecution/AudienceExecution';
import {
  AudienceSpecification,
  AudienceSpecificationType,
  SqlAudienceSpecification,
} from './audienceBackTypes/audienceSpecification';
import { PreparationStep } from './audienceBackTypes/preparationStep';
import { DeployedAudience } from '../../types/DeployedAudience';
import { DataSource, FileProcessingSettings } from './audienceBackTypes/source';
import { IDecryption } from './audienceBackTypes/decryption';
import { ActiveAudience, ActiveSQLAudience } from '../../types/ActiveAudience/ActiveAudience';
import {
  SqlAudienceQueryDescriptorState,
  SqlAudienceQueryDescriptorStatus,
} from '../../types/Audience/SQL';
import {
  AudienceDeduplicationKey,
  ActiveSQLSourceConfiguration,
  sftpModifiedAtColumnSource,
  SftpSourceType,
} from '../../types';
import { IBackDataValue } from './audienceBackTypes/audienceColumn';
import { ConnectorCategoriesEnum } from '@features/connectors/types';
import { PagingSettings } from '@contracts/PagingSettings';

// get source entities
export enum GetSourceEntitiesInputTypes {
  connection = 'connection',
  audiences = 'audiences',
  syncs = 'syncs',
}

export enum SourceEntitiesOutputTypes {
  db = 'db',
  // TODO @@@@koralex [change to fs]
  sftp = 'sftp',
  api = 'api',
  audiences = 'audiences',
  syncs = 'syncs',
}

export enum GetSourceEntitiesTypes {
  file = 'file',
  folder = 'folder',
  databaseSchema = 'databaseSchema',
  databaseTable = 'databaseTable',
  databaseView = 'databaseView',
  audience = 'audience',
  sync = 'sync',
  apiEntity = 'apiEntity',
}

export type GetSourceEntitiesInput =
  | {
      type: GetSourceEntitiesInputTypes.audiences;
    }
  | {
      type: GetSourceEntitiesInputTypes.syncs;
    }
  | {
      type: GetSourceEntitiesInputTypes.connection;
      connectionId: string;
      connectorCategory: ConnectorCategoriesEnum;
    };

export type AudienceEntity = {
  key: string;
  type: GetSourceEntitiesTypes.audience;
  name: string;
  audienceId: string;
};

export type SyncEntity = {
  key: string;
  type: GetSourceEntitiesTypes.sync;
  name: string;
  syncId: string;
};

export type DatabaseSchemaEntity = {
  key: string;
  type: GetSourceEntitiesTypes.databaseSchema;
  name: string;
  tables: DatabaseTableEntity[];
  views: DatabaseViewEntity[];
};

export type DatabaseTableEntity = {
  key: string;
  type: GetSourceEntitiesTypes.databaseTable;
  name: string;
  schema: string;
  meta?: any;
};

export type DatabaseViewEntity = {
  key: string;
  type: GetSourceEntitiesTypes.databaseView;
  name: string;
  schema: string;
  meta?: any;
};
export type ApiEntity = {
  key: string;
  type: GetSourceEntitiesTypes.apiEntity;
  name: string;
};

export const EntityFactory = {
  file(props: { key: string; name: string }): FileEntity {
    return { type: FileSystemEntities.file, ...props };
  },

  folder(props: {
    key: string;
    name: string;
    children: (FileEntity | FolderEntity)[];
  }): FolderEntity {
    return { type: FileSystemEntities.folder, ...props };
  },

  audience(props: { key: string; name: string; audienceId: string }): AudienceEntity {
    return { type: GetSourceEntitiesTypes.audience, ...props };
  },
  sync(props: { key: string; name: string; syncId: string }): SyncEntity {
    return { type: GetSourceEntitiesTypes.sync, ...props };
  },

  databaseSchema(props: {
    key: string;
    name: string;
    tables: DatabaseTableEntity[];
    views: DatabaseViewEntity[];
  }): DatabaseSchemaEntity {
    return { type: GetSourceEntitiesTypes.databaseSchema, ...props };
  },

  databaseTable(props: {
    key: string;
    name: string;
    schema: string;
    location?: any;
  }): DatabaseTableEntity {
    return { type: GetSourceEntitiesTypes.databaseTable, ...props };
  },

  databaseView(props: {
    key: string;
    name: string;
    schema: string;
    meta: any;
  }): DatabaseViewEntity {
    return { type: GetSourceEntitiesTypes.databaseView, ...props };
  },

  api(props: { key: string; name: string }): ApiEntity {
    return { type: GetSourceEntitiesTypes.apiEntity, ...props };
  },
};

export type SourceEntity =
  | FileEntity
  | FolderEntity
  | DatabaseSchemaEntity
  | DatabaseTableEntity
  | AudienceEntity
  | SyncEntity
  | DatabaseViewEntity
  | ApiEntity;

export type SelectableEntity =
  | FileEntity
  | FolderEntity
  | AudienceEntity
  | SyncEntity
  | DatabaseTableEntity
  | DatabaseViewEntity
  | ApiEntity;

export enum FileSystemEntities {
  file = 'file',
  folder = 'folder',
}

export type FileEntity = {
  key: string;
  type: FileSystemEntities.file;
  name: string;
};

export type FolderEntity = {
  key: string;
  type: FileSystemEntities.folder;
  name: string;
  children: FileSystemEntity[];
};

export type FileSystemEntity = FileEntity | FolderEntity;

export type DBEntitiesOutput = {
  type: SourceEntitiesOutputTypes.db;
  entities: (DatabaseSchemaEntity | DatabaseTableEntity | DatabaseViewEntity)[];
};
export type GetSourceEntitiesOutput =
  | DBEntitiesOutput
  | {
      type: SourceEntitiesOutputTypes.sftp;
      entities: FileSystemEntity[];
    }
  | {
      type: SourceEntitiesOutputTypes.api;
      entities: ApiEntity[];
    }
  | {
      type: SourceEntitiesOutputTypes.audiences;
      entities: AudienceEntity[];
    }
  | {
      type: SourceEntitiesOutputTypes.syncs;
      entities: SyncEntity[];
    };

// API: draftSource($command)
export type APIRequestDraftSource = (
  accountId: string,
  dataWarehouseId: string,
  draftSourceInput: RequestDraftSourceInput,
  abortSignal?: AbortSignal,
  force?: boolean
) => Promise<RequestDraftSourceOutput>;
export type APIDraftSource = (
  draftSourceId: string,
  abortSignal?: AbortSignal
) => Promise<DraftSourceOutput>;

export type APIDraftAudienceSource = (
  draftSourceId: string,
  audienceId: string,
  abortSignal?: AbortSignal
) => Promise<DraftAudienceSourceOutput>;

export type APIDraftSyncSource = (
  draftSourceId: string,
  syncId: string,
  abortSignal?: AbortSignal
) => Promise<DraftSyncSourceOutput>;

// describe source
export enum RequestDraftSourceInputTypes {
  connection = 'connection',
  audience = 'audience',
  sync = 'sync',
}

export enum CounterTypes {
  none = 'none',
  counter = 'counter',
}

export type DataSourceColumn = {
  id: string;
  name: string;
  type: { dataType: columnTypesEnum };
  isNullable: boolean;
};

export type BackColumn = {
  id: string;
  name: string;
  type: IColumnType;
  isNullable: boolean;
  externalName?: string;
  defaultValue?: IBackDataValue;
};
export type RecordsCounter =
  | { type: CounterTypes.counter; counter: number }
  | { type: CounterTypes.none };
type Entity = { name: string }; // to change
type DBEntity = { name: string }; // to change

export type RecordsSample = Readonly<{
  size: number;
  records: Record<string, any>[];
}>;

export type AdvancedSftSpecificSettings = {
  type: 'advancedSftSpecificSettings';
  pattern: string;
  // TODO @@@@slava define it later
};
export type FileEntityReference = {
  type: 'fileEntityReference';
  path: string;
};

export type SftpSourceModifiedAtColumnFromSource = {
  type: sftpModifiedAtColumnSource.source;
};
export type SftpSourceModifiedAtColumnFromFileName = {
  type: sftpModifiedAtColumnSource.fileName;
  regex: string;
  columnName: string;
};
export type SftpSourceModifiedAtColumn =
  | SftpSourceModifiedAtColumnFromSource
  | SftpSourceModifiedAtColumnFromFileName;

export type ISftpSourceSettings = {
  type: ConnectorType.sftp;
  directoryPath: string;
  sourceType: SftpSourceType;
  processing: FileProcessingSettings;
  decryption: IDecryption;

  filesMatchPattern: string;
  filesMatchPatternOptions: string;
  modifiedAtColumn?: SftpSourceModifiedAtColumn;
  ignoredDirectories: string[];
};
export type IPostgresSourceSettings = {
  type: ConnectorType.postgres;
  schemaName: string;
  tableName: string;
};

export type IBigQuerySourceFilteringCriteria = {
  query: string;
  parameters: (string | number | boolean | null)[];
};

export type IBigQuerySourceSettings = {
  type: ConnectorType.bigQuery;
  datasetId: string;
  tableId: string;
  location: string;
  fullModeFilter: IBigQuerySourceFilteringCriteria;
  incrementalModeFilter: IBigQuerySourceFilteringCriteria;
};

export type IMySQLSourceSettings = {
  type: ConnectorType.mysql;
  schemaName: string;
  objectName: string;
};

export type IDatabaseSourceSettings = IPostgresSourceSettings | IBigQuerySourceSettings | IMySQLSourceSettings;

// TODO @@@@koralex [align the types and have it in one place]
export enum SourceSettingsType {
  api = 'api',
}

export type ISourceSettingsV2<Settings extends Record<string, any> = Record<string, any>> =
  IApiSourceSettingsV2<Settings>;

export type IApiSourceSettingsV2<Settings extends Record<string, any> = Record<string, any>> = {
  type: SourceSettingsType.api;
  entityTypeId: string;
  entitySettings: Settings;
};

export type RequestDraftSourceInput =
  | {
      type: RequestDraftSourceInputTypes.connection;
      connectionId: string;
      sourceSettings:
        | ISftpSourceSettings
        | IDatabaseSourceSettings
        // | ISendInBlueSourceSettings
        // | IShopifySettings
        | {
            type: ConnectorType.api;
            reference: Entity;
          }
        // | {
        //     type: ConnectorType.bigQuery;
        //     reference: Entity;
        //   }
        // | {
        //     type: ConnectorType.googleSpreadhsheets;
        //     reference: Entity;
        //   }
        | {
            type: ConnectorType.snowflake;
            reference: Entity;
          }
        | ISourceSettingsV2;
    }
  | {
      type: RequestDraftSourceInputTypes.audience;
      audienceId: string;
    }
  | {
      type: RequestDraftSourceInputTypes.sync;
      syncId: string;
    };

export enum DraftSourceStatus {
  ok = 'ok',
  error = 'error',
  pending = 'pending',
}

// describeSourceStatus
export type RequestDraftSourceOutput = {
  draftSourceId: string;
};

export type DraftSourceOutput =
  | {
      status: DraftSourceStatus.ok;
      sourceDescription: {
        counter: RecordsCounter;
        // TODO @@@@koralex [we don't provide ids for the columns]
        shape: BackColumn[];
        sample: Record<string, any>[];
        primaryKeys: null | string[];
        updatedAtColumnName: null | string;
      };
    }
  | {
      status: DraftSourceStatus.error;
      error: string;
    }
  | {
      status: DraftSourceStatus.pending;
    };
export type DraftAudienceSourceOutput =
  | {
      status: DraftSourceStatus.ok;
      sourceDescription: {
        counter: RecordsCounter;
        shape: BackColumn[];
        sample: Record<string, any>[];
        primaryKeys: null | string[];
        updatedAtColumnName: null | string;
      };
      deployedAudience: DeployedAudience;
      deduplicationKeys: AudienceDeduplicationKey[];
    }
  | {
      status: DraftSourceStatus.error;
      error: string;
    }
  | {
      status: DraftSourceStatus.pending;
    };

export type DraftSyncSourceOutput =
  | {
      status: DraftSourceStatus.ok;
      syncAudienceId: string;
      sourceDescription: {
        counter: RecordsCounter;
        shape: BackColumn[];
        sample: Record<string, any>[];
        primaryKeys: null | string[];
        updatedAtColumnName: null | string;
      };
    }
  | {
      status: DraftSourceStatus.error;
      error: string;
    }
  | {
      status: DraftSourceStatus.pending;
    };

export type DescribeSqlAudienceSourcesShapes = (
  sources: ActiveSQLSourceConfiguration[],
  accountId: string,
  dataWarehouseId: string,
  abortSignal?: AbortSignal
) => Promise<DescribeSqlAudienceSourcesShapesOutput>;

export type DescribeSqlAudienceSourcesShapesOutput = {
  sources: {
    name: string;
    source: DataSource;
    // TODO @@@@koralex [we don't provide ids for the columns]
    shape: { columns: BackColumn[] };
  }[];
  loaded?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
};

export type APIRequestSqlAudienceQueryDescriptor = (
  audience: ActiveSQLAudience,
  accountId: string,
  dataWarehouseId: string,
  abortSignal?: AbortSignal
) => Promise<RequestSqlAudienceQueryDescriptorOutput>;

export type RequestSqlAudienceQueryDescriptorOutput = {
  id: string;
  loaded?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
};

export type APISqlAudienceQueryDescriptor = (
  id: string,
  abortSignal?: AbortSignal
) => Promise<SqlAudienceQueryDescriptorOutput>;

export type SqlAudienceQueryDescriptorInput = Pick<
  SqlAudienceSpecification,
  'sqlQuery' | 'sqlMode' | 'sources'
> & {
  accountId: string;
  dataWarehouseId: string;
};

export type SqlAudienceQueryDescriptorOutput = {
  id: string;
  accountId: string;
  dataWarehouseId: string;
  version: number;
  createdAt: Date;
  updatedAt: Date;

  status: SqlAudienceQueryDescriptorStatus;
  state: SqlAudienceQueryDescriptorState;
  error?: Error;

  request: SqlAudienceQueryDescriptorInput;

  shape: { columns: BackColumn[] } | null;
};

// API: previewAudience($command)
export type APIRequestPreviewAudience = (
  audience: ActiveAudience,
  accountId: string,
  dataWarehouseId: string,
  traceparent: string,
  abortSignal?: AbortSignal
) => Promise<RequestPreviewAudienceOutput>;

export type RequestPreviewAudienceOutput = {
  audiencePreviewId: string;
  loaded?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
};

export type APIPreviewAudience = (
  audiencePreviewId: string,
  samplePaging: PagingSettings,
  abortSignal?: AbortSignal
) => Promise<PreviewAudienceOutput>;

export type PreviewAudienceOutput = {
  id: string;

  accountId: string;

  dataWarehouseId: string;

  version: number;

  createdAt: Date;

  updatedAt: Date;

  status: AudiencePreviewStatus;

  state: AudiencePreviewState;

  error?: Error;

  request: RequestPreviewAudienceInput;

  shape: { columns: BackColumn[] } | null;

  shapes: BackAudiencePreviewShapes | null;

  counter: RecordsCounter | null;

  draftAudienceId: string | null;

  executionId: string | null;

  sample: RecordsSample | null;
};

export type BackAudiencePreviewShapesPreparationStepsType = {
  steps: { step: PreparationStep; inputShape: BackColumn[] }[];
  outputShape: BackColumn[];
};
type IBackAudiencePreviewShapesOfDefaultAudience = {
  audienceType: AudienceSpecificationType.default;
  preparationSteps: BackAudiencePreviewShapesPreparationStepsType;
};

type IBackAudiencePreviewShapesOfSqlAudience = {
  audienceType: AudienceSpecificationType.sql;
};
export type BackAudiencePreviewShapes =
  | IBackAudiencePreviewShapesOfSqlAudience
  | IBackAudiencePreviewShapesOfDefaultAudience;

export type APIGetAudiencePreviewSample = (
  audiencePreviewId: string,
  samplePaging: PagingSettings
) => Promise<RecordsSample>;

export type RequestPreviewAudienceInput = {
  name: string;
  longName: string;
  accountId: string;
  dataWarehouseId: string;
  businessCategory: string;
  specification: AudienceSpecification;
};
// type AudienceSpecification =
//   DefaultAudienceSpecification
//   | SqlAudienceSpecification;
