import { ActiveDefaultAudience, buildTypesEnum } from '@features/audiences/types';
import { Audience } from '../../audienceBackTypes/audience';
import {
  ActiveAudience,
  ActiveSQLAudience,
} from '@features/audiences/types/ActiveAudience/ActiveAudience';
import {
  mappingDefaultAudienceSpecificationProperty,
  mappingSqlAudienceSpecificationProperty,
} from '@features/audiences/ducks/api/mappingAudienceTypes/toBackType/mappingAudienceSpecificationProperty/mappingAudienceSpecificationProperty';
import {
  DefaultAudienceSpecification,
  SqlAudienceSpecification,
} from '@features/audiences/ducks/api/audienceBackTypes/audienceSpecification';
import { BusinessCategoryEnum } from '@features/objects/types';

export const DefaultActiveAudienceToBackType = (
  audience: ActiveDefaultAudience,
  accountId: string,
  dataWarehouseId: string,
  version?: number
): Audience<DefaultAudienceSpecification> => {
  return {
    accountId,
    dataWarehouseId,
    id: audience.key,
    version: version || 0,
    name: audience.name || '',
    longName: audience.longName ?? '',
    deployment: audience.deployment,
    // Could cause error in case business category is undefined
    businessCategory: audience.category as BusinessCategoryEnum,
    specification: mappingDefaultAudienceSpecificationProperty(audience),
  };
};

export const SqlActiveAudienceToBackType = (
  audience: ActiveSQLAudience,
  accountId: string,
  dataWarehouseId: string,
  version?: number
): Audience<SqlAudienceSpecification> => {
  return {
    accountId,
    dataWarehouseId,
    id: audience.key,
    version: version || 0,
    name: audience.name || '',
    longName: audience.longName ?? '',
    deployment: audience.deployment,
    // Could cause error in case business category is undefined
    businessCategory: audience.category as BusinessCategoryEnum,
    specification: mappingSqlAudienceSpecificationProperty(audience),
  };
};

export function toBackType({
  audience,
  accountId,
  dataWarehouseId,
  version,
}: {
  audience: ActiveAudience;
  accountId: string;
  dataWarehouseId: string;
  version?: number;
}): Audience {
  switch (audience.buildType) {
    case buildTypesEnum.BUSINESS: {
      return DefaultActiveAudienceToBackType(audience, accountId, dataWarehouseId, version);
    }

    case buildTypesEnum.DATA: {
      return SqlActiveAudienceToBackType(audience, accountId, dataWarehouseId, version);
    }

    default: {
      throw new Error(`Case ${audience.buildType} is not supported`);
    }
  }
}
