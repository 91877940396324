import { IColumnType } from './columnType';

export type AudienceColumnId = string;

export type AudienceColumnName = string;

export type AudienceColumnType = IColumnType;

export enum BackDataValueType {
  static = 'static',
  sequence = 'sequence',
  function = 'function',
  special = 'special',
}

// Static Values
export type IStaticDataValue<DataType> = {
  type: BackDataValueType.static;
  value: DataType;
};

// Sequence Values

export type ISequenceDataValue = Readonly<{
  type: BackDataValueType.sequence;

  sequence: ISequenceInformation;
}>;
export type ISequenceInformation = Readonly<{
  id: string;
  namespace: string;
}>;
// Function Values
export enum BackFunctionArgumentType {
  value = 'value',
  sequence = 'sequence',
}
export type IFunctionValueArgument = {
  type: BackFunctionArgumentType.value;
  dataType: IColumnType;
  value: any;
};
export type IFunctionSequenceArgument = {
  type: BackFunctionArgumentType.sequence;
  sequence: ISequenceInformation;
  outputType: IColumnType;
};
export type IBackFunctionArgument = IFunctionValueArgument | IFunctionSequenceArgument;
export type IFunctionDataValue = {
  type: BackDataValueType.function;

  name: string;
  args: IBackFunctionArgument[];
  outputType: IColumnType;
};

// Special Values
export enum SpecialDataValue {
  CurrentTimestamp = 'current_timestamp',
  RandomUUID = 'random_uuid',
}
export type ISpecialDataValue<Value extends SpecialDataValue | null> = Readonly<{
  type: BackDataValueType.special;

  value: Value;
}>;

// Combined Values

export type IBackDataValue<
  StaticValueDataType = any,
  SpecialDataValueType extends SpecialDataValue | null = any
> =
  | IStaticDataValue<StaticValueDataType>
  | ISequenceDataValue
  | IFunctionDataValue
  | ISpecialDataValue<SpecialDataValueType>;

export type AudienceColumn = Readonly<{
  id: AudienceColumnId;
  name: AudienceColumnName;
  type: AudienceColumnType;
  defaultValue?: IBackDataValue;
}>;
