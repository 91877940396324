import { accountDefaultConfiguration } from '@data/account';
import { createSlice } from '@reduxjs/toolkit';
import { isAccountBlockedFromSavingData } from '@utils/block-accounts';
import type {
  AccountReducerActions,
  AccountsDetailsReducerActions,
  AccountState,
  UpdateActiveAccountReducerActions,
} from './types';

export const INITIAL_THEME_STATE = {
  loaded: false,
  isFetching: false,
  account: accountDefaultConfiguration,
  accountsDetails: [],
} as AccountState;

const accountSlice = createSlice({
  name: 'accounts',

  initialState: INITIAL_THEME_STATE,
  reducers: {
    getAccount: (state) => {
      state.isFetching = true;
    },
    getAccountSuccess: (state, { payload }: AccountReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.account = payload.account;
    },
    getAccountFailed: (state, { payload }: AccountReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveAccount: (state) => {
      state.isFetching = true;
    },
    saveAccountSuccess: (state, { payload }: AccountReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.account = payload.account;
    },
    saveAccountFailed: (state, { payload }: AccountReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    getAccounts: (state) => {
      state.isFetching = true;
    },
    getAccountsSuccess: (state, { payload }: AccountsDetailsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.accountsDetails = payload.accountsDetails;
    },
    getAccountsFailed: (state, { payload }: AccountsDetailsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    updateActiveAccount: (state) => {
      state.isFetching = true;
    },
    updateActiveAccountSuccess: (state, { payload }: UpdateActiveAccountReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.activeAccountId = payload.activeAccountId;
      state.isAccountIdBlocked = isAccountBlockedFromSavingData(payload.activeAccountId);

      state.account.visibleFeatures = payload.visibleFeatures || state.account.visibleFeatures;
      state.account.accessibleFeatures =
        payload.accessibleFeatures || state.account.accessibleFeatures;
    },
    updateActiveAccountFailed: (state, { payload }: UpdateActiveAccountReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
  },
});

export default accountSlice;
