import { AudienceColumn } from './audienceColumn';
import { ColumnAggregation } from './columnAggregations';
import { PreparationStep } from './preparationStep';
import { DataSource, SourceColumn } from './source';
import { SqlMode } from '@features/audiences/types/ActiveAudience/ActiveAudience';
import { ParentAudience } from '@features/audiences/ducks/api/audienceBackTypes/ParentAudience';
import { Schedule } from '@contracts/schedule';
import { BackDataApiPreparation } from './backColumnPreparationStep';
import { BusinessModel } from './BusinessModel';

export enum AudienceSpecificationType {
  default = 'default',
  sql = 'sql',
}

export type AudienceSpecification = DefaultAudienceSpecification | SqlAudienceSpecification;

// TODO @@@@slava do we need business type for Audience
export type DefaultAudienceSpecification = Readonly<{
  type: AudienceSpecificationType.default;

  // Only Unioned-Sources Columns
  // TODO @@@@slava [high] rename to unionedColumns or baseAudienceColumns or find any good name,
  //  that represents that these columns now only contains shape of unioned sources
  //  and other audience columns calculated by `preparations steps` and `aggregations`
  // TODO @@@@gregory discussing how modified-at / updated-at fits into the picture
  columns: AudienceColumn[];

  // Only the first key is supported - Dedupe does NOT support multi-key
  //   -> Add support later
  deduplicationSettings: BackDeduplicationSettings;

  immutable: AudienceColumn['id'][];
  nonNullableAtMergeColumns?: string[];

  sources: {
    unioned: UnionedAudienceSource[];
    joined: JoinedAudienceSource[];
  };

  parent: ParentAudience | null;
  preparationSteps: PreparationStep[];
  // Data-API Settings
  dataApiEnabled: boolean;
  dataApiMandatoryColumns?: string[];
  dataApiProtectedColumns?: string[];
  dataApiPreparations?: BackDataApiPreparation[];
  schedule: Schedule;
  businessModel?: BusinessModel;
}>;

export type UnionedAudienceSource = {
  source: DataSource;
  columnsMapping: SourceToAudienceColumnMapping[];
};

// TODO @@@slava might be later add id???
export type SourceToAudienceColumnMapping = {
  sourceColumnId: SourceColumn['id'];
  audienceColumnId: AudienceColumn['id'];
};

// Joined Source / Aggregations
export enum JoinedAudienceSourceConditionType {
  regular = 'regular',
  source_id_list = 'source_id_list',
}

export enum JoinedAudienceSourceColumnConditionType {
  master_id = 'master_id',
  data = 'data',
}

export type RegularJoinedAudienceSourceOnDataConditionColumn = Readonly<{
  type: JoinedAudienceSourceColumnConditionType.data;
  sourceColumnId: SourceColumn['id'];
  audienceColumnId: AudienceColumn['id'];
}>;

export type RegularJoinedAudienceSourceOnMasterIdConditionColumn = Readonly<{
  type: JoinedAudienceSourceColumnConditionType.master_id;
  sourceColumnId: SourceColumn['id'];
}>;

export type RegularJoinedAudienceSourceConditionColumn =
  | RegularJoinedAudienceSourceOnMasterIdConditionColumn
  | RegularJoinedAudienceSourceOnDataConditionColumn;

export type RegularJoinedAudienceSourceCondition = Readonly<{
  type: JoinedAudienceSourceConditionType.regular;
  columns: RegularJoinedAudienceSourceConditionColumn[];
}>;

export type SourceIdJoinedAudienceSourceCondition = Readonly<{
  type: JoinedAudienceSourceConditionType.source_id_list;

  sourceColumnId: SourceColumn['id'];

  sourceId: string;
  sourceIdColumnId: SourceColumn['id'];
}>;
export type JoinedAudienceSourceCondition =
  | RegularJoinedAudienceSourceCondition
  | SourceIdJoinedAudienceSourceCondition;

export type JoinedAudienceSource<
  Condition extends JoinedAudienceSourceCondition = JoinedAudienceSourceCondition
> = Readonly<{
  source: DataSource;
  condition: Condition;
  aggregatedColumns: AggregatedAudienceColumn[];
}>;

export type JoinedSourceAudienceJoinCondition = Readonly<{
  sourceColumnId: SourceColumn['id'];
  audienceColumnId: AudienceColumn['id'];
}>;

export type AggregatedAudienceColumn = Readonly<{
  targetColumnId: AudienceColumn['id'];
  targetColumnName: AudienceColumn['name'];

  aggregation: ColumnAggregation;
}>;

// -----------

// TODO @@@@koralex do we need extra settings to manage the case for the full/incremental mode?
// TODO @@@@koralex can we validate a query for all cases for incremental mode?
// TODO @@@@koralex what's expected output for input incremental mode? it could be full or incremental
export type SqlAudienceSpecification = Readonly<{
  readonly type: AudienceSpecificationType.sql;

  // TODO @@@@slava generalize???
  columns: AudienceColumn[];
  deduplicationSettings: BackDeduplicationSettings;

  immutable: AudienceColumn['id'][];

  // TODO @@@@slava remove completely if necessary
  modifiedAtColumnId: AudienceColumn['id'];

  sources: SqlAudienceSource[];

  sqlQuery: string;

  sqlMode?: SqlMode;

  schedule: Schedule;

  dataApiEnabled: boolean;
}>;

export type SqlAudienceSource = {
  source: DataSource;
};

export type BackDeduplicationSettings = Readonly<{
  // TODO @@@@koralex order is important? = YES, have weights = NO?
  // [['masterId'], ['email', 'firstName']]
  keys: BackAudienceDeduplicationKeys;
  idsOfColumnValuesToBePreserved: AudienceColumn['id'][];
}>;
export type BackAudienceDeduplicationKeys =
  | BackAudienceDeduplicationKey[]
  | BackAudienceDeduplicationMasterId;
export type BackAudienceDeduplicationMasterId = 'masterid';

export type BackAudienceDeduplicationKey = Readonly<{
  columnIds: AudienceColumn['id'][];
}>;
