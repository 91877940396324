import React, { createContext } from 'react';
import { AppContextType } from '@features/app/types';
import { ReducerStates } from '@redux/reducers';
import { connect } from 'react-redux';
import { appDefaultConfiguration } from '@data/app';

export const AppContext = createContext<AppContextType>(appDefaultConfiguration);
const mapStateToProps = (state: ReducerStates) => {
  return {
    appContext: state.appContext.appContext,
  };
};
// @@@Amine connect the context to the state
const AppContextContainer = ({
  children,
  appContext,
}: {
  children: JSX.Element;
  appContext: AppContextType;
}) => {
  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};
export const AppContextProvider = connect(mapStateToProps)(AppContextContainer);
