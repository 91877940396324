import { OrganizationByDomain, OrganizationType } from '@features/organizations/types';
import { caseNever } from '@utils/case-never';

const getDataWarehouseNameSpaces = (
  organizationByDomain: OrganizationByDomain,
  accountSlug: string
) => {
  switch (organizationByDomain.type) {
    case OrganizationType.octolis:
      return {
        publicNamespace: `${organizationByDomain.type}_${accountSlug}_public`,
        privateNamespace: `${organizationByDomain.type}_${accountSlug}_private`,
        temporaryNamespace: `${organizationByDomain.type}_${accountSlug}_temporary`,
      };
    case OrganizationType.customer:
      return {
        publicNamespace: `${organizationByDomain.partnerId}_${accountSlug}_public`,
        privateNamespace: `${organizationByDomain.partnerId}_${accountSlug}_private`,
        temporaryNamespace: `${organizationByDomain.partnerId}_${accountSlug}_temporary`,
      };
    case OrganizationType.partner:
      return {
        publicNamespace: `${organizationByDomain.partnerId}_${accountSlug}_public`,
        privateNamespace: `${organizationByDomain.partnerId}_${accountSlug}_private`,
        temporaryNamespace: `${organizationByDomain.partnerId}_${accountSlug}_temporary`,
      };

    default:
      caseNever(organizationByDomain);
  }
};

export default getDataWarehouseNameSpaces;
