import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

import { thunk } from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import sagas from './sagas';
import reducers from './reducers';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const sagaMiddleware = createSagaMiddleware();

const middleware = [routerMiddleware, thunk, sagaMiddleware];

const store = configureStore({
  reducer: reducers(routerReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
  devTools: {
    trace: true,
  },
});

sagaMiddleware.run(sagas);

export const history = createReduxHistory(store);

export default store;
