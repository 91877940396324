import { gql } from '@apollo/client';

const CREATE_SYNC = gql`
  mutation ($command: CreateSyncCommand!) {
    createSync(command: $command) {
      id
      version
      accountId
      dataWarehouseId
      name
      isEnabled
      specification {
        audienceId
        destination
        segmentation
        executionTrigger {
          ... on RealTimeSyncExecutionTriggerSpecification {
            type
          }
          ... on ManualSyncExecutionTriggerSpecification {
            type
          }
          ... on ScheduledSyncExecutionTriggerSpecification {
            type
            schedule {
              type
              value
            }
            recordsScope {
              ... on AllSyncRecordsScope {
                type
              }

              ... on UnprocessedSyncRecordsScope {
                type
              }

              ... on SyncRecordsInTimePeriodScope {
                type
                filter {
                  ... on SyncCreatedRecordsScopeFilter {
                    type
                    createdAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }

                  ... on SyncUpdatedRecordsScopeFilter {
                    type
                    updatedAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }

                  ... on SyncCreatedOrUpdatedRecordsScopeFilter {
                    type
                    createdAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                    updatedAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
const UPDATE_SYNC = gql`
  mutation ($command: UpdateSyncCommand!) {
    updateSync(command: $command) {
      __typename
      ... on Sync {
        id
        version
        accountId
        dataWarehouseId
        name
        isEnabled
        specification {
          audienceId
          destination
          segmentation
          executionTrigger {
            ... on RealTimeSyncExecutionTriggerSpecification {
              type
            }
            ... on ManualSyncExecutionTriggerSpecification {
              type
            }
            ... on ScheduledSyncExecutionTriggerSpecification {
              type
              schedule {
                type
                value
              }
              recordsScope {
                ... on AllSyncRecordsScope {
                  type
                }

                ... on UnprocessedSyncRecordsScope {
                  type
                }

                ... on SyncRecordsInTimePeriodScope {
                  type
                  filter {
                    ... on SyncCreatedRecordsScopeFilter {
                      type
                      createdAt {
                        ... on RecordsScopeBeforeDateFilter {
                          type
                          before
                        }

                        ... on RecordsScopeAfterDateFilter {
                          type
                          after
                        }

                        ... on DateRangeRecordsScopeFilter {
                          type
                          after
                          before
                        }
                      }
                    }

                    ... on SyncUpdatedRecordsScopeFilter {
                      type
                      updatedAt {
                        ... on RecordsScopeBeforeDateFilter {
                          type
                          before
                        }

                        ... on RecordsScopeAfterDateFilter {
                          type
                          after
                        }

                        ... on DateRangeRecordsScopeFilter {
                          type
                          after
                          before
                        }
                      }
                    }

                    ... on SyncCreatedOrUpdatedRecordsScopeFilter {
                      type
                      createdAt {
                        ... on RecordsScopeBeforeDateFilter {
                          type
                          before
                        }

                        ... on RecordsScopeAfterDateFilter {
                          type
                          after
                        }

                        ... on DateRangeRecordsScopeFilter {
                          type
                          after
                          before
                        }
                      }
                      updatedAt {
                        ... on RecordsScopeBeforeDateFilter {
                          type
                          before
                        }

                        ... on RecordsScopeAfterDateFilter {
                          type
                          after
                        }

                        ... on DateRangeRecordsScopeFilter {
                          type
                          after
                          before
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
const DELETE_SYNC = gql`
  mutation ($command: DeleteSyncCommand!) {
    deleteSync(command: $command) {
      __typename
    }
  }
`;

const ENABLE_SYNC = gql`
  mutation ($command: EnableSyncCommand!) {
    enableSync(command: $command) {
      __typename
    }
  }
`;

const DISABLE_SYNC = gql`
  mutation ($command: DisableSyncCommand!) {
    disableSync(command: $command) {
      __typename
    }
  }
`;
const REQUEST_EXECUTE_SYNC = gql`
  mutation ($command: RequestSyncExecutionCommand!) {
    requestSyncExecution(command: $command) {
      __typename
      ... on SyncExecutionRequestAccepted {
        executionId
      }
    }
  }
`;
const CREATE_DATABASE_SYNC_TABLE = gql`
  mutation createDatabaseSyncTable($command: CreateDatabaseSyncTableCommand!) {
    createDatabaseSyncTable(command: $command) {
      success
      errors
    }
  }
`;
const ALTER_DATABASE_SYNC_TABLE = gql`
  mutation alterDatabaseSyncTable($command: AlterDatabaseSyncTableCommand!) {
    alterDatabaseSyncTable(command: $command) {
      success
      modified
      errors
    }
  }
`;
const VALIDATE_DATABASE_SYNC_TABLE = gql`
  mutation validateDatabaseSyncTable($command: ValidateDatabaseSyncTableCommand!) {
    validateDatabaseSyncTable(command: $command) {
      success
      errors
    }
  }
`;
export {
  CREATE_SYNC,
  DELETE_SYNC,
  ENABLE_SYNC,
  DISABLE_SYNC,
  REQUEST_EXECUTE_SYNC,
  UPDATE_SYNC,
  CREATE_DATABASE_SYNC_TABLE,
  ALTER_DATABASE_SYNC_TABLE,
  VALIDATE_DATABASE_SYNC_TABLE,
};
