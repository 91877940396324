export enum ManualAudienceExecutionSubjectType {
  entireAudience = 'entireAudience',
  connectionSource = 'connectionSource',
  connectionSourceCasting = 'connectionSourceCasting',
  webhookSource = 'webhookSource',
  syncSource = 'syncSource',
  sourcesMapping = 'sourcesMapping',
  aggregatesAndPreparations = 'aggregatesAndPreparations',
  sql = 'sql',
}
export type EntireAudienceManualAudienceExecutionSubjectInputType = {
  type: ManualAudienceExecutionSubjectType.entireAudience;
};
export enum SourceExecutionMode {
  full = 'full',
  incremental = 'incremental',
}

export type ConnectionSourceManualAudienceExecutionSubjectInputType = {
  type: ManualAudienceExecutionSubjectType.connectionSource;
  sourceId: string;
  mode?: SourceExecutionMode;
};

export type ConnectionSourceCastingManualAudienceExecutionSubjectInputType = {
  type: ManualAudienceExecutionSubjectType.connectionSourceCasting;
  sourceId: string;
};
export type WebhookSourceManualAudienceExecutionSubjectInputType = {
  type: ManualAudienceExecutionSubjectType.webhookSource;
  sourceId: string;
};
export type SyncSourceManualAudienceExecutionSubjectInputType = {
  type: ManualAudienceExecutionSubjectType.syncSource;
  syncId: string;
};
export type SourcesMappingManualAudienceExecutionSubjectInputType = {
  type: ManualAudienceExecutionSubjectType.sourcesMapping;
};
export type AggregatesAndPreparationsManualAudienceExecutionSubjectInputType = {
  type: ManualAudienceExecutionSubjectType.aggregatesAndPreparations;
};

export type SqlManualAudienceExecutionSubjectInputType = {
  type: ManualAudienceExecutionSubjectType.sql;
};

export type ManualAudienceExecutionSubjectInputType =
  | EntireAudienceManualAudienceExecutionSubjectInputType
  | ConnectionSourceManualAudienceExecutionSubjectInputType
  | ConnectionSourceCastingManualAudienceExecutionSubjectInputType
  | WebhookSourceManualAudienceExecutionSubjectInputType
  | SyncSourceManualAudienceExecutionSubjectInputType
  | SourcesMappingManualAudienceExecutionSubjectInputType
  | AggregatesAndPreparationsManualAudienceExecutionSubjectInputType
  | SqlManualAudienceExecutionSubjectInputType;
export interface RequestAudienceExecutionCommand {
  audienceId: string;
  subject: ManualAudienceExecutionSubjectInputType;
  description: string;
}

export enum AudienceExecutionResultsTypes {
  audienceExecutionRequestAccepted = 'AudienceExecutionRequestAccepted',
  audienceExecutionAudienceNotFound = 'AudienceExecutionAudienceNotFound',
  audienceExecutionInvalidRequest = 'AudienceExecutionInvalidRequest',
  audienceRecordsScopeIsEmpty = 'AudienceRecordsScopeIsEmpty',
  audienceExecutionAudienceNotDeployed = 'AudienceExecutionAudienceNotDeployed',
  audienceExecutionRequestNotAcceptedDueSubjectAlreadyTriggered = 'AudienceExecutionAudienceNotDeployed',
}

type AudienceExecutionRequestAccepted = {
  __typename: AudienceExecutionResultsTypes.audienceExecutionRequestAccepted;
  executionId: string;
};
type AudienceExecutionAudienceNotFound = {
  __typename: AudienceExecutionResultsTypes.audienceExecutionAudienceNotFound;
  audienceId: string;
};
type AudienceExecutionAudienceNotDeployed = {
  __typename: AudienceExecutionResultsTypes.audienceExecutionAudienceNotDeployed;
  audienceId: string;
};
type AudienceExecutionInvalidRequest = {
  __typename: AudienceExecutionResultsTypes.audienceExecutionInvalidRequest;
  audienceId: string;
  error: string;
};
type AudienceRecordsScopeIsEmpty = {
  __typename: AudienceExecutionResultsTypes.audienceRecordsScopeIsEmpty;
  audienceId: string;
};
type AudienceExecutionRequestNotAcceptedDueSubjectAlreadyTriggered = {
  __typename: AudienceExecutionResultsTypes.audienceExecutionRequestNotAcceptedDueSubjectAlreadyTriggered;
  queuedExecutionId: string;
};
export type RequestAudienceExecutionResult =
  | AudienceExecutionRequestAccepted
  | AudienceExecutionAudienceNotFound
  | AudienceExecutionInvalidRequest
  | AudienceRecordsScopeIsEmpty
  | AudienceExecutionAudienceNotDeployed
  | AudienceExecutionRequestNotAcceptedDueSubjectAlreadyTriggered;
