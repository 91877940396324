import { IRecordsScopeFilter } from './ISyncRecordsScopeFilter';

export enum SyncRecordsScope {
  allRecords = 'allRecords',
  unprocessedRecords = 'unprocessedRecords',
  recordsInTimePeriod = 'recordsInTimePeriod',
}

export type IAllSyncRecordsScope = {
  type: SyncRecordsScope.allRecords;
};
export type IUnprocessedSyncRecordsScope = {
  type: SyncRecordsScope.unprocessedRecords;
};
export type ISyncRecordsInTimePeriodScope = {
  type: SyncRecordsScope.recordsInTimePeriod;
  filter: IRecordsScopeFilter;
};
export type ISyncRecordsScope =
  | IAllSyncRecordsScope
  | IUnprocessedSyncRecordsScope
  | ISyncRecordsInTimePeriodScope;
