import { call, put } from 'redux-saga/effects';
import audiencesSlice from '../audiencesSlice';
import {
  RequestAudienceExecutionFailedAction,
  RequestAudienceExecutionSaga,
  RequestAudienceExecutionSuccessAction,
} from '../types';
import { requestAudienceExecution } from '../api/api';
import { getApiErrorMessage } from '@utils/api-error-handler';

export function* requestAudienceExecutionSaga({ payload }: RequestAudienceExecutionSaga) {
  const { requestAudienceExecutionCommand } = payload;
  try {
    const requestAudienceExecutionResponse: { executionId: string } = yield call(
      requestAudienceExecution,
      requestAudienceExecutionCommand
    );
    yield put<RequestAudienceExecutionSuccessAction>({
      type: audiencesSlice.actions.requestAudienceExecutionSuccess.type,
      payload: {
        executionId: requestAudienceExecutionResponse.executionId,
      },
    });
  } catch (error: unknown) {
    const errorMessage = getApiErrorMessage(error);
    yield put<RequestAudienceExecutionFailedAction>({
      type: audiencesSlice.actions.requestAudienceExecutionFailed.type,
      payload: {
        errorDetails: errorMessage,
      },
    });
  }
}
