export enum AudiencePreviewStatus {
  ok = 'ok',
  error = 'error',
  pending = 'pending',
}

export enum AudienceExecutionStatus {
  pending = 'pending',
  in_progress = 'in_progress',
  completed = 'completed',
  failed = 'failed',
  aborting = 'aborting',
  aborted = 'aborted',
}
export enum AudiencePreviewState {
  deploying = 'deploying',
  deployed = 'deployed',
  executing = 'executing',
  executed = 'executed',
  deploymentFailed = 'deploymentFailed',
  executionFailed = 'executionFailed',
}

export type Execution = {
  id: string;
  version: number;
  createdAt: Date;
  updatedAt: Date;
  steps: any;
  status: AudienceExecutionStatus;
  startedAt: Date;
  endedAt: Date;
};

export type LatestExecution =
  | PendingLatestExecution
  | InProgressLatestExecution
  | FinishedLatestExecution;

export type PendingLatestExecution = Readonly<{
  id: string;
  status: AudienceExecutionStatus.pending;
  createdAt: Date;
  startedAt: undefined;
  endedAt: undefined;
}>;

export type InProgressLatestExecution = Readonly<{
  id: string;
  status: AudienceExecutionStatus.in_progress | AudienceExecutionStatus.aborting;
  createdAt: Date;
  startedAt: Date;
  endedAt: undefined;
}>;

export type FinishedLatestExecution =
  | Readonly<{
      id: string;
      status: AudienceExecutionStatus.completed | AudienceExecutionStatus.failed;
      createdAt: Date;
      startedAt: Date;
      endedAt: Date;
    }>
  | AbortedLatestExecution;

export type AbortedLatestExecution = Readonly<{
  id: string;
  status: AudienceExecutionStatus.aborted;
  createdAt: Date;
  startedAt: Date;
  endedAt: Date;
  abortedBy: string;
}>;
