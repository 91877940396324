import ScreenSpinner from '@components/screenSpinner';
import React, { ComponentType, FC, useEffect, useMemo } from 'react';
import { useAuth0 } from '@providers/Auth0Provider';

interface IProtectedRouteProps {
  component: ComponentType<{}>;
}

export const ProtectedRoute = ({ component }: IProtectedRouteProps) => {
  const Component = useMemo(() => {
    return withAuthenticationRequired(component);
  }, [component]);

  return <Component />;
};

const withAuthenticationRequired = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const { isAuthenticated, loading, loginWithRedirect } = useAuth0();
    useEffect(() => {
      if (loading || isAuthenticated) {
        return;
      }
      const opts = {
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      };
      (async (): Promise<void> => {
        loginWithRedirect && (await loginWithRedirect(opts));
      })();
    }, [loading, isAuthenticated]);

    return isAuthenticated ? <Component {...props} /> : <ScreenSpinner />;
  };
};
