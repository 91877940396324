import { columnShapeTypeEnum, DefaultColumn, MappingRow } from '@features/audiences/types';
import { AudienceColumn } from '@features/audiences/types/AudienceColumn';
import { AudienceColumn as BackAudienceColumn } from '@features/audiences/ducks/api/audienceBackTypes/audienceColumn';
import { generateId } from '@utils/helpers';
import { BackColumn } from '../../types';
import { mappingColumnTypePropertyToFront } from './mappingColumnTypePropertyToFront';

export const mappingDefaultAudienceShapeToFront = (
  columns: BackColumn[],
  mappingRows: MappingRow[]
): AudienceColumn[] => {
  // Mapping audience columns property
  const audienceShape: AudienceColumn[] = columns.map((col) => {
    // Search column from mappingRows with column name
    const audienceMapping = mappingRows.find(
      (mappingRow) => mappingRow.audienceColumnName === col.name
    );
    const column: AudienceColumn = {
      id: audienceMapping ? audienceMapping.key : generateId(),
      type: mappingColumnTypePropertyToFront(col.type),
      name: col.name,
    };
    return column;
  });
  return audienceShape;
};

export const mappingSqlAudienceShapeToFront = (columns: BackColumn[]): AudienceColumn[] => {
  // Mapping audience columns property
  const audienceShape: AudienceColumn[] = columns.map((col) => {
    return {
      id: generateId(),
      type: mappingColumnTypePropertyToFront(col.type),
      name: col.name,
    };
  });
  return audienceShape;
};

export function mapBackAudienceColumnsToFront(columns: BackAudienceColumn[]): DefaultColumn[] {
  return columns.map(
    ({ id, name, type, defaultValue }): DefaultColumn => ({
      id,
      name,
      shapeType: columnShapeTypeEnum.DEFAULT,
      type: mappingColumnTypePropertyToFront(type),
      externalName: name,
      isNullable: !!defaultValue, // TODO @@@@koralex some of the columns should not be nullable, but
    })
  );
}
