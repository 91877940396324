import { ITyped } from '@contracts/ITyped';
import { JSONValue } from '@contracts/JSONValue';

export enum SegmentColumnOperatorType {
  is = 'is',
  isNot = 'isNot',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
  matchesRegExp = 'matchesRegExp',
  contains = 'contains',
  isIn = 'isIn',
  isNotIn = 'isNotIn',
}

export type isOperator = ITyped<{
  type: SegmentColumnOperatorType.is;
  value: JSONValue;
}>;
export type isNotOperator = ITyped<{
  type: SegmentColumnOperatorType.isNot;
  value: JSONValue;
}>;
export type isEmptyOperator = ITyped<{
  type: SegmentColumnOperatorType.isEmpty;
}>;
export type isNotEmptyOperator = ITyped<{
  type: SegmentColumnOperatorType.isNotEmpty;
}>;
export type matchesRegExpOperator = ITyped<{
  type: SegmentColumnOperatorType.matchesRegExp;
  expression: string;
  isCaseSensitive: boolean;
}>;
export type containsOperator = ITyped<{
  type: SegmentColumnOperatorType.contains;
  value: JSONValue;
}>;
export type isInOperator = ITyped<{
  type: SegmentColumnOperatorType.isIn;
  values: JSONValue[];
}>;
export type isNotInOperator = ITyped<{
  type: SegmentColumnOperatorType.isNotIn;
  values: JSONValue[];
}>;
export type SegmentColumnOperator =
  | isOperator
  | isNotOperator
  | isEmptyOperator
  | isNotEmptyOperator
  | matchesRegExpOperator
  | containsOperator
  | isInOperator
  | isNotInOperator;
