import { IColumnType } from '@features/audiences/ducks/api/audienceBackTypes/columnType';
import {
  FormattingPreparationActionErrorHandlingStrategy,
  PhoneFormattingInvalidOutput,
  PhoneFormattingOutputPrefix,
  RoundNumbersPreparationActionColumnConfig,
  TruncateTimestampsPreparationActionColumnConfig,
} from '@features/audiences/types';
export type BackSourcePreparation =
  | FormatAsStringColumnPreparationAction
  | FormatAsBooleanColumnPreparationAction
  | CustomFunctionColumnPreparationAction
  | RenameColumnPreparationAction
  | ChangeStringsCasesColumnPreparationAction
  | FindReplaceInStringsColumnPreparationAction
  | ReplaceStringColumnValuesWithNullColumnPreparationAction
  | TrimStringColumnValuesColumnPreparationAction
  | FormatPhoneNumberPreparationAction
  | RoundNumberPreparationAction
  | TruncateTimestampPreparationAction;

export type BackDataApiPreparation =
  | CustomFunctionColumnPreparationAction
  | ChangeStringsCasesColumnPreparationAction
  | FindReplaceInStringsColumnPreparationAction
  | ReplaceStringColumnValuesWithNullColumnPreparationAction
  | TrimStringColumnValuesColumnPreparationAction
  | FormatPhoneNumberPreparationAction
  | RoundNumberPreparationAction
  | TruncateTimestampPreparationAction;

export enum PreparationActionType {
  filter_by_flags = 'filter_by_flags',

  format_as_string = 'format_as_string',
  format_as_boolean = 'format_as_boolean',

  extract_json_by_path = 'extract_json_by_path',

  round_numbers = 'round_numbers',

  combine_values = 'combine_values',
  concat_values_as_string = 'concat_values_as_string',
  hash_values = 'hash_values',

  conditional_statement_sql = 'conditional_statement_sql',
  custom_function = 'custom_function',
  custom_sql = 'custom_sql',

  copy_columns = 'copy_columns',
  // create_columns = 'create_columns',
  delete_columns = 'delete_columns',
  keep_columns = 'keep_columns',
  rename_columns = 'rename_columns',

  change_strings_cases = 'change_strings_cases',
  find_replace_in_strings = 'find_replace_in_strings',
  format_phone_numbers = 'format_phone_numbers',
  replace_string_column_values_with_null = 'replace_string_column_values_with_null',
  // split_emails_into_parts = 'split_emails_into_parts',
  split_string_column_values_to_array_columns = 'split_string_column_values_to_array_columns',
  test_string_column_values_with_regex = 'test_string_column_values_with_regex',
  trim_string_column_values = 'trim_string_column_values',

  // extract_parts_from_timestamps = 'extract_parts_from_timestamps',
  format_timestamps_as_strings = 'format_timestamps_as_strings',
  generate_current_timestamp_columns = 'generate_current_timestamp_columns',
  truncate_timestamps = 'truncate_timestamps',

  // Scoring
  rfm = 'rfm',
  ltv = 'ltv',
  random_repartition = 'random_repartition',
  avg_time_between_two_records = 'avg_time_between_two_records',
  // Business Preparation
  civility_to_gender = 'civility_to_gender',
  encrypt_pii = 'encrypt_pii',
  split_email_address_to_local_domain = 'split_email_address_to_local_domain',
  get_age_from_date = 'get_age_from_date',
  exclude_records_from_source = 'exclude_records_from_source',
  enrich_from_iso_language_codes = 'enrich_from_iso_language_codes',
  enrich_from_iso_country_codes = 'enrich_from_iso_country_codes',
  check_emails_validity = 'check_emails_validity',
  split_url = 'split_url',
  firstname_analysis = 'firstname_analysis',
  ip_to_geoloc = 'ip_to_geoloc',
}
export type FormatAsStringColumnPreparationAction = Readonly<{
  type: PreparationActionType.format_as_string;
  column: string; // Source Id
}>;

export type CustomFunctionColumnPreparationAction = Readonly<{
  type: PreparationActionType.custom_function;
  function: {
    name: string;
    expectedType: IColumnType;
  };
  column: string;
}>;

export type RenameColumnPreparationAction = Readonly<{
  type: PreparationActionType.rename_columns;
  column: {
    originalColumnId: string; // Source Id
    targetColumnName: string; // Source name
  };
}>;

export type ChangeStringsCasesColumnPreparationAction = Readonly<{
  type: PreparationActionType.change_strings_cases;
  column: {
    columnId: string; // Source Id
    stringCase: StringCaseChangeType;
  };
}>;
export enum StringCaseChangeType {
  lowercase = 'lowercase',
  uppercase = 'uppercase',
  initcase = 'initcase',
  initcase_first_only = 'initcase_first_only',
}

export type FindReplaceInStringsColumnPreparationAction = Readonly<{
  type: PreparationActionType.find_replace_in_strings;
  column: {
    columnId: string;
    find: string;
    replace: string;
    isCaseSensitive: boolean;
  };
}>;

export type ReplaceStringColumnValuesWithNullColumnPreparationAction = Readonly<{
  type: PreparationActionType.replace_string_column_values_with_null;
  column: string; // Source Id
}>;

export type TrimStringColumnValuesColumnPreparationAction = Readonly<{
  type: PreparationActionType.trim_string_column_values;
  column: string; // Source Id
}>;

export type FormatPhoneNumberPreparationAction = Readonly<{
  type: PreparationActionType.format_phone_numbers;

  prefix: PhoneFormattingOutputPrefix;

  output: PhoneFormattingInvalidOutput;

  column: string;
}>;

export type FormatAsBooleanColumnPreparationAction = Readonly<{
  type: PreparationActionType.format_as_boolean;

  column: string;

  onError: FormattingPreparationActionErrorHandlingStrategy;
}>;
export type RoundNumberPreparationAction = Readonly<{
  type: PreparationActionType.round_numbers;

  column: RoundNumbersPreparationActionColumnConfig;
}>;

export type TruncateTimestampPreparationAction = Readonly<{
  type: PreparationActionType.truncate_timestamps;

  column: TruncateTimestampsPreparationActionColumnConfig;
}>;
