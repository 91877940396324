import { BusinessTerm } from '../../audiences/ducks/api/types/ListBusinessTerms';
import { ActiveDefaultAudience } from '../../audiences/types/ActiveAudience/ActiveAudience';
import { BusinessColumn } from '../../audiences/ducks/api/audienceBackTypes/BusinessModel';

const adaptBusinessModelToBusinessTerms = (
  activeAudience: ActiveDefaultAudience,
  businessTerms: BusinessTerm[]
) => {
  const businessModelColumns = activeAudience.businessModel.columns;
  const activeAudienceCategory = activeAudience.category;

  const newBusinessModel = businessModelColumns.reduce(
    (newBusinessModel: BusinessColumn[], current) => {
      const currentBusinessTerm = businessTerms.find(
        (businessTerm) =>
          businessTerm.name === current.businessTerm &&
          activeAudienceCategory &&
          businessTerm.businessCategories.includes(activeAudienceCategory)
      );
      const currentColumnType = activeAudience.mappingRows.find(
        (mappingRow) => mappingRow.key === current.columnId
      )?.audienceColumnType;
      const currentBusinessTermTypes = currentBusinessTerm?.columnConstraints.types.some(
        (type) => type.dataType === currentColumnType
      );
      if (currentBusinessTerm && currentColumnType && currentBusinessTermTypes) {
        return [...newBusinessModel, current];
      }
      return newBusinessModel;
    },
    []
  );
  return {
    columns: newBusinessModel,
  };
};

export default adaptBusinessModelToBusinessTerms;
