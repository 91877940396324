import { OrganizationsState } from '@features/organizations/ducks/organizationsSlice';
import { ReducerStates } from '@redux/reducers';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { rootReducer } from 'saga-slice';
import ScreenSpinner from '@components/screenSpinner';
import NotFound from '@components/notFound';
import { NotFoundType } from '@components/notFound/types';
import appContextSlice from '@features/app/ducks/appContextSlice';
import { getOrganizationAppContextName } from '@utils/getOrganizationAppContextName';
import { AppThemeDetails } from '@features/app/types';
import { extractObjectKeys } from '@utils/helpers';
import { InitPlatformSagaPayload } from '@features/app/ducks/types';
import { ConfigProvider } from 'antd';
import { ThemeConfig } from 'antd/lib';
import { AnyAction } from 'redux-saga';
const theme: ThemeConfig = {
  token: {
    fontFamily: "'Work Sans', sans-serif",
  },
  components: {
    Layout: {
      siderBg: 'var(--main-bg-color)',
      lightTriggerColor: '#fff',
      triggerBg: '#fff',
    },
    Modal: {
      contentBg: '#f5f6fa',
      headerBg: '#f5f6fa',
      footerBg: '#f5f6fa',
    },
  },
};

function getOrganizationState(state: ReducerStates) {
  return state.organizations;
}
function getAppThemeDetailsState(state: ReducerStates) {
  return state.appContext.appContext.theme;
}
const mapStateToProps = (state: ReducerStates) => {
  return {
    organizationState: getOrganizationState(state),
    appThemeDetails: getAppThemeDetailsState(state),
  };
};
const mapDispatchToProps: (
  dispatch: ThunkDispatch<ReturnType<typeof rootReducer>, void, AnyAction>
) => void = (dispatch) => {
  return {
    initPlatform: bindActionCreators(appContextSlice.actions.initPlatform, dispatch),
  };
};
interface IInitialIndexCallsProps {
  children?: React.ReactNode;
  organizationState: OrganizationsState;
  appThemeDetails: AppThemeDetails;
  initPlatform?: (p: InitPlatformSagaPayload) => void;
}
const InitialIndexCallsContainer: React.FC<IInitialIndexCallsProps> = ({
  children,
  organizationState,
  appThemeDetails,
  initPlatform,
}) => {
  useEffect(() => {
    const getOrganizationDomain = () => {
      const isLocalhost =
        window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
      const query = new URLSearchParams(window.location.search);
      const queryDomain = query.get('domain');
      if (isLocalhost) {
        return queryDomain
          ? queryDomain
          : process.env.REACT_APP_AUTH_ADMIN_DOMAIN || window.location.hostname;
      }
      return window.location.hostname;
    };
    initPlatform && initPlatform({ domain: getOrganizationDomain() });
  }, []);
  useEffect(() => {
    const applyCssVariables = (variablesMapper: { [key: string]: string }) => {
      const cssVars = extractObjectKeys(variablesMapper);
      for (const variable of cssVars) {
        document.documentElement.style.setProperty(variable, variablesMapper[variable]);
      }
    };
    if (appThemeDetails.variables) {
      applyCssVariables(appThemeDetails.variables);
    }
  }, [appThemeDetails]);

  if (organizationState.organizationByDomain) {
    try {
      const organizationAppContextName = getOrganizationAppContextName(
        organizationState.organizationByDomain.organization
      );
      require(`@styles/organizationThemes/${organizationAppContextName}.less`);
    } catch (e) {
      console.log(e);
    }
  }

  if (!organizationState.loaded) {
    return <ScreenSpinner />;
  }
  if (organizationState.loaded && !organizationState.organizationByDomain?.auth0OrganizationId) {
    return <NotFound type={NotFoundType.organizationNotFound} />;
  }
  return <ConfigProvider theme={theme}>{children} </ConfigProvider>;
};
export default connect(mapStateToProps, mapDispatchToProps)(InitialIndexCallsContainer);
