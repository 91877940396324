import {
  ColumnAggregation,
  ColumnAggregationSortDirection,
  ColumnAggregationTypeBack,
  ColumnFilter,
  EveryColumnAggregationValue,
  SomeColumnAggregationValue,
} from '@features/audiences/ducks/api/audienceBackTypes/columnAggregations';
import { AggregateDetails, ColumnAggregationType } from '@features/audiences/types';
import { mappingDatePeriodProperty } from './mappingDatePeriodProperty';
import { mappingStrategyProperty } from './mappingStrategyProperty';
import canFrontAggregateBeSorted from '@features/audiences/helpers/canFrontAggregateBeSorted';

export const mappingAggregateProperty = (aggregation: AggregateDetails): ColumnAggregation => {
  const mapAggregateColumnFilter = (aggregation: AggregateDetails) =>
    aggregation.filterByColumns.filterByColumnsStatus
      ? mappingColumnFilterProperty(aggregation)
      : undefined;

  const sort =
    aggregation.function &&
    canFrontAggregateBeSorted(aggregation.function) &&
    aggregation.sort.sourceColumnId &&
    aggregation.sort.direction
      ? [
          {
            sourceColumnId: aggregation.sort.sourceColumnId,
            direction: aggregation.sort.direction,
          },
        ]
      : undefined;
  switch (aggregation.function) {
    case ColumnAggregationType.any: // Available in backend as 'some'
      return {
        type: ColumnAggregationTypeBack.some,
        sourceColumnId: aggregation.sourceColumnId || '',
        value: SomeColumnAggregationValue.is_true, // We don't have this property in the front
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.average:
      return {
        type: ColumnAggregationTypeBack.average,
        sourceColumnId: aggregation.sourceColumnId || '',
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.count: // Available in backend as 'count_values'
      return {
        type: ColumnAggregationTypeBack.count_values,
        sourceColumnId: aggregation.sourceColumnId || '',
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        distinct: false, // We don't have this property in the front
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.countall: // Available in backend as 'count_records'
      return {
        type: ColumnAggregationTypeBack.count_records,
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        distinct: false, // We don't have this property in the front
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.every: // Available in backend as 'every'
      return {
        type: ColumnAggregationTypeBack.every,
        sourceColumnId: aggregation.sourceColumnId || '',
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        value: EveryColumnAggregationValue.is_true, // We don't have this property in the front
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.first: // Available in backend as 'first_value'
      return {
        sort,
        type: ColumnAggregationTypeBack.first_value,
        sourceColumnId: aggregation.sourceColumnId || '',
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        ignoreNull: aggregation.excludeNullValues,
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.last:
      return {} as ColumnAggregation;
    case ColumnAggregationType.list: // Available in backend as 'array'
      return {
        sort,
        type: ColumnAggregationTypeBack.array,
        sourceColumnId: aggregation.sourceColumnId || '',
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        ignoreNull: aggregation.excludeNullValues,
        distinct: false, // We don't have this property in the front
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.max:
      return {
        type: ColumnAggregationTypeBack.max,
        sourceColumnId: aggregation.sourceColumnId || '',
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.min:
      return {
        type: ColumnAggregationTypeBack.min,
        sourceColumnId: aggregation.sourceColumnId || '',
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.sum: // Available in backend as 'sum'
      return {
        type: ColumnAggregationTypeBack.sum,
        sourceColumnId: aggregation.sourceColumnId || '',
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.average_difference: // Available in backend as 'sum'
      return {
        sort,
        type: ColumnAggregationTypeBack.average_difference,
        sourceColumnId: aggregation.sourceColumnId || '',
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        columnFilter: mapAggregateColumnFilter(aggregation),
      };
    case ColumnAggregationType.most_frequent_value: // Available in backend as 'sum'
      return {
        type: ColumnAggregationTypeBack.most_frequent_value,
        sourceColumnId: aggregation.sourceColumnId || '',
        datePeriodFilter: mappingDatePeriodProperty(aggregation),
        columnFilter: mapAggregateColumnFilter(aggregation),
      };

    default:
      return {} as ColumnAggregation;
  }
};
const mappingColumnFilterProperty = (aggregation: AggregateDetails): ColumnFilter => {
  return {
    sourceColumnId: aggregation.filterByColumns.sourceFilterColumnId || '',
    strategy: mappingStrategyProperty(
      aggregation.filterByColumns.filterOperatorValue as string,
      aggregation.filterByColumns.filterOperator
    ),
  };
};
