import { all } from 'redux-saga/effects';
import { appContextSagas } from '@features/app/ducks/sagas';
import { profilesSagas } from '@features/profiles/ducks/sagas';
import { connectorsSagas } from '@features/connectors/ducks/sagas';
import { connectionsSagas } from '@features/connections/ducks/sagas';
import { organizationsSagas } from '@features/organizations/ducks/sagas';
import { usersSagas } from '@features/users/ducks/sagas';
import { accountsSagas } from '@features/account/ducks/sagas';
import { audienceSagas } from '@features/audiences/ducks/sagas/allSagas';
import { redirectionsSagas } from '@features/redirections/ducks/sagas';
import { dataWarehouseSaga } from '@features/dataWarehouse/ducks/sagas';
import { syncsSagas } from '@features/syncs/ducks/sagas';
import { webhooksSagas } from '@features/webhooks/ducks/sagas';

export default function* rootSaga() {
  yield all([
    ...appContextSagas,
    ...profilesSagas,
    ...connectorsSagas,
    ...organizationsSagas,
    ...usersSagas,
    ...connectionsSagas,
    ...accountsSagas,
    ...syncsSagas,
    ...audienceSagas,
    ...redirectionsSagas,
    ...dataWarehouseSaga,
    ...webhooksSagas,
  ]);
}
