import {
  HeadersWhitelist,
  IpAddressWhitelistEntry,
  WebhookCommand,
} from '../../../webhooks/ducks/backTypes/types';
import {
  ActiveIpAddressWhitelistEntry,
  ActiveWebhook,
  HeadersWhitelistConfig,
  IpAddressWhitelistEntryType,
} from '../../../webhooks/types/types';
import { caseNever } from '@utils/case-never';

export const mappingWebhookToBack = (
  activeWebhook: ActiveWebhook,
  accountId?: string,
  dataWarehouseId?: string
): WebhookCommand => {
  if (!activeWebhook.name) {
    throw new Error("Name doesn't exist in the creation command");
  }
  return {
    accountId,
    dataWarehouseId,
    name: activeWebhook.name,
    description: activeWebhook.description,
    methodsWhitelist: activeWebhook.methodsWhitelist,
    headersWhitelist: mappingHeadersWhitelist(activeWebhook.headersWhitelist || []),
    ipAddressesWhitelist: mappingIPAddressesWhitelist(activeWebhook.ipAddressesWhitelist || []),
  };
};

const mappingHeadersWhitelist = (
  headersWhitelist: HeadersWhitelistConfig[]
): HeadersWhitelist[] => {
  return headersWhitelist.map((headerWhitelist) => {
    return {
      name: headerWhitelist.name || '',
      values: headerWhitelist.values.map((value) => value.value || ''),
    };
  });
};
const mappingIPAddressesWhitelist = (
  ipAddressWhitelist: ActiveIpAddressWhitelistEntry[]
): IpAddressWhitelistEntry[] => {
  return ipAddressWhitelist.map((ipWhitelist) => {
    switch (ipWhitelist.type) {
      case IpAddressWhitelistEntryType.ipAddress:
        return {
          type: IpAddressWhitelistEntryType.ipAddress,
          value: ipWhitelist.value ?? '',
        };
      case IpAddressWhitelistEntryType.ipAddressRange:
        return {
          type: IpAddressWhitelistEntryType.ipAddressRange,
          from: ipWhitelist.from ?? '',
          to: ipWhitelist.to ?? '',
        };
      default:
        return caseNever(ipWhitelist);
    }
  });
};
