import { DeployedAudienceDataShape } from '@features/audiences/types/DeployedAudience';
import { BackDeployedAudienceDataShape } from '../../audienceBackTypes/deployedAudience';
import { mappingDefaultAudienceColumnValueToFront } from '../toFrontType/mappingColumns/mappingDefaultAudienceColumnValueToFront';
import { mappingColumnTypePropertyToFront } from '../toFrontType/mappingColumnTypePropertyToFront';

export const mappingAudienceDataShape = (
  backDeployedAudienceDataShape: BackDeployedAudienceDataShape
): DeployedAudienceDataShape => {
  return {
    modifiedAtId: backDeployedAudienceDataShape.modifiedAtId,
    primaryKeyId: backDeployedAudienceDataShape.primaryKeyId,
    systemColumns: backDeployedAudienceDataShape.systemColumns,
    sourceIdListColumns: backDeployedAudienceDataShape.sourceIdListColumns,
    columns: backDeployedAudienceDataShape.columns.map((elem) => ({
      id: elem.id,
      name: elem.name,
      type: mappingColumnTypePropertyToFront(elem.type),
      defaultValue:
        elem.defaultValue && mappingDefaultAudienceColumnValueToFront(elem.defaultValue),
    })),
  };
};
