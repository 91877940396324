import { Column, columnShapeTypeEnum, StaticColumn } from '@features/audiences/types';
import { DefaultColumn } from '@features/audiences/types/Column';
import { DataType } from '../../audienceBackTypes/columnType';
import {
  SourceColumn,
  SourceColumnRetrievalType,
  StaticSourceColumn,
  ValueSourceColumn,
} from '../../audienceBackTypes/source';
import { mappingColumnTypePropertyToFront } from './mappingColumnTypePropertyToFront';

// Mapping SelectedColumns type from back type to front
export const mappingSelectedColumnsProperty = (sourceColumns: SourceColumn[]): Column[] => {
  return sourceColumns.map(mapColumn);
};

const mapColumn = (sourceColumn: SourceColumn): Column => {
  switch (sourceColumn.retrievalType) {
    case SourceColumnRetrievalType.static:
      return mapStaticColumn(sourceColumn);

    case SourceColumnRetrievalType.value:
      return mapSourceValueColumn(sourceColumn);
  }
};

const mapStaticColumn = (column: StaticSourceColumn): StaticColumn => ({
  shapeType: columnShapeTypeEnum.STATIC,
  id: column.id,
  name: column.name,
  type: mappingColumnTypePropertyToFront(column.type),
  castTo: column.castTo && mappingColumnTypePropertyToFront(column.castTo),
  isNullable: true,
  value: column.value,
});

const mapSourceValueColumn = (column: ValueSourceColumn): DefaultColumn => ({
  shapeType: columnShapeTypeEnum.DEFAULT,
  id: column.id,
  name: column.name,
  externalName: column.externalName || column.name,
  type: mappingColumnTypePropertyToFront(column.type),
  castTo: column.castTo && mappingColumnTypePropertyToFront(column.castTo),
  format: column.castTo?.dataType === DataType.Timestamp ? column.castTo.format : undefined,
  isNullable: true,
});
