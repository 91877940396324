import React, { createContext } from 'react';
import { ReducerStates } from '@redux/reducers';
import { connect } from 'react-redux';
import redirectionsSlice from '@features/redirections/ducks/redirectionsSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { rootReducer } from 'saga-slice';
import {
  RedirectToAudiencesEditorPayload,
  RedirectToDatasetBuilderPayload,
  RedirectToLinkPayload,
  RedirectToNewSyncBuilderPayload,
  RedirectToSqlAudienceEditorPayload,
  RedirectToSqlAudienceViewPayload,
  RedirectToSyncsSagaEditorPayload,
} from '@features/redirections/ducks/types';

export type RedirectionContextType = {
  goToLink: (link: string) => void;
  goToMonitoring: () => void;
  goToAccounrResourcesExecutionsMonitoring: () => void;
  goToSourceCatalog: () => void;
  goToConnections: () => void;
  goToSyncs: () => void;
  goToSyncsCreator: () => void;
  goToSyncsEditor: (syncKey: string) => void;
  goToNewSyncs: () => void;
  goToNewSyncBuilder: (syncId?: string) => void;
  goToDatasets: () => void;
  goToDatasetBuilder: (datasetId?: string) => void;
  goToAudiences: () => void;
  goToCreateAudience: () => void;
  goToPrepareAudienceData: () => void;
  goToEditAudienceData: (audienceKey: string) => void;
  goToSQLAudience: (audienceKey: string) => void;
  goToSQLAudienceEdit: (audienceKey?: string) => void;
  goToAccountManagement: () => void;
  goToDestination: () => void;
  goToLogout: () => void;
  goToAccountSelector: () => void;
  goToAccountSettings: () => void;
  goToProfile: () => void;
  goToPlan: () => void;
  goToBilling: () => void;
  goToDashboard: () => void;
};

export const RedirectionContext = createContext<RedirectionContextType>({
  goToLink: (link: string) => {},
  goToSourceCatalog: () => {},
  goToMonitoring: () => {},
  goToAccounrResourcesExecutionsMonitoring: () => {},
  goToConnections: () => {},
  goToSyncs: () => {},
  goToSyncsCreator: () => {},
  goToSyncsEditor: (syncKey: string) => {},
  goToNewSyncs: () => {},
  goToNewSyncBuilder: (syncId?: string) => {},
  goToDatasets: () => {},
  goToDatasetBuilder: (datasetId?: string) => {},
  goToAudiences: () => {},
  goToCreateAudience: () => {},
  goToPrepareAudienceData: () => {},
  goToEditAudienceData: (audienceKey: string) => {},
  goToSQLAudience: () => {},
  goToSQLAudienceEdit: (audienceKey?: string) => {},
  goToAccountManagement: () => {},
  goToDestination: () => {},
  goToLogout: () => {},
  goToAccountSelector: () => {},
  goToAccountSettings: () => {},
  goToProfile: () => {},
  goToPlan: () => {},
  goToBilling: () => {},
  goToDashboard: () => {},
});
// Redux State
const mapStateToProps = (state: ReducerStates) => {
  return {};
};
// Redirection Handlers
const mapDispatchToProps: (
  dispatch: ThunkDispatch<ReturnType<typeof rootReducer>, void, AnyAction>
) => void = (dispatch) => {
  return {
    redirectToSourceCatalog: bindActionCreators(
      redirectionsSlice.actions.redirectToSourceCatalog,
      dispatch
    ),
    redirectToMonitoring: bindActionCreators(
      redirectionsSlice.actions.redirectToMonitoring,
      dispatch
    ),
    redirectToAccountResourcesExecutionsMonitoring: bindActionCreators(
      redirectionsSlice.actions.redirectToAccountResourcesExecutionsMonitoring,
      dispatch
    ),
    redirectToConnection: bindActionCreators(
      redirectionsSlice.actions.redirectToConnection,
      dispatch
    ),
    redirectToNewSyncs: bindActionCreators(redirectionsSlice.actions.redirectToNewSyncs, dispatch),
    redirectToNewSyncBuilder: bindActionCreators(
      redirectionsSlice.actions.redirectToNewSyncBuilder,
      dispatch
    ),

    redirectToDatasets: bindActionCreators(redirectionsSlice.actions.redirectToDatasets, dispatch),
    redirectToDatasetBuilder: bindActionCreators(
      redirectionsSlice.actions.redirectToDatasetBuilder,
      dispatch
    ),
    redirectToAudiences: bindActionCreators(
      redirectionsSlice.actions.redirectToAudiences,
      dispatch
    ),
    redirectToAudiencesCreator: bindActionCreators(
      redirectionsSlice.actions.redirectToAudiencesCreator,
      dispatch
    ),
    redirectToPrepareAudience: bindActionCreators(
      redirectionsSlice.actions.redirectToPrepareAudience,
      dispatch
    ),
    redirectToAudiencesEditor: bindActionCreators(
      redirectionsSlice.actions.redirectToAudiencesEditor,
      dispatch
    ),
    redirectToSqlAudienceEditor: bindActionCreators(
      redirectionsSlice.actions.redirectToSqlAudienceEditor,
      dispatch
    ),
    redirectToSqlAudienceView: bindActionCreators(
      redirectionsSlice.actions.redirectToSqlAudienceView,
      dispatch
    ),
    redirectToSyncs: bindActionCreators(redirectionsSlice.actions.redirectToSyncs, dispatch),
    redirectToSyncsCreator: bindActionCreators(
      redirectionsSlice.actions.redirectToSyncsCreator,
      dispatch
    ),
    redirectToSyncsEditor: bindActionCreators(
      redirectionsSlice.actions.redirectToSyncsEditor,
      dispatch
    ),
    redirectToAccountManagement: bindActionCreators(
      redirectionsSlice.actions.redirectToAccountManagement,
      dispatch
    ),
    redirectToDestination: bindActionCreators(
      redirectionsSlice.actions.redirectToDestination,
      dispatch
    ),

    redirectToLogout: bindActionCreators(redirectionsSlice.actions.redirectToLogout, dispatch),
    redirectToAccountSettings: bindActionCreators(
      redirectionsSlice.actions.redirectToAccountSettings,
      dispatch
    ),
    redirectToProfile: bindActionCreators(redirectionsSlice.actions.redirectToProfile, dispatch),
    redirectToPlan: bindActionCreators(redirectionsSlice.actions.redirectToPlan, dispatch),

    redirectToAccountSelector: bindActionCreators(
      redirectionsSlice.actions.redirectToAccountSelector,
      dispatch
    ),
    redirectToLink: bindActionCreators(redirectionsSlice.actions.redirectToLink, dispatch),
    redirectToBilling: bindActionCreators(redirectionsSlice.actions.redirectToBilling, dispatch),
    redirectToDashboard: bindActionCreators(
      redirectionsSlice.actions.redirectToDashboard,
      dispatch
    ),
  };
};

interface IRedirectionContextContainerProps {
  children: JSX.Element;
  redirectToSourceCatalog?: () => void;
  redirectToMonitoring?: () => void;
  redirectToAccountResourcesExecutionsMonitoring?: () => void;
  redirectToConnection?: () => void;
  redirectToDatasets?: () => void;
  redirectToDatasetBuilder?: (p: RedirectToDatasetBuilderPayload) => void;
  redirectToNewSyncs?: () => void;
  redirectToNewSyncBuilder?: (p: RedirectToNewSyncBuilderPayload) => void;
  redirectToAudiences?: () => void;
  redirectToAudiencesCreator?: () => void;
  redirectToPrepareAudience?: () => void;
  redirectToAudiencesEditor?: (p: RedirectToAudiencesEditorPayload) => void;
  redirectToSqlAudienceEditor?: (p: RedirectToSqlAudienceEditorPayload) => void;
  redirectToSqlAudienceView?: (p: RedirectToSqlAudienceViewPayload) => void;
  redirectToSyncs?: () => void;
  redirectToSyncsCreator?: () => void;
  redirectToSyncsEditor?: (p: RedirectToSyncsSagaEditorPayload) => void;
  redirectToAccountManagement?: () => void;
  redirectToDestination?: () => void;
  redirectToLogout?: () => void;
  redirectToAccountSelector?: () => void;
  redirectToAccountSettings?: () => void;
  redirectToProfile?: () => void;
  redirectToPlan?: () => void;
  redirectToBilling?: () => void;
  redirectToDashboard?: () => void;
  redirectToLink?: (p: RedirectToLinkPayload) => void;
}

const RedirectionContextContainer = ({
  children,
  redirectToSourceCatalog,
  redirectToMonitoring,
  redirectToAccountResourcesExecutionsMonitoring,
  redirectToConnection,
  redirectToDatasets,
  redirectToDatasetBuilder,
  redirectToNewSyncs,
  redirectToNewSyncBuilder,
  redirectToAudiences,
  redirectToAudiencesCreator,
  redirectToPrepareAudience,
  redirectToAudiencesEditor,
  redirectToSqlAudienceView,
  redirectToSqlAudienceEditor,
  redirectToSyncs,
  redirectToSyncsCreator,
  redirectToSyncsEditor,
  redirectToAccountManagement,
  redirectToDestination,
  redirectToLogout,
  redirectToAccountSelector,
  redirectToAccountSettings,
  redirectToProfile,
  redirectToPlan,
  redirectToBilling,
  redirectToDashboard,
  redirectToLink,
}: IRedirectionContextContainerProps) => {
  return (
    <RedirectionContext.Provider
      value={{
        // Link redirection
        goToLink: (link: string) => redirectToLink && redirectToLink({ link }),
        // Source catalog redirections
        goToSourceCatalog: () => redirectToSourceCatalog && redirectToSourceCatalog(),
        // Monitoring redirections
        goToMonitoring: () => redirectToMonitoring && redirectToMonitoring(),

        // Account resources executions monitoring redirections
        goToAccounrResourcesExecutionsMonitoring: () =>
          redirectToAccountResourcesExecutionsMonitoring?.(),

        // Connection redirections
        goToConnections: () => redirectToConnection && redirectToConnection(),
        // Syncs redirections
        goToSyncs: () => redirectToSyncs && redirectToSyncs(),
        goToSyncsCreator: () => redirectToSyncsCreator && redirectToSyncsCreator(),
        goToSyncsEditor: (syncKey: string) =>
          redirectToSyncsEditor && redirectToSyncsEditor({ syncKey }),
        // Datasets redirections
        goToDatasets: () => redirectToDatasets && redirectToDatasets(),
        goToDatasetBuilder: (datasetId?: string) =>
          redirectToDatasetBuilder && redirectToDatasetBuilder({ datasetId }),

        // New Syncs redirections
        goToNewSyncs: () => redirectToNewSyncs && redirectToNewSyncs(),
        goToNewSyncBuilder: (syncId?: string) =>
          redirectToNewSyncBuilder && redirectToNewSyncBuilder({ syncId }),
        // Audience redirections
        goToAudiences: () => redirectToAudiences && redirectToAudiences(),
        goToCreateAudience: () => redirectToAudiencesCreator && redirectToAudiencesCreator(),
        goToPrepareAudienceData: () => redirectToPrepareAudience && redirectToPrepareAudience(),
        goToEditAudienceData: (audienceKey: string) =>
          redirectToAudiencesEditor && redirectToAudiencesEditor({ audienceKey }),
        goToSQLAudience: (audienceKey: string) =>
          redirectToSqlAudienceView && redirectToSqlAudienceView({ audienceKey }),
        goToSQLAudienceEdit: (audienceKey?: string) => {
          const payload: RedirectToSqlAudienceEditorPayload = {};
          if (audienceKey) {
            payload.audienceKey = audienceKey;
          }
          return redirectToSqlAudienceEditor && redirectToSqlAudienceEditor(payload);
        },
        // Account management
        goToAccountManagement: () => redirectToAccountManagement && redirectToAccountManagement(),
        // Destination
        goToDestination: () => redirectToDestination && redirectToDestination(),
        // Recipe
        goToLogout: () => redirectToLogout && redirectToLogout(),
        goToAccountSelector: () => redirectToAccountSelector && redirectToAccountSelector(),
        goToAccountSettings: () => redirectToAccountSettings?.(),
        goToProfile: () => redirectToProfile?.(),
        goToPlan: () => redirectToPlan?.(),
        goToBilling: () => redirectToBilling && redirectToBilling(),
        goToDashboard: () => redirectToDashboard && redirectToDashboard(),
      }}
    >
      {children}
    </RedirectionContext.Provider>
  );
};

export const RedirectionContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(RedirectionContextContainer);
