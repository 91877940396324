import { columnDisplayEnum, MappedColumn } from '@features/audiences/types';
import { columnTypesEnum } from '@features/objects/types';

export const emptyMappedColumnValues: MappedColumn = {
  columnId: '',
  columnName: '',
};
export const emptyMappedJoinColumnValues: MappedColumn = {
  columnId: '',
  columnName: '',
  aggregate: undefined,
  columnDisplay: columnDisplayEnum.LONG_LINE,
};
export const emptyMappedUnionColumnValues: MappedColumn = {
  columnId: '',
  columnName: '',
  columnDisplay: columnDisplayEnum.SELECT,
};
