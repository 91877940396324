export type Organization = {
  key: string;
  details: {
    name: string;
    short_name: string;
  };
  theme: string;
};

export enum OrganizationType {
  octolis = 'octolis',
  partner = 'partner',
  customer = 'customer',
}
export enum PartnerId {
  splio = 'splio',
  brevo = 'brevo',
}
export type OrganizationByDomain =
  | OctolisOrganizationByDomain
  | PartnerOrganizationByDomain
  | CustomerOrganizationByDomain;

export type OctolisOrganizationByDomain = {
  type: OrganizationType.octolis;
};
export type PartnerOrganizationByDomain = {
  type: OrganizationType.partner;
  partnerId: PartnerId;
};
export type CustomerOrganizationByDomain = {
  type: OrganizationType.customer;
  accountId: string;
  partnerId?: PartnerId;
};
