import { CSSProperties } from 'react';

export enum FeatureEnum {
  billing = 'billing',
  dashboard = 'dashboard',
  connections = 'connections',
  audiences = 'audiences',
  datasets = 'datasets',
  syncs = 'syncs',
  sources = 'sources',
  destinations = 'destinations',
  flows = 'flows',
  sourceFlows = 'sourceFlows',
  destinationFlows = 'destinationFlows',
  internalFlows = 'internalFlows',
  recipes = 'recipes',
  objects = 'objects',
  users = 'users',
  accountManagement = 'accountManagement',
  monitoring = 'monitoring',
  sourceCatalog = 'sourceCatalog',
  settings = 'settings',
  newSyncs = 'newSyncs',
  accountResourcesExecutionsMonitoring = 'accountResourcesExecutionsMonitoring',
}

export type Features = keyof typeof FeatureEnum;

export type AppContextType = {
  language: string;
  labels: { [key: string]: string };
  i18nLabels: { [key: string]: string };
  theme: AppThemeDetails;
  imagesUrls: {
    appIcon: string;
    appLogo: string;
    whiteAppLogo: string;
    smallWhiteAppLogo: string;
  };
  accessibleFeatures: Features[];
  visibleFeatures: Features[];
  pureReverseETl?: boolean;
  db?: string;
};
export type AppThemeDetails = {
  name: string;
  connectionFlowLogoStyle?: CSSProperties;
  variables: { [key: string]: string };
};
