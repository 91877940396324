import {
  AudienceDeduplicationKey,
  MappingRow,
  mappingRowTypesEnum,
} from '@features/audiences/types';
import { AudienceColumn } from '../../../audienceBackTypes/audienceColumn';
import { mappingColumnTypePropertyToFront } from '../mappingColumnTypePropertyToFront';
import { mappingDefaultAudienceColumnValueToFront } from './mappingDefaultAudienceColumnValueToFront';

// Mapping audience columns type from back to the front audience columns
export const mappingAudienceColumns = (
  audienceBackColumns: AudienceColumn[],
  deduplicationKeys: AudienceDeduplicationKey[]
): MappingRow[] => {
  return audienceBackColumns.map((column) => {
    const newMappingRow: MappingRow = {
      rowType: mappingRowTypesEnum.DEFAULT,
      key: column.id,
      audienceColumnName: column.name,
      audienceColumnType: mappingColumnTypePropertyToFront(column.type),
      mappedColumns: {},
      primaryKey: deduplicationKeys.some((key) => key.columnIds.includes(column.id)),
      defaultValue:
        column.defaultValue && mappingDefaultAudienceColumnValueToFront(column.defaultValue),
    };
    return newMappingRow;
  });
};
