import {
  DateBoundaryType,
  DatePeriodFilter,
} from '@features/audiences/ducks/api/audienceBackTypes/columnAggregations';
import { DatePeriodTypes } from '@features/audiences/types';

// This function Should return  DatePeriodTypes enum
export const mappingDatePeriodProperty = (
  backDatePeriodType?: DatePeriodFilter
): DatePeriodTypes => {
  const startDateBoundary = backDatePeriodType?.dateRange.start;
  const endDateBoundary = backDatePeriodType?.dateRange.end;
  const startDate: Date = new Date();
  const endDate: Date = new Date();
  let isThisYear: boolean = false; // DatePeriodTypes.this_year
  let isLastYear: boolean = false; // DatePeriodTypes.last_year
  let isAllTime: boolean = false; // DatePeriodTypes.all_time
  if (
    startDateBoundary?.type === DateBoundaryType.relative &&
    endDateBoundary?.type === DateBoundaryType.relative
  ) {
    // custom cases
    if (
      startDateBoundary.year === 0 &&
      endDateBoundary.year === 0 &&
      !startDateBoundary.day &&
      !startDateBoundary.month &&
      !endDateBoundary.day &&
      !endDateBoundary.month
    ) {
      isThisYear = true;
    } else if (
      startDateBoundary.year === -1 &&
      endDateBoundary.year === -1 &&
      !startDateBoundary.day &&
      !startDateBoundary.month &&
      !endDateBoundary.day &&
      !endDateBoundary.month
    ) {
      isLastYear = true;
    } else if (startDateBoundary.year === undefined && startDateBoundary.year === null) {
      isAllTime = true;
    }
    // General case
    // set end/start year
    startDateBoundary.year &&
      startDate.setFullYear(startDate.getFullYear() + startDateBoundary.year);
    endDateBoundary.year && endDate.setFullYear(endDate.getFullYear() + endDateBoundary.year);
    // set end/start month
    startDateBoundary.month === 'first'
      ? startDate.setMonth(0)
      : startDateBoundary.month === 'last'
      ? startDate.setMonth(11)
      : startDateBoundary.month &&
        startDate.setMonth(startDate.getMonth() + startDateBoundary.month);

    endDateBoundary.month === 'first'
      ? endDate.setMonth(0)
      : endDateBoundary.month === 'last'
      ? endDate.setMonth(11)
      : endDateBoundary.month && endDate.setMonth(endDate.getMonth() + endDateBoundary.month);
    // set end/start date
    startDateBoundary.day === 'first'
      ? startDate.setDate(1)
      : startDateBoundary.day === 'last'
      ? startDate.setDate(28)
      : startDateBoundary.day && startDate.setDate(startDate.getDate() + startDateBoundary.day);
    endDateBoundary.day === 'first'
      ? endDate.setDate(1)
      : endDateBoundary.day === 'last'
      ? endDate.setDate(28)
      : endDateBoundary.day && endDate.setDate(endDate.getDate() + endDateBoundary.day);
  }
  // Diff in days between the two dates (start,end)
  const diffDays: number = dateDiffInDays(startDate, endDate);
  if (isThisYear) {
    return DatePeriodTypes.this_year;
  }
  if (isLastYear) {
    return DatePeriodTypes.last_year;
  }
  if (isAllTime) {
    return DatePeriodTypes.all_time;
  }
  if (diffDays >= 10 && diffDays <= 49) {
    return DatePeriodTypes.thirty_days;
  }
  if (diffDays >= 50 && diffDays <= 79) {
    return DatePeriodTypes.sixty_days;
  }
  if (diffDays >= 80 && diffDays <= 110) {
    return DatePeriodTypes.ninety_days;
  }
  if (diffDays >= 110 && diffDays <= 139) {
    return DatePeriodTypes.one_twenty_days;
  }
  if (diffDays >= 140 && diffDays <= 169) {
    return DatePeriodTypes.one_fifty_days;
  }
  if (diffDays >= 170 && diffDays <= 200) {
    return DatePeriodTypes.one_eighty_days;
  }
  if (diffDays >= 320 && diffDays <= 400) {
    return DatePeriodTypes.three_sixty_five_days;
  }
  return DatePeriodTypes.all_time;
};

function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
