import {
  Connection,
  ConnectionStatus,
  ConnectionUsage,
  ConnectorType,
} from '@features/connections/types';
import { connectors } from './connectors';
// use sourceConnections instead connections
export const sourceConnections: Connection[] = [
  // {
  //   id: 'connection1',
  //   name: 'Marketing SFTP',
  //   connector: connectors[3],
  //   canBeUsedAs: [ConnectionUsage.source],
  //   status: ConnectionStatus.ok,
  //   settings: {
  //     type: ConnectorType.sftp,
  //     host: 'sftp.octolis.cloud',
  //     port: 22,
  //     user: 'admin',
  //     password: 'admin',
  //   },
  // },
  // {
  //   id: 'connection2',
  //   name: 'Marketing DB',
  //   connector: connectors[2],
  //   usedAs: [ConnectionUsage.source],
  //   status: ConnectionStatus.ok,
  //   settings: {
  //     type: ConnectorType.db,
  //     host: 'examplepg@db.octolis.cloud',
  //     port: 5432,
  //     user: 'postgres',
  //     password: 'postgres',
  //     sslMode: 'verify',
  //   },
  // },
  // {
  //   id: 'connection3',
  //   name: 'Magento',
  //   connector: connectors[10],
  //   usedAs: [ConnectionUsage.source],
  //   status: ConnectionStatus.ok,
  //   settings: {
  //     type: ConnectorType.api,
  //     url: 'customdomain.magento.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   id: 'connection4',
  //   name: 'Octolis WebTracker',
  //   connector: connectors[11],
  //   usedAs: [ConnectionUsage.source],
  //   status: ConnectionStatus.ok,
  //   settings: {
  //     type: ConnectorType.api,
  //     url: 'customdomain.octolis.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   id: 'connection5',
  //   name: 'Snowflake',
  //   connector: connectors[12],
  //   usedAs: [ConnectionUsage.source],
  //   status: ConnectionStatus.ok,
  //   settings: {
  //     type: ConnectorType.db,
  //     host: 'examplepg@db.snowflake.cloud',
  //     port: 5432,
  //     user: 'admin',
  //     password: 'snowflaker',
  //     sslMode: 'verify',
  //   },
  // },
  // {
  //   key: 'connection6',
  //   name: 'BigQuery',
  //   connector: connectors[13],
  //   usedAs: ['source'],
  //   credentials: {
  //     connectorType: ConnectorType.api,
  //     url: 'customdomain.bigquery.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   key: 'connection3',
  //   name: 'Sendinblue',
  //   usedAs: ['source'],
  //   connector: connectors[1],
  //   credentials: {
  //     connectorType: ConnectorType.api,
  //     url: 'customdomain.sendinblue.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
];
export const destinationAPIConnections: Connection[] = [
  // {
  //   id: 'connection6',
  //   name: 'Salesforce',
  //   canBeUsedAs: [ConnectionUsage.destination],
  //   connector: connectors[0],
  //   status: ConnectionStatus.ok,
  //   settings: {
  //     type: ConnectorType.sendinblue,
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   id: 'connection7',
  //   name: 'Zendesk',
  //   usedAs: [ConnectionUsage.destination],
  //   connector: connectors[7],
  //   status: ConnectionStatus.ok,
  //   settings: {
  //     type: ConnectorType.api,
  //     url: 'customdomain.zendesk.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sd985654qs',
  //   },
  // },
  // {
  //   id: 'connection8',
  //   name: 'Splio',
  //   usedAs: [ConnectionUsage.destination],
  //   connector: connectors[8],
  //   status: ConnectionStatus.ok,
  //   settings: {
  //     type: ConnectorType.api,
  //     url: 'customdomain.splio.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   id: 'connection9',
  //   name: 'Google Ads',
  //   usedAs: [ConnectionUsage.destination],
  //   connector: connectors[9],
  //   status: ConnectionStatus.ok,
  //   settings: {
  //     type: ConnectorType.api,
  //     url: 'customdomain.googleAds.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   key: 'connection10',
  //   name: 'Mailchimp',
  //   connector: connectors[5],
  //   usedAs: [ConnectionUsage.destination],
  //   credentials: {
  //     url: 'customdomain.mailchimp.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   key: 'connection11',
  //   name: 'Intercom ',
  //   usedAs: [ConnectionUsage.destination],
  //   connector: connectors[6],
  //   credentials: {
  //   connectorType: ConnectorType.api,
  //     url: 'customdomain.intercom.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   key: 'connection12',
  //   name: 'Sendinblue',
  //   usedAs: [ConnectionUsage.destination],
  //   connector: connectors[1],
  //   credentials: {
  //   connectorType: ConnectorType.api,
  //     url: 'customdomain.sendinblue.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
];
export const connections: Connection[] = [...sourceConnections, ...destinationAPIConnections];
export const dataWareHouseTree: {
  octolis_raw: {
    files: string[];
  };
  octolis_output: {
    files: string[];
  };
} = {
  octolis_raw: {
    files: [
      'contacts_salesforce',
      'contacts_cegid',
      'contacts_newsletter',
      'orders_salesforce',
      'orders_cegid',
      'stores_cegid',
      'salespersons_cegid',
    ],
  },
  octolis_output: {
    files: ['contacts', 'orders', 'stores', 'salespersons'],
  },
};

export const staticTree: {
  raw: {
    files: string[];
  };
  imports: {
    files: string[];
  };
} = {
  raw: {
    files: [
      'contacts_salesforce_YYYYMMDD.csv',
      'contacts_cegid_YYYYMMDD.csv',
      'contacts_newsletter_YYYYMMDD.csv',
      'orders_salesforce_YYYYMMDD.csv',
      'orders_cegid_YYYYMMDD.csv',
      'stores_cegid_YYYYMMDD.csv',
      'salespersons_cegid_YYYYMMDD.csv',
    ],
  },
  imports: {
    files: ['contacts.csv', 'orders.csv', 'stores.csv', 'salespersons.csv'],
  },
};

export const integrationsTree: {
  'Available integrations': {
    files: string[];
  };
} = {
  'Available integrations': {
    files: ['All contacts', 'All orders', 'All products'],
  },
};
