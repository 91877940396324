import { columnShapeTypeEnum, DefaultColumn } from '@features/audiences/types';
import { AudienceColumn } from '@features/audiences/types/AudienceColumn';

export function mapDeployedAudienceColumnToDefaultColumn({
  id,
  name,
  type,
  defaultValue,
}: AudienceColumn): DefaultColumn {
  return {
    id,
    name,
    shapeType: columnShapeTypeEnum.DEFAULT,
    type,
    externalName: name,
    isNullable: !!defaultValue,
  };
}

export function mapDeployedAudienceColumnsToDefaultColumns(
  columns: AudienceColumn[]
): DefaultColumn[] {
  return columns.map(mapDeployedAudienceColumnToDefaultColumn);
}
