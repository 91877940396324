import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
// connectorsSlice
import profilesSlice from '@features/profiles/ducks/profilesSlice';
// types
import * as types from './types';

export function* getProfilesSaga() {
  try {
    const getProfilesPayload: types.ApiGetProfilesPayload = yield call(API.getProfiles);
    yield put({ type: profilesSlice.actions.getProfilesSuccess.type, payload: getProfilesPayload });
  } catch (error) {
    yield put({ type: profilesSlice.actions.getProfilesFailed.type, payload: error });
  }
}
function* getProfileSaga({ payload }: types.GetProfileSaga) {
  try {
    const { profileType } = payload;
    const getProfilePayload: types.ApiGetProfilePayload = yield call(API.getProfile, profileType);
    yield put({ type: profilesSlice.actions.getProfileSuccess.type, payload: getProfilePayload });
  } catch (error) {
    yield put({ type: profilesSlice.actions.getProfileFailed.type, payload: error });
  }
}
function* saveProfilesSaga({ payload }: types.SaveProfilesSaga) {
  try {
    const { profiles } = payload;
    const saveProfilesPayload: types.ApiGetProfilePayload = yield call(API.saveProfiles, profiles);
    yield put({
      type: profilesSlice.actions.saveProfilesSuccess.type,
      payload: saveProfilesPayload,
    });
  } catch (error) {
    yield put({ type: profilesSlice.actions.saveProfilesFailed.type, payload: error });
  }
}

export const profilesSagas = [
  takeLatest(profilesSlice.actions.getProfiles.type, getProfilesSaga),
  takeLatest(profilesSlice.actions.getProfile.type, getProfileSaga),
  takeLatest(profilesSlice.actions.saveProfiles.type, saveProfilesSaga),
];
