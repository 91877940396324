import { createSlice } from '@reduxjs/toolkit';
import type { ReducerLanguageActions, ReducerThemeActions } from './types';
import { AppContextType } from '@features/app/types';
import { appDefaultConfiguration } from '@data/app';

export interface AppContextState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  appContext: AppContextType;
}

export const INITIAL_THEME_STATE = {
  loaded: false,
  isFetching: false,
  appContext: appDefaultConfiguration,
} as AppContextState;

const appContextSlice = createSlice({
  name: 'appContext',

  initialState: INITIAL_THEME_STATE,
  reducers: {
    getAppContext: (state) => {
      state.isFetching = true;
    },
    getAppContextSuccess: (state, { payload }: ReducerThemeActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.appContext = payload.appContext;
    },
    getAppContextFailed: (state, { payload }: ReducerThemeActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveAppContext: (state) => {
      state.isFetching = true;
    },
    saveAppContextSuccess: (state, { payload }: ReducerThemeActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.appContext = payload.appContext;
    },
    saveAppContextFailed: (state, { payload }: ReducerThemeActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    setAppLanguage: (state) => {
      state.isFetching = true;
    },
    setAppLanguageSuccess: (state, { payload }: ReducerLanguageActions) => {
      state.isFetching = false;
      state.appContext = {
        ...state.appContext,
        language: payload.language,
      };
    },
    setAppLanguageFailed: (state, { payload }: ReducerThemeActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    setAppIconAndName: () => {},
    initPlatform: () => {},
    initAppLayout: () => {},
  },
});

export default appContextSlice;
