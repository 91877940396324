import { AppContextType } from '@features/app/types';
import { OrganizationType, PartnerId } from '@features/organizations/types';

export const appOctolisConfiguration: AppContextType = {
  language: 'en',
  labels: {
    appNameDisplayed: 'Octolis',
    appName: 'Octolis',
    copyright: `©${new Date().getFullYear()} Octolis`,
  },
  imagesUrls: {
    appLogo: 'https://cdn.octolis.cloud/logo/octolis.svg',
    appIcon: 'https://cdn.octolis.cloud/logo/octo.svg',
    whiteAppLogo: 'https://cdn.octolis.cloud/logo/octolis-white.svg',
    smallWhiteAppLogo: 'https://cdn.octolis.cloud/logo/octo-small-white.svg',
  },
  accessibleFeatures: [],
  visibleFeatures: [],
  i18nLabels: {},
  theme: {
    name: 'Octolis',
    connectionFlowLogoStyle: {
      top: 'calc(50% - 15px)',
      left: 'calc(50% - 55px)',
      width: '110px',
      height: '30px',
    },
    variables: {
      '--main-primary-color': '#30218E',
      '--main-bg-color': '#30218E',
      '--main-text-color': '#444',
      '--main-select-input-child-button': '#3c3fee',
      '--layout-trigger-background': '#30218E',
      '--menu-dark-inline-submenu-bg': '#27416B',
      '--main-auth-login-button-bg-color': '#4f33fa',
      '--main-bg-auth-color': '#4f33fa',
      '--main-wave-bg-auth-color': '#583dfa',
      '--sider-menu-bg-selected': 'rgba(64, 64, 64, 0.5)',
      '--sider-menu-bg-hover': 'rgba(224, 224, 224, 0.2)',
    },
  },
};
export const appSplioConfiguration: AppContextType = {
  language: 'en',
  labels: {
    appNameDisplayed: 'Splio',
    appName: 'Splio',
    copyright: `©${new Date().getFullYear()} Splio`,
  },

  imagesUrls: {
    appLogo: 'https://cdn.octolis.cloud/partners/splio.svg',
    appIcon: 'https://cdn.octolis.cloud/partners/splio.ico',
    whiteAppLogo: 'https://cdn.octolis.cloud/partners/splio-white.png',
    smallWhiteAppLogo: 'https://cdn.octolis.cloud/partners/splio.ico',
  },
  accessibleFeatures: [],
  visibleFeatures: [],
  i18nLabels: {},
  theme: {
    name: 'Splio',
    connectionFlowLogoStyle: {
      top: 'calc(50% - 20px)',
      width: '150px',
      height: '50px',
    },
    variables: {
      '--main-primary-color': '#27416B',
      '--main-bg-color': '#27416B',
      '--main-text-color': '#444',
      '--main-select-input-child-button': '#3c3fee',
      '--layout-trigger-background': '#27418B',
      '--menu-dark-inline-submenu-bg': '#27416B',
      '--main-auth-login-button-bg-color': '#27416B',
      '--main-bg-auth-color': '#27416B',
      '--main-wave-bg-auth-color': '#27416B',
      '--sider-menu-bg-selected': 'rgba(64, 64, 64, 0.5)',
      '--sider-menu-bg-hover': 'rgba(224, 224, 224, 0.2)',
    },
  },
};
export const appBrevoConfiguration: AppContextType = {
  language: 'en',
  labels: {
    appNameDisplayed: 'Brevo',
    appName: 'Brevo',
    copyright: `©${new Date().getFullYear()} Brevo`,
  },
  imagesUrls: {
    appLogo: 'https://cdn.octolis.cloud/partners/brevo.svg',
    appIcon: 'https://cdn.octolis.cloud/partners/brevo-icon.svg',
    whiteAppLogo: 'https://cdn.octolis.cloud/partners/brevo-white.svg',
    smallWhiteAppLogo: 'https://cdn.octolis.cloud/partners/brevo-icon.svg',
  },
  accessibleFeatures: [],
  visibleFeatures: [],
  i18nLabels: {},
  theme: {
    name: 'Brevo',
    connectionFlowLogoStyle: {
      top: 'calc(50% - 18px)',
      left: '12%',
      width: '120px',
      height: '35px',
    },
    variables: {
      '--main-primary-color': '#004227',
      '--main-bg-color': '#006a43',
      '--main-text-color': '#444',
      '--main-select-input-child-button': '#3c3fee',
      '--layout-trigger-background': '#006a43',
      '--menu-dark-inline-submenu-bg': '#004227',
      '--main-auth-login-button-bg-color': '#004227',
      '--main-bg-auth-color': '#004227',
      '--main-wave-bg-auth-color': '#004227',
      '--sider-menu-bg-selected': 'rgba(224, 224, 224, 0.2)',
      '--sider-menu-bg-hover': 'rgba(192, 192, 192, 0.5)',
    },
  },
};

// We will change this variable depending on prod configuration
export const appDefaultConfiguration: AppContextType = appOctolisConfiguration;

export function getApplicationConfiguration(organizationName?: string): AppContextType {
  switch (organizationName) {
    case PartnerId.splio:
      return appSplioConfiguration;
    case PartnerId.brevo:
      return appBrevoConfiguration;
    case OrganizationType.octolis:
      return appOctolisConfiguration;
    default:
      return appDefaultConfiguration;
  }
}
