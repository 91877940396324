import {
  activeAccountDetailsStateSelector,
  activeAccountIdStateSelector,
  organizationByDomainSelector,
} from '@redux/selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import dataWarehouseSlice from './DataWarehouseSlice';
import * as API from './api';
import * as ConnectionAPIs from '@features/connections/ducks/api/api';
// types
import {
  APIGetDataWarehousesPayload,
  CreateDataWarehouseSaga,
  DataWarehouseCreatePayload,
  DataWarehouseWithNeonCreationStatusUpdate,
  NEONDatabaseCreationPayload,
  TestDataWarehouseSettingsSaga,
  UpdateActiveDataWarehouseSaga,
} from './types';
import {
  ApiSaveConnectionPayload,
  ApiTestConnectionPayload,
} from '@features/connections/ducks/types';
import {
  validateCreateConnectionPayloadFromDataWarehouse,
  validateCreateDataWarehousePayload,
} from '@features/dataWarehouse/ducks/validators';
import { push } from 'redux-first-history';
import { appRoutesEnum, layoutRoutesEnum } from '@routes/types';
import { User, UserManager } from 'oidc-client-ts';
import { oidcNEONSettings } from '@features/dataWarehouse/oidcNEONSettings';
import { OrganizationByDomain } from '@features/organizations/types';
import { AccountDetails } from '@features/account/types';
import getDataWarehouseNameSpaces from '@features/dataWarehouse/helpers/getDataWarehouseNameSpaces';
import { DataWarehouseCreationProgress } from '@features/dataWarehouse/types/NeonDatabaseCreationProcess';

function* updateActiveDataWarehouseSaga({ payload }: UpdateActiveDataWarehouseSaga) {
  try {
    yield put({
      payload,
      type: dataWarehouseSlice.actions.updateActiveDataWarehouseIdSuccess.type,
    });
  } catch (error) {
    yield put({
      type: dataWarehouseSlice.actions.updateActiveDataWarehouseIdFailed.type,
      payload: error,
    });
  }
}

export function* getDatawarehousesSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const getDatawarehousesPayload: APIGetDataWarehousesPayload = yield call(
      API.getDatawarehouses,
      accountId
    );
    yield put({
      type: dataWarehouseSlice.actions.getDatawarehousesSuccess.type,
      payload: getDatawarehousesPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: dataWarehouseSlice.actions.getDatawarehousesFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}

function* testDataWarehouseSettingsSaga({ payload }: TestDataWarehouseSettingsSaga) {
  try {
    const { settings } = payload;
    const testSettingsPayload: ApiTestConnectionPayload = yield call(
      ConnectionAPIs.testSettings,
      settings
    );
    yield put({
      type: dataWarehouseSlice.actions.testDataWarehouseSettingsSuccess.type,
      payload: { status: testSettingsPayload.status },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: dataWarehouseSlice.actions.testDataWarehouseSettingsFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}
function* createDataWarehouseSaga({ payload }: CreateDataWarehouseSaga) {
  try {
    const { activeDataWarehouse, createConnection } = payload;
    const dataWarehouseCreatePayload = validateCreateDataWarehousePayload(activeDataWarehouse);
    if (dataWarehouseCreatePayload) {
      const accountId: string = yield select(activeAccountIdStateSelector);
      const createDataWarehousePayload: ApiTestConnectionPayload = yield call(
        API.createDataWarehouse,
        dataWarehouseCreatePayload
      );
      yield put({
        type: dataWarehouseSlice.actions.createDataWarehouseSuccess.type,
        payload: createDataWarehousePayload,
      });
      if (createConnection) {
        const createDataWarehouseConnectionPayload =
          validateCreateConnectionPayloadFromDataWarehouse(activeDataWarehouse);
        if (createDataWarehouseConnectionPayload) {
          const createConnectionPayload: ApiSaveConnectionPayload = yield call(
            ConnectionAPIs.saveConnection,
            createDataWarehouseConnectionPayload,
            accountId
          );
          yield put({
            type: dataWarehouseSlice.actions.createConnectionDataWarehouseSuccess.type,
            payload: {
              connection: createConnectionPayload.connection,
            },
          });
        }
      }
      const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.CONNECTIONS}`;
      yield put(push(toRoute));
    } else {
      yield put({
        type: dataWarehouseSlice.actions.createDataWarehouseFailed.type,
        payload: {
          err: "dataWarehouse payload isn't validated ",
          errorDetails: "dataWarehouse payload isn't validated ",
        },
      });
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: dataWarehouseSlice.actions.createDataWarehouseFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}
function* createDataWarehouseWithNeonSaga({ payload }: CreateDataWarehouseSaga) {
  try {
    yield put<DataWarehouseWithNeonCreationStatusUpdate>({
      type: dataWarehouseSlice.actions.updateDataWarehouseCreationWithNeonStatus.type,
      payload: {
        isCreateDataWarehouseProcessCalled: true,
        dataWarehouseCreationProgress: DataWarehouseCreationProgress.default,
      },
    });
    const userManagerInstance = new UserManager(oidcNEONSettings);
    yield userManagerInstance.signinPopup();
    const authUser: User | null = yield userManagerInstance.getUser();
    const access_token = authUser?.access_token; // Coming from the Neon auth User object

    yield put<DataWarehouseWithNeonCreationStatusUpdate>({
      type: dataWarehouseSlice.actions.updateDataWarehouseCreationWithNeonStatus.type,
      payload: {
        dataWarehouseCreationProgress: DataWarehouseCreationProgress.databaseCreationStarted,
      },
    });
    const neonInitOptions: RequestInit = {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`,
      },
      method: 'POST',
      body: JSON.stringify({
        project: { name: 'Octolis', settings: {}, region_id: 'eu-central-1' },
      }),
    };
    const response: Response = yield fetch(
      'https://console.neon.tech/api/v1/projects',
      neonInitOptions
    );

    yield put<DataWarehouseWithNeonCreationStatusUpdate>({
      type: dataWarehouseSlice.actions.updateDataWarehouseCreationWithNeonStatus.type,
      payload: {
        isCreateDataWarehouseProcessCalled: true,
        dataWarehouseCreationProgress: DataWarehouseCreationProgress.dataWarehouseParsingStarted,
      },
    });
    const project: NEONDatabaseCreationPayload = yield response.json();
    if (response.ok) {
      const [{ dsn, name: user, password }] = project.roles.filter(
        ({ name }) => name !== 'web_access'
      );
      const [{ name: database }] = project.databases;
      const host = dsn?.split('@')[1];

      yield put<DataWarehouseWithNeonCreationStatusUpdate>({
        type: dataWarehouseSlice.actions.updateDataWarehouseCreationWithNeonStatus.type,
        payload: {
          isCreateDataWarehouseProcessCalled: true,
          dataWarehouseCreationProgress: DataWarehouseCreationProgress.dataWarehouseCreationStarted,
        },
      });
      const accountId: string = yield select(activeAccountIdStateSelector);
      const accountDetails: AccountDetails[] = yield select(activeAccountDetailsStateSelector);
      const organizationByDomain: OrganizationByDomain = yield select(organizationByDomainSelector);

      const activeAccountSlug = accountDetails?.find((account) => account.id === accountId)?.slug;
      if (activeAccountSlug && organizationByDomain) {
        const dataWarehouseNameSpaces = getDataWarehouseNameSpaces(
          organizationByDomain,
          activeAccountSlug
        );
        if (dataWarehouseNameSpaces) {
          const dataWarehouseCreateCommand: DataWarehouseCreatePayload = {
            accountId,
            credentials: {
              host,
              user,
              password,
              database,
              port: 5432,
              ssl: true,
            },
            ...dataWarehouseNameSpaces,
          };
          const createDataWarehousePayload: ApiTestConnectionPayload = yield call(
            API.createDataWarehouse,
            dataWarehouseCreateCommand
          );
          yield put({
            type: dataWarehouseSlice.actions.createDataWarehouseSuccess.type,
            payload: createDataWarehousePayload,
          });
          yield put<DataWarehouseWithNeonCreationStatusUpdate>({
            type: dataWarehouseSlice.actions.updateDataWarehouseCreationWithNeonStatus.type,
            payload: {
              isCreateDataWarehouseProcessCalled: true,
              dataWarehouseCreationProgress: DataWarehouseCreationProgress.done,
            },
          });
          const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.CONNECTIONS}`;
          yield put(push(toRoute));
        }
      } else {
        yield put<DataWarehouseWithNeonCreationStatusUpdate>({
          type: dataWarehouseSlice.actions.updateDataWarehouseCreationWithNeonStatus.type,
          payload: {
            dataWarehouseCreationProgress: DataWarehouseCreationProgress.done,
            error: true,
            errorMessage:
              'account slug or organization provided are invalid, please contact support!',
          },
        });
      }
    } else {
      yield put<DataWarehouseWithNeonCreationStatusUpdate>({
        type: dataWarehouseSlice.actions.updateDataWarehouseCreationWithNeonStatus.type,
        payload: {
          dataWarehouseCreationProgress: DataWarehouseCreationProgress.done,
          error: true,
          errorMessage: 'request to create database with neon failed!',
        },
      });
    }
  } catch (err: unknown) {
    console.log(err);
    if (err instanceof Error) {
      yield put<DataWarehouseWithNeonCreationStatusUpdate>({
        type: dataWarehouseSlice.actions.updateDataWarehouseCreationWithNeonStatus.type,
        payload: {
          dataWarehouseCreationProgress: DataWarehouseCreationProgress.done,
          error: true,
          errorMessage: err.message,
        },
      });
    }
  }
}
export const dataWarehouseSaga = [
  takeLatest(
    dataWarehouseSlice.actions.updateActiveDataWarehouseId.type,
    updateActiveDataWarehouseSaga
  ),
  takeLatest(dataWarehouseSlice.actions.getDatawarehouses.type, getDatawarehousesSaga),
  takeLatest(
    dataWarehouseSlice.actions.testDataWarehouseSettings.type,
    testDataWarehouseSettingsSaga
  ),
  takeLatest(dataWarehouseSlice.actions.createDataWarehouse.type, createDataWarehouseSaga),
  takeLatest(
    dataWarehouseSlice.actions.createDataWarehouseWithNeon.type,
    createDataWarehouseWithNeonSaga
  ),
];
