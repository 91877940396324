import { DataApiPreparation, PreparationActionType } from '@features/audiences/types';
import { BackDataApiPreparation } from '../../../audienceBackTypes/backColumnPreparationStep';
import { mappingColumnTypePropertyToBack } from './mappingColumnTypePropertyToBack';

export const mappingDataApiPreparationToBack = (
  frontDataApiPreparations?: DataApiPreparation[]
): BackDataApiPreparation[] => {
  return (frontDataApiPreparations || []).map((preparation) => {
    if (preparation.type === PreparationActionType.custom_function) {
      return {
        type: PreparationActionType.custom_function,
        function: {
          name: preparation.function.name,
          expectedType: mappingColumnTypePropertyToBack(preparation.function.expectedType),
        },
        column: preparation.column,
      };
    }
    return preparation;
  });
};
