import { createSlice } from '@reduxjs/toolkit';

import { Profile, ProfileType } from '@features/profiles/types';
import {
  ProfileReducerActions,
  ProfilesReducerActions,
  SaveProfilesReducerActions,
} from '@features/profiles/ducks/types';

export interface ProfilesState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  profile: Profile;
  profiles: Profile[];
}

export const INITIAL_THEME_STATE: ProfilesState = {
  loaded: false,
  isFetching: false,
  profiles: [],
  profile: {
    name: '',
    type: ProfileType.user,
    id: '',
    rights: {
      billing: [],
      dashboard: [],
      connections: [],
      audiences: [],
      datasets: [],
      syncs: [],
      users: [],
      destinations: [],
      accountManagement: [],
    },
  },
};

const profilesSlice = createSlice({
  name: 'rights',

  initialState: INITIAL_THEME_STATE,
  reducers: {
    getProfile: (state) => {
      state.isFetching = true;
    },
    getProfileSuccess: (state, { payload }: ProfileReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.profile = payload.profile;
    },
    getProfileFailed: (state, { payload }: ProfileReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    // for testing
    getStateProfile: (state, { payload }: any) => {
      const stateProfile = state.profiles.find((a) => a.name === payload.profileName);
      if (stateProfile) {
        state.profile = stateProfile;
      }
    },

    getProfiles: (state) => {
      state.isFetching = true;
    },
    getProfilesSuccess: (state, { payload }: ProfilesReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.profiles = payload.profiles;
    },
    getProfilesFailed: (state, { payload }: ProfilesReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveProfiles: (state, { payload }: SaveProfilesReducerActions) => {
      state.isFetching = true;
    },
    saveProfilesSuccess: (state, { payload }: SaveProfilesReducerActions) => {
      state.isFetching = false;
      state.profiles = payload.profiles;
    },
    saveProfilesFailed: (state, { payload }: SaveProfilesReducerActions) => {
      state.isFetching = false;
      state.errorDetails = payload.errorDetails;
    },
  },
});

export default profilesSlice;
