import {
  DataValueType,
  FunctionArgumentType,
  IDataValue,
  IFunctionArgument,
} from '@features/audiences/types/AudienceColumn';
import { caseNever } from '@utils/case-never';
import { generateId } from '@utils/helpers';
import {
  BackDataValueType,
  BackFunctionArgumentType,
  IBackDataValue,
  IBackFunctionArgument,
} from '../../../audienceBackTypes/audienceColumn';
import { mappingColumnTypePropertyToFront } from '../mappingColumnTypePropertyToFront';

export const mappingDefaultAudienceColumnValueToFront = (
  defaultValue: IBackDataValue
): IDataValue | undefined => {
  const defaultValueType = defaultValue.type;
  switch (defaultValueType) {
    case BackDataValueType.static:
      return { type: DataValueType.static, value: defaultValue.value };
    case BackDataValueType.sequence:
      return { type: DataValueType.sequence, sequence: defaultValue.sequence };
    case BackDataValueType.function:
      return {
        type: DataValueType.function,
        args: mapDataValueArgsProperty(defaultValue.args),
        name: defaultValue.name,
        outputType: mappingColumnTypePropertyToFront(defaultValue.outputType),
      };
    case BackDataValueType.special:
      return { type: DataValueType.special, value: defaultValue.value };
    default:
      caseNever(defaultValueType);
  }
};

const mapDataValueArgsProperty = (args: IBackFunctionArgument[]): IFunctionArgument[] => {
  const frontArgs: IFunctionArgument[] = [];
  args.forEach((elem) => {
    const type = elem.type;
    switch (type) {
      case BackFunctionArgumentType.value:
        frontArgs.push({
          key: generateId(),
          type: FunctionArgumentType.value,
          value: elem.value,
          dataType: mappingColumnTypePropertyToFront(elem.dataType),
        });
        break;
      case BackFunctionArgumentType.sequence:
        frontArgs.push({
          key: generateId(),
          type: FunctionArgumentType.sequence,
          outputType: mappingColumnTypePropertyToFront(elem.outputType),
          sequence: elem.sequence,
        });
        break;
      default:
        caseNever(type);
    }
  });
  return frontArgs;
};
