import {
  ApiGetAudiencePayload,
  UpdateSyncAsSourceDataSaga,
} from '@features/audiences/ducks/types';
import { call, select } from 'redux-saga/effects';
import { getAudience } from '@features/audiences/ducks/api/api';
import { getDeployedSync, getSyncs } from '@features/syncs/ducks/api/api';
import { APIDeployedSyncPayload, ApiSyncsPayload } from '@features/syncs/ducks/types';

import {
  buildTypesEnum,
  Column,
  columnShapeTypeEnum,
  dataTypesEnum,
  DefaultSourceConfiguration,
  isSyncSource,
  sourceTypesEnum,
} from '@features/audiences/types';
import { caseNever } from '@utils/case-never';
import { setMappingRowOfTheJoinCondition } from '../api/mappingAudienceTypes/toFrontType/mappingColumns/mapJoinByColumnsToMappingRows';
import assert from 'assert';
import {
  mappingColumnTypePropertyToFront
} from '../api/mappingAudienceTypes/toFrontType/mappingColumnTypePropertyToFront';
import { shortenColumnName } from '../../utils/columns';
import { toSnakeCase } from '@utils/to-snake-case';
import { AudienceUnion } from '../../types/Audience/Audience';
import { activeAccountIdStateSelector } from '@redux/selectors';

export function* updateNamePropertyOfSyncAsSource(audiences: AudienceUnion[]) {
  const accountId: string = yield select(activeAccountIdStateSelector);

  const syncsResult: ApiSyncsPayload = yield call(
    getSyncs,
    accountId
  );

  if (syncsResult.error) {
    // TODO @@@@koralex [error?]
    return audiences;
  }

  for (const audience of audiences) {
    for (const source of audience.sources) {
      if (source.dataSource.type !== sourceTypesEnum.SYNC) {
        continue;
      }
      const syncId = source.dataSource.syncId;
      const sync = syncsResult.syncs.find((sync) => sync.key === syncId);

      if (!sync) {
        continue;
      }
      source.dataSource.name = sync.name;
    }
  }
  return audiences;
}

export function* updateSyncAsSourceData({ payload }: UpdateSyncAsSourceDataSaga) {
  const { audience, abortSignal } = payload;

  for (const source of audience.sources) {
    if (!isSyncSource(source)) {
      continue;
    }

    const syncId = source.dataSource.syncId;
    const deployedSyncResult: APIDeployedSyncPayload = yield call(
      getDeployedSync,
      syncId
    );
    const deployedSync = deployedSyncResult.deployedSync;
    if (!deployedSync) {
      continue;
    }

    const audienceUsedInSyncResult: ApiGetAudiencePayload = yield call(
      getAudience,
      deployedSync.audienceId
    );
    const audienceUsedInSync = audienceUsedInSyncResult.audience;
    if (!audienceUsedInSync) {
      continue;
    }

    source.dataSource.name = deployedSync.name;
    source.dataSource.category = audienceUsedInSync.category;

    const dataPlaneSyncState = deployedSync.dataPlane.state;
    assert(dataPlaneSyncState, 'Data plane state is not defined');
    source.selectedColumns = [
      ...source.selectedColumns,
      ...[
        {
          ...dataPlaneSyncState.shape.masterId,
          id: source.dataSource.masterIdColumnId
        },
        {
          ...dataPlaneSyncState.shape.updatedAt,
          id: source.dataSource.updatedAtColumnId,
        },
      ].map(
        (col): Column => ({
          id: col.id,
          name: shortenColumnName(toSnakeCase(col.name)),
          externalName: col.name,
          type: mappingColumnTypePropertyToFront(col.type),
          shapeType: columnShapeTypeEnum.DEFAULT,
          isNullable: col.isNullable,
        })
      ),
    ];

    switch (audience.buildType) {
      case buildTypesEnum.DATA: {
        break;
      }
      case buildTypesEnum.BUSINESS: {
        const defaultSyncSource = source as DefaultSourceConfiguration;

        if (defaultSyncSource.dataType === dataTypesEnum.JOIN) {
          audience.mappingRows = setMappingRowOfTheJoinCondition(
            audience.sources,
            defaultSyncSource,
            audience.mappingRows
          );
        }

        // updateAudienceAsSourceColumns(source, deployedAudienceResult.deployedAudience);

        audience.mappingRows = audience.mappingRows.map((mappingRow) => {
          const mappedColumn = mappingRow.mappedColumns[source.key];
          const column = source.selectedColumns.find(({ id }) => id === mappedColumn.columnId);

          return {
            ...mappingRow,
            mappedColumns: {
              ...mappingRow.mappedColumns,
              [source.key]: {
                ...mappedColumn,
                columnName: column ? column.name : mappedColumn.columnName,
                columnType: column ? column.type : mappedColumn.columnType,
              },
            },
          };
        });
        break;
      }
      default:
        return caseNever(audience);
    }
  }

  return audience;
}
