export enum NotFoundType {
  default = 'default',
  datawarehouseNotFound = 'datawarehouseNotFound',
  inaccessibleFeature = 'inaccessibleFeature',
  hiddenFeature = 'hiddenFeature',
  underConstruction = 'underConstruction',
  appScreenNotfound = 'appScreenNotfound',
  authScreenNotfound = 'authScreenNotfound',
  organizationNotFound = 'organizationNotFound',
}
