import { combineReducers, Reducer } from 'redux';
import appContextSlice, { AppContextState } from '@features/app/ducks/appContextSlice';
import profilesSlice, { ProfilesState } from '@features/profiles/ducks/profilesSlice';
import connectorsSlice, { ConnectorsState } from '@features/connectors/ducks/connectorsSlice';
import organizationsSlice, {
  OrganizationsState,
} from '@features/organizations/ducks/organizationsSlice';
import usersSlice, { UsersState } from '@features/users/ducks/usersSlice';
import connectionsSlice, { ConnectionsState } from '@features/connections/ducks/connectionsSlice';
import accountSlice from '@features/account/ducks/accountSlice';
import syncsSlice, { SyncsState } from '@features/syncs/ducks/syncsSlice';
import audiencesSlice, { AudienceState } from '@features/audiences/ducks/audiencesSlice';
import redirectionsSlice, {
  RedirectionsState,
} from '@features/redirections/ducks/redirectionsSlice';
import { AccountState } from '@features/account/ducks/types';
import dataWarehouseSlice, {
  DataWarehouseState,
} from '@features/dataWarehouse/ducks/DataWarehouseSlice';
import webhooksSlice, { WebhooksState } from '@features/webhooks/ducks/webhooksSlice';
import { RouterState } from 'redux-first-history';

export interface ReducerStates {
  appContext: AppContextState;
  profiles: ProfilesState;
  connectors: ConnectorsState;
  organizations: OrganizationsState;
  users: UsersState;
  connections: ConnectionsState;
  account: AccountState;
  router: RouterState;
  syncs: SyncsState;
  audiences: AudienceState;
  redirections: RedirectionsState;
  dataWarehouses: DataWarehouseState;
  webhooks: WebhooksState;
}
const reducers = (routerReducer: Reducer<RouterState>) =>
  combineReducers({
    router: routerReducer,
    appContext: appContextSlice.reducer,
    profiles: profilesSlice.reducer,
    connectors: connectorsSlice.reducer,
    organizations: organizationsSlice.reducer,
    users: usersSlice.reducer,
    connections: connectionsSlice.reducer,
    account: accountSlice.reducer,
    syncs: syncsSlice.reducer,
    audiences: audiencesSlice.reducer,
    redirections: redirectionsSlice.reducer,
    dataWarehouses: dataWarehouseSlice.reducer,
    webhooks: webhooksSlice.reducer,
  });
export default reducers;
