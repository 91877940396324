import { DataWarehouseCreatePayload } from '@features/dataWarehouse/ducks/types';
import { ActiveConnection, ConnectionUsage, ConnectorType } from '@features/connections/types';
import { ActiveDataWarehouse } from '@features/dataWarehouse/types';

export const validateCreateDataWarehousePayload = (
  activeDataWarehouse: ActiveDataWarehouse
): DataWarehouseCreatePayload | undefined => {
  if (
    activeDataWarehouse.accountId &&
    activeDataWarehouse.credentials.database &&
    activeDataWarehouse.credentials.port &&
    activeDataWarehouse.credentials.user &&
    activeDataWarehouse.credentials.host &&
    activeDataWarehouse.credentials.password &&
    activeDataWarehouse.credentials.ssl !== undefined &&
    activeDataWarehouse.privateNamespace &&
    activeDataWarehouse.publicNamespace &&
    activeDataWarehouse.temporaryNamespace
  ) {
    return {
      accountId: activeDataWarehouse.accountId,
      credentials: {
        host: activeDataWarehouse.credentials.host,
        port: activeDataWarehouse.credentials.port,
        user: activeDataWarehouse.credentials.user,
        password: activeDataWarehouse.credentials.password,
        database: activeDataWarehouse.credentials.database,
        ssl: activeDataWarehouse.credentials.ssl,
      },
      publicNamespace: activeDataWarehouse.publicNamespace,
      privateNamespace: activeDataWarehouse.privateNamespace,
      temporaryNamespace: activeDataWarehouse.temporaryNamespace,
    };
  }
  return undefined;
};
export const validateCreateConnectionPayloadFromDataWarehouse = (
  activeDataWarehouse: ActiveDataWarehouse
): ActiveConnection | undefined => {
  if (
    activeDataWarehouse.credentials.database &&
    activeDataWarehouse.credentials.port &&
    activeDataWarehouse.credentials.user &&
    activeDataWarehouse.credentials.host &&
    activeDataWarehouse.credentials.password &&
    activeDataWarehouse.credentials.ssl !== undefined
  ) {
    return {
      name: 'DataWareHouse connection',
      settings: {
        type: ConnectorType.postgres,
        host: activeDataWarehouse.credentials.host,
        port: activeDataWarehouse.credentials.port,
        user: activeDataWarehouse.credentials.user,
        password: activeDataWarehouse.credentials.password,
        database: activeDataWarehouse.credentials.database,
        ssl: activeDataWarehouse.credentials.ssl,
      },
      canBeUsedAs: [ConnectionUsage.source],
    };
  }
  return undefined;
};
