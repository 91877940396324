export enum AudienceSavingProgress {
  default = 'default',
  saveStarted = 'saveStarted',
  saveEnded = 'saveEnded',
  updateStarted = 'updateStarted',
  updateEnded = 'updateEnded',
  deployStarted = 'deployStarted',
  deployEnded = 'deployEnded',
}
export const audienceProgressDetails: {
  [progressStep in AudienceSavingProgress]: { progressValue: number; progressTitle: string };
} = {
  default: { progressValue: 0, progressTitle: 'Start' },
  saveStarted: { progressValue: 0, progressTitle: 'Create' },
  saveEnded: { progressValue: 40, progressTitle: 'Created' },
  updateStarted: { progressValue: 0, progressTitle: 'Update' },
  updateEnded: { progressValue: 40, progressTitle: 'Updated' },
  deployStarted: { progressValue: 60, progressTitle: 'Deploy' },
  deployEnded: { progressValue: 100, progressTitle: 'Deployed' },
};
