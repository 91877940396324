import { columnTypesEnum } from '@features/objects/types';

export enum DataValueType {
  static = 'static',
  sequence = 'sequence',
  function = 'function',
  special = 'special',
}

// Static Values

export type IStaticDataValue<DataType> = Readonly<{
  type: DataValueType.static;

  value: DataType;
}>;

// Sequence Values
// Sequence Values

export type ISequenceDataValue = Readonly<{
  type: DataValueType.sequence;

  sequence: ISequenceInformation;
}>;
export type ISequenceInformation = Readonly<{
  id: string;
  namespace: string;
}>;

// Function Values
export enum FunctionArgumentType {
  value = 'value',
  sequence = 'sequence',
}
export type IFunctionValueArgument = {
  key: string;
  type: FunctionArgumentType.value;
  dataType: columnTypesEnum;
  value: any;
};
export type IFunctionSequenceArgument = {
  key: string;
  type: FunctionArgumentType.sequence;
  sequence: ISequenceInformation;
  outputType: columnTypesEnum;
};
export type IFunctionArgument = IFunctionValueArgument | IFunctionSequenceArgument;
export type IFunctionDataValue = {
  type: DataValueType.function;
  name: string;
  args: IFunctionArgument[];
  outputType: columnTypesEnum;
};

// Special Values
export enum SpecialDataValue {
  CurrentTimestamp = 'current_timestamp',
  RandomUUID = 'random_uuid',
  Null = 'null',
}
export type ISpecialDataValue = Readonly<{
  type: DataValueType.special;

  value: SpecialDataValue;
}>;

export type IDataValue =
  | IStaticDataValue<any>
  | ISequenceDataValue
  | IFunctionDataValue
  | ISpecialDataValue;

export type AudienceColumn = Readonly<{
  id: string;
  name: string;
  type: columnTypesEnum;
  defaultValue?: IDataValue;
}>;
