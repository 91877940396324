export enum ModifiedAtOnlyChangeStrategy {
  ignore = 'ignore',
  silent_update = 'silent_update',
  full_update = 'full_update',
}
export enum ModifiedAtNullValuesStrategy {
  filter_out = 'filter_out',
  set_current_time = 'set_current_time',
}
export type ModifiedAtOptions = Readonly<{
  // Determines how records where the modified-at is the only change are handled
  // Default: Silent-Update
  onlyChange?: ModifiedAtOnlyChangeStrategy;

  // Determines how DEDUPED records are handled when they have NULL modified-at
  // Default: Set-Current-Time
  nullValues?: ModifiedAtNullValuesStrategy;
}>;
