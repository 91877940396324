import {
  ActiveConnectionRateLimitingSettings,
  ConnectionRateLimitingSettings,
  ConnectorType,
} from '../..';

export interface BrevoConnectionSettings {
  type: ConnectorType.brevo;
  apiKey: string;
  trackerApiKey: string;
  rateLimiting: ConnectionRateLimitingSettings;
  rateLimitTier?: BrevoConnectionRateLimitTier;
}

export interface ActiveBrevoConnectionSettings {
  type: ConnectorType.brevo;
  apiKey?: string;
  trackerApiKey?: string;
  rateLimiting?: ActiveConnectionRateLimitingSettings;
  rateLimitTier?: BrevoConnectionRateLimitTier;
}

export enum BrevoConnectionRateLimitTier {
  enterprise = 'enterprise',
  enterprisePlus = 'enterprisePlus',
}

export const defaultBrevoConnectionRateLimitTier = BrevoConnectionRateLimitTier.enterprise;
