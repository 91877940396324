import {
  ActiveAudienceDataSource,
  ActiveDataSource,
  ActiveConnectionDataSource,
  ActiveSourceConfiguration,
  JoinActiveSourceConfiguration,
  sourceTypesEnum,
  dataTypesEnum,
  MappingRow,
  DefaultMappingRow,
  mappingRowTypesEnum,
  MasterIdMappingRow,
  ModifiedAtMappingRow,
  AggregateMappingRow,
  Column,
  StaticColumn,
  DefaultColumn,
  columnShapeTypeEnum,
  SourceIdMappingRow,
  UnionActiveSourceConfiguration,
  ActiveSyncDataSource,
} from '.';

// Using type predicates functions
export const isActiveAudienceDataSourceType = (
  audienceSource: ActiveDataSource
): audienceSource is ActiveAudienceDataSource => audienceSource.type === sourceTypesEnum.AUDIENCE;

export const isActiveSyncDataSourceType = (
  audienceSource: ActiveDataSource
): audienceSource is ActiveSyncDataSource => audienceSource.type === sourceTypesEnum.SYNC;

export const isActiveConnectionDataSourceType = (
  audienceSource: ActiveDataSource
): audienceSource is ActiveConnectionDataSource =>
  audienceSource.type === sourceTypesEnum.CONNECTION;
// Used for Join source configuration
export const isJoinSourceConfigurationType = (
  source: ActiveSourceConfiguration
): source is JoinActiveSourceConfiguration => source.dataType === dataTypesEnum.JOIN;
export const isUnionSourceConfigurationType = (
  source: ActiveSourceConfiguration
): source is UnionActiveSourceConfiguration => source.dataType === dataTypesEnum.UNION;
// Mapping rows types predicates
export const isDefaultMappingRow = (mappingRow: MappingRow): mappingRow is DefaultMappingRow =>
  mappingRow.rowType === mappingRowTypesEnum.DEFAULT;
export const isAggregateMappingRow = (mappingRow: MappingRow): mappingRow is AggregateMappingRow =>
  mappingRow.rowType === mappingRowTypesEnum.AGGREGATE_ROW;
export const isMasterIdMappingRow = (mappingRow: MappingRow): mappingRow is MasterIdMappingRow =>
  mappingRow.rowType === mappingRowTypesEnum.MASTER_ID_ROW;
export const isModifiedAtMappingRow = (
  mappingRow: MappingRow
): mappingRow is ModifiedAtMappingRow => mappingRow.rowType === mappingRowTypesEnum.MODIFIED_AT_ROW;
export const isSourceIdMappingRow = (mappingRow: MappingRow): mappingRow is SourceIdMappingRow =>
  mappingRow.rowType === mappingRowTypesEnum.SOURCE_ID_LIST;

// Columns
export const isStaticColumn = (column: Column): column is StaticColumn =>
  column.shapeType === columnShapeTypeEnum.STATIC;

export const isDefaultColumn = (column: Column): column is DefaultColumn =>
  column.shapeType === columnShapeTypeEnum.DEFAULT;
