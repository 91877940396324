import {
  ActiveDefaultAudience,
  ActiveSourceConfiguration,
  AudienceDeduplicationKey,
  buildTypesEnum,
  JoinActiveSourceConfiguration,
  MappingRow,
  UnionActiveSourceConfiguration,
} from '@features/audiences/types';
import { AudienceReadModel, Execution } from '../../audienceBackTypes/audience';
import { AudienceSpecificationType } from '../../audienceBackTypes/audienceSpecification';
import {
  mappingAggregatedColumns,
  mappingAudienceColumns,
  mappingSourcesMappedColumns,
} from './mappingColumns';
import { mappingUnionedSources } from './mappingUnionedSources';
import { mappingJoinedSources } from './mappingJoinedSources';
import mappingPreparationStepsToFront from './mappingPreparationStepsToFront';
import {
  AudienceExecutionStatus,
  LatestExecution,
} from '@features/audiences/types/AudienceExecution/AudienceExecution';
import { caseNever } from '@utils/case-never';
import {
  ActiveAudience,
  ActiveAudienceBase,
  ActiveSQLAudience,
  SqlMode,
} from '@features/audiences/types/ActiveAudience/ActiveAudience';
import { mapSqlSourceFromBackToFront } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mapSqlSourcesFromBackToFront';
import { mappingDeduplicationKeysToFront } from './mappingDeduplicationKeysToFront';
import { mappingParentAudienceToFront } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mappingParentAudienceToFront';
import { mapBackAudienceColumnsToFront } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mappingAudienceShapeToFront';
import { mappingDataApiPreparations } from './mappingDataApiPreparations';

// Currently works only for the default audience specification (we should include SQL audience types in front)
export const toFrontType = (audienceFromBack: AudienceReadModel): ActiveAudience => {
  // Get deduplicationSettings from the backend type
  const deduplicationKeys: AudienceDeduplicationKey[] =
    mappingDeduplicationKeysToFront(audienceFromBack);

  // Get idsOfColumnValuesToBePreserved from the backend type
  const preservedKeys: string[] =
    audienceFromBack.specification.deduplicationSettings.idsOfColumnValuesToBePreserved;

  // Initial audience front type with the easy to map properties
  const audienceFrontBase: ActiveAudienceBase = {
    key: audienceFromBack.id,
    name: audienceFromBack.name,
    longName: audienceFromBack.longName,
    version: audienceFromBack.version,
    category: audienceFromBack.businessCategory,
    deduplicationSettings: {
      deduplicationKeys,
      preservedValuesKeys: preservedKeys,
    },

    deployment: audienceFromBack.deployment,
    latestExecution: mapLatestExecutionFromBackType(
      audienceFromBack.lastExecution ? [audienceFromBack.lastExecution] : undefined
    ),
    dataApiEnabled: audienceFromBack.specification.dataApiEnabled,
    immutable: audienceFromBack.specification.immutable,
    schedule: audienceFromBack.specification.schedule,
  };

  switch (audienceFromBack.specification.type) {
    case AudienceSpecificationType.default: {
      let sourcesConfigurationList: ActiveSourceConfiguration[] = [];
      // Prepare sources configuration list
      // Unioned sources list
      const unionedSourcesConfiguration: UnionActiveSourceConfiguration[] = mappingUnionedSources(
        audienceFromBack.specification.sources.unioned
      );
      sourcesConfigurationList = unionedSourcesConfiguration;
      // Joined sources list
      const joinedSourcesConfiguration: JoinActiveSourceConfiguration[] = mappingJoinedSources(
        audienceFromBack.specification.sources.joined,
        unionedSourcesConfiguration.length
      );
      sourcesConfigurationList = [...sourcesConfigurationList, ...joinedSourcesConfiguration];
      // Prepare audience mapping columns
      // Init audienceMapping with audience columns
      let audienceMapping: MappingRow[] = mappingAudienceColumns(
        audienceFromBack.specification.columns,
        deduplicationKeys
      );
      // add Joined/unioned/aggregated columns to the audienceMapping with audience columns
      audienceMapping = mappingSourcesMappedColumns(
        audienceFromBack.specification.sources,
        sourcesConfigurationList,
        audienceMapping
      );

      audienceMapping = mappingAggregatedColumns(
        audienceFromBack.specification.sources.joined,
        sourcesConfigurationList,
        audienceMapping
      );

      const defaultAudience: ActiveDefaultAudience = {
        ...audienceFrontBase,
        buildType: buildTypesEnum.BUSINESS,
        preparationSteps: mappingPreparationStepsToFront(
          audienceFromBack.specification.preparationSteps
        ),
        sources: sourcesConfigurationList,
        mappingRows: audienceMapping,
        businessModel: audienceFromBack.specification.businessModel || { columns: [] },
        parent: mappingParentAudienceToFront(audienceFromBack.specification.parent),
        dataApiProtectedColumns: audienceFromBack.specification.dataApiProtectedColumns,
        dataApiMandatoryColumns: audienceFromBack.specification.dataApiMandatoryColumns,
        dataApiPreparations: mappingDataApiPreparations(
          audienceFromBack.specification.dataApiPreparations
        ),
      };

      return defaultAudience;
    }
    case AudienceSpecificationType.sql: {
      const sqlAudience: ActiveSQLAudience = {
        ...audienceFrontBase,
        buildType: buildTypesEnum.DATA,
        mode: audienceFromBack.specification.sqlMode ?? SqlMode.query,
        sqlQuery: audienceFromBack.specification.sqlQuery,
        modifiedAtColumnId: audienceFromBack.specification.modifiedAtColumnId,
        sources: mapSqlSourceFromBackToFront(audienceFromBack.specification.sources),
        columns: mapBackAudienceColumnsToFront(audienceFromBack.specification.columns),
        schedule: audienceFromBack.specification.schedule,
      };

      return sqlAudience;
    }
    default:
      caseNever(audienceFromBack.specification);
  }

  throw new Error('Mapping case is not handled');
};

export const mapLatestExecutionFromBackType = (
  executions: undefined | [] | [{ execution: Execution }]
): LatestExecution | undefined => {
  if (!executions || executions.length === 0) {
    return undefined;
  }

  const { execution } = executions[0];
  switch (execution.status) {
    case AudienceExecutionStatus.pending:
      return {
        ...execution,
        createdAt: new Date(execution.createdAt),
      };

    case AudienceExecutionStatus.in_progress:
    case AudienceExecutionStatus.aborting:
      return {
        ...execution,
        createdAt: new Date(execution.createdAt),
        startedAt: new Date(execution.startedAt),
      };

    case AudienceExecutionStatus.completed:
    case AudienceExecutionStatus.failed:
      return {
        ...execution,
        createdAt: new Date(execution.createdAt),
        startedAt: new Date(execution.startedAt),
        endedAt: new Date(execution.endedAt),
      };

    case AudienceExecutionStatus.aborted:
      return {
        ...execution,
        createdAt: new Date(execution.createdAt),
        startedAt: new Date(execution.startedAt),
        endedAt: new Date(execution.endedAt),
        abortedBy: execution.abortedBy,
      };

    default:
      caseNever(execution);
  }
};
