import { gql } from '@apollo/client';

export const GET_DATAWAREHOUSES = gql`
  query ($accountId: String!) {
    dataWarehouses(accountId: $accountId) {
      id
    }
  }
`;

export const GET_DATAWAREHOUSES_CREDENTIALS = gql`
  query GetDataWarehousesCredentials($accountId: String!) {
    dataWarehouses(accountId: $accountId) {
      id
      readOnlyCredentials {
        ... on PostgresDataWarehouseCredentials {
          type
          host
          port
          user
          password
          database
          ssl
        }
      }
    }
  }
`;
