import { createSlice } from '@reduxjs/toolkit';
import {
  ActiveConnectionDataSource,
  ActiveDefaultAudience,
  ActivePreparationStep,
  ActiveSourceConfiguration,
  AudienceDeduplicationKey,
  AudienceLifeCycle,
  AudienceMode,
  AudienceSavingProgress,
  buildTypesEnum,
  CounterTypes,
  dataTypesEnum,
  MappingRow,
  retrievalModeEnum,
  sourceTypesEnum,
} from '../types';
import {
  ActiveMappingRowsPayload,
  ActivePreparationPayload,
  ActivePreparationStepsPayload,
  ActiveSourceConfigurationPayload,
  ActiveSQLAudienceSettingsPayload,
  ActiveSQLSourceConfigurationPayload,
  AudienceDataReducerActions,
  AudienceReducerActions,
  AudiencesReducerActions,
  DeleteAudienceReducerActions,
  DeployedAudiencesReducerActions,
  DeploymentStatusReducerActions,
  DescribeSourceReducerAction,
  DescribeSourceStatusReduceAction,
  GetAudiencePreviewSampleReducerActions,
  GetAudienceReducerActions,
  GetSourceEntitiesReducerActions,
  NewAActiveSourceConfigurationPayload,
  ReducerAudienceActions,
  ReducerAudienceModeActions,
  ReducerSQLAudienceActions,
  ReducerSqlAudienceStepsActions,
  RequestAudienceExecutionFailedAction,
  RequestAudienceExecutionSaga,
  RequestAudienceExecutionSuccessAction,
  SqlAudienceQueryDescriptorReducerActions,
  UpdateActiveAudienceColumnsReducerActions,
  UpdateAudiencePreviewRequestedIdReducerActions,
  UpdateAudiencePreviewTraceparentReducerActions,
  UpdatePreviewAudienceSampleReducerActions,
  UpdatePreviewAudienceShapeReducerActions,
} from './types';
import { generateId, upsertInArray } from '@utils/helpers';
import { DraftSourceStatus, GetSourceEntitiesOutput } from '@features/audiences/ducks/api/types';
import {
  ActiveSQLAudience,
  ActiveSQLAudienceSettings,
  SqlMode,
} from '@features/audiences/types/ActiveAudience/ActiveAudience';
import { AudienceData, DeployedAudience } from '../types/DeployedAudience';
import { AudiencePreview } from '@features/audiences/types/AudiencePreview/AudiencePreview';
import { AudienceSpecificationType } from '@features/audiences/ducks/api/audienceBackTypes/audienceSpecification';
import { ActiveSQLSourceConfiguration } from '@features/audiences/types/ActiveAudience/ActiveSQLSourceConfiguration';
import { SqlAudienceQueryDescriptor } from '@features/audiences/types/Audience/SQL/SqlAudienceQueryDescriptor';
import { caseNever } from '@utils/case-never';
import { AudienceUnion } from '@features/audiences/types/Audience/Audience';
import { SqlAudienceSteps } from '@features/audiences/types/SQLAudienceSteps';
import { getDefaultModifiedAtOptions } from '@features/audiences/helpers/constants/datasource';
import { AudienceColumn } from '../types/AudienceColumn';
import { DEFAULT_AUDIENCE_PREVIEW_SAMPLE_PAGE_SIZE } from '../helpers/constants/constants';
import { ScheduleType } from '@contracts/schedule';
import {
  BusinessTerm,
  BusinessTermsFailedReducerActions,
  BusinessTermsSuccessReducerActions,
} from '../../audiences/ducks/api/types/ListBusinessTerms';

export type DescribeSourceProcessState = {
  describeSourceProcessId?: string;
  status?: DraftSourceStatus;
  error?: string;
};

export interface AudiencePreviewWorkflow extends AudiencePreview {
  isFetchingPreviewAudience?: boolean;
  isFetchingPreviewAudienceShape?: boolean;
  isFetchingPreviewAudienceSample?: boolean;
  previewAudienceError?: {
    title: string;
    details: string;
  };
  traceparent?: string;
}
export interface AudienceState {
  loaded: boolean;
  isFetching?: boolean;
  isFetchingDescribeAudience?: boolean;
  isFetchingAudiences?: boolean;
  isFetchingSqlAudienceSourcesShapes?: boolean;
  isFetchingRunSqlAudienceQuery?: boolean;
  isFetchingSqlAudienceQueryDescriptor?: boolean;
  isFetchingAudienceData?: boolean;
  isFetchingActiveSourceObject?: boolean;
  isFetchingConnectionPointers?: boolean;
  error?: boolean;
  errorDetails?: string;
  listBusinessTerms: {
    isFetchingBusinessTerms?: boolean;
    businessTermsErrorsDetails?: string;
    businessTerms: BusinessTerm[];
  };
  audiencePreview: AudiencePreviewWorkflow;
  describeSourceProcessState: DescribeSourceProcessState;
  audiences: AudienceUnion[];
  audiencesTableList: AudienceUnion[];
  deployedAudiences: DeployedAudience[];
  activeDeployedAudienceData?: AudienceData;
  sourceEntities?: GetSourceEntitiesOutput;
  sqlSourceEntities?: GetSourceEntitiesOutput;
  activeAudience: ActiveDefaultAudience;
  audienceMode: AudienceMode;
  sqlAudienceQueryDescriptor: SqlAudienceQueryDescriptor;
  activeSQLAudienceSettings: ActiveSQLAudienceSettings;
  activeSQLAudience: ActiveSQLAudience;
  activeSQLSourceConfiguration: ActiveSQLSourceConfiguration;
  sqlAudienceSteps: SqlAudienceSteps;
  activeMappingRows: MappingRow[];
  activeAudienceColumns: AudienceColumn[];
  activeSourceConfiguration: ActiveSourceConfiguration;
  activePreparation: ActivePreparationStep;
  audienceLifeCycle: AudienceLifeCycle;
  requestAudienceExecutionResult?: {
    audienceId?: string;
    isRequestAudienceExecutionCalled?: boolean;
    executionId?: string;
    errorDetails?: string;
  };
  audienceEditorInitialization: boolean;
}

const initialAudienceLifeCycle: AudienceLifeCycle = {
  audienceSavingProgress: AudienceSavingProgress.default,
  isCreateAudienceProcessCalled: false,
  isEditAudienceProcessCalled: false,
};

const initialSource: ActiveConnectionDataSource = {
  type: sourceTypesEnum.CONNECTION,
  modifiedAtOptions: getDefaultModifiedAtOptions(),
  modifiedAtFormat: 'YYYY-MM-DD',
};
const initialAudienceDeduplicationKeys: AudienceDeduplicationKey[] = [
  { key: generateId(), columnIds: [] },
];
const initialActiveSQLAudienceSettings: ActiveSQLAudienceSettings = {
  mode: SqlMode.query,
  deduplicationSettings: {
    deduplicationKeys: initialAudienceDeduplicationKeys,
    preservedValuesKeys: [],
  },
  dataApiEnabled: false,
  immutable: [],
};
const initialAudiencePreview: AudiencePreview = {
  counter: {
    type: CounterTypes.none,
  },
  sample: {
    size: 0,
    page: 1,
    pageSize: DEFAULT_AUDIENCE_PREVIEW_SAMPLE_PAGE_SIZE,
    records: [],
  },
  shape: [],
  shapeDetails: {
    masterIdColumnId: '',
    modifiedAtColumnId: '',
    preparationStepsAudienceColumnsIds: [],
    deletedPreparationStepAudienceColumns: [],
  },
  shapes: {
    audienceType: AudienceSpecificationType.default,
    preparationSteps: {
      steps: [],
      outputShape: [],
    },
  },
};

const initialSqlAudienceQueryDescriptor: SqlAudienceQueryDescriptor = {
  shape: {
    columns: [],
  },
};
const initialActivePreparations: ActivePreparationStep = {
  key: generateId(),
};
export const initialActiveSourceConfiguration: ActiveSourceConfiguration = {
  key: generateId(),
  combinationLevel: 0,
  dataSource: initialSource,
  dataType: dataTypesEnum.UNION,
  retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
  selectedColumns: [],
  deduplicationSettings: {
    deduplicationKeys: [],
  },
  preparations: [],
};
const initialActiveSQLSourceConfiguration: ActiveSQLSourceConfiguration = {
  key: generateId(),
  dataSource: initialSource,
  retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
  selectedColumns: [],
  deduplicationSettings: {
    deduplicationKeys: [],
  },
  preparations: [],
  columns: [],
};

const initialAudienceMode: AudienceMode = {
  buildType: buildTypesEnum.BUSINESS,
  name: '',
  longName: '',
};

const initialActiveAudience: ActiveDefaultAudience = {
  key: generateId(),
  version: 0,
  buildType: buildTypesEnum.BUSINESS,
  sources: [],
  parent: null,
  mappingRows: [],
  preparationSteps: [],
  deduplicationSettings: {
    deduplicationKeys: initialAudienceDeduplicationKeys,
    preservedValuesKeys: [],
  },
  schedule: {
    type: ScheduleType.none,
  },
  immutable: [],
  deployment: null,
  dataApiEnabled: false,
  dataApiProtectedColumns: [],
  businessModel: { columns: [] },
};

const initialActiveSQLAudience: ActiveSQLAudience = {
  key: generateId(),
  mode: SqlMode.query,
  version: 0,
  sources: [],
  deduplicationSettings: {
    deduplicationKeys: initialAudienceDeduplicationKeys,
    preservedValuesKeys: [],
  },
  schedule: {
    type: ScheduleType.none,
  },
  immutable: [],
  dataApiEnabled: false,
  columns: [],
  sqlQuery: '',
  deployment: null,
};

const initialSqlAudienceSteps: SqlAudienceSteps = {
  invalidSettingsProperties: null,
  isSettingsValid: false,
  isQueryValid: false,
  isSettingsUpdated: false,
};

export const INITIAL_AUDIENCE_STATE: AudienceState = {
  loaded: false,
  isFetching: false,
  isFetchingActiveSourceObject: false,
  audiences: [],
  audiencesTableList: [],
  deployedAudiences: [],
  activeMappingRows: [],
  activeAudienceColumns: [],
  describeSourceProcessState: {},
  audienceMode: initialAudienceMode,
  activeAudience: initialActiveAudience,
  activeSQLAudienceSettings: initialActiveSQLAudienceSettings,
  audiencePreview: initialAudiencePreview,
  listBusinessTerms: {
    businessTerms: [],
  },
  sqlAudienceQueryDescriptor: initialSqlAudienceQueryDescriptor,
  activeSQLAudience: initialActiveSQLAudience,
  activeSourceConfiguration: initialActiveSourceConfiguration,
  activeSQLSourceConfiguration: initialActiveSQLSourceConfiguration,
  activePreparation: initialActivePreparations,
  audienceLifeCycle: initialAudienceLifeCycle,
  sqlAudienceSteps: initialSqlAudienceSteps,
  audienceEditorInitialization: false,
};

const audiencesSlice = createSlice({
  name: 'audiences',
  initialState: INITIAL_AUDIENCE_STATE,
  reducers: {
    // Audience crud reducers

    getAudiences: (state) => {
      state.isFetching = true;
      state.isFetchingAudiences = true;
    },
    getAudiencesSuccess: (state, { payload }: AudiencesReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.isFetchingAudiences = false;
      state.audiences = payload.audiences;
    },
    getAudiencesFailed: (state, { payload }: AudiencesReducerActions) => {
      state.isFetching = false;
      state.isFetchingAudiences = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    getAudiencesTableList: (state) => {
      state.isFetching = true;
      state.isFetchingAudiences = true;
    },
    getAudiencesTableListSuccess: (state, { payload }: AudiencesReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.isFetchingAudiences = false;
      state.audiencesTableList = payload.audiences;
    },
    getAudiencesTableListFailed: (state, { payload }: AudiencesReducerActions) => {
      state.isFetching = false;
      state.isFetchingAudiences = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getAudience: (state) => {
      state.isFetching = true;
    },
    getAudienceSuccess: (state, { payload }: GetAudienceReducerActions) => {
      state.loaded = true;
      state.isFetching = false;

      if (!payload.audience) {
        return;
      }

      switch (payload.audience.buildType) {
        case buildTypesEnum.BUSINESS: {
          state.activeAudience = payload.audience;
          break;
        }

        case buildTypesEnum.DATA: {
          state.activeSQLAudience = payload.audience;
          break;
        }

        default:
          return caseNever(payload.audience);
      }
    },

    getAudienceFailed: (state, { payload }: GetAudienceReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    createAudienceProcess: (state) => {
      state.loaded = false;
      state.isFetching = true;
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        ...initialAudienceLifeCycle,
        isCreateAudienceProcessCalled: true,
        audienceId: state.activeAudience.key,
        audienceSavingProgress: AudienceSavingProgress.saveStarted,
      };
    },
    createAudienceProcessSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        isCreateAudienceProcessCalled: false,
      };
    },
    createAudienceProcessFailed: (state, { payload }: AudienceReducerActions) => {
      state.isFetching = false;
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        isCreateAudienceProcessCalled: false,
        errorPayload: payload.errorPayload,
        errorMessage: payload.errorDetails,
      };
    },
    updateAudienceProcess: (state) => {
      state.loaded = false;
      state.isFetching = true;
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        ...initialAudienceLifeCycle,
        isEditAudienceProcessCalled: true,
        audienceSavingProgress: AudienceSavingProgress.updateStarted,
      };
    },
    updateAudienceProcessSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        isEditAudienceProcessCalled: false,
      };
    },
    updateAudienceProcessFailed: (state, { payload }: AudienceReducerActions) => {
      state.isFetching = false;
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        isEditAudienceProcessCalled: false,
        errorPayload: payload.errorPayload,
        errorMessage: payload.errorDetails,
      };
    },

    saveAudienceSuccess: (state, { payload }: AudienceReducerActions) => {
      state.audiences = upsertInArray(
        payload.audience,
        state.audiences,
        (audience) => audience.key
      );

      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        audienceSavingProgress: AudienceSavingProgress.saveEnded,
      };
    },
    saveAudienceFailed: (state, { payload }: AudienceReducerActions) => {
      state.error = true;
      state.errorDetails = payload.errorDetails;
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        isCreateAudienceProcessCalled: false,
        errorMessage: payload.errorDetails,
        errorPayload: payload.errorPayload,
        audienceSavingProgress: AudienceSavingProgress.saveEnded,
      };
    },

    updateAudienceSuccess: (state, { payload }: AudienceReducerActions) => {
      const audiencesNewValues = upsertInArray(
        payload.audience,
        state.audiences,
        (audience) => audience.key
      );

      state.loaded = true;
      state.isFetching = false;
      state.audiences = audiencesNewValues;
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        audienceSavingProgress: AudienceSavingProgress.updateEnded,
      };
    },
    updateAudienceFailed: (state, { payload }: AudienceReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        isEditAudienceProcessCalled: false,
        errorMessage: payload.errorDetails,
        errorPayload: payload.errorPayload,
        audienceSavingProgress: AudienceSavingProgress.updateEnded,
      };
    },

    updateAudienceDeploymentStatus: (state) => {
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        audienceSavingProgress: AudienceSavingProgress.deployStarted,
      };
    },
    updateAudienceDeploymentStatusSuccess: (state, { payload }: DeploymentStatusReducerActions) => {
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        deployment: payload.deployment,
        audienceSavingProgress: AudienceSavingProgress.deployEnded,
      };
    },
    updateAudienceDeploymentStatusFailed: (state, { payload }: DeploymentStatusReducerActions) => {
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        audienceSavingProgress: AudienceSavingProgress.deployEnded,
        deployment: payload.deployment,
        errorMessage: payload.deployment.executionError.message,
      };
    },
    clearAudienceLifeCycle: (state) => {
      state.audienceLifeCycle = {
        ...state.audienceLifeCycle,
        ...initialAudienceLifeCycle,
      };
    },

    deleteAudience: (state) => {
      state.isFetching = true;
    },
    deleteAudienceSuccess: (state, { payload }: DeleteAudienceReducerActions) => {
      const audiencesNewValues = state.audiences.filter((a) => a.key !== payload.audienceId);
      state.loaded = true;
      state.isFetching = false;
      state.audiences = audiencesNewValues;
    },
    deleteAudienceFailed: (state, { payload }: AudienceReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    // Describe audience reducers

    clearAudiencePreview: (state) => {
      state.audiencePreview = initialAudiencePreview;
    },

    previewAudience: (state) => {
      state.audiencePreview = {
        ...initialAudiencePreview,
        traceparent: state.audiencePreview.traceparent,
        isFetchingPreviewAudience: true,
        isFetchingPreviewAudienceShape: true,
        isFetchingPreviewAudienceSample: true,
      };
    },
    updateAudiencePreviewRequestedId: (
      state,
      { payload }: UpdateAudiencePreviewRequestedIdReducerActions
    ) => {
      const { previewId } = payload;
      state.audiencePreview = {
        ...state.audiencePreview,
        id: previewId,
      };
    },
    updateAudiencePreviewTraceparent: (
      state,
      { payload }: UpdateAudiencePreviewTraceparentReducerActions
    ) => {
      state.audiencePreview = {
        ...state.audiencePreview,
        traceparent: payload.traceparent,
      };
    },
    updatePreviewAudienceShape: (state, { payload }: UpdatePreviewAudienceShapeReducerActions) => {
      const { shape, shapeDetails, shapes } = payload;
      state.audiencePreview = {
        ...state.audiencePreview,
        shape,
        shapeDetails,
        shapes,
        isFetchingPreviewAudienceShape: false,
      };
    },

    updatePreviewAudienceSample: (
      state,
      { payload }: UpdatePreviewAudienceSampleReducerActions
    ) => {
      const { sample, counter } = payload;
      state.audiencePreview = {
        ...state.audiencePreview,
        sample,
        counter,
        isFetchingPreviewAudienceSample: false,
      };
    },
    previewAudienceSuccess: (state) => {
      state.loaded = true;
      state.audiencePreview.isFetchingPreviewAudience = false;
    },

    previewAudienceFailed: (
      state,
      { payload }: { payload: { errorTitle: string; errorDetails: string } }
    ) => {
      state.audiencePreview = {
        ...state.audiencePreview,
        isFetchingPreviewAudience: false,
        isFetchingPreviewAudienceShape: false,
        isFetchingPreviewAudienceSample: false,
        previewAudienceError: {
          title: payload.errorTitle,
          details: payload.errorDetails,
        },
      };
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    cleanPreviewAudienceState: (
      state,
      { payload }: { payload: { audiencePreviewId?: string } }
    ) => {
      state.audiencePreview = {
        ...initialAudiencePreview,
        id: payload.audiencePreviewId,
      };
    },
    updateActiveAudienceColumns: (
      state,
      { payload }: UpdateActiveAudienceColumnsReducerActions
    ) => {
      const { activeAudienceColumns } = payload;
      state.activeAudienceColumns = activeAudienceColumns;
    },
    getAudiencePreviewSample: (state) => {
      state.audiencePreview.isFetchingPreviewAudience = true;
    },
    getAudiencePreviewSampleSuccess: (
      state,
      { payload }: GetAudiencePreviewSampleReducerActions
    ) => {
      state.audiencePreview.isFetchingPreviewAudience = false;
      state.audiencePreview.sample = payload.sample;
    },
    getAudiencePreviewSampleFailed: (state) => {
      state.audiencePreview.isFetchingPreviewAudience = false;
      state.error = true;
    },

    runSqlAudienceQuery: (state) => {
      state.loaded = false;
      state.isFetching = true;
      state.isFetchingRunSqlAudienceQuery = true;
    },
    runSqlAudienceQuerySuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
      state.isFetchingRunSqlAudienceQuery = false;
    },
    runSqlAudienceQueryFail: (state, { payload }: { payload: { errorDetails: string } }) => {
      state.loaded = true;
      state.isFetching = false;
      state.errorDetails = payload.errorDetails;
      state.isFetchingRunSqlAudienceQuery = false;
    },

    sqlAudienceQueryDescriptor: (state) => {
      state.isFetchingSqlAudienceQueryDescriptor = true;
      state.sqlAudienceQueryDescriptor = initialSqlAudienceQueryDescriptor;
    },
    sqlAudienceQueryDescriptorSuccess: (
      state,
      { payload }: SqlAudienceQueryDescriptorReducerActions
    ) => {
      state.loaded = true;
      state.isFetchingSqlAudienceQueryDescriptor = false;
      state.sqlAudienceQueryDescriptor = payload.sqlAudienceQueryDescriptor;
    },
    sqlAudienceQueryDescriptorFailed: (
      state,
      { payload }: { payload: { errorDetails: string } }
    ) => {
      state.isFetchingSqlAudienceQueryDescriptor = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    describeSqlAudienceSourcesShapes: (state) => {
      state.isFetchingSqlAudienceSourcesShapes = true;
    },
    describeSqlAudienceSourcesShapesSuccess: (state) => {
      state.loaded = true;
      state.isFetchingSqlAudienceSourcesShapes = false;
    },
    describeSqlAudienceSourcesShapesFailed: (
      state,
      { payload }: { payload: { errorDetails: string } }
    ) => {
      state.isFetchingSqlAudienceSourcesShapes = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    // Init screens
    initAudienceEditor: (state) => {
      state.isFetching = true;
      state.audienceEditorInitialization = true;
    },
    initAudienceEditorSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
      state.audienceEditorInitialization = false;
    },
    initAudienceEditorFailed: (state, { payload }: { payload: { errorDetails: string } }) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
      state.audienceEditorInitialization = false;
    },

    clearSqlAudienceEditor: (state) => {
      state.activeSQLAudience = {
        ...initialActiveSQLAudience,
        key: generateId(),
      };

      state.activeSQLSourceConfiguration = {
        ...initialActiveSQLSourceConfiguration,
        key: generateId(),
        dataSource: { ...initialActiveSQLSourceConfiguration.dataSource },
      };

      state.activeSQLAudienceSettings = {
        ...initialActiveSQLAudienceSettings,
      };

      state.audiencePreview = initialAudiencePreview;

      state.audienceEditorInitialization = false;

      state.sqlAudienceQueryDescriptor = {
        ...initialSqlAudienceQueryDescriptor,
      };

      state.sqlAudienceSteps = {
        ...initialSqlAudienceSteps,
      };
    },

    initSqlAudienceView: (state) => {
      state.isFetching = true;
    },
    initSqlAudienceViewSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    initSqlAudienceViewFailed: (state, { payload }: AudienceReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    // source entities

    getSourceEntitiesSuccess: (state, { payload }: GetSourceEntitiesReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.isFetchingConnectionPointers = false;
      state.sourceEntities = payload.sourceEntities;
    },

    getSourceEntitiesFailed: (state, { payload }: AudienceReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.isFetchingConnectionPointers = false;
      state.errorDetails = payload.errorDetails;
    },

    clearSourceEntities: (state) => {
      state.sourceEntities = undefined;
    },
    // Active audience reducers
    saveFirstSourceForm: (state) => {
      state.isFetching = true;
    },
    saveFirstSourceFormSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    saveFirstSourceFormFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    createSourceDataForm: (state) => {
      state.isFetching = true;
    },
    createSourceDataFormSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    createSourceDataFormFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    editSourceDataForm: (state) => {
      state.isFetching = true;
    },
    editSourceDataFormSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    editSourceDataFormFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    createSourceDataFormForSql: (state) => {
      state.isFetching = true;
    },
    createSourceDataFormForSqlSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    createSourceDataFormForSqlFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    editSourceDataFormForSql: (state) => {
      state.isFetching = true;
    },
    editSourceDataFormForSqlSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    editSourceDataFormForSqlFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    createJoinedAudienceSourceForm: (state) => {
      state.isFetching = true;
    },
    createJoinedAudienceSourceFormSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    createJoinedAudienceSourceFormFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    editJoinedAudienceSourceForm: (state) => {
      state.isFetching = true;
    },
    editJoinedAudienceSourceFormSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    editJoinedAudienceSourceFormFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    setupJoinedAudienceSource: (state) => {
      state.isFetching = true;
    },
    setupJoinedAudienceSourceForEdition: (state) => {},
    setupJoinedAudienceSourceSuccess: (
      state,
      { payload }: { type: string; payload: { newSourceConfig: ActiveSourceConfiguration } }
    ) => {
      state.loaded = true;
      state.isFetching = false;
      state.activeSourceConfiguration = payload.newSourceConfig;
    },
    setupJoinedAudienceSourceFailed: (
      state,
      { payload }: { type: string; payload: { errorDetails: string } }
    ) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    // Update redux state direct actions
    updateAudienceMode: (state, { payload }: ReducerAudienceModeActions) => {
      const { audienceMode } = payload;
      state.audienceMode = {
        ...audienceMode,
      };
    },

    clearAudienceMode: (state) => {
      state.audienceMode = {
        ...initialAudienceMode,
      };
    },

    updateActiveAudience: (state, { payload }: ReducerAudienceActions) => {
      const { activeAudience } = payload;
      state.activeAudience = activeAudience;
    },

    updateActiveSQLAudience: (state, { payload }: ReducerSQLAudienceActions) => {
      const { activeSQLAudience } = payload;
      state.activeSQLAudience = activeSQLAudience;
    },

    updateActivePreparation: (state, { payload }: ActivePreparationPayload) => {
      const { preparation } = payload;
      state.activePreparation = preparation;
    },

    updateActivePreparationSteps: (state, { payload }: ActivePreparationStepsPayload) => {
      const { preparationSteps } = payload;
      state.activeAudience.preparationSteps = preparationSteps;
    },

    updateActiveMappingRows: (state, { payload }: ActiveMappingRowsPayload) => {
      const { mappingRows } = payload;
      state.activeMappingRows = mappingRows;
    },

    updateActiveSourceConfiguration: (state, { payload }: ActiveSourceConfigurationPayload) => {
      const { sourceConfiguration } = payload;
      state.activeSourceConfiguration = sourceConfiguration;
    },

    updateActiveSQLSourceConfiguration: (
      state,
      { payload }: ActiveSQLSourceConfigurationPayload
    ) => {
      const { sourceConfiguration } = payload;
      state.activeSQLSourceConfiguration = sourceConfiguration;
    },

    getDeployedAudiences: (state) => {
      state.isFetching = true;
    },

    getDeployedAudiencesSuccess: (state, { payload }: DeployedAudiencesReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.deployedAudiences = payload.deployedAudiences;
    },
    getDeployedAudiencesFailed: (state, { payload }: DeployedAudiencesReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getAudienceData: (state) => {
      state.isFetchingAudienceData = true;
    },
    getAudienceDataSuccess: (state, { payload }: AudienceDataReducerActions) => {
      state.loaded = true;
      state.isFetchingAudienceData = false;
      state.activeDeployedAudienceData = payload.audienceData;
    },

    clearDefaultAudienceView: (state) => {
      state.activeDeployedAudienceData = undefined;
      state.activeAudience = { ...initialActiveAudience, key: generateId() };
      state.activeMappingRows = [];
      state.audienceLifeCycle = {
        ...initialAudienceLifeCycle,
      };
    },

    clearSqlAudienceView: (state) => {
      state.activeDeployedAudienceData = undefined;
      state.activeSQLAudience = { ...initialActiveSQLAudience, key: generateId() };
      state.audienceLifeCycle = {
        ...initialAudienceLifeCycle,
      };
    },
    getAudienceDataFailed: (state, { payload }: AudienceDataReducerActions) => {
      state.isFetchingAudienceData = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    updateActiveSQLAudienceSettings: (state, { payload }: ActiveSQLAudienceSettingsPayload) => {
      const { activeSQLAudienceSettings } = payload;
      state.activeSQLAudienceSettings = activeSQLAudienceSettings;
    },
    updateRequestDraftSourceStatus: (state, { payload }: DescribeSourceStatusReduceAction) => {
      const { status } = payload;
      state.describeSourceProcessState = {
        ...state.describeSourceProcessState,
        status,
      };
    },
    requestAudienceExecution: (
      state,
      { payload }: Pick<RequestAudienceExecutionSaga, 'payload'>
    ) => {
      state.requestAudienceExecutionResult = {
        audienceId: payload.requestAudienceExecutionCommand.audienceId,
        errorDetails: undefined,
        executionId: undefined,
        isRequestAudienceExecutionCalled: true,
      };
    },
    cleanAudienceExecutionResult: (state) => {
      state.requestAudienceExecutionResult = undefined;
    },
    requestAudienceExecutionSuccess: (
      state,
      { payload }: RequestAudienceExecutionSuccessAction
    ) => {
      state.requestAudienceExecutionResult = {
        ...state.requestAudienceExecutionResult,
        errorDetails: undefined,
        executionId: payload.executionId,
        isRequestAudienceExecutionCalled: false,
      };
    },
    requestAudienceExecutionFailed: (state, { payload }: RequestAudienceExecutionFailedAction) => {
      state.requestAudienceExecutionResult = {
        ...state.requestAudienceExecutionResult,
        errorDetails: payload.errorDetails,
        isRequestAudienceExecutionCalled: false,
      };
    },

    draftSource: (state) => {
      state.loaded = false;
      state.isFetchingActiveSourceObject = true;
    },
    draftSourceSuccess: (state, { payload }: DescribeSourceReducerAction) => {
      const { status } = payload;
      state.loaded = true;
      state.isFetchingActiveSourceObject = false;
      state.describeSourceProcessState = {
        ...state.describeSourceProcessState,
        status,
        error: undefined,
      };
    },

    draftSourceFailed: (state, { payload }) => {
      state.isFetchingActiveSourceObject = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
      state.describeSourceProcessState = {
        ...state.describeSourceProcessState,
        status: DraftSourceStatus.error,
        error: payload.errorDetails,
      };
    },

    updateSqlAudienceSteps: (state, { payload }: ReducerSqlAudienceStepsActions) => {
      const { steps } = payload;
      state.sqlAudienceSteps = {
        ...state.sqlAudienceSteps,
        ...steps,
      };
    },

    // Preparation step
    saveInitialAudiencePreparationStep: (
      state,
      { payload }: ActiveSourceConfigurationPayload
    ) => {},
    saveSourceDataPreparationStep: (state, { payload }: ActiveSourceConfigurationPayload) => {},
    // empty actions / initial actions
    setEmptyActiveAudience: (state) => {
      state.activeAudience = initialActiveAudience;
    },
    setInitialActiveAudienceFormsStates: (state) => {
      state.activeAudience = { ...initialActiveAudience, key: generateId() };
      state.activeMappingRows = [];
      state.activeSourceConfiguration = {
        ...initialActiveSourceConfiguration,
        key: generateId(),
        dataSource: { ...initialActiveSourceConfiguration.dataSource },
      };
    },

    setInitialActiveSQLAudienceFormsStates: (state) => {
      state.activeSQLAudience = { ...initialActiveSQLAudience, key: generateId() };
      state.activeSQLSourceConfiguration = {
        ...initialActiveSQLSourceConfiguration,
        key: generateId(),
        dataSource: { ...initialActiveSQLSourceConfiguration.dataSource },
      };

      state.activeSQLAudienceSettings = {
        ...initialActiveSQLAudienceSettings,
      };
    },
    setInitialAudienceModeFormsStates: (state) => {
      state.audienceMode = { ...initialAudienceMode };
    },

    setNewActiveSourceConfiguration: (state, { payload }: NewAActiveSourceConfigurationPayload) => {
      const { combinationLevel } = payload;
      state.describeSourceProcessState = {};
      state.sourceEntities = undefined;
      state.activeSourceConfiguration = {
        ...initialActiveSourceConfiguration,
        combinationLevel,
        key: generateId(),
        dataSource: { ...initialActiveSourceConfiguration.dataSource },
      };
    },

    setNewActiveSQLSourceConfiguration: (state) => {
      state.describeSourceProcessState = {};
      state.sourceEntities = undefined;
      state.activeSQLSourceConfiguration = {
        ...initialActiveSQLSourceConfiguration,
        key: generateId(),
        dataSource: { ...initialActiveSQLSourceConfiguration.dataSource },
      };
    },
    getSourceEntitiesForAudienceSource: (state) => {
      state.isFetchingConnectionPointers = true;
    },

    // init audience forms
    initAudienceSourceCreation: () => {},
    initAudiencesArchitectureView: () => {},

    // Business Terms

    listBusinessTerms: (state) => {
      state.listBusinessTerms.isFetchingBusinessTerms = true;
    },
    listBusinessTermsSuccess: (state, { payload }: BusinessTermsSuccessReducerActions) => {
      const { businessTerms } = payload;
      state.listBusinessTerms.isFetchingBusinessTerms = false;
      state.listBusinessTerms.businessTerms = businessTerms;
    },

    listBusinessTermsFailed: (state, { payload }: BusinessTermsFailedReducerActions) => {
      const { errorDetails } = payload;
      state.listBusinessTerms.isFetchingBusinessTerms = false;
      state.listBusinessTerms.businessTermsErrorsDetails = errorDetails;
    },
  },
});

export default audiencesSlice;
