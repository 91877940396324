import { IColumnType } from '@features/audiences/ducks/api/audienceBackTypes/columnType';

export interface ApiConnectionEntityFieldReadModel {
  id: string;
  label: string;
  type: IColumnType;
  canBeKey: boolean;
  isUnique: boolean;
  isOutput: boolean;
  isRequired: boolean;
}
export interface ApiConnectionEntitySchemaReadModel {
  __typename: GetApiDestinationSchemaTypename.ApiConnectionEntityDestinationSchema;
  id: string;
  label: string;
  fields: ApiConnectionEntityFieldReadModel[];
}
export interface EntityTypeNotFound {
  __typename: GetApiDestinationSchemaTypename.EntityTypeNotFound;

  connectionId: string;
  connectionType: string;
  entityTypeId: string;
}
export interface ConnectionNotFound {
  __typename: GetApiDestinationSchemaTypename.ConnectionNotFound;
  id: string;
}
export enum GetApiDestinationSchemaTypename {
  ApiConnectionEntityDestinationSchema = 'ApiConnectionEntityDestinationSchema',
  EntityTypeNotFound = 'EntityTypeNotFound',
  ConnectionNotFound = 'ConnectionNotFound',
}
export type GetApiDestinationSchemaOutput =
  | ApiConnectionEntitySchemaReadModel
  | EntityTypeNotFound
  | ConnectionNotFound;
