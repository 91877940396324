import { MappingRow, PreparationActionType, PreparationStep } from '../types';
import { AudienceColumn } from '../types/AudienceColumn';

export const getDeletedPrepAudienceColumns = (
  preparationSteps: PreparationStep[],
  mappingRows: MappingRow[]
): AudienceColumn[] => {
  const deletedAudienceColumnIds = preparationSteps.reduce((result: string[], prep) => {
    if (prep.configuration.type === PreparationActionType.delete_columns) {
      prep.configuration.columns.map((elem) => result.push(elem.originalColumnId));
    }
    return result;
  }, []);
  const deletedAudienceColumns: AudienceColumn[] = [];
  deletedAudienceColumnIds.forEach((id) => {
    const mappingRow = mappingRows.find((row) => row.key === id);
    if (mappingRow) {
      const audienceColumn: AudienceColumn = {
        id: mappingRow.key,
        name: mappingRow.audienceColumnName,
        type: mappingRow.audienceColumnType,
      };
      deletedAudienceColumns.push(audienceColumn);
    }
  });
  return deletedAudienceColumns;
};
