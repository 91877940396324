import React, { useEffect, useState } from 'react';
import { User } from '@auth0/auth0-spa-js';
import { Auth0Context } from '@providers/Auth0Provider';

import {
  getFrontendTestingSignInRequest,
  removeFrontendTestingSignInRequest,
} from './frontend-testing';

export const FrontendTestingAuth0Provider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<User | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const request = getFrontendTestingSignInRequest();
    if (request) {
      setUser({
        name: request.user.sub,
        sub: request.user.sub,
      });
      setIsAuthenticated(true);
      setLoading(false);
    } else {
      logout();
    }
  }, []);

  const loginWithPopup = async (params = {}) => undefined;

  const handleRedirectCallback = async () => undefined;

  const logout = () => {
    removeFrontendTestingSignInRequest();
    setIsAuthenticated(false);
  };

  const getIdTokenClaims = () => undefined;

  const loginWithRedirect = async () => Promise.resolve();

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen: false,
        loginWithPopup,
        handleRedirectCallback,
        logout,
        getIdTokenClaims,
        loginWithRedirect,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
