import { gql } from '@apollo/client';
const GET_CONNECTIONS = gql`
  query ($accountId: String!) {
    connections(accountId: $accountId) {
      __typename
      id
      name
      status
      canBeUsedAs
      type
      settings
    }
  }
`;
const GET_CONNECTION = gql`
  query getConnection($id: String!) {
    connection(id: $id) {
      id
      version
      name
      status
      canBeUsedAs
      settings
    }
  }
`;

const GET_CONNECTION_RESOURCES = gql`
  query ($query: GetConnectionResourcesQuery!) {
    getConnectionResources(query: $query) {
      __typename
      ... on ConnectionResourceList {
        resources {
          id
          type
          label
          canHaveChildren
          path
        }
        nextPageToken
      }
    }
  }
`;

const LIST_API_CONNECTION_ENTITY_TYPES = gql`
  query ($query: ListApiConnectionEntityTypesQuery!) {
    listApiConnectionEntityTypes(query: $query) {
      __typename
      ... on ApiConnectionEntityTypeList {
        types {
          id
          label
          isSource
          isDestination
          destinationOperations
        }
      }
    }
  }
`;

export {
  GET_CONNECTIONS,
  GET_CONNECTION,
  GET_CONNECTION_RESOURCES,
  LIST_API_CONNECTION_ENTITY_TYPES,
};
