import {
  IDatabaseDestinationSettings,
  IFileSystemDestinationSettings,
  IWebhookDestinationSettings,
} from './IDestinationSettings';

export enum DestinationEnum {
  api = 'api',
  fs = 'fs',
  db = 'db',
  webhook = 'webhook',
}

type IConnectionIdAware = { connectionId: string };
export type IApiDestinationSettingsV2<
  EntityTypeId = any,
  EntitySettings extends Record<string, any> | undefined = Record<string, any> | undefined
> = {
  entityTypeId: EntityTypeId;
  entitySettings: EntitySettings;
  keyFieldId?: string;
  destinationSettings?: Record<string, any>;
};
export type IApiDestinationV2<
  Settings extends IApiDestinationSettingsV2 = IApiDestinationSettingsV2
> = {
  type: DestinationEnum.api;
  settings: Settings;
} & IConnectionIdAware;
export type IFileSystemDestinationV2<
  Settings extends IFileSystemDestinationSettings = IFileSystemDestinationSettings
> = {
  type: DestinationEnum.fs;
  settings: Settings;
} & IConnectionIdAware;
export type IDatabaseDestinationV2<
  Settings extends IDatabaseDestinationSettings = IDatabaseDestinationSettings
> = {
  type: DestinationEnum.db;
  settings: Settings;
} & IConnectionIdAware;

export type IWebhookDestinationV2<
  Settings extends IWebhookDestinationSettings = IWebhookDestinationSettings
> = {
  type: DestinationEnum.webhook;
  settings: Settings;
};

export type IDestination =
  | IApiDestinationV2
  | IFileSystemDestinationV2
  | IDatabaseDestinationV2
  | IWebhookDestinationV2;
