import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
// connectorsSlice
import organizationsSlice from '@features/organizations/ducks/organizationsSlice';
// types
import * as types from './types';

function* getOrganizationsSaga({ payload }: types.GetOrganizationsSaga) {
  try {
    const { app } = payload;
    const getOrganizationsPayload: types.ApiGetOrganizations = yield call(
      API.getOrganizations,
      app
    );
    yield put({
      type: organizationsSlice.actions.getOrganizationsSuccess.type,
      payload: getOrganizationsPayload,
    });
  } catch (error) {
    yield put({ type: organizationsSlice.actions.getOrganizationsFailed.type, payload: error });
  }
}
function* getOrganizationSaga({ payload }: types.GetOrganizationSaga) {
  try {
    const { organization } = payload;
    const getOrganizationPayload: types.ApiGetOrganization = yield call(
      API.getOrganization,
      organization
    );
    yield put({
      type: organizationsSlice.actions.getOrganizationSuccess.type,
      payload: getOrganizationPayload,
    });
  } catch (error) {
    yield put({ type: organizationsSlice.actions.getOrganizationFailed.type, payload: error });
  }
}
export function* getOrganizationByDomainSaga({ payload }: types.GetOrganizationDomainSaga) {
  const { domain } = payload;
  const getOrganizationPayload: types.APIGetOrganizationByDomainPayload = yield call(
    API.getOrganizationByDomain,
    domain
  );
  if (!getOrganizationPayload.error && getOrganizationPayload.organizationByDomain) {
    yield put({
      type: organizationsSlice.actions.getOrganizationByDomainSuccess.type,
      payload: getOrganizationPayload,
    });
  } else {
    yield put({
      type: organizationsSlice.actions.getOrganizationByDomainFailed.type,
      payload: { errorDetails: getOrganizationPayload.errorDetails },
    });
  }
}
export const organizationsSagas = [
  takeLatest(organizationsSlice.actions.getOrganizations.type, getOrganizationsSaga),
  takeLatest(organizationsSlice.actions.getOrganization.type, getOrganizationSaga),
  takeLatest(organizationsSlice.actions.getOrganizationByDomain.type, getOrganizationByDomainSaga),
];
