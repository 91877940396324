import { createSlice } from '@reduxjs/toolkit';
import {
  ActiveDataWarehouseIdReducerActions,
  APICreateDataWarehousePayload,
  APIGetDataWarehousesPayload,
} from './types';
import { ConnectionStatus } from '@features/connections/types';
import { ActiveDataWarehouse } from '@features/dataWarehouse/types';
import { DataWarehouseCreationProgress } from '@features/dataWarehouse/types/NeonDatabaseCreationProcess';

export type NeonDataWarehouseCreationLifeCycle = {
  isCreateDataWarehouseProcessCalled?: boolean;
  dataWarehouseCreationProgress: DataWarehouseCreationProgress;
  error?: boolean;
  errorMessage?: string;
};
export interface DataWarehouseState {
  loaded: boolean;
  isFetching?: boolean;
  isTestingDataWarehouseSettings?: boolean;
  error?: boolean;
  errorDetails?: string;
  activeDataWarehouseId?: string;
  activeDataWarehouse: ActiveDataWarehouse;
  dataWarehouseSettingsTest?: ConnectionStatus;
  dataWarehouses: string[];
  dataWarehouseWithNeonCreationStatus: NeonDataWarehouseCreationLifeCycle;
}
export const INITIAL_DATA_WAREHOUSE_STATE: DataWarehouseState = {
  activeDataWarehouseId: '',
  activeDataWarehouse: {
    credentials: {
      ssl: false,
    },
  },
  dataWarehouses: [],
  dataWarehouseWithNeonCreationStatus: {
    dataWarehouseCreationProgress: DataWarehouseCreationProgress.default,
  },
  loaded: false,
};

const dataWarehouseSlice = createSlice({
  name: 'dataWarehouses',

  initialState: INITIAL_DATA_WAREHOUSE_STATE,
  reducers: {
    updateActiveDataWarehouseId: (state) => {
      state.isFetching = true;
    },
    updateActiveDataWarehouseIdSuccess: (
      state,
      { payload }: ActiveDataWarehouseIdReducerActions
    ) => {
      state.loaded = true;
      state.isFetching = false;
      state.activeDataWarehouseId = payload.activeDataWarehouseId;
    },
    updateActiveDataWarehouseIdFailed: (
      state,
      { payload }: ActiveDataWarehouseIdReducerActions
    ) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    getDatawarehouses: (state) => {
      state.isFetching = true;
      state.loaded = false;
    },
    getDatawarehousesSuccess: (state, { payload }: { payload: APIGetDataWarehousesPayload }) => {
      state.isFetching = false;
      state.loaded = true;
      state.dataWarehouses = payload.dataWarehouses;
      state.activeDataWarehouseId = payload.dataWarehouses.length ? payload.dataWarehouses[0] : '';
    },
    getDatawarehousesFailed: (state, { payload }: { payload: { errorDetails: string } }) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    updateActiveDataWarehouse: (
      state,
      { payload }: { payload: { activeDataWarehouse: ActiveDataWarehouse } }
    ) => {
      state.isFetching = false;
      state.error = true;
      state.activeDataWarehouse = payload.activeDataWarehouse;
    },

    testDataWarehouseSettings: (state) => {
      state.isTestingDataWarehouseSettings = true;
    },
    testDataWarehouseSettingsSuccess: (
      state,
      { payload }: { payload: { status: ConnectionStatus } }
    ) => {
      state.isTestingDataWarehouseSettings = false;
      state.dataWarehouseSettingsTest = payload.status;
    },
    testDataWarehouseSettingsFailed: (
      state,
      { payload }: { payload: { errorDetails: string } }
    ) => {
      state.isTestingDataWarehouseSettings = false;
      state.dataWarehouseSettingsTest = ConnectionStatus.unreachable;
    },

    createDataWarehouse: (state) => {
      state.isFetching = true;
      state.loaded = false;
    },
    createDataWarehouseSuccess: (
      state,
      { payload }: { payload: APICreateDataWarehousePayload }
    ) => {
      state.isFetching = false;
      state.loaded = true;
      state.activeDataWarehouseId = payload.dataWarehouse.id;
    },
    createDataWarehouseFailed: (state, { payload }: { payload: { errorDetails: string } }) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    createConnectionDataWarehouseSuccess: (
      state,
      { payload }: { payload: { errorDetails: string } }
    ) => {
      state.isFetching = false;
    },
    createConnectionDataWarehouseFailed: (
      state,
      { payload }: { payload: { errorDetails: string } }
    ) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    createDataWarehouseWithNeon: (state) => {
      state.isFetching = true;
      state.loaded = false;
    },
    createDataWarehouseWithNeonSuccess: (
      state,
      { payload }: { payload: APICreateDataWarehousePayload }
    ) => {
      state.isFetching = false;
      state.loaded = true;
      state.activeDataWarehouseId = payload.dataWarehouse.id;
    },
    createDataWarehouseWithNeonFailed: (
      state,
      { payload }: { payload: { errorDetails: string } }
    ) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    updateDataWarehouseCreationWithNeonStatus: (
      state,
      { payload }: { payload: NeonDataWarehouseCreationLifeCycle }
    ) => {
      state.dataWarehouseWithNeonCreationStatus = {
        ...state.dataWarehouseWithNeonCreationStatus,
        ...payload,
      };
    },
  },
});

export default dataWarehouseSlice;
