import { UserManagerSettings } from 'oidc-client-ts';
import { oidcNEONConstants } from '@features/dataWarehouse/constants/oidcNEONConstants';

export const oidcNEONSettings: UserManagerSettings = {
  authority: oidcNEONConstants.stsAuthority ? oidcNEONConstants.stsAuthority : '',
  client_id: oidcNEONConstants.clientId ? oidcNEONConstants.clientId : '',
  redirect_uri: `${oidcNEONConstants.clientRoot}oauth/neon`,
  post_logout_redirect_uri: `${oidcNEONConstants.clientRoot}`,
  response_type: 'code',
  scope: oidcNEONConstants.clientScope,
};
