const resourceTypes = {
  sources: 'Source',
  sources_plural: 'Sources',
  datasets: 'Dataset',
  datasets_plural: 'Datasets',
  syncs: 'Synchronisation',
  syncs_plural: 'Synchronisations',
};

const resourceErrors = {
  'error-banner-title': 'Les dernières modifications ne sont pas déployées.',
  'invariant-error-banner-description':
    'Veuillez modifier tous les éléments en rouge pour déployer vos dernières modifications.',
  'eventual-error-banner-description':
    'Veuillez modifier tous les éléments en jaune pour déployer vos dernières modifications.',
  'error-banner-more-info': "Plus d'informations.",
  'rollback-invalid-changes': 'Annuler mes dernières modifications',
  'eventual-error-help-mark':
    'Certaines modifications sont nécessaires pour pouvoir déployer vos dernières modifications.',
  'invariant-error-help-mark': 'Il y a des erreurs qui doivent être corrigées.',
};

const connectionSourceErrors = {
  'source-name-is-empty-title': 'Nom de la source vide',
  'source-name-is-empty-description':
    'Le champ Nom ne peut pas être vide. Veuillez indiquer un nom pour la source.',

  'source-name-is-too-long-title': 'Nom de la source trop long',
  'source-name-is-too-long-description':
    'Le nom de la source de connexion ne peut pas dépasser la longueur maximale de 30 caractères.',

  'source-name-duplicated-title': 'Nom de la source en double',
  'source-name-duplicated-description':
    'Ce nom est déjà utilisé par une autre source. Veuillez choisir un nom unique.',

  'updated-at-column-type-mismatching-timestamp-type-title':
    'Incompatibilité de type dans la colonne "Updated at"',
  'updated-at-column-type-mismatching-timestamp-type-description':
    'La colonne "Updated at" doit être de type timestamp ou convertie en timestamp.',
  'created-at-column-type-mismatching-timestamp-type-title':
    'Incompatibilité de type dans la colonne "Created at"',
  'created-at-column-type-mismatching-timestamp-type-description':
    'La colonne "Created at" doit être de type timestamp ou convertie en timestamp.',

  'source-connection-not-found-title': 'Connexion non valide',
  'source-connection-not-found-description':
    'Veuillez sélectionner une connexion existante activée.',

  'source-semantic-entity-not-found-title': 'Entité sémantique non valide',
  'source-semantic-entity-not-found-description':
    'Veuillez sélectionner une entité sémantique existante.',

  'source-settings-not-valid-title': 'Paramètres de la source non valides',
  'source-settings-not-valid-description':
    'Les paramètres de la source ne sont pas valides selon les exigences du connecteur.',

  'too-many-source-column-title': 'Limite Maximale de Colonnes Dépassée',
  'too-many-source-column-description':
    'Le nombre de colonnes a dépassé la limite maximale de 50. Veuillez réduire le nombre de colonnes.',

  'deduplication-key-is-empty-title': 'Clé de déduplication vide',
  'deduplication-key-is-empty-description':
    'La clé de déduplication ne peut pas être vide. Veuillez sélectionner les colonnes qui seront utilisées comme clé.',

  'source-have-no-column-title': 'Aucune colonne sélectionnée',
  'source-have-no-column-description':
    'Une source doit avoir au moins une colonne. Veuillez sélectionner les colonnes qui seront importées dans la source.',
};

const sourceRelationshipsErrors = {
  'mismatching-parent-and-child-datatype-title':
    'Incompatibilité de DataType dans les colonnes des sources',
  'mismatching-parent-and-child-datatype-description':
    'Le type de données de la colonne source référencée ne correspond pas au type de l’autre colonne source.',
  'join-condition-columns-not-found-title': 'Colonnes de relation des sources non trouvées',
  'join-condition-columns-not-found-description':
    "Les colonnes sélectionnées pour la relation entre les sources n'ont pas été trouvées. Veuillez sélectionner les colonnes existantes pour la relation",
};

const datasetErrors = {
  'empty-dataset-name':
    'Le nom du dataset ne peut pas être vide. Veuillez fournir un nom pour le dataset.',

  'dataset-name-is-empty-title': 'Nom du dataset vide',
  'dataset-name-is-empty-description':
    'Le champ Nom ne peut pas être vide. Veuillez indiquer un nom pour le dataset.',

  'dataset-name-is-too-long-title': 'Nom du dataset trop long',
  'dataset-name-is-too-long-description':
    'Le nom du dataset ne peut pas dépasser la longueur maximale de 30 caractères.',

  'dataset-name-duplicated-title': 'Nom de dataset en double',
  'dataset-name-duplicated-description':
    'Ce nom de dataset est déjà utilisé. Veuillez choisir un nom unique.',

  'dataset-semantic-entity-not-found-title': 'Entité sémantique non valide pour le dataset',
  'dataset-semantic-entity-not-found-description':
    'Veuillez sélectionner une entité sémantique existante pour le dataset.',

  'dataset-duplicated-semantic-entity-title': "Conflit d'entité sémantique",
  'dataset-duplicated-semantic-entity-description':
    'Un autre dataset utilise déjà cette entité sémantique. Veuillez en choisir une différente.',

  'dataset-column-semantic-entity-not-found-title':
    'Entité sémantique non valide pour la colonne du dataset',
  'dataset-column-semantic-entity-not-found-description':
    'Veuillez sélectionner un champ sémantique existant pour cette colonne du dataset.',

  'dataset-duplicated-column-semantic-entity-title': 'Champ sémantique en double dans le dataset',
  'dataset-duplicated-column-semantic-entity-description':
    'Une autre colonne dans le dataset utilise déjà ce champ sémantique. Veuillez en choisir un différent.',

  'dataset-mismatching-source-datatype-title':
    "Mauvaise correspondance de type de données dans l'ensemble de données et la colonne source",
  'dataset-mismatching-source-datatype-description':
    "Le type de données de la colonne de la source de connexion référencée ne correspond pas à la colonne de l'ensemble de données. Vous pouvez enregistrer mais les modifications ne seront pas déployées tant que le problème n'est pas résolu.",

  'dataset-column-mismatch-semantic-field-datatype-title':
    'Incohérence des types de données entre la colonne du dataset et le champ sémantique',
  'dataset-column-mismatch-semantic-field-datatype-description':
    "Le type de données de la colonne ne correspond pas au champ sémantique. Vous pouvez enregistrer, mais les modifications ne seront pas déployées tant que le problème n'est pas résolu.",

  'dataset-deduplication-is-required-for-semantic-entity-title':
    'Déduplication requise pour cette entité',
  'dataset-deduplication-is-required-for-semantic-entity-description':
    'La déduplication est requise pour cette entité. Veuillez sélectionner les clés de déduplication.',

  'dataset-deduplication-keys-invalid-datatype-title':
    'Type de données invalide pour les clés de déduplication',
  'dataset-deduplication-keys-invalid-datatype-description':
    'Les clés de déduplication ont un type de données invalide. Veuillez sélectionner des colonnes avec un type de données valide pour la déduplication. Notez que les types JSON et Array ne sont pas acceptés comme clés de déduplication.',
};

const columnErrors = {
  'column-name-not-valid-title': 'Ajustement du Nom de la Colonne Nécessaire',
  'column-name-not-valid-description':
    "Le nom de la colonne doit être normalisé. Assurez-vous qu'il n'est pas vide, a au maximum 50 caractères, qu'il est composé uniquement de lettres minuscules, de chiffres et de soulignements. Vérifiez également qu'il ne commence ni ne se termine par un double tiret bas.",

  'column-name-duplicated-title': 'Nom de colonne en double',
  'column-name-duplicated-description':
    'Ce nom de colonne est déjà utilisé. Veuillez choisir un nom unique.',
};

const transformedColumnsErrors = {
  'column-transformation-normalizePhoneNumber-countryCode-column-not-found-error-title':
    'Colonne introuvable.',
  'column-transformation-normalizePhoneNumber-countryCode-column-not-found-error-description':
    "La colonne sélectionnée pour le code pays n'existe plus.",

  'column-transformation-normalizePhoneNumber-countryCode-column-type-mismatch-error-title':
    'Incompatibilité de type',
  'column-transformation-normalizePhoneNumber-countryCode-column-type-mismatch-error-description':
    "La colonne sélectionnée pour le code pays n'est pas de type String.",

  'column-transformation-cyclic-dependency-error-title': 'Dépendance cyclique détectée',
  'column-transformation-cyclic-dependency-error-description':
    'La colonne sélectionnée a une dépendance cyclique sur la colonne sur laquelle cette transformation est appliquée, créant une boucle infinie.',
};

const calculatedColumnsErrors = {
  'calculated-column-syntax-error-title': 'Erreur de syntaxe',
  'calculated-column-syntax-error-description':
    'Il semble y avoir une erreur de syntaxe dans votre colonne calculée. Raison : {{reason}} de {{start}} à {{end}}.',

  'calculated-columns-cyclic-dependency-title': 'Dépendance cyclique détectée',
  'calculated-columns-cyclic-dependency-description':
    'Vos colonnes calculées ont une dépendance cyclique, créant une boucle infinie : {{names}}',

  'calculated-column-syntax-not-supported-title': 'Syntaxe non prise en charge',
  'calculated-column-syntax-not-supported-description':
    "La syntaxe utilisée dans votre colonne calculée n'est pas prise en charge. Raison : {{reason}} À la position {{start}}.",

  'calculated-column-reference-not-found-title': 'Référence introuvable',
  'calculated-column-reference-not-found-description':
    'Impossible de trouver la colonne référencée "{{reference}}" à la position {{start}}.',

  'calculated-column-reference-uncompilable-title': 'Référence non compilable',
  'calculated-column-reference-uncompilable-description':
    'La référence "{{reference}}" dans votre colonne calculée n\'a pas pu être compilée, à la position {{start}}.',

  'calculated-column-type-mismatch-title': 'Incompatibilité de type',
  'calculated-column-type-mismatch-description':
    'Il y a une incompatibilité de type dans votre colonne calculée. Raison : {{reason}} À la position {{start}}.',
};

const scoreErrors = {
  'no-relationships-score-requirement-violation-title': 'No Relationship Violation',
  'no-relationships-score-requirement-violation-description':
    "Il semble que la relation requise pour le score spécifié n'existe pas. Veuillez vous assurer que vous avez définie la relation entre les entités {{parent}} et {{child}}.",

  'no-dataset-score-requirement-violation-title': 'No Dataset Violation',
  'no-dataset-score-requirement-violation-description':
    "Cette erreur se produit lorsque le dataset requis pour le score spécifié n'existe pas. Pour résoudre cela, assurez-vous d'avoir défini le dataset pour l'entité sémantique {{entity}} dans vos ressources.",

  'no-dataset-column-score-requirement-violation-title': 'No Dataset Column Violation',
  'no-dataset-column-score-requirement-violation-description':
    "Il semble que la colonne de dataset requise pour le score spécifié n'existe pas. Pour résoudre ce problème, assurez-vous de définir la colonne pour le champ sémantique {{field}} du dataset pour l'entité sémantique {{entity}}.",
};

const aggregateErrors = {
  'aggregate-current-dataset-does-not-exist': `Le jeu de données actuel n'existe pas. Veuillez sélectionner un jeu de données valide.`,
  'aggregate-invalid-dataset-path-title': 'Le chemin du jeu de données est invalide.',
  'aggregate-invalid-dataset-path':
    'Le chemin du jeu de données est invalide. Les raisons possibles incluent une colonne cible inexistante dans le jeu de données, des filtres ne correspondant pas aux valeurs de la colonne cible, ou un chemin de jeu de données incorrect/inaccessible.',
  'aggregate-duplicate-column-id':
    "L'identifiant de colonne en double a été spécifié. Assurez-vous que chaque identifiant de colonne est unique.",
  'aggregate-duplicate-column-name':
    'Nom de colonne en double. Veuillez vous assurer que chaque nom de colonne est unique.',
  'aggregate-filter-invalid-dataset-path':
    'Le chemin de jeu de données spécifié est invalide. Veuillez fournir un chemin de jeu de données valide.',
  'aggregated-column-filter-column-not-found':
    "La colonne spécifiée dans le filtre n'existe pas. Veuillez sélectionner une colonne existante.",
  'aggregated-column-filter-column-type-not-supported':
    "Le type de colonne spécifié dans le filtre n'est pas pris en charge. Veuillez choisir une colonne avec un type pris en charge.",
  'count-aggregate-column-not-found':
    "La colonne spécifiée pour l'agrégation de comptage n'existe pas. Veuillez sélectionner une colonne existante.",
  'average-aggregate-column-not-found': "Colonne d'agrégation moyenne non trouvée",
  'average-aggregate-column-type-not-supported':
    "Type non pris en charge pour la colonne d'agrégation moyenne",
  'sum-aggregate-column-not-found': "Colonne d'agrégation de somme non trouvée",
  'sum-aggregate-column-type-not-supported':
    "Type non pris en charge pour la colonne d'agrégation de somme",
  'first-value-aggregate-selected-column-not-found':
    'Colonne sélectionnée non trouvée pour la première valeur agrégée',
  'first-n-values-aggregate-selected-column-not-found':
    'Colonne sélectionnée non trouvée pour les premières valeurs N agrégées',
  'first-n-values-aggregate-column-order-by-column-not-found':
    'Colonne de tri non trouvée pour les premières valeurs N agrégées',
  'first-n-values-aggregate-column-order-by-column-type-not-supported':
    'Type non pris en charge pour la colonne de tri dans les premières valeurs N agrégées',
  'first-n-values-aggregate-invalid-number-of-values':
    'Nombre de valeurs spécifié invalide pour les premières valeurs N agrégées',
  'first-value-aggregate-column-order-by-column-column-not-found':
    'Colonne de tri non trouvée pour la première valeur agrégée',
  'first-value-aggregate-column-order-by-column-type-not-supported':
    'Type non pris en charge pour la colonne de tri dans la première valeur agrégée',
  'last-n-values-aggregate-selected-column-not-found':
    'Colonne sélectionnée non trouvée pour les dernières valeurs N agrégées',
  'last-n-values-aggregate-column-order-by-column-not-found':
    'Colonne de tri non trouvée pour les dernières valeurs N agrégées',
  'last-n-values-aggregate-column-order-by-column-type-not-supported':
    'Type non pris en charge pour la colonne de tri dans les dernières valeurs N agrégées',
  'last-n-values-aggregate-invalid-number-of-values':
    'Nombre de valeurs spécifié invalide pour les dernières valeurs N agrégées',
  'last-value-aggregate-selected-column-not-found':
    'Colonne sélectionnée non trouvée pour la dernière valeur agrégée',
  'last-value-aggregate-column-order-by-column-column-not-found':
    'Colonne de tri non trouvée pour la dernière valeur agrégée',
  'last-value-aggregate-column-order-by-column-type-not-supported':
    'Type non pris en charge pour la colonne de tri dans la dernière valeur agrégée',
  'most-frequent-value-aggregate-selected-column-not-found': `Colonne sélectionnée introuvable pour l'agrégat de valeur la plus fréquente`,
  'nth-value-aggregate-selected-column-not-found':
    'Colonne sélectionnée non trouvée pour la N-ième valeur agrégée',
  'nth-value-aggregate-column-order-by-column-not-found':
    'Colonne de tri non trouvée pour la N-ième valeur agrégée',
  'nth-value-aggregate-column-order-by-column-type-not-supported':
    'Type non pris en charge pour la colonne de tri dans la N-ième valeur agrégée',
  'nth-value-aggregate-invalid-rank': 'Rang spécifié invalide pour la N-ième valeur agrégée',
};

const syncErrors = {
  'sync-name-empty-title': 'Nom de synchronisation vide',
  'sync-name-empty-description':
    "Le champ du nom ne peut pas être vide. Veuillez fournir un nom pour l'opération de synchronisation.",

  'sync-name-duplicated-title': 'Nom de synchronisation en double',
  'sync-name-duplicated-description':
    "Ce nom de synchronisation est déjà utilisé. Veuillez choisir un nom unique pour l'opération de synchronisation.",

  'sync-name-is-too-long-title': 'Nom de synchronisation trop long',
  'sync-name-is-too-long-description':
    'Le nom de synchronisation ne peut pas dépasser une longueur maximale de 30 caractères.',

  'sync-connection-not-found-title': 'Connexion invalide',
  'sync-connection-not-found-description':
    "Veuillez sélectionner une connexion existante pour l'opération de synchronisation.",

  'sync-connection-is-immutable-title': 'La connexion est immuable',
  'sync-connection-is-immutable-description':
    'La connexion de destination de la synchronisation ne peut pas être modifiée.',

  'sync-destination-type-immutable-title': 'Type de destination immuable',
  'sync-destination-type-immutable-description': 'Le type de destination ne peut pas être modifié.',

  'sync-destination-settings-type-immutable-title': 'Type de paramètres de destination immuable',
  'sync-destination-settings-type-immutable-description':
    'Le type de paramètres de la destination ne peut pas être modifié.',

  'sync-destination-settings-type-mismatch-connection-type-title':
    'Incompatibilité du type de paramètres de destination',
  'sync-destination-settings-type-mismatch-connection-type-description':
    'Le type de paramètres de la destination ne correspond pas au type de connexion.',

  'sync-api-destination-type-mismatch-connector-kind-title':
    "Incompatibilité du type de destination de l'API",
  'sync-api-destination-type-mismatch-connector-kind-description':
    "Le type de destination de l'API ne correspond pas au type de connecteur.",

  'sync-api-entity-type-not-found-title': "Type d'entité API non valide",
  'sync-api-entity-type-not-found-description':
    "Veuillez sélectionner un type d'entité API valide.",

  'sync-api-entity-type-is-not-destination-title': "Le type d'entité API n'est pas une destination",
  'sync-api-entity-type-is-not-destination-description':
    "Le type d'entité API sélectionné ne peut pas être utilisé comme destination.",

  'sync-api-entity-settings-not-valid-title': "Paramètres d'entité API non valides",
  'sync-api-entity-settings-not-valid-description':
    "Les paramètres d'entité API ne sont pas valides selon les exigences de l'API.",

  'sync-api-entity-destination-settings-not-valid-title':
    "Paramètres de destination d'entité API non valides",
  'sync-api-entity-destination-settings-not-valid-description':
    "Les paramètres de destination de l'entité API ne sont pas valides selon les exigences de l'API.",

  'sync-api-entity-settings-immutable-title': "Paramètres d'entité API immuables",
  'sync-api-entity-settings-immutable-description':
    "Les paramètres de l'entité API ne peuvent pas être modifiés ou mis à jour.",

  'sync-api-key-field-is-immutable-title': 'Champ de clé API immuable',
  'sync-api-key-field-is-immutable-description':
    'Le champ de clé API ne peut pas être modifié ou mis à jour.',

  'sync-api-key-field-is-mandatory-for-entity-title':
    "Le champ de clé API est obligatoire pour l'entité",
  'sync-api-key-field-is-mandatory-for-entity-description':
    "Le champ de clé API est obligatoire pour ce type d'entité. Veuillez fournir un champ de clé API valide.",

  'sync-api-entity-type-immutable-title': "Type d'entité API immuable",
  'sync-api-entity-type-immutable-description':
    "Le type d'entité API sélectionné ne peut pas être modifié ou mis à jour.",

  'sync-delete-strategy-not-supported-by-entity-title':
    "Stratégie de suppression non prise en charge par l'entité",
  'sync-delete-strategy-not-supported-by-entity-description':
    "La stratégie de suppression sélectionnée n'est pas prise en charge par le type d'entité.",

  'sync-destination-fields-duplicated-title': 'Champs de destination en double',
  'sync-destination-fields-duplicated-description':
    'Ce nom de champ de destination est déjà mappé. Veuillez vous assurer que chaque champ est mappé une seule fois.',

  'sync-dataset-immutable-title': 'Jeu de données immuable',
  'sync-dataset-immutable-description':
    'Le jeu de données sélectionné ne peut pas être modifié ou mis à jour.',

  'sync-segment-condition-not-valid-title': 'Condition de segment non valide',
  'sync-segment-condition-not-valid-description':
    "La condition du segment fourni n'est pas valide.",
};

const status = {
  'deployment-pending': 'Le déploiement est en attente',
  'deployment-in-progress': 'Le déploiement est en cours',
  'deployment-failed': 'Le déploiement a échoué',

  'execution-enqueued': "L'exécution a été mise en file d'attente",
  'execution-in-progres': "L'exécution est en cours",
  'execution-failed': "L'exécution a échoué",
  'execution-completed': "L'exécution est terminée",
};

const resources = {
  ...resourceTypes,
  ...resourceErrors,
  ...connectionSourceErrors,
  ...sourceRelationshipsErrors,
  ...datasetErrors,
  ...columnErrors,
  ...transformedColumnsErrors,
  ...calculatedColumnsErrors,
  ...scoreErrors,
  ...status,
  ...syncErrors,
  ...aggregateErrors,
};

export default resources;
