import { ConditionType, FilterOperatorTypes } from '@features/filter/types';
import { columnTypesEnum } from '@features/objects/types';
import {
  RecordsLeavingSegmentStrategy,
  SyncSegmentation,
} from '@features/syncs/types/SyncSegmentation';
import {
  SegmentColumnOperator,
  SegmentColumnOperatorType,
} from '@features/syncs/types/backTypes/SegmentColumnOperators';
import {
  SegmentColumnCondition,
  SegmentConditionGroupLevel2,
  SyncSegmentationSpecification,
} from '@features/syncs/types/backTypes/SyncSegmentationSpecification';
import { caseNever } from '@utils/case-never';
export const mappingSegmentationToBack = (
  syncSegmentation?: SyncSegmentation
): SyncSegmentationSpecification | undefined => {
  return syncSegmentation?.dataFilter?.groupFilters
    ? {
        condition: {
          items: (syncSegmentation?.dataFilter?.groupFilters || []).reduce(
            (result: SegmentConditionGroupLevel2[], elem) => {
              const conditions: SegmentColumnCondition[] = elem.conditions.map((condition) => ({
                columnId: condition.fieldId,
                operator: mappingOperatorsToBack(condition),
              }));
              result.push({ items: conditions });
              return result;
            },
            []
          ),
        },
        recordsLeavingStrategy:
          syncSegmentation?.recordsLeavingStrategy ?? RecordsLeavingSegmentStrategy.ignore,
      }
    : undefined;
};
const mappingOperatorsToBack = (condition: ConditionType): SegmentColumnOperator => {
  if (!condition.operator) {
    throw new Error('Undefined condition operator details');
  }
  switch (condition.operator.type) {
    case FilterOperatorTypes.is:
      let isValue = condition.operator.value;
      if (condition.fieldType === columnTypesEnum.BOOLEAN) {
        isValue = parseStringToBooleanValue(isValue);
      }
      return { type: SegmentColumnOperatorType.is, value: isValue };
    case FilterOperatorTypes.isNot:
      let isNotValue = condition.operator.value;
      if (condition.fieldType === columnTypesEnum.BOOLEAN) {
        isNotValue = parseStringToBooleanValue(isNotValue);
      }
      return { type: SegmentColumnOperatorType.isNot, value: isNotValue };
    case FilterOperatorTypes.isEmpty:
      return { type: SegmentColumnOperatorType.isEmpty };
    case FilterOperatorTypes.isNotEmpty:
      return { type: SegmentColumnOperatorType.isNotEmpty };
    case FilterOperatorTypes.regex:
      return {
        type: SegmentColumnOperatorType.matchesRegExp,
        expression: condition.operator.value,
        isCaseSensitive: condition.operator.isCaseSensitive || false,
      };
    case FilterOperatorTypes.contains:
      return { type: SegmentColumnOperatorType.contains, value: condition.operator.value };

    case FilterOperatorTypes.isInListOfSystemIds:
    case FilterOperatorTypes.isInListOfStrings:
    case FilterOperatorTypes.isInListOfDates:
      return {
        type: SegmentColumnOperatorType.isIn,
        values: condition.operator.value,
      };
    case FilterOperatorTypes.isInListOfFloats:
    case FilterOperatorTypes.isInListOfIntegers:
      return {
        type: SegmentColumnOperatorType.isIn,
        values: parseStringValuesToNumbers(condition.operator.value),
      };
    case FilterOperatorTypes.isInListOfBooleans:
      return {
        type: SegmentColumnOperatorType.isIn,
        values: condition.operator.value.map((b: string) => parseStringToBooleanValue(b)),
      };

    case FilterOperatorTypes.isNotInListOfSystemIds:
    case FilterOperatorTypes.isNotInListOfStrings:
    case FilterOperatorTypes.isNotInListOfDates:
      return {
        type: SegmentColumnOperatorType.isNotIn,
        values: condition.operator.value,
      };
    case FilterOperatorTypes.isNotInListOfFloats:
    case FilterOperatorTypes.isNotInListOfIntegers:
      return {
        type: SegmentColumnOperatorType.isNotIn,
        values: parseStringValuesToNumbers(condition.operator.value),
      };
    case FilterOperatorTypes.isNotInListOfBooleans:
      return {
        type: SegmentColumnOperatorType.isNotIn,
        values: condition.operator.value.map((b: string) => parseStringToBooleanValue(b)),
      };

    default:
      return caseNever(condition.operator.type);
  }
};
const parseStringValuesToNumbers = (values: string[]) => {
  return values.map((n: string) => +n);
};
const parseStringToBooleanValue = (value: string) => {
  return value === 'true' ? true : false;
};
/******
 condition: {
      items: [
        {
          items: [
            {
              columnId: 'column1',
              operator: { type: SegmentColumnOperatorType.is, value: 'test' },
            },
            {
              columnId: 'column2',
              operator: { type: SegmentColumnOperatorType.is, value: 'aaa' },
            },
          ],
        },
        {
          items: [
            {
              columnId: 'column3',
              operator: { type: SegmentColumnOperatorType.isEmpty },
            },
          ],
        },
      ],
    }
 *
 *
 */
const dataFilter = {
  key: '20d16ad6-c68f-4e15-aae4-1179ab419d71',
  combinationOperator: 'or',
  groupFilters: [
    {
      combinationOperator: 'and',
      conditions: [
        {
          id: '1b5ac1e2-0d2c-4a56-8598-1872a2ca6454',
          fieldId: '1319979c-be31-4724-834f-7fe288d03d39',
          fieldName: '__modified_at__',
          fieldType: 'timestamp',
          operator: {
            type: 'is in list of dates',
            value: [
              '2023-05-09T23:00:00.000Z',
              '2023-05-10T23:00:00.000Z',
              '2023-05-11T23:00:00.000Z',
            ],
          },
        },
      ],
      id: 'd67faeec-25f5-4522-aa83-92100632b1ae',
      type: 'property',
    },
    {
      combinationOperator: 'and',
      conditions: [
        {
          id: '4546df8f-7b6a-4a4a-9766-a57e5945b7b9',
          fieldId: '974c9597-9978-4be5-8c52-5d21e4c97872',
          fieldName: '__created_at__',
          fieldType: 'timestamp',
          operator: {
            type: 'is',
            value: '2023-05-23T23:00:00.000Z',
          },
        },
      ],
      id: '4314f6b8-889b-4ad0-9ec3-16533ab3bfde',
      type: 'property',
    },
    {
      combinationOperator: 'and',
      conditions: [
        {
          id: '5ff693fd-d8fa-4580-88ed-e5de58a77392',
          fieldId: 'aa2b27dd-9109-44aa-a9ca-c395f82b7d0c',
          fieldName: 'json',
          fieldType: 'json',
          operator: {
            type: 'is empty',
          },
        },
      ],
      id: 'fc99dc59-35af-4ed4-8038-cc94c699b11c',
      type: 'property',
    },
    {
      combinationOperator: 'and',
      conditions: [
        {
          id: '321cd38b-f0ae-45e5-a60d-15c6ad6bb0e7',
          fieldId: '683834fc-11ae-48f8-be36-c5c666dcefef',
          fieldName: 'string',
          fieldType: 'string',
          operator: {
            type: 'regex',
            value: 'sdf',
          },
        },
      ],
      id: '21c58d2d-84c3-47ed-a2e6-b3682b5f292f',
      type: 'property',
    },
    {
      combinationOperator: 'and',
      conditions: [
        {
          id: 'bcb5b826-86cf-498b-a9e1-8f862efba1bc',
          fieldId: '683834fc-11ae-48f8-be36-c5c666dcefef',
          fieldName: 'string',
          fieldType: 'string',
          operator: {
            type: 'is in list of strings',
            value: ['sqd', 'qsd'],
          },
        },
      ],
      id: '6cbd6b9a-b9a9-4dcb-b03d-c975712c459d',
      type: 'property',
    },
    {
      combinationOperator: 'and',
      conditions: [
        {
          id: 'b54b9811-9c2c-44a6-a1d7-785f61317fc2',
          fieldId: '683834fc-11ae-48f8-be36-c5c666dcefef',
          fieldName: 'string',
          fieldType: 'string',
          operator: {
            type: 'is',
            value: 'sqd',
          },
        },
      ],
      id: 'd9047eef-5b59-43cd-9808-b0dcc6c5c3db',
      type: 'property',
    },
  ],
};
