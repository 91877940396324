const monitoring = {
  executionsMonitoringStatus: 'Statut',
  executionsMonitoringCreatedAt: 'Date de création',
  executionsMonitoringResources: 'Ressources',
  executionsMonitoringExecutionId: "ID d'exécution",
  executionsMonitoringCreationDate: 'Date de création',
  executionsMonitoringEndDate: 'Date de fin',
  completionRate: 'Taux de complétion',
  batchSize: 'Taille du lot',

  executionDetails: "Détails de l'exécution",
  listOfActions: 'Liste des actions',

  actionResource: 'Ressource',
  actionStartDate: 'Date de début',
  actionEndDate: 'Date de fin',
  actionStatus: 'Statut',
  actionType: "Type d'action",
  actionOptions: 'Options',

  executionStatusNothingToDo: 'Aucune action',
  executionStatusPending: 'En attente',
  executionStatusInProgress: 'En cours',
  executionStatusCompleted: 'Terminé',
  executionStatusFailed: 'Échoué',

  actionStatusPending: 'En attente',
  actionStatusSkipped: 'Ignoré',
  actionStatusInProgress: 'En cours',
  actionStatusFailed: 'Échoué',
  actionStatusCompleted: 'Terminé',

  actionCopyResourceId: "Copier l'ID de la resource",
  actionSeeLogs: 'Voir les logs',
  actionLogsTitle: 'Logs',

  sourceActionResetSourceData: 'Resource source data',
  sourceActionFetchNewSourceData: 'Fetch new source data',
  sourceActionComputeSource: 'Compute source',
  sourceActionPatchSourceData: 'Patch source data',
  sourceActionComputeSourceCheapMetrics: 'Compute source cheap metrics',
  sourceActionComputeSourceColumnExpensiveMetrics: 'Compute source expensive metrics',

  datasetActionInitializeDataset: 'Initialize dataset',
  datasetActionIntegrateSourceDataToDataset: 'Integrate source data to dataset',
  datasetActionComputeDataset: 'Compute dataset',
  datasetActionComputeDatasetCheapMetrics: 'Compute dataset cheap metrics',
  datasetActionComputeDatasetColumnExpensiveMetrics: 'Compute dataset expensive metrics',

  syncActionDeliverAllSyncRecords: 'Deliver all sync records',
  syncActionDeliverSyncRecordsIncrement: 'Deliver sync records increment',
  syncActionDeliverSpecificSyncRecords: 'Deliver specific sync records',

  analyticsSyncActionDeliverDatasetRecordsToAnalytics: 'Deliver dataset records to analytics',

  garbageCollectionActionCleanUpDataWarehouseGarbage: 'Clean up datawarehouse garbage',
  garbageCollectionActionCleanUpExecutionGarbage: 'Clean up execution garbage',
  garbageCollectionActionResetPreparedSourceData: 'Reset prepared source data',
  garbageCollectionActionCleanUpExternalDataShapeSnapshotsGarbage:
    'Clean up external data shape snapshots garbage',

  resources: 'Resources',
};

export default monitoring;
