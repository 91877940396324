import { gql } from '@apollo/client';

const CREATE_WEBHOOK = gql`
  mutation ($command: CreateWebhookCommand!) {
    createWebhook(command: $command) {
      __typename
      ... on Webhook {
        id
        accountId
        dataWarehouseId
        name
        description
        methodsWhitelist
        ipAddressesWhitelist {
          ... on IpAddress {
            type
            value
          }
          ... on IpAddressRange {
            type
            from
            to
          }
        }
        headersWhitelist {
          name
          values
        }
        deployment {
          id
          version
          status
        }
      }
    }
  }
`;

const UPDATE_WEBHOOK = gql`
  mutation ($command: UpdateWebhookCommand!) {
    updateWebhook(command: $command) {
      __typename
      ... on WebhookNotFound {
        id
      }
      ... on WebhookVersionOutdated {
        id
      }
      ... on Webhook {
        id
        version
        createdAt
        updatedAt
        accountId
        dataWarehouseId
        name
        description
        ipAddressesWhitelist {
          ... on IpAddress {
            type
            value
          }
          ... on IpAddressRange {
            type
            from
            to
          }
        }
        methodsWhitelist
        headersWhitelist {
          name
          values
        }
      }
    }
  }
`;
const DELETE_WEBHOOK = gql`
  mutation ($command: DeleteWebhookCommand!) {
    deleteWebhook(command: $command) {
      __typename
    }
  }
`;
export { CREATE_WEBHOOK, UPDATE_WEBHOOK, DELETE_WEBHOOK };
