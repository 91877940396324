import { AudienceData } from '@features/audiences/types/DeployedAudience';
import { BackAudienceData } from '../../audienceBackTypes/deployedAudience';
import { mappingAudienceDataShape } from './mappingAudienceDataShape';
import { PagingSettings } from '@contracts/PagingSettings';

export const toFrontAudienceData = (
  { accountId, count, data, id, shape }: BackAudienceData,
  { page, pageSize }: PagingSettings,
  search?: string | undefined
): AudienceData => {
  return {
    id,
    accountId,
    count,
    data,
    paginationDetails: { page, pageSize, search },
    shape: mappingAudienceDataShape(shape),
  };
};
