import '@styles/core/index.less';
import '@styles/layouts/auth.less';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from './configs/i18n';
import store, { history } from '@redux/store';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/fr';
import { AppContextProvider } from '@providers/AppContextProvider';
import { RedirectionContextProvider } from '@providers/RedirectionProvider';
import { ProfileContextProvider } from '@providers/ProfileContextProvider';
import * as dotenv from 'dotenv';
import { Auth0ContainerProvider } from '@providers/Auth0Provider';
import InitialIndexCalls from './InitialIndexCalls';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from './components/errorBoundaryFallback';
import { ApmProvider } from '@providers/ApmProvider';
import { ConnectApmToAuth0 } from '@providers/ConnectApmToAuth0';
import PlatformRoutesV2 from '@routes/PlatformRoutesV2';
// Env config
dotenv.config();

const PlatformWithProviders = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <ApmProvider>
        <Provider store={store}>
          <Router history={history}>
            <I18nextProvider i18n={i18n}>
              <AppContextProvider>
                <InitialIndexCalls>
                  <Auth0ContainerProvider>
                    <ConnectApmToAuth0>
                      <RedirectionContextProvider>
                        <ProfileContextProvider>
                          <PlatformRoutesV2 />
                        </ProfileContextProvider>
                      </RedirectionContextProvider>
                    </ConnectApmToAuth0>
                  </Auth0ContainerProvider>
                </InitialIndexCalls>
              </AppContextProvider>
            </I18nextProvider>
          </Router>
        </Provider>
      </ApmProvider>
    </ErrorBoundary>
  );
};

const container = document.getElementById('root');
if (!container) throw new Error('Root container missing in index.html');

const root = ReactDOM.createRoot(container);
root.render(<PlatformWithProviders />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
