import { DeployedAudienceSource } from '@features/audiences/types/DeployedAudience';
import { BackDeployedAudienceSource } from '../../audienceBackTypes/deployedAudience';
import { DataSourceType } from '@features/audiences/ducks/api/audienceBackTypes/source';
import { sourceTypesEnum } from '@features/audiences/types';

export const mappingDeployedAudienceSources = (
  backSources: BackDeployedAudienceSource[]
): DeployedAudienceSource[] => {
  return backSources.map((source): DeployedAudienceSource | undefined => {
    switch (source.type) {
      case DataSourceType.audience:
        return {
          type: sourceTypesEnum.AUDIENCE,
          audienceId: source.audienceId,
        };
      case DataSourceType.connection:
        // return {
        //   type: sourceTypesEnum.CONNECTION,
        //   id: source.id,
        //   connectionId: source.connectionId
        // };

        return undefined
      default:
        return undefined;
    }
  }).filter((source): source is DeployedAudienceSource => Boolean(source))
};
