import {
  PreparationStep,
  PreparationStepType,
} from '@features/audiences/ducks/api/audienceBackTypes/preparationStep';
import {
  PreparationActionType,
  PreparationStep as FrontPreparationStep,
  PreparationStepCategoriesEnum,
} from '@features/audiences/types';
import { generateId } from '@utils/helpers';
import { mappingColumnTypePropertyToFront } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mappingColumnTypePropertyToFront';
import { mappingColumnTypePropertyToBack } from '@features/audiences/ducks/api/mappingAudienceTypes/toBackType/mappingAudienceSpecificationProperty/mappingColumnTypePropertyToBack';

const mappingPreparationStepsToFront = (
  backPreparations: PreparationStep[]
): FrontPreparationStep[] => {
  return backPreparations.flatMap((backPreparation): FrontPreparationStep[] => {
    switch (backPreparation.type) {
      case PreparationStepType.generate_current_timestamp_columns: {
        return [
          {
            key: generateId(),
            title: 'Generate current timestamp Columns',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.generate_current_timestamp_columns,
              columns: backPreparation.columns.map((col) => {
                return {
                  targetColumnId: col.targetColumnId,
                  targetColumnName: col.targetColumnName,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.filter_by_flags: {
        return [
          {
            key: generateId(),
            title: 'Filter by flags',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.filter_by_flags,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  flagColumnId: col.flagColumnId,
                  expectedValue: col.expectedValue,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.format_as_string: {
        return [
          {
            key: generateId(),
            title: 'Change columns type to string',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.format_as_string,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  originalColumnId: col,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.format_as_boolean: {
        return [
          {
            key: generateId(),
            title: 'Change columns type to boolean',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.format_as_boolean,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  originalColumnId: col,
                };
              }),
              onError: backPreparation.onError,
            },
          },
        ];
      }
      case PreparationStepType.combine_values: {
        return [
          {
            key: generateId(),
            title: 'Combine values',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.combine_values,
              columns: backPreparation.columns.map((columns) => {
                return {
                  key: generateId(),
                  expectedType: mappingColumnTypePropertyToFront(columns.expectedType),
                  originalColumnIds: columns.originalColumnIds,
                  targetColumnId: columns.targetColumnId,
                  targetColumnName: columns.targetColumnName,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.concat_values_as_string: {
        return [
          {
            key: generateId(),
            title: 'Concat columns',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.concat_values_as_string,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  targetColumnId: col.targetColumnId,
                  targetColumnName: col.targetColumnName,
                  delimiter: col.delimiter,
                  originalColumnIds: col.originalColumnIds,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.hash_values: {
        return [
          {
            key: generateId(),
            title: 'Hash columns',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              lowercase: backPreparation.lowercase,
              algorithm: backPreparation.algorithm,
              type: PreparationActionType.hash_values,
              columns: backPreparation.columns.map((column) => {
                return {
                  ...column,
                  key: generateId(),
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.custom_sql: {
        return [
          {
            key: generateId(),
            title: 'Custom SQL',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.custom_sql,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  targetColumnId: col.targetColumnId,
                  targetColumnName: col.targetColumnName,
                  sqlExpression: col.sqlExpression,
                  expectedType: mappingColumnTypePropertyToFront(col.expectedType),
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.custom_function: {
        return [
          {
            key: generateId(),
            title: 'Custom function',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.custom_function,
              columns: backPreparation.columns,
              function: {
                name: backPreparation.function.name,
                expectedType: mappingColumnTypePropertyToFront(
                  backPreparation.function.expectedType
                ),
              },
            },
          },
        ];
      }
      case PreparationStepType.conditional_statement_sql: {
        return [
          {
            key: generateId(),
            title: 'Conditional statement sql',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.conditional_statement_sql,
              columns: backPreparation.columns.map((col) => {
                return {
                  targetColumnId: col.targetColumnId,
                  conditionSqlExpression: col.conditionSqlExpression,
                  onTrueSqlExpression: col.onTrueSqlExpression,
                  onFalseSqlExpression: col.onFalseSqlExpression,
                  expectedType: mappingColumnTypePropertyToFront(col.expectedType),
                  targetColumnName: col.targetColumnName,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.copy_columns: {
        return [
          {
            key: generateId(),
            title: 'Copy columns',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.copy_columns,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  originalColumnId: col.originalColumnId,
                  targetColumnId: col.targetColumnId,
                  targetColumnName: col.targetColumnName,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.keep_columns: {
        return [
          {
            key: generateId(),
            title: 'Keep only some columns',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.keep_columns,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  originalColumnId: col,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.delete_columns: {
        return [
          {
            key: generateId(),
            title: 'Delete columns',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.delete_columns,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  originalColumnId: col,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.rename_columns: {
        return [
          {
            key: generateId(),
            title: 'Rename columns',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.rename_columns,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  originalColumnId: col.originalColumnId,
                  targetColumnName: col.targetColumnName,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.change_strings_cases: {
        return [
          {
            key: generateId(),
            title: 'Change column casing',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.change_strings_cases,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  columnId: col.columnId,
                  stringCase: col.stringCase,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.find_replace_in_strings: {
        return [
          {
            key: generateId(),
            title: 'Find and replace',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.find_replace_in_strings,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  columnId: col.columnId,
                  isCaseSensitive: col.isCaseSensitive,
                  find: col.find,
                  replace: col.replace,
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.replace_string_column_values_with_null: {
        return [
          {
            key: generateId(),
            title: 'Replace string column values with null',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.replace_string_column_values_with_null,
              columns: backPreparation.columns,
            },
          },
        ];
      }
      case PreparationStepType.split_string_column_values_to_array_columns: {
        const delimiter: string = backPreparation.columns.length
          ? backPreparation.columns[0].delimiter
          : '';
        return [
          {
            key: generateId(),
            title: 'Split string column values',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.split_string_column_values_to_array_columns,
              columns: {
                delimiter,
                splitStringColumnValuesList: backPreparation.columns.map((c) => {
                  return {
                    key: generateId(),
                    originalColumnId: c.originalColumnId,
                    targetColumnId: c.targetColumnId,
                    targetColumnName: c.targetColumnName,
                  };
                }),
              },
            },
          },
        ];
      }
      case PreparationStepType.test_string_column_values_with_regex: {
        return [
          {
            key: generateId(),
            title: 'Test if column contains (Regex match)',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.test_string_column_values_with_regex,
              columns: backPreparation.columns.map((col) => {
                return {
                  ...col,
                  key: generateId(),
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.trim_string_column_values: {
        return [
          {
            key: generateId(),
            title: 'Trim string',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.trim_string_column_values,
              columns: backPreparation.columns,
            },
          },
        ];
      }
      case PreparationStepType.round_numbers: {
        return [
          {
            key: generateId(),
            title: 'Round numbers',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.round_numbers,
              columns: backPreparation.columns.map((colConfig) => ({
                key: generateId(),
                columnId: colConfig.columnId,
                decimals: colConfig.decimals,
              })),
            },
          },
        ];
      }
      case PreparationStepType.format_timestamps_as_strings: {
        return [
          {
            key: generateId(),
            title: 'Format timestamps as strings ',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.format_timestamps_as_strings,
              columns: backPreparation.columns.map((colConfig) => ({
                key: generateId(),
                originalColumnId: colConfig.originalColumnId,
                format: colConfig.format,
                targetColumnId: colConfig.targetColumnId,
                targetColumnName: colConfig.targetColumnName,
              })),
            },
          },
        ];
      }
      case PreparationStepType.format_phone_numbers: {
        return [
          {
            key: generateId(),
            title: 'Format phone numbers',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.format_phone_numbers,
              prefix: backPreparation.prefix,
              output: backPreparation.output,
              columns: backPreparation.columns,
            },
          },
        ];
      }
      case PreparationStepType.extract_json_by_path: {
        return [
          {
            key: generateId(),
            title: 'Extract path from JSON column',
            category: PreparationStepCategoriesEnum.DATA_PREPARATION,
            configuration: {
              type: PreparationActionType.extract_json_by_path,
              columns: backPreparation.columns.map((col) => {
                return {
                  ...col,
                  outputType: col.outputType
                    ? mappingColumnTypePropertyToFront(col.outputType)
                    : undefined,
                  key: generateId(),
                };
              }),
            },
          },
        ];
      }
      case PreparationStepType.check_emails_validity: {
        return [
          {
            key: generateId(),
            title: 'Check email validity\n',
            category: PreparationStepCategoriesEnum.BUSINESS_PREPARATION,
            configuration: {
              type: PreparationActionType.check_emails_validity,
              columns: backPreparation.columns.map((col) => {
                return {
                  key: generateId(),
                  originalColumnId: col.originalColumnId,
                  targetColumnId: col.targetColumnId,
                  targetColumnName: col.targetColumnName,
                };
              }),
            },
          },
        ];
      }
    }
  });
};

export default mappingPreparationStepsToFront;
