import { createSlice } from '@reduxjs/toolkit';
import type { ConnectorsReducerActions } from './types';
import { Connector, ConnectorCategoriesEnum } from '@features/connectors/types';

export const INITIAL_CONNECTORS_STATE: ConnectorsState = {
  loaded: false,
  isFetching: false,
  connectors: [],
  connectorsCategories: [],
};
export interface ConnectorsState {
  loaded: boolean;
  isFetching?: boolean;
  shouldBeClosed?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  page?: number;
  limit?: number;
  total?: number;
  connectors: Connector[];
  connectorsCategories: {
    key: ConnectorCategoriesEnum;
    name: string;
  }[];
}
const connectorsSlice = createSlice({
  name: 'connectors',
  initialState: INITIAL_CONNECTORS_STATE,

  reducers: {
    getConnectors: (state) => {
      state.isFetching = true;
      state.connectors = [];
    },
    getConnectorsSuccess: (state, { payload }: ConnectorsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.connectors = payload.connectors;
      state.connectorsCategories = payload.connectorsCategories;
    },
    getConnectorsFailed: (state, { payload }: ConnectorsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
  },
});
export default connectorsSlice;
