import { Connector } from '@features/connectors/types';
import { Sync, SyncsTableRecord } from '@features/syncs/types';

export const mappingSyncToTableRecordType = (
  sync: Sync,
  audience: { id: string; name: string } | undefined,
  connection: { id: string; name: string; connector: Connector } | undefined
): SyncsTableRecord => {
  return {
    key: sync.key,
    name: sync.name,
    audience: audience?.name ?? '',
    destination: connection?.name ?? '',
    destinationLogo: connection?.connector.logo ?? '',
    destinationTitle: connection?.connector.title ?? '',
    status: sync.active,
    latestExecution: sync.latestExecution,
    deployment: sync.deployment,
    // schedule: typeof sync.schedule === 'string' ? sync.schedule : '',
  };
};
