// Importing type
import * as apiTypes from './types';
import { connectors, connectorsCategoriesData } from '@data/connectors';

const getConnectors: apiTypes.APIGetConnectors = async () => {
  let connectorsCategories;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  connectorsCategories = connectorsCategoriesData;

  if (!connectors) {
    error = true;
    errorDetails = 'No sources found.';
  }
  return {
    connectors,
    connectorsCategories,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getConnectors };
