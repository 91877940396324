import {
  ActiveSourceConfigurationPayload,
  InitAudiencesArchitectureSaga,
  InitAudienceSourceCreationSaga,
} from '@features/audiences/ducks/types';
import { all, put } from 'redux-saga/effects';
import audiencesSlice, {
  initialActiveSourceConfiguration,
} from '@features/audiences/ducks/audiencesSlice';
import { getConnectionsSaga } from '@features/connections/ducks/sagas';
import { getAudiencesSaga } from '@features/audiences/ducks/sagas/allSagas';
import { getSyncsSaga } from '@features/syncs/ducks/sagas/sagas';

export function* initAudienceSourceCreationSaga({}: InitAudienceSourceCreationSaga) {
  yield all([
    getConnectionsSaga(),
    put<ActiveSourceConfigurationPayload>({
      type: audiencesSlice.actions.updateActiveSourceConfiguration.type,
      payload: { sourceConfiguration: initialActiveSourceConfiguration },
    }),
    put({
      type: audiencesSlice.actions.clearSourceEntities.type,
    }),
    put({
      type: audiencesSlice.actions.updateRequestDraftSourceStatus.type,
      payload: {},
    }),
  ]);
}
export function* initAudiencesArchitectureViewSaga({
  payload: { abortSignal },
}: InitAudiencesArchitectureSaga) {
  yield all([
    getAudiencesSaga({ type: 'getAudiences', payload: { abortSignal } }),
    getSyncsSaga({ type: 'getSyncs', payload: { abortSignal } }),
  ]);
}
