import { AudienceColumn } from '@features/audiences/types/AudienceColumn';
import { MASTER_ID_COLUMN_NAME, MODIFIED_AT_COLUMN_NAME } from './constants/constants';

const sortAudienceColumns = (audienceColumns: AudienceColumn[]): AudienceColumn[] => {
  const masterIdColumn = audienceColumns.find((col) => col.name === MASTER_ID_COLUMN_NAME);
  const modifiedAtColumn = audienceColumns.find((col) => col.name === MODIFIED_AT_COLUMN_NAME);
  const audienceSystemColumns = audienceColumns.filter(
    (col) =>
      col.name.startsWith('__') &&
      col.name !== MASTER_ID_COLUMN_NAME &&
      col.name !== MODIFIED_AT_COLUMN_NAME
  );
  const otherAudienceColumns = audienceColumns.filter(
    (col) =>
      col.name !== MASTER_ID_COLUMN_NAME &&
      col.name !== MODIFIED_AT_COLUMN_NAME &&
      !col.name.startsWith('__')
  );
  if (masterIdColumn && modifiedAtColumn) {
    return [masterIdColumn, modifiedAtColumn, ...otherAudienceColumns, ...audienceSystemColumns];
  }
  return audienceColumns;
};

export default sortAudienceColumns;
