import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
// connectorsSlice
import connectorsSlice, { ConnectorsState } from '@features/connectors/ducks/connectorsSlice';

export function* getConnectorsSaga() {
  try {
    const getConnectorsPayload: ConnectorsState = yield call(API.getConnectors);
    yield put({
      type: connectorsSlice.actions.getConnectorsSuccess.type,
      payload: getConnectorsPayload,
    });
  } catch (error) {
    yield put({ type: connectorsSlice.actions.getConnectorsFailed.type, payload: error });
  }
}

export const connectorsSagas = [
  takeLatest(connectorsSlice.actions.getConnectors.type, getConnectorsSaga),
];
