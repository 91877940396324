import { ActiveSourceSettings, SftpSourceType } from '@features/audiences/types';
import { ConnectorType } from '@features/connections/types';
import {
  FileProcessingSettings,
  FileType,
  ISourceSettings,
} from '../../../audienceBackTypes/source';
import { EncryptionType } from '@features/audiences/ducks/api/audienceBackTypes/decryption';
import { caseNever } from '@utils/case-never';
import { ActiveFileProcessingSettings } from '@features/audiences/types/ActiveAudience/ActiveSourceSettings';
import { isValidJsonProcessingPattern } from '../../../../../helpers/validators';
import { SourceSettingsType } from '../../../../api/types';

export const mappingSourceSettingsProcessingPropertyToBack = (
  processing: ActiveFileProcessingSettings
): FileProcessingSettings | undefined => {
  switch (processing.type) {
    case FileType.csv:
      if (!processing.escape || !processing.quote || !processing.delimiter) {
        throw new Error('Properties are missing in csv processing in mapping to back');
      }
      return {
        type: FileType.csv,
        delimiter: processing.delimiter,
        escape: processing.escape,
        quote: processing.quote,
        bom: processing.bom,
      };
    case FileType.json:
      if (!isValidJsonProcessingPattern(processing.pattern)) {
        throw new Error('Property pattern is missing in json processing in mapping to back');
      }
      return {
        type: FileType.json,
        pattern: processing.pattern,
      };
    default:
      caseNever(processing);
  }
};
export const mappingSourceSettingsProperty = (
  sourceSettings: ActiveSourceSettings | undefined
): ISourceSettings => {
  switch (sourceSettings?.type) {
    case ConnectorType.sftp:
      const processing = mappingSourceSettingsProcessingPropertyToBack(sourceSettings.processing);
      if (!processing) {
        throw new Error('Processing type is missing in mapping to front');
      }
      return {
        processing,
        type: ConnectorType.sftp,
        sourceType: sourceSettings.sourceType || SftpSourceType.file,
        decryption: {
          type: EncryptionType.none,
        },
        directoryPath: sourceSettings.directoryPath || '',
        filesMatchPattern: sourceSettings.filesMatchPattern || '',
        filesMatchPatternOptions: sourceSettings.filesMatchPatternOptions || '',
        ignoredDirectories: sourceSettings.ignoredDirectories,
        modifiedAtColumn: sourceSettings.modifiedAtColumn,
      };
    case ConnectorType.postgres:
      return {
        type: ConnectorType.postgres,
        schemaName: sourceSettings.schemaName || '',
        tableName: sourceSettings.tableName || '',
      };
    case ConnectorType.bigQuery:
      return {
        type: ConnectorType.bigQuery,
        datasetId: sourceSettings.datasetId,
        tableId: sourceSettings.tableId,
        location: sourceSettings.location,
        fullModeFilter: sourceSettings.fullModeFilter,
        incrementalModeFilter: sourceSettings.incrementalModeFilter,
      };
    case ConnectorType.mysql:
      return {
        type: ConnectorType.mysql,
        schemaName: sourceSettings.schemaName,
        objectName: sourceSettings.objectName,
      };

    case ConnectorType.snowflake:
      return {
        type: ConnectorType.snowflake,
        schemaName: sourceSettings.schemaName,
        tableName: sourceSettings.tableName,
      };
    case SourceSettingsType.api:
      return sourceSettings;
    case ConnectorType.api: // Api type is not available in the back
      return {} as ISourceSettings;

    default:
      return {} as ISourceSettings;
  }
};
