import { all, call, put, select, takeLatest } from 'redux-saga/effects';
// types
import {
  ApiAudiencePayload,
  ApiDeleteAudiencePayload,
  ApiGetAudiencePayload,
  ApiGetAudiencesPayload,
  ApiGetAudienceTableListPayload,
  ApiGetDeployedAudiencesPayload,
  ApiGetSourceEntityPayload,
  CreateAudienceJoinedSourceDataFormSaga,
  CreateAudienceProcessSaga,
  CreateSourceDataFormForSqlSaga,
  CreateSourceDataFormSaga,
  DeleteAudienceSaga,
  DescribeSqlAudienceSourcesShapesSaga,
  EditJoinedAudienceSourceFormSaga,
  EditSourceDataFormForSqlSaga,
  EditSourceDataFormSaga,
  GetAudienceDataSaga,
  GetAudienceSaga,
  GetAudiencesSaga,
  GetAudiencesTableListSaga,
  GetDeployedAudiencesSaga,
  GetSourceEntitiesSaga,
  InitAudienceEditorSaga,
  InitAudienceViewSaga,
  ReducerSQLAudienceActions,
  RunSqlAudienceQuerySaga,
  SaveAudienceSaga,
  SaveInitialAudienceFormSaga,
  SetupJoinedAudienceSourceForEditionSaga,
  SetupJoinedAudienceSourceSaga,
  SqlAudienceQueryDescriptorSaga,
  UpdateAudienceProcessSaga,
  UpdateAudienceSaga,
  WaitAndGetAudienceDeploymentStatusSaga,
} from '../types';
import audiencesSlice from '../audiencesSlice';
import {
  ActiveSourceConfiguration,
  ActiveSQLSourceConfiguration,
  buildTypesEnum,
  Column,
  columnShapeTypeEnum,
  ConnectionDataSource,
  dataTypesEnum,
  DefaultAudience,
  DefaultColumn,
  DefaultSourceConfiguration,
  JoinActiveSourceConfiguration,
  retrievalModeEnum,
  retrieveFrequencyEnum,
  sourceTypesEnum,
  SqlSourceConfiguration,
} from '../../types';
import {
  deleteAudience,
  describeSqlAudienceSourcesShapes,
  getAudience,
  getAudienceData,
  getAudiences,
  getAudiencesTableList,
  getDeployedAudiences,
  getSourceEntities,
  requestSqlAudienceQueryDescriptor,
  saveAudience,
  sqlAudienceQueryDescriptor,
  updateAudience,
} from '../api/api';
import { generateId, sleep } from '@utils/helpers';
import {
  APIRequestSqlAudienceQueryDescriptor,
  DescribeSqlAudienceSourcesShapesOutput,
  GetSourceEntitiesInputTypes,
  GetSourceEntitiesOutput,
  GetSourceEntitiesTypes,
  RequestSqlAudienceQueryDescriptorOutput,
  SourceEntitiesOutputTypes,
  SqlAudienceQueryDescriptorOutput,
} from '../api/types';
import { connectors } from '@data/connectors';
import { Connection } from '@features/connections/types';
import * as connectionsApi from '@features/connections/ducks/api/api';
import { getConnections } from '@features/connections/ducks/api/api';
import { ApiConnectionEntityTypeOutput } from '@features/connections/types/ApiConnectionEntity';
import { Connector, ConnectorCategoriesEnum } from '@features/connectors/types';
import {
  activeAccountIdStateSelector,
  activeAudienceSelector,
  activeDataWarehouseIdStateSelector,
  activeSourceConfigurationAudienceSelector,
  activeSqlAudienceSelector,
  audiencePreviewSelector,
  businessTermsSelector,
  deployedAudiencesSelector,
  sqlAudienceQueryDescriptorSelector,
  sqlAudienceStepsSelector,
} from '@redux/selectors';
import {
  redirectToAudiencesEditorSaga,
  redirectToAudiencesSaga,
  redirectToPrepareAudienceSaga,
  redirectToSqlAudienceEditorSaga,
} from '@features/redirections/ducks/sagas';
import { AudiencePreview } from '@features/audiences/types/AudiencePreview/AudiencePreview';
import { SqlAudienceQueryDescriptorStatus } from '@features/audiences/types/Audience/SQL';
import { SqlAudienceQueryDescriptor } from '@features/audiences/types/Audience/SQL/SqlAudienceQueryDescriptor';
import {
  ActiveDefaultAudience,
  ActiveSQLAudience,
  ActiveSQLAudienceSettings,
} from '@features/audiences/types/ActiveAudience/ActiveAudience';
import { DataSourceType } from '@features/audiences/ducks/api/audienceBackTypes/source';
import { caseNever } from '@utils/case-never';
import { mappingColumnTypePropertyToFront } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mappingColumnTypePropertyToFront';
import { AudienceUnion, SqlAudience } from '@features/audiences/types/Audience/Audience';
import { SqlAudienceSteps } from '@features/audiences/types/SQLAudienceSteps';
import { AudienceColumn } from '@features/audiences/types/AudienceColumn';
import { draftSourceSaga } from '@features/audiences/ducks/sagas/draftSource';
import {
  updateAudienceAsSourceData,
  updateNamePropertyOfAudiencesAsSource,
} from '@features/audiences/ducks/sagas/audienceAsSource';
import { updateNamePropertyOfSyncAsSource, updateSyncAsSourceData } from './syncAsSource';
import { Deployment } from '@contracts/Deployment';
import { DeploymentStatus } from '@contracts/DeploymentStatus';
import { DataType } from '../api/audienceBackTypes/columnType';
import {
  initAudiencesArchitectureViewSaga,
  initAudienceSourceCreationSaga,
} from '@features/audiences/ducks/sagas/inits';
import { getAudiencePreviewSampleSaga, previewAudienceSaga } from './previewAudience';
import { AudienceData, DeployedAudience } from '@features/audiences/types/DeployedAudience';
import { isJoinSourceConfigurationType } from '@features/audiences/types/typesPredicates';
import { listBusinessTermsSaga } from '../../../audiences/ducks/sagas/listBusinessTermsSaga';
import adaptBusinessModelToBusinessTerms from '@features/audiences/helpers/adaptBusinessModelToBusinessTerms';
import { BusinessTerm } from '@features/audiences/ducks/api/types/ListBusinessTerms';
import { requestAudienceExecutionSaga } from './audienceExecution';
import { MASTER_ID_COLUMN_NAME } from '@features/audiences/helpers/constants/constants';
import { isEqual } from 'lodash';

// Audience CRUD saga's
export function* getAudiencesSaga({ payload }: GetAudiencesSaga) {
  const { abortSignal } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const getAudiencesPayload: ApiGetAudiencesPayload = yield call(
      getAudiences,
      accountId,
      abortSignal
    );
    let audiences: AudienceUnion[] = updateNamePropertyOfAudiencesAsSource(
      getAudiencesPayload.audiences
    );

    audiences = yield call(updateNamePropertyOfSyncAsSource, audiences);

    yield put({
      type: audiencesSlice.actions.getAudiencesSuccess.type,
      payload: { ...getAudiencesPayload, audiences },
    });
  } catch (error) {
    yield put({
      type: audiencesSlice.actions.getAudiencesFailed.type,
      payload: { errorDetails: String(error) },
    });
  }
}

const updateAudiencesNumberOfRecords = (
  audiences: AudienceUnion[],
  deployedAudiences: { id: string; name: string; numberOfRecords: number }[]
) =>
  audiences.map((audience) => {
    const numberOfRecords = deployedAudiences.find((x) => x.id === audience.key)?.numberOfRecords;
    if (numberOfRecords) {
      return { ...audience, numberOfRecords };
    }
    return audience;
  });

function* getAudiencesTableListSaga({ payload }: GetAudiencesTableListSaga) {
  const { abortSignal } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const getAudiencesTableListPayload: ApiGetAudienceTableListPayload = yield call(
      getAudiencesTableList,
      accountId,
      abortSignal
    );
    let audiences: AudienceUnion[] = getAudiencesTableListPayload.audiences;
    audiences = updateNamePropertyOfAudiencesAsSource(audiences);
    audiences = yield call(updateNamePropertyOfSyncAsSource, audiences);
    // TODO @@@@koralex [should we do the same for syncs?]
    audiences = updateAudiencesNumberOfRecords(
      audiences,
      getAudiencesTableListPayload.deployedAudiences
    );
    yield put({
      type: audiencesSlice.actions.getAudiencesTableListSuccess.type,
      payload: { ...getAudiencesTableListPayload, audiences },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.getAudiencesTableListFailed.type,
        payload: { errorDetails: err.message, error: true },
      });
    }
  }
}

function* getAudienceSaga({ payload }: GetAudienceSaga) {
  try {
    const { audienceKey } = payload;
    const getAudiencePayload: ApiGetAudiencePayload = yield call(getAudience, audienceKey);
    yield put({
      type: audiencesSlice.actions.getAudienceSuccess.type,
      payload: getAudiencePayload,
    });
  } catch (error) {
    yield put({ type: audiencesSlice.actions.getAudienceFailed.type, payload: error });
  }
}

function* createAudienceProcessSaga({ payload }: CreateAudienceProcessSaga) {
  const { audience } = payload;
  try {
    const createdAudience: AudienceUnion | undefined = yield call(saveAudienceSaga, {
      payload: { audience },
    });
    yield sleep(300);
    if (createdAudience) {
      const audienceDeployment: Deployment | null = yield waitAndGetAudienceDeploymentStatusSaga({
        payload: { audienceKey: createdAudience.key },
      });
      yield sleep(300);
      // Redirections in case error/completed
      if (audienceDeployment?.status === DeploymentStatus.completed) {
        yield call(redirectToAudiencesSaga);
        yield put({
          type: audiencesSlice.actions.clearAudienceLifeCycle.type,
        });
      } else {
        switch (createdAudience.buildType) {
          case buildTypesEnum.DATA: {
            yield call(redirectToSqlAudienceEditorSaga, {
              payload: { audienceKey: createdAudience.key },
              type: 'redirectToSQLAudience',
            });
            break;
          }
          case buildTypesEnum.BUSINESS: {
            yield call(redirectToAudiencesEditorSaga, {
              payload: { audienceKey: createdAudience.key },
              type: 'redirectToAudiencesEditor',
            });
            break;
          }
          default:
            caseNever(createdAudience);
        }
      }
      // Clean life cycle prop isCreateAudienceProcessCalled
      yield put({
        type: audiencesSlice.actions.createAudienceProcessSuccess.type,
      });
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.createAudienceProcessFailed.type,
        payload: { error: err, errorDetails: 'Failed to create audience', errorPayload: err },
      });
    }
  }
}

function* updateAudienceProcessSaga({ payload }: UpdateAudienceProcessSaga) {
  const { audience } = payload;
  try {
    const updatedAudience: AudienceUnion | undefined = yield call(updateAudienceSaga, {
      payload: { audience },
    });
    yield sleep(500);
    if (updatedAudience) {
      const audienceDeployment: Deployment | null = yield waitAndGetAudienceDeploymentStatusSaga({
        payload: { audienceKey: updatedAudience.key },
      });
      yield sleep(500);
      // Redirections in case error/completed
      if (audienceDeployment?.status === DeploymentStatus.completed) {
        yield call(redirectToAudiencesSaga);
        yield put({
          type: audiencesSlice.actions.clearAudienceLifeCycle.type,
        });
      }
      // Clean life cycle prop isCreateAudienceProcessCalled
      yield put({
        type: audiencesSlice.actions.updateAudienceProcessSuccess.type,
      });
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.updateAudienceProcessFailed.type,
        payload: { error: err, errorDetails: 'Failed to update audience', errorPayload: err },
      });
    }
  }
}

function* updateAudienceSaga({ payload }: UpdateAudienceSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);
  try {
    const { audience } = payload;
    const updateAudiencePayload: ApiAudiencePayload = yield call(
      updateAudience,
      audience,
      accountId,
      dataWarehouseId
    );
    yield put({
      type: audiencesSlice.actions.updateAudienceSuccess.type,
      payload: updateAudiencePayload,
    });
    return updateAudiencePayload.audience;
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.updateAudienceFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* saveAudienceSaga({ payload }: SaveAudienceSaga) {
  const { audience } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);
  try {
    const saveAudiencePayload: ApiAudiencePayload = yield call(
      saveAudience,
      audience,
      accountId,
      dataWarehouseId
    );
    yield put({
      type: audiencesSlice.actions.saveAudienceSuccess.type,
      payload: saveAudiencePayload,
    });
    return saveAudiencePayload.audience;
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.saveAudienceFailed.type,
        payload: { error: err, errorDetails: err.message, errorPayload: err },
      });
    }
  }
}

function* waitAndGetAudienceDeploymentStatusSaga({
  payload,
}: WaitAndGetAudienceDeploymentStatusSaga) {
  const { audienceKey } = payload;
  let deployment: Deployment | null = null;
  let isAudienceExecutionInProgress: boolean = true;
  yield put({
    type: audiencesSlice.actions.updateAudienceDeploymentStatus.type,
  });
  while (isAudienceExecutionInProgress) {
    yield sleep(1200);
    const audiencePayload: ApiGetAudiencePayload = yield call(getAudience, audienceKey);

    if (!audiencePayload.audience) {
      return;
    }
    isAudienceExecutionInProgress =
      audiencePayload.audience.deployment?.status === DeploymentStatus.in_progress ||
      audiencePayload.audience.deployment?.status === DeploymentStatus.pending;
    deployment = audiencePayload.audience.deployment;
  }
  if (deployment?.executionError || deployment?.commitError || deployment?.rollBackError) {
    yield put({
      type: audiencesSlice.actions.updateAudienceDeploymentStatusFailed.type,
      payload: { deployment },
    });
  } else {
    yield put({
      type: audiencesSlice.actions.updateAudienceDeploymentStatusSuccess.type,
      payload: { deployment },
    });
  }
  return deployment;
}

function* deleteAudienceSaga({ payload }: DeleteAudienceSaga) {
  const { audience } = payload;

  try {
    const deleteAudiencePayload: ApiDeleteAudiencePayload = yield call(deleteAudience, audience);
    yield put({
      type: audiencesSlice.actions.deleteAudienceSuccess.type,
      payload: deleteAudiencePayload,
    });
  } catch (error) {
    yield put({ type: audiencesSlice.actions.deleteAudienceFailed.type, payload: error });
  }
}

// Get populated audience with connection
function* getPopulatedAudienceSaga(payload: { audience: AudienceUnion }) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    // Get connections api
    const connectionsResult: Connection[] = yield call(getConnections, accountId);
    // Add connection details to the sources
    const sources = payload.audience.sources.map(
      (source): DefaultSourceConfiguration | SqlSourceConfiguration => {
        if (source.dataSource.type === sourceTypesEnum.CONNECTION) {
          const connectionId: string = source.dataSource.connection.id;
          const connection: Connection | undefined = connectionsResult.find(
            (connection) => connection.id === connectionId
          );
          const newDataSourceValue: ConnectionDataSource = {
            ...source.dataSource,
            connection: {
              ...connection,
              connector: connectors.find(
                (connector) => connector.connectorType === connection?.settings.type
              ) as Connector,
            } as Connection,
          };
          return {
            ...source,
            dataSource: newDataSourceValue,
          };
        }
        return source;
      }
    );
    const populatedAudience = { ...payload.audience, sources };
    return { audience: populatedAudience, error: null };
  } catch (error) {
    return { error, audience: payload.audience };
  }
}

// Screen init
function* initAudienceEditorSaga({ payload }: InitAudienceEditorSaga) {
  try {
    const { audienceKey } = payload;
    const getAudiencePayload: ApiGetAudiencePayload = yield call(getAudience, audienceKey);
    if (!getAudiencePayload.audience) {
      return;
    }

    yield put({
      type: audiencesSlice.actions.listBusinessTerms.type,
      payload: {},
    });

    let audience: AudienceUnion = yield updateAudienceAsSourceData({
      payload: { audience: getAudiencePayload.audience },
    });

    audience = yield updateSyncAsSourceData({
      payload: { audience: getAudiencePayload.audience },
    });
    const populatedAudiencePayload: { audience: AudienceUnion; error: unknown } =
      yield getPopulatedAudienceSaga({
        audience,
      });

    if (populatedAudiencePayload.error === null) {
      switch (populatedAudiencePayload.audience.buildType) {
        case buildTypesEnum.DATA: {
          const audience: SqlAudience = populatedAudiencePayload.audience;

          const activeSQLAudienceSettings: ActiveSQLAudienceSettings = {
            category: audience.category,
            dataApiEnabled: audience.dataApiEnabled,
            deduplicationSettings: audience.deduplicationSettings,
            immutable: audience.immutable,
            interval: retrieveFrequencyEnum.EVERY_HOUR, // TODO @@@@koralex HARDCODE
            mode: audience.mode,
            modifiedAtColumn: audience.modifiedAtColumnId,
          };

          yield put({
            type: audiencesSlice.actions.updateActiveSQLAudience.type,
            payload: { activeSQLAudience: audience },
          });

          yield call(describeSqlAudienceSourcesShapesSaga, {
            payload: {
              sources: audience.sources.map((source) => ({
                ...source,
                columns: [],
              })),
            },
          });

          yield all([
            put({
              type: audiencesSlice.actions.updateActiveSQLAudienceSettings.type,
              payload: {
                activeSQLAudienceSettings,
              },
            }),
            put({
              type: audiencesSlice.actions.updateSqlAudienceSteps.type,
              payload: {
                steps: {
                  isQueryValid: true,
                  isSettingsValid: true,
                },
              },
            }),
            put({
              type: audiencesSlice.actions.setNewActiveSQLSourceConfiguration.type,
            }),
          ]);

          yield call(runSqlAudienceQuerySaga, {
            type: 'runSqlAudienceQuerySaga',
            payload: {},
          });

          break;
        }
        case buildTypesEnum.BUSINESS: {
          const audience: DefaultAudience = populatedAudiencePayload.audience;
          const businessTerms: BusinessTerm[] = yield select(businessTermsSelector);
          const newBusinessModel = adaptBusinessModelToBusinessTerms(audience, businessTerms);
          yield all([
            put({
              type: audiencesSlice.actions.updateActiveAudience.type,
              payload: {
                activeAudience: {
                  ...audience,
                  businessModel: newBusinessModel,
                },
              },
            }),
            put({
              type: audiencesSlice.actions.updateActiveMappingRows.type,
              payload: { mappingRows: audience.mappingRows },
            }),
            put({
              type: audiencesSlice.actions.setNewActiveSourceConfiguration.type,
              payload: { combinationLevel: audience.sources.length },
            }),
          ]);

          yield call(listBusinessTermsSaga, {
            type: 'listBusinessTermsSaga',
            payload: {},
          });
          break;
        }
        default:
          return caseNever(populatedAudiencePayload.audience);
      }

      yield put({
        type: audiencesSlice.actions.initAudienceEditorSuccess.type,
      });
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.initAudienceEditorFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* initSqlAudienceViewSaga({ payload }: InitAudienceViewSaga) {
  try {
    const { audienceKey } = payload;
    //  get audience api call
    const getAudiencePayload: ApiGetAudiencePayload = yield call(getAudience, audienceKey);

    if (!getAudiencePayload.audience) {
      return;
    }
    let audience: AudienceUnion = yield updateAudienceAsSourceData({
      payload: { audience: getAudiencePayload.audience },
    });

    audience = yield updateSyncAsSourceData({
      payload: { audience: getAudiencePayload.audience },
    });

    if (audience.buildType !== buildTypesEnum.DATA) {
      return;
    }
    yield put<ReducerSQLAudienceActions>({
      type: audiencesSlice.actions.updateActiveSQLAudience.type,
      payload: { activeSQLAudience: audience },
    });
    yield put({
      type: audiencesSlice.actions.initSqlAudienceViewSuccess.type,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.initSqlAudienceViewFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* saveFirstSourceFormSaga({ payload }: SaveInitialAudienceFormSaga) {
  try {
    const { activeAudience, sourceConfiguration } = payload;
    yield all([
      put({
        type: audiencesSlice.actions.updateActiveAudience.type,
        payload: {
          activeAudience,
        },
      }),
      put({
        type: audiencesSlice.actions.saveInitialAudiencePreparationStep.type,
        payload: {
          sourceConfiguration,
        },
      }),
      put({
        type: audiencesSlice.actions.updateActiveMappingRows.type,
        payload: {
          mappingRows: activeAudience.mappingRows,
        },
      }),
      // Redirection after success
      call(redirectToPrepareAudienceSaga),
      put({
        type: audiencesSlice.actions.setNewActiveSourceConfiguration.type,
        payload: {
          combinationLevel: activeAudience.sources.length,
        },
      }),
      put({
        type: audiencesSlice.actions.saveFirstSourceFormSuccess.type,
      }),
    ]);
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.saveFirstSourceFormFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* createSourceDataFormSaga({ payload }: CreateSourceDataFormSaga) {
  try {
    const { activeAudience, sourceConfiguration } = payload;
    yield all([
      put({
        type: audiencesSlice.actions.updateActiveAudience.type,
        payload: {
          activeAudience,
        },
      }),
      put({
        type: audiencesSlice.actions.updateActiveMappingRows.type,
        payload: {
          mappingRows: activeAudience.mappingRows,
        },
      }),
      put({
        type: audiencesSlice.actions.saveSourceDataPreparationStep.type,
        payload: {
          sourceConfiguration,
        },
      }),
      put({
        type: audiencesSlice.actions.setNewActiveSourceConfiguration.type,
        payload: {
          combinationLevel: activeAudience.sources.length,
        },
      }),
      put({
        type: audiencesSlice.actions.createSourceDataFormSuccess.type,
      }),
    ]);
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.createSourceDataFormFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* editAudienceSourceSaga({ payload }: EditSourceDataFormSaga) {
  try {
    const { activeAudience } = payload;
    yield all([
      put({
        type: audiencesSlice.actions.updateActiveAudience.type,
        payload: {
          activeAudience,
        },
      }),
      put({
        type: audiencesSlice.actions.updateActiveMappingRows.type,
        payload: {
          mappingRows: activeAudience.mappingRows,
        },
      }),

      put({
        type: audiencesSlice.actions.setNewActiveSourceConfiguration.type,
        payload: {
          combinationLevel: activeAudience.sources.length,
        },
      }),
      put({
        type: audiencesSlice.actions.editSourceDataFormSuccess.type,
      }),
    ]);
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.editSourceDataFormFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* createJoinedAudienceSourceFormSaga({ payload }: CreateAudienceJoinedSourceDataFormSaga) {
  try {
    const { activeAudience } = payload;
    yield all([
      put({
        type: audiencesSlice.actions.updateActiveAudience.type,
        payload: {
          activeAudience,
        },
      }),
      put({
        type: audiencesSlice.actions.updateActiveMappingRows.type,
        payload: {
          mappingRows: activeAudience.mappingRows,
        },
      }),
      put({
        type: audiencesSlice.actions.setNewActiveSourceConfiguration.type,
        payload: {
          combinationLevel: activeAudience.sources.length,
        },
      }),
      put({
        type: audiencesSlice.actions.createJoinedAudienceSourceFormSuccess.type,
      }),
    ]);
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.createJoinedAudienceSourceFormFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* editJoinedAudienceSourceFormSaga({ payload }: EditJoinedAudienceSourceFormSaga) {
  try {
    const { activeAudience } = payload;
    yield all([
      put({
        type: audiencesSlice.actions.updateActiveAudience.type,
        payload: {
          activeAudience,
        },
      }),
      put({
        type: audiencesSlice.actions.updateActiveMappingRows.type,
        payload: {
          mappingRows: activeAudience.mappingRows,
        },
      }),

      put({
        type: audiencesSlice.actions.setNewActiveSourceConfiguration.type,
        payload: {
          combinationLevel: activeAudience.sources.length,
        },
      }),
      put({
        type: audiencesSlice.actions.editJoinedAudienceSourceFormSuccess.type,
      }),
    ]);
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.editJoinedAudienceSourceFormFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* setupJoinedAudienceSourceSaga({ payload }: SetupJoinedAudienceSourceSaga) {
  try {
    const { audienceId, condition } = payload;
    const activeAudience: ActiveDefaultAudience = yield select(activeAudienceSelector);
    const deployedAudiences: DeployedAudience[] = yield select(deployedAudiencesSelector);
    const activeSourceConfiguration: ActiveSourceConfiguration = yield select(
      activeSourceConfigurationAudienceSelector
    );
    const audienceAlreadyJoinedSource: JoinActiveSourceConfiguration | undefined =
      activeAudience.sources.find(
        (source) =>
          isJoinSourceConfigurationType(source) &&
          source.dataSource.type === sourceTypesEnum.AUDIENCE &&
          source.dataSource.audienceId === audienceId
      ) as JoinActiveSourceConfiguration;
    const selectedDeployedAudienceDetails = deployedAudiences.find((aud) => aud.id === audienceId);
    const selectedColumns = (selectedDeployedAudienceDetails?.dataShape.columns || []).map((x) => {
      const col: Column = x.defaultValue
        ? {
            id: x.id,
            name: x.name,
            type: x.type,
            value: x.defaultValue,
            isNullable: false,
            shapeType: columnShapeTypeEnum.STATIC,
          }
        : {
            id: x.id,
            externalName: x.name,
            name: x.name,
            type: x.type,
            isNullable: false,
            shapeType: columnShapeTypeEnum.DEFAULT,
          };
      return col;
    });
    const newSourceConfig: JoinActiveSourceConfiguration | undefined = audienceAlreadyJoinedSource
      ? { ...audienceAlreadyJoinedSource, condition, selectedColumns }
      : {
          selectedColumns,
          condition,
          key: generateId(),
          combinationLevel: activeSourceConfiguration.combinationLevel,
          dataType: dataTypesEnum.JOIN,
          preparations: [],
          retrievalMode: retrievalModeEnum.ALL,
          deduplicationSettings: {
            deduplicationKeys: selectedDeployedAudienceDetails?.dataShape.primaryKeyId
              ? [selectedDeployedAudienceDetails?.dataShape.primaryKeyId]
              : [],
          },
          dataSource: {
            audienceId,
            type: sourceTypesEnum.AUDIENCE,
            name: selectedDeployedAudienceDetails && selectedDeployedAudienceDetails.name,
            category: selectedDeployedAudienceDetails?.businessCategory,
            updatedAtColumnId: selectedDeployedAudienceDetails?.dataShape.modifiedAtId,
          },
        };

    yield put({
      type: audiencesSlice.actions.setupJoinedAudienceSourceSuccess.type,
      payload: {
        newSourceConfig,
      },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.setupJoinedAudienceSourceFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* setupJoinedAudienceSourceForEditionSaga({
  payload,
}: SetupJoinedAudienceSourceForEditionSaga) {
  const { sourceConfiguration } = payload;
  const deployedAudiences: DeployedAudience[] = yield select(deployedAudiencesSelector);

  const selectedDeployedAudienceDetails = deployedAudiences.find(
    (aud) =>
      sourceConfiguration.dataSource.type === sourceTypesEnum.AUDIENCE &&
      aud.id === sourceConfiguration.dataSource.audienceId
  );
  const selectedColumns =
    sourceConfiguration.dataSource.type === sourceTypesEnum.AUDIENCE
      ? (selectedDeployedAudienceDetails?.dataShape.columns || []).map((x) => {
          const col: Column = x.defaultValue
            ? {
                id: x.id,
                name: x.name,
                type: x.type,
                value: x.defaultValue,
                isNullable: false,
                shapeType: columnShapeTypeEnum.STATIC,
              }
            : {
                id: x.id,
                externalName: x.name,
                name: x.name,
                type: x.type,
                isNullable: false,
                shapeType: columnShapeTypeEnum.DEFAULT,
              };
          return col;
        })
      : sourceConfiguration.selectedColumns;
  yield put({
    type: audiencesSlice.actions.updateActiveSourceConfiguration.type,
    payload: {
      sourceConfiguration: { ...sourceConfiguration, selectedColumns },
    },
  });
}

function* createSourceDataFormForSqlSaga({ payload }: CreateSourceDataFormForSqlSaga) {
  try {
    const { activeAudience, sourceConfiguration } = payload;

    yield put<ReducerSQLAudienceActions>({
      type: audiencesSlice.actions.updateActiveSQLAudience.type,
      payload: {
        activeSQLAudience: activeAudience,
      },
    });

    yield call(describeSqlAudienceSourcesShapesSaga, {
      payload: {
        sources: [payload.sourceConfiguration],
      },
    });

    yield all([
      put({
        type: audiencesSlice.actions.saveSourceDataPreparationStep.type,
        payload: {
          sourceConfiguration,
        },
      }),
      put({
        type: audiencesSlice.actions.setNewActiveSQLSourceConfiguration.type,
        payload: {},
      }),
      put({
        type: audiencesSlice.actions.createSourceDataFormForSqlSuccess.type,
      }),
    ]);
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.createSourceDataFormForSqlFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* editAudienceSourceForSqlSaga({ payload }: EditSourceDataFormForSqlSaga) {
  try {
    const { activeAudience } = payload;

    yield put<ReducerSQLAudienceActions>({
      type: audiencesSlice.actions.updateActiveSQLAudience.type,
      payload: {
        activeSQLAudience: activeAudience,
      },
    });

    yield call(describeSqlAudienceSourcesShapesSaga, {
      payload: {
        sources: [payload.sourceConfiguration],
      },
    });

    yield all([
      put({
        type: audiencesSlice.actions.setNewActiveSQLSourceConfiguration.type,
        payload: {},
      }),
      put({
        type: audiencesSlice.actions.editSourceDataFormForSqlSuccess.type,
      }),
    ]);
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.editSourceDataFormForSqlFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

export function* getDeployedAudiencesSaga({ payload }: GetDeployedAudiencesSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const { abortSignal } = payload;
  try {
    const deployedAudiencesPayload: ApiGetDeployedAudiencesPayload = yield call(
      getDeployedAudiences,
      accountId,
      abortSignal
    );
    yield put({
      type: audiencesSlice.actions.getDeployedAudiencesSuccess.type,
      payload: deployedAudiencesPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.getDeployedAudiencesFailed.type,
        payload: { errorDetails: err },
      });
    }
  }
}

function* getAudienceDataSaga({ payload }: GetAudienceDataSaga) {
  try {
    const audienceDataResult: AudienceData = yield call(
      getAudienceData,
      payload.audienceId,
      payload.paging,
      payload.search,
      payload.abortSignal
    );
    yield put({
      type: audiencesSlice.actions.getAudienceDataSuccess.type,
      payload: { audienceData: audienceDataResult },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.getAudienceDataFailed.type,
        payload: { errorDetails: err.message },
      });
    }
  }
}

function* getSourceEntitiesSaga({ payload }: GetSourceEntitiesSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const { command } = payload;
  let sourceEntities: GetSourceEntitiesOutput;

  try {
    if (
      command.type === GetSourceEntitiesInputTypes.connection &&
      command.connectorCategory === ConnectorCategoriesEnum.API
    ) {
      const getApiDestinationEntitiesPayload: ApiConnectionEntityTypeOutput = yield call(
        connectionsApi.getApiConnectionEntityTypes,
        {
          connectionId: command.connectionId,
        }
      );
      sourceEntities = {
        type: SourceEntitiesOutputTypes.api,
        entities: getApiDestinationEntitiesPayload.types
          .filter(({ isSource }) => isSource)
          .map(({ id, label }) => ({
            type: GetSourceEntitiesTypes.apiEntity,
            name: label,
            key: id,
          })),
      };
    } else {
      const getSourceEntitiesPayload: ApiGetSourceEntityPayload = yield call(
        getSourceEntities,
        accountId,
        command
      );

      sourceEntities = getSourceEntitiesPayload.sourceEntities;
    }

    yield put({
      type: audiencesSlice.actions.getSourceEntitiesSuccess.type,
      payload: {
        sourceEntities,
      },
    });
  } catch (error) {
    console.log(error);
    yield put({ type: audiencesSlice.actions.getSourceEntitiesFailed.type, payload: error });
  }
}

function* describeSqlAudienceSourcesShapesSaga({ payload }: DescribeSqlAudienceSourcesShapesSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);
  const { sources: sourcesPayload, abortSignal } = payload;
  try {
    const result: DescribeSqlAudienceSourcesShapesOutput = yield call(
      describeSqlAudienceSourcesShapes,
      sourcesPayload,
      accountId,
      dataWarehouseId,
      abortSignal
    );

    const { sources: sourcesResult } = result;
    const activeAudience: ActiveSQLAudience = yield select(activeSqlAudienceSelector);

    const updatedSources = activeAudience.sources.map((activeAudienceSource) => {
      const foundSourceInTheResult = sourcesResult.find(({ source: sourceFromResult }) => {
        switch (sourceFromResult.type) {
          case DataSourceType.connection: {
            return activeAudienceSource.key === sourceFromResult.id;
          }
          case DataSourceType.audience: {
            return (
              activeAudienceSource.dataSource.type === sourceTypesEnum.AUDIENCE &&
              activeAudienceSource.dataSource.audienceId === sourceFromResult.audienceId
            );
          }
          case DataSourceType.sync: {
            return (
              activeAudienceSource.dataSource.type === sourceTypesEnum.SYNC &&
              activeAudienceSource.dataSource.syncId === sourceFromResult.syncId
            );
          }
          case DataSourceType.webhook: {
            return (
              activeAudienceSource.dataSource.type === sourceTypesEnum.WEBHOOK &&
              activeAudienceSource.dataSource.webhookId === sourceFromResult.webhookId
            );
          }
          default:
            return caseNever(sourceFromResult);
        }
      });

      if (!foundSourceInTheResult) {
        return activeAudienceSource;
      }

      const updatedSource: ActiveSQLSourceConfiguration = {
        ...activeAudienceSource,
        columns: foundSourceInTheResult.shape.columns.map((column) => {
          return {
            ...column,
            type: mappingColumnTypePropertyToFront(column.type),
            shapeType: columnShapeTypeEnum.DEFAULT,
            // TODO @@@@koralex potential issues with this? because it's optional on the backend.
            externalName: column.externalName || column.name,
            name: column.name,
            format: column.type.dataType === DataType.Timestamp ? column.type.format : undefined,
          };
        }),
      };
      return updatedSource;
    });
    yield put<ReducerSQLAudienceActions>({
      type: audiencesSlice.actions.updateActiveSQLAudience.type,
      payload: {
        activeSQLAudience: {
          ...activeAudience,
          sources: updatedSources,
        },
      },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.describeSqlAudienceSourcesShapesFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* sqlAudienceQueryDescriptorSaga({ payload }: SqlAudienceQueryDescriptorSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);
  const { audience, abortSignal } = payload;
  try {
    const requestSqlAudienceQueryDescriptorOutput: RequestSqlAudienceQueryDescriptorOutput =
      yield call<APIRequestSqlAudienceQueryDescriptor>(
        requestSqlAudienceQueryDescriptor,
        audience,
        accountId,
        dataWarehouseId,
        abortSignal
      );

    const {
      id: requestSqlAudienceQueryDescriptorId,
      error,
      errorDetails,
    } = requestSqlAudienceQueryDescriptorOutput;

    if (error) {
      yield put({
        type: audiencesSlice.actions.sqlAudienceQueryDescriptorFailed.type,
        payload: {
          error,
          errorDetails,
        },
      });

      return {
        sqlAudienceQueryDescriptor: {
          id: requestSqlAudienceQueryDescriptorId,
          shape: null,
        },
        error: errorDetails,
      };
    }

    let sqlAudienceQueryDescriptorOutput: SqlAudienceQueryDescriptorOutput | undefined = undefined;
    let isFinished = false;
    while (!isFinished) {
      yield sleep(500, abortSignal);
      sqlAudienceQueryDescriptorOutput = yield call(
        sqlAudienceQueryDescriptor,
        requestSqlAudienceQueryDescriptorId,
        abortSignal
      );
      isFinished =
        sqlAudienceQueryDescriptorOutput?.status === SqlAudienceQueryDescriptorStatus.ok ||
        sqlAudienceQueryDescriptorOutput?.status === SqlAudienceQueryDescriptorStatus.error;
    }

    const activeAudience: ActiveSQLAudience = yield select(activeSqlAudienceSelector);

    if (sqlAudienceQueryDescriptorOutput?.status === SqlAudienceQueryDescriptorStatus.ok) {
      const columns = (sqlAudienceQueryDescriptorOutput.shape?.columns || [])
        .filter(({ name }) => !name.startsWith('__') && !name.endsWith('__'))
        .map((col): DefaultColumn => {
          const foundColumn = activeAudience.columns.find(
            (existingColumn) => existingColumn.name === col.name
          );

          if (foundColumn) {
            return foundColumn;
          }

          return {
            id: generateId(),
            type: mappingColumnTypePropertyToFront(col.type),
            name: col.name,
            shapeType: columnShapeTypeEnum.DEFAULT,
            externalName: col.externalName || col.name,
            isNullable: col.isNullable,
            format: col.type.dataType === DataType.Timestamp ? col.type.format : undefined,
          };
        });

      const sqlAudienceQueryDescriptor: SqlAudienceQueryDescriptor = {
        id: requestSqlAudienceQueryDescriptorId,
        shape: {
          columns,
        },
      };
      yield put({
        type: audiencesSlice.actions.sqlAudienceQueryDescriptorSuccess.type,
        payload: { sqlAudienceQueryDescriptor },
      });
      yield put<ReducerSQLAudienceActions>({
        type: audiencesSlice.actions.updateActiveSQLAudience.type,
        payload: {
          activeSQLAudience: {
            ...activeAudience,
            columns,
          },
        },
      });

      return {
        sqlAudienceQueryDescriptor,
      };
    } else if (
      sqlAudienceQueryDescriptorOutput?.status === SqlAudienceQueryDescriptorStatus.error
    ) {
      const sqlAudienceQueryDescriptor: SqlAudienceQueryDescriptor = {
        id: requestSqlAudienceQueryDescriptorId,
        shape: null,
      };
      yield put({
        type: audiencesSlice.actions.updateActiveSQLAudience.type,
        payload: {
          activeSQLAudience: {
            ...activeAudience,
            columns: [],
          },
        },
      });
      yield put({
        type: audiencesSlice.actions.sqlAudienceQueryDescriptorSuccess.type,
        payload: { sqlAudienceQueryDescriptor },
      });
      const errorDetails = 'error with SQL audience query descriptor';
      yield put({
        type: audiencesSlice.actions.sqlAudienceQueryDescriptorFailed.type,
        payload: {
          errorDetails,
          error: sqlAudienceQueryDescriptorOutput.error,
        },
      });

      return {
        sqlAudienceQueryDescriptor,
        error: errorDetails,
      };
    } else {
      return null;
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.sqlAudienceQueryDescriptorFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }

    return {
      sqlAudienceQueryDescriptor: {
        shape: null,
      },
      error: err,
    };
  }
}

function isAudienceColumnsSettingsValid(columns: AudienceColumn[], audience: ActiveSQLAudience) {
  const validate = (
    ids: string | string[] | undefined,
    { canBeEmpty }: { canBeEmpty: boolean } = { canBeEmpty: true }
  ) => {
    const _ids = !Array.isArray(ids) ? [ids] : ids || [];

    if (!canBeEmpty && !_ids.length) {
      return false;
    }

    return _ids.every((id) => columns.find(({ id: columnId }) => id === columnId));
  };

  const validationResults = [
    {
      isValid:
        validate(
          audience.deduplicationSettings.deduplicationKeys.flatMap(({ columnIds }) => columnIds),
          {
            canBeEmpty: false,
          }
        ) ||
        isEqual(audience.deduplicationSettings.deduplicationKeys[0].columnIds, [
          MASTER_ID_COLUMN_NAME,
        ]),
      property: 'deduplicationSettings.deduplicationKeys',
    },
    {
      isValid: validate(audience.deduplicationSettings.preservedValuesKeys),
      property: 'deduplicationSettings.preservedValuesKeys',
    },
    {
      isValid: validate(audience.immutable),
      property: 'immutable',
    },
    {
      isValid: validate(audience.modifiedAtColumnId, { canBeEmpty: false }),
      property: 'modifiedAtColumnId',
    },
  ];

  return {
    isValid: validationResults.every((i) => i.isValid),
    invalidProperties: validationResults
      .filter(({ isValid }) => !isValid)
      .map(({ property }) => property),
  };
}

function* runSqlAudienceQuerySaga({ payload }: RunSqlAudienceQuerySaga) {
  const steps: SqlAudienceSteps = yield select(sqlAudienceStepsSelector);
  const audience: ActiveSQLAudience = yield select(activeSqlAudienceSelector);
  const audiencePreview: AudiencePreview = yield select(audiencePreviewSelector);

  if (!steps.isQueryValid) {
    const { sqlAudienceQueryDescriptor, error } = yield sqlAudienceQueryDescriptorSaga({
      type: 'sqlAudienceQueryDescriptor',
      payload: {
        audience,
        abortSignal: payload.abortSignal,
      },
    });

    if (error || sqlAudienceQueryDescriptor.error) {
      yield put({
        type: audiencesSlice.actions.runSqlAudienceQueryFail.type,
        payload: {
          errorDetails: sqlAudienceQueryDescriptor.error,
        },
      });

      return;
    }

    yield put({
      type: audiencesSlice.actions.updateSqlAudienceSteps.type,
      payload: {
        steps: {
          isQueryValid: true,
          invalidSettingsProperties: null,
        },
      },
    });
  }

  if (!steps.isSettingsValid) {
    const sqlAudienceQueryDescriptor: SqlAudienceQueryDescriptor = yield select(
      sqlAudienceQueryDescriptorSelector
    );

    if (!sqlAudienceQueryDescriptor.shape) {
      yield put({
        type: audiencesSlice.actions.updateSqlAudienceSteps.type,
        payload: {
          steps: {
            isSettingsValid: false,
            invalidSettingsProperties: null,
          },
        },
      });
      yield put({
        type: audiencesSlice.actions.runSqlAudienceQuerySuccess.type,
      });
      return;
    }

    const audienceColumnSettingsValidityResult: {
      isValid: boolean;
      invalidProperties: null | string[];
    } = yield isAudienceColumnsSettingsValid(sqlAudienceQueryDescriptor.shape.columns, audience);

    if (!audienceColumnSettingsValidityResult.isValid) {
      yield put({
        type: audiencesSlice.actions.updateSqlAudienceSteps.type,
        payload: {
          steps: {
            isSettingsValid: false,
            invalidSettingsProperties: audienceColumnSettingsValidityResult.invalidProperties,
          },
        },
      });
      yield put({
        type: audiencesSlice.actions.runSqlAudienceQueryFail.type,
        payload: {
          errorDetails: 'invalid audience settings',
        },
      });

      return;
    }

    yield put({
      type: audiencesSlice.actions.updateSqlAudienceSteps.type,
      payload: {
        steps: {
          isSettingsValid: true,
          invalidSettingsProperties: null,
        },
      },
    });
  }

  const newStepsState: SqlAudienceSteps = yield select(sqlAudienceStepsSelector);

  if (newStepsState.isQueryValid && newStepsState.isSettingsValid) {
    const updatedAudience: ActiveSQLAudience = yield select(activeSqlAudienceSelector);

    const audiencePreviewResult: {
      audiencePreview: AudiencePreview | undefined;
      error: string | undefined;
    } = yield call(previewAudienceSaga, {
      type: 'audiencePreview',
      payload: {
        audience: updatedAudience,
        samplePaging: {
          page: audiencePreview?.sample?.page || 1,
          pageSize: audiencePreview?.sample?.pageSize || 5,
        },
        abortSignal: payload.abortSignal,
      },
    });

    if (audiencePreviewResult.error) {
      yield put({
        type: audiencesSlice.actions.runSqlAudienceQueryFail.type,
        payload: {
          errorDetails: audiencePreviewResult.error,
        },
      });

      return;
    }

    yield put({
      type: audiencesSlice.actions.runSqlAudienceQuerySuccess.type,
    });
  }

  yield put({
    type: audiencesSlice.actions.runSqlAudienceQuerySuccess.type,
  });
}

export const audienceSagas = [
  // Audience CRUD
  takeLatest(audiencesSlice.actions.getAudiences.type, getAudiencesSaga),
  takeLatest(audiencesSlice.actions.getAudiencesTableList.type, getAudiencesTableListSaga),
  takeLatest(audiencesSlice.actions.getAudience.type, getAudienceSaga),
  takeLatest(audiencesSlice.actions.createAudienceProcess.type, createAudienceProcessSaga),
  takeLatest(audiencesSlice.actions.updateAudienceProcess.type, updateAudienceProcessSaga),
  takeLatest(audiencesSlice.actions.deleteAudience.type, deleteAudienceSaga),

  // Audience Helpers
  takeLatest(audiencesSlice.actions.getDeployedAudiences.type, getDeployedAudiencesSaga),
  takeLatest(audiencesSlice.actions.getAudienceData.type, getAudienceDataSaga),
  // Used for active audience forms
  takeLatest(audiencesSlice.actions.previewAudience.type, previewAudienceSaga),
  takeLatest(audiencesSlice.actions.getAudiencePreviewSample.type, getAudiencePreviewSampleSaga),
  takeLatest(audiencesSlice.actions.saveFirstSourceForm.type, saveFirstSourceFormSaga),
  takeLatest(audiencesSlice.actions.createSourceDataForm.type, createSourceDataFormSaga),
  takeLatest(audiencesSlice.actions.editSourceDataForm.type, editAudienceSourceSaga),

  takeLatest(
    audiencesSlice.actions.createSourceDataFormForSql.type,
    createSourceDataFormForSqlSaga
  ),
  takeLatest(audiencesSlice.actions.editSourceDataFormForSql.type, editAudienceSourceForSqlSaga),

  takeLatest(
    audiencesSlice.actions.createJoinedAudienceSourceForm.type,
    createJoinedAudienceSourceFormSaga
  ),
  takeLatest(
    audiencesSlice.actions.editJoinedAudienceSourceForm.type,
    editJoinedAudienceSourceFormSaga
  ),
  // Init screens
  takeLatest(audiencesSlice.actions.initAudienceEditor.type, initAudienceEditorSaga),
  takeLatest(audiencesSlice.actions.initSqlAudienceView.type, initSqlAudienceViewSaga),

  takeLatest(audiencesSlice.actions.getSourceEntitiesForAudienceSource.type, getSourceEntitiesSaga),
  takeLatest(audiencesSlice.actions.draftSource.type, draftSourceSaga),
  takeLatest(
    audiencesSlice.actions.sqlAudienceQueryDescriptor.type,
    sqlAudienceQueryDescriptorSaga
  ),
  takeLatest(audiencesSlice.actions.runSqlAudienceQuery.type, runSqlAudienceQuerySaga),
  takeLatest(
    audiencesSlice.actions.initAudienceSourceCreation.type,
    initAudienceSourceCreationSaga
  ),
  takeLatest(
    audiencesSlice.actions.initAudiencesArchitectureView.type,
    initAudiencesArchitectureViewSaga
  ),
  takeLatest(audiencesSlice.actions.setupJoinedAudienceSource.type, setupJoinedAudienceSourceSaga),
  takeLatest(
    audiencesSlice.actions.setupJoinedAudienceSourceForEdition.type,
    setupJoinedAudienceSourceForEditionSaga
  ),
  takeLatest(audiencesSlice.actions.listBusinessTerms.type, listBusinessTermsSaga),
  takeLatest(audiencesSlice.actions.requestAudienceExecution.type, requestAudienceExecutionSaga),
];
