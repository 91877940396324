import React from 'react';
import { ActiveSourceConfiguration, Column } from '@features/audiences/types';

// Audience Name in the mapping : SourceNam + SourcePrimaryKeyColumnName

const getSourceIDConditionAudienceColumnsName = (
  sources: ActiveSourceConfiguration[],
  sourceId: string,
  sourceColumnKey: string
) => {
  const source = sources.find((source) => source.key === sourceId);
  const sourceColumn = source?.selectedColumns.find((col) => col.id === sourceColumnKey);

  return `${source?.dataSource.name} ${sourceColumn?.name} list`;
};

export default getSourceIDConditionAudienceColumnsName;
