import { TreeStructure } from '@components/form/items/OctoTreeSelect';
import { ActiveConnection, Connection, ConnectorType } from '@features/connections/types';
import { createSlice } from '@reduxjs/toolkit';
import { upsertInArray } from '@utils/helpers';
import type {
  ConnectionPointerReducerActions,
  ConnectionReducerActions,
  ConnectionsReducerActions,
  DeleteConnectionError,
  DeleteConnectionReducerActions,
  TestSettingsReducerActions,
} from './types';

export interface ConnectionsState {
  loaded: boolean;
  isFetching?: boolean;
  isTesting?: boolean;
  isFetchingConnectionPointers?: boolean;
  shouldBeClosed?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  deleteError?: DeleteConnectionError;
  updateActiveConnectionError?: string | undefined;
  page?: number;
  limit?: number;
  total?: number;
  connections: Connection[];
  connection?: Connection;
  activeConnection: ActiveConnection;
  pointers: TreeStructure;
}

export const INITIAL_SOURCE_STATE = {
  loaded: false,
  isFetching: false,
  isTesting: false,
  isFetchingConnectionPointers: false,
  connections: [],
  pointers: {},
  activeConnection: {
    canBeUsedAs: [],
  },
} as ConnectionsState;

const connectionsSlice = createSlice({
  name: 'connections',
  initialState: INITIAL_SOURCE_STATE,

  reducers: {
    getConnections: (state) => {
      state.isFetching = true;
    },
    getConnectionsSuccess: (state, { payload }: ConnectionsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.connections = payload.connections;
    },
    getConnectionsFailed: (state, { payload }: ConnectionsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getConnection: (state) => {
      state.isFetching = true;
      state.deleteError = undefined;
    },
    getConnectionSuccess: (state, { payload }: ConnectionReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.connection = payload.connection;
      state.activeConnection = {
        ...payload.connection,
        settings: {
          ...payload.connection.settings,
        },
      };

      if (
        state.activeConnection.settings?.type === ConnectorType.shopify &&
        state.activeConnection.settings.isShopifyPlus === null
      ) {
        state.activeConnection.settings.isShopifyPlus = false;
      }
    },
    getConnectionFailed: (state, { payload }: ConnectionReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    updateConnection: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    updateConnectionSuccess: (state, { payload }: ConnectionReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.connection = payload.connection;
      state.connections = upsertInArray(
        payload.connection,
        state.connections,
        (connection) => connection.id
      );
      state.activeConnection = {
        canBeUsedAs: [],
      };
    },
    updateConnectionFailed: (state, { payload }: ConnectionReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveSourceConnection: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    // saveConnection: (state) => {
    //   state.isFetching = true;
    //   state.shouldBeClosed = false;
    // },
    saveConnectionSuccess: (state, { payload }: ConnectionReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.connections = upsertInArray(
        payload.connection,
        state.connections,
        (connection) => connection.id
      );
      state.activeConnection = {
        canBeUsedAs: [],
      };
    },
    saveConnectionFailed: (state, { payload }: ConnectionReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    deleteConnection: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    deleteConnectionSuccess: (state, { payload }: DeleteConnectionReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.error = false;
      state.deleteError = undefined;
      state.connections = state.connections.filter(
        (connection) => connection.id !== payload.connection.id
      );
    },
    deleteConnectionFailed: (state, { payload }: DeleteConnectionReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.deleteError = payload.error;
    },

    testConnection: (state) => {
      state.isTesting = true;
      state.shouldBeClosed = false;
    },
    testConnectionSuccess: (state, { payload }: ConnectionReducerActions) => {
      state.loaded = true;
      state.isTesting = false;
      state.shouldBeClosed = true;
      state.connections = state.connections.filter(
        (connection) => connection.id !== payload.connection.id
      );
    },
    testConnectionFailed: (state, { payload }: ConnectionReducerActions) => {
      state.isTesting = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    testSettings: (state) => {
      state.isTesting = true;
      state.activeConnection = {
        ...state.activeConnection,
        status: undefined,
      };
    },
    testSettingsSuccess: (state, { payload }: TestSettingsReducerActions) => {
      state.isTesting = false;
      state.activeConnection = {
        ...state.activeConnection,
        status: payload.status,
      };
    },
    testSettingsFailed: (state, { payload }: ConnectionReducerActions) => {
      state.isTesting = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getConnectionPointers: (state) => {
      state.isFetchingConnectionPointers = true;
      state.pointers = {};
    },
    getConnectionPointersSuccess: (state, { payload }: ConnectionPointerReducerActions) => {
      state.loaded = true;
      state.isFetchingConnectionPointers = false;
      state.pointers = payload.pointers;
    },
    getConnectionPointersFailed: (state, { payload }: ConnectionPointerReducerActions) => {
      state.isFetchingConnectionPointers = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    setActiveConnection: (state, { payload }: ConnectionReducerActions) => {
      state.activeConnection = payload.connection;
    },
    updateActiveConnection: () => {},
    updateActiveConnectionPayload: (state, { payload }: ConnectionReducerActions) => {
      state.activeConnection = { ...state.activeConnection, ...payload.connection };
    },
    updateActiveConnectionError: (state, { payload }: ConnectionReducerActions) => {
      state.updateActiveConnectionError = payload.errorDetails;
    },
    cleanActiveConnection: (state) => {
      state.activeConnection = { ...INITIAL_SOURCE_STATE.activeConnection };
    },
  },
});
export default connectionsSlice;
