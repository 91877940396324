import { AudienceReadModel } from '@features/audiences/ducks/api/audienceBackTypes/audience';
import { AudienceSpecificationType } from '@features/audiences/ducks/api/audienceBackTypes/audienceSpecification';
import { caseNever } from '@utils/case-never';

export function mapBackendAudienceSpecificationToDataSources(
    specification: AudienceReadModel['specification'],
) {
    switch (specification.type) {
        case AudienceSpecificationType.default: {
            return [
                ...specification.sources.unioned.map(({ source }) => source),
                ...specification.sources.joined.map(({ source }) => source),
            ];
        }
        case AudienceSpecificationType.sql: {
            return specification.sources.map(({ source }) => source);
        }
        default:
            return caseNever(specification);
    }
}