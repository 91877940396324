import {
  ActiveSQLSourceConfiguration,
  Column,
  columnShapeTypeEnum,
  retrievalModeEnum,
} from '../../../../types';
import { SqlAudienceSource } from '../../audienceBackTypes/audienceSpecification';
import { DataSourceType } from '../../audienceBackTypes/source';
import { mappingSelectedColumnsProperty } from './mappingSelectedColumnsProperty';
import { mappingSourcePreparationToFront } from './mappingJoinedSources';
import {
  mapSelectedEntity,
  mapSelectedEntityOfAudienceAsSource,
  mapSelectedEntityOfSyncAsSource,
} from './mappingSelectedEntity';
import { caseNever } from '@utils/case-never';
import {
  mapBackendAudienceDatasourceToActiveDataSource,
  mapBackendConnectionDatasourceToActiveDataSource,
  mapBackendSyncDatasourceToActiveDataSource,
  mapBackendWebhookDatasourceToActiveDataSource,
} from './mapDatasource';
import { mappingColumnTypePropertyToFront } from './mappingColumnTypePropertyToFront';
import { mapSelectedColumnsPropertyForWebhookSource } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mappingSelectedColumnsPropertyForWebhookSource';

// Mapping unioned sources back type to sources configuration front type
export const mapSqlSourceFromBackToFront = (
  sourcesBackType: SqlAudienceSource[]
): ActiveSQLSourceConfiguration[] => {
  // Add sources columns to the audience mapping for the front type
  return sourcesBackType.map((backSourceConfig, index): ActiveSQLSourceConfiguration => {
    // const sourceConfigurationKey: string = generateId();

    switch (backSourceConfig.source.type) {
      case DataSourceType.audience: {
        // Prepare dataSource and source configuration for audience as source types
        const dataSource = mapBackendAudienceDatasourceToActiveDataSource(backSourceConfig.source);

        return {
          columns: [],
          dataSource,
          key: backSourceConfig.source.audienceId,
          selectedColumns: [], // @@@Todo:we should get the selected columns list for the audience as source case
          retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
          // @@@Todo: we should get audience as source primary keys
          deduplicationSettings: {
            deduplicationKeys: [],
          },
          preparations: [],
          selectedEntity: mapSelectedEntityOfAudienceAsSource(backSourceConfig.source),
        };
      }
      case DataSourceType.connection: {
        // Prepare dataSource and source configuration for connection source types
        const dataSource = mapBackendConnectionDatasourceToActiveDataSource(
          backSourceConfig.source
        );

        return {
          columns: [],
          dataSource,
          key: backSourceConfig.source.id,
          selectedColumns: mappingSelectedColumnsProperty(backSourceConfig.source.columns), // @@@Todo: we should maybe add isNullable and shapeType property for the source columns ( shapeType = 'default' | 'static' )
          retrievalMode: retrievalModeEnum.INCREMENTAL_MODE, // @@@Note: property exists only in front we will fake it for now
          deduplicationSettings: {
            deduplicationKeys: backSourceConfig.source.deduplicationSettings.key.columnIds,
          },
          preparations: mappingSourcePreparationToFront(backSourceConfig.source.preparations),
          selectedEntity: mapSelectedEntity(backSourceConfig.source.sourceSettings),
        };
      }
      case DataSourceType.sync: {
        // Prepare dataSource and source configuration for connection source types
        const dataSource = mapBackendSyncDatasourceToActiveDataSource(backSourceConfig.source);

        return {
          columns: [],
          dataSource,
          key: backSourceConfig.source.syncId,
          selectedColumns: [
            ...backSourceConfig.source.columns.map(
              (col): Column => ({
                id: col.id,
                name: col.name,
                externalName: col.fieldId,
                type: mappingColumnTypePropertyToFront(col.type),
                shapeType: columnShapeTypeEnum.DEFAULT,
                isNullable: true, // TODO @@@@koralex [incorrect]
              })
            ),
          ],
          retrievalMode: retrievalModeEnum.INCREMENTAL_MODE, // @@@Note: property exists only in front we will fake it for now
          deduplicationSettings: {
            deduplicationKeys: [backSourceConfig.source.masterIdColumnId],
          },
          preparations: [],
          selectedEntity: mapSelectedEntityOfSyncAsSource(backSourceConfig.source),
        };
      }
      case DataSourceType.webhook: {
        const dataSource = mapBackendWebhookDatasourceToActiveDataSource(backSourceConfig.source);
        return {
          dataSource,
          columns: [],
          key: backSourceConfig.source.id,
          selectedColumns: mapSelectedColumnsPropertyForWebhookSource(
            backSourceConfig.source.columns
          ),
          retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
          preparations: mappingSourcePreparationToFront(backSourceConfig.source.preparations),
        };
      }
      default:
        caseNever(backSourceConfig.source);
    }

    throw new Error('Mapping case is not handled');
  });
};
