import { OctoRecordsFilter } from '@features/filter/types';
import { BusinessCategoryEnum } from '@features/objects/types';
import { buildTypesEnum } from '../enums/buildTypesEnum';
import { MappingRow } from '../MappingRow';
import {
  ActiveSourceConfiguration,
  AudienceDeduplicateSettings,
  DefaultColumn,
  PreparationStep,
  ActiveParentAudience,
  DataApiPreparation,
} from '@features/audiences/types';
import { ActiveSourceDescription } from './ActiveSourceDescription';
import { LatestExecution } from '../AudienceExecution/AudienceExecution';
import { ActiveSQLSourceConfiguration } from '@features/audiences/types/ActiveAudience/ActiveSQLSourceConfiguration';
import { Deployment } from '@contracts/Deployment';
import { Schedule } from '@contracts/schedule';
import { BusinessModel } from '../../../audiences/ducks/api/audienceBackTypes/BusinessModel';

export type ActiveAudienceBase = {
  key: string;
  version: number;
  name?: string;
  longName?: string;
  category?: BusinessCategoryEnum;
  deduplicationSettings: AudienceDeduplicateSettings;
  immutable: string[];
  dataApiEnabled: boolean;
  deployment: Deployment | null;
  latestExecution?: LatestExecution;
  schedule: Schedule;
};

export type ActiveAudience = ActiveDefaultAudience | ActiveSQLAudience;

export type ActiveDefaultAudience = ActiveAudienceBase & {
  buildType?: buildTypesEnum.BUSINESS;
  filter?: OctoRecordsFilter;
  sources: ActiveSourceConfiguration[];

  parent: ActiveParentAudience | null;
  preparationSteps: PreparationStep[];
  mappingRows: MappingRow[];
  sourceDescription?: ActiveSourceDescription;
  nonNullableAtMergeColumns?: string[];
  // Data-API Settings
  dataApiMandatoryColumns?: string[];
  dataApiProtectedColumns?: string[];
  dataApiPreparations?: DataApiPreparation[];
  businessModel: BusinessModel;
};

export enum SqlMode {
  query = 'query',
  statements = 'statements',
}

export type ActiveSQLAudience = ActiveAudienceBase & {
  sqlQuery?: string;
  buildType?: buildTypesEnum.DATA;
  sources: ActiveSQLSourceConfiguration[];
  modifiedAtColumnId?: string;
  mode: SqlMode;
  columns: DefaultColumn[];
};

export interface ActiveSQLAudienceSettings {
  mode: SqlMode;
  category?: BusinessCategoryEnum;
  deduplicationSettings: AudienceDeduplicateSettings;
  immutable: string[];
  dataApiEnabled: boolean;
  modifiedAtColumn?: string;
  interval?: string;
}
