import {
  ActiveParentAudience,
  ActiveParentAudienceCondition,
} from '@features/audiences/types/ActiveAudience/ActiveParentAudience';
import {
  ParentAudience,
  ParentAudienceConditionType,
} from '@features/audiences/ducks/api/audienceBackTypes/ParentAudience';
import { generateId } from '@utils/helpers';

export const mappingParentAudienceToFront = (
  parentAudience: ParentAudience | null
): ActiveParentAudience | null => {
  if (parentAudience) {
    const frontConditions: ActiveParentAudienceCondition[] = [];

    parentAudience.conditions.forEach((condition) => {
      switch (condition.type) {
        case ParentAudienceConditionType.regular:
          const newColumns = condition.columns.map((col) => {
            return {
              key: generateId(),
              childColumnId: col.childColumnId,
              parentColumnId: col.parentColumnId,
            };
          });
          frontConditions.push({
            key: generateId(),
            type: ParentAudienceConditionType.regular,
            columns: newColumns,
          });
          break;
        case ParentAudienceConditionType.source_id_list:
          frontConditions.push({
            key: generateId(),
            type: ParentAudienceConditionType.source_id_list,
            childColumnId: condition.childColumnId,
            parentSourceId: condition.parentSourceId,
            parentSourceIdColumnId: condition.parentSourceIdColumnId,
          });
          break;
      }
    });
    return {
      audienceId: parentAudience.audienceId,
      join: parentAudience.join,
      conditions: frontConditions,
      draftSourceId: parentAudience.draftSourceId,
    };
  }
  return null;
};
