import { Object as ToolBeltObject } from 'ts-toolbelt';

import { MAX_COLUMN_NAME_LENGTH } from '@features/audiences/helpers/constants/constants';
import { Column, columnShapeTypeEnum } from '@features/audiences/types';

function randomString(length: number, chars = '0123456789abcdefghijklmnopqrstuvwxyz') {
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export function shortenColumnName(name: string, nameLength: number = MAX_COLUMN_NAME_LENGTH): string {
    if (name.length <= nameLength) {
        return name;
    }
    const randomSuffixLength = 2;

    const cutName = name.substring(0, (nameLength || MAX_COLUMN_NAME_LENGTH) - randomSuffixLength);

    return `${cutName}${randomString(randomSuffixLength)}`
}

export function getColumnNameForComparison (col: ToolBeltObject.P.Pick<Column, ['shapeType' | 'externalName' | 'name']>): string {
    return col.shapeType === columnShapeTypeEnum.DEFAULT && col.externalName ? col.externalName : col.name;
}
