import {
  ActiveBaseSourceConfiguration,
  ActiveDefaultAudience,
  Column,
  columnShapeTypeEnum,
  dataTypesEnum,
  PreparationActionType,
  SourcePreparation,
  sourceTypesEnum,
} from '@features/audiences/types';
import {
  JoinedAudienceSource,
  JoinedAudienceSourceConditionType,
  UnionedAudienceSource,
} from '../../../audienceBackTypes/audienceSpecification';
import {
  DataSource,
  DataSourceType,
  SourceColumn,
  SourceColumnRetrievalType,
  StaticSourceColumn,
  SyncDataSourceColumn,
  ValueSourceColumn,
} from '../../../audienceBackTypes/source';
import { mappingSourceSettingsProperty } from './mappingSourceSettingsProperty';
import { mappingColumnTypePropertyToBack } from './mappingColumnTypePropertyToBack';
import { BackSourcePreparation } from '../../../audienceBackTypes/backColumnPreparationStep';
import { caseNever } from '@utils/case-never';
import { ScheduleType } from '@contracts/schedule';
import { BusinessCategoryEnum } from '@features/objects/types';
import { AudienceSourceTriggerType } from '../../../audienceBackTypes/AudienceSourceExecutionTrigger';

export type SourceConfigurationIdsToSourceIds = {
  [sourceConfigurationId: string]: string;
};

export function mapBaseSourceConfigToDataSource(
  sourceConfig: ActiveBaseSourceConfiguration
): DataSource {
  switch (sourceConfig.dataSource.type) {
    case sourceTypesEnum.CONNECTION: {
      return {
        type: DataSourceType.connection,
        id: sourceConfig.key,
        name: sourceConfig.dataSource.name || '',
        connectionId: sourceConfig.dataSource.connection?.id || '',
        modifiedAtColumnId: sourceConfig.dataSource.updatedAtColumnId || '',
        // Could cause error in case business category is undefined
        businessCategory: sourceConfig.dataSource.category as BusinessCategoryEnum,
        columns: mappingSelectedColumnsProperty(sourceConfig.selectedColumns),
        sourceSettings: mappingSourceSettingsProperty(sourceConfig.dataSource.sourceSettings),
        deduplicationSettings: {
          key: { columnIds: sourceConfig.deduplicationSettings?.deduplicationKeys || [] },
        },
        schedule: sourceConfig.dataSource.schedule || { type: ScheduleType.none },
        preparations: mappingSourcePreparationToBack(sourceConfig.preparations),
        draftSourceId: sourceConfig.dataSource.draftSourceId,
        modifiedAtOptions: sourceConfig.dataSource.modifiedAtOptions,
        modifiedAtFormat: sourceConfig.dataSource.modifiedAtFormat,
      };
    }
    case sourceTypesEnum.AUDIENCE: {
      return {
        type: DataSourceType.audience,
        audienceId: sourceConfig.dataSource.audienceId || sourceConfig.key,
        draftSourceId: sourceConfig.dataSource.draftSourceId,
        executionTrigger: sourceConfig.dataSource.executionTrigger ?? {
          type: AudienceSourceTriggerType.realTime,
        },
      };
    }
    case sourceTypesEnum.SYNC: {
      const masterIdColumnId = sourceConfig.dataSource.masterIdColumnId ?? '';
      const modifiedAtColumnId = sourceConfig.dataSource.updatedAtColumnId ?? '';
      const selectedColumns = sourceConfig.selectedColumns.filter(
        ({ id }) => ![masterIdColumnId, modifiedAtColumnId].includes(id)
      );
      return {
        type: DataSourceType.sync,
        syncId: sourceConfig.dataSource.syncId || sourceConfig.key,
        draftSourceId: sourceConfig.dataSource.draftSourceId,
        masterIdColumnId: masterIdColumnId,
        modifiedAtColumnId: modifiedAtColumnId,
        columns: mappingSelectedColumnsToSyncColumnsProperty(selectedColumns),
      };
    }
    case sourceTypesEnum.WEBHOOK: {
      return {
        id: sourceConfig.dataSource.id,
        type: DataSourceType.webhook,
        webhookId: sourceConfig.dataSource.webhookId || sourceConfig.key,
        filterExpression: sourceConfig.dataSource.filterExpression,
        columns: sourceConfig.dataSource.columns,
        keyColumnIds: sourceConfig.dataSource.keyColumnIds || [],
        modifiedAtColumnId: sourceConfig.dataSource.updatedAtColumnId,
        preparations: mappingSourcePreparationToBack(sourceConfig.preparations),
        draftSourceId: sourceConfig.dataSource.draftSourceId,
      };
    }

    default:
      return caseNever(sourceConfig.dataSource);
  }
}

export const mappingSourcesProperty = (
  audience: ActiveDefaultAudience
): {
  unioned: UnionedAudienceSource[];
  joined: JoinedAudienceSource[];
  sourceConfigurationIdsToSourceIds: SourceConfigurationIdsToSourceIds;
} => {
  const backSourcesConfig: {
    unioned: UnionedAudienceSource[];
    joined: JoinedAudienceSource[];
  } = { joined: [], unioned: [] };

  let sourceConfigurationIdsToSourceIds: SourceConfigurationIdsToSourceIds = {};

  audience.sources.forEach((sourceConfig) => {
    // UNION and source is AUDIENCE
    if (
      sourceConfig.dataType === dataTypesEnum.UNION &&
      sourceConfig.dataSource.type === sourceTypesEnum.AUDIENCE
    ) {
      backSourcesConfig.unioned.push({
        source: mapBaseSourceConfigToDataSource(sourceConfig),
        columnsMapping: [],
      });
      sourceConfigurationIdsToSourceIds = {
        ...sourceConfigurationIdsToSourceIds,
        [sourceConfig.key]: sourceConfig.dataSource.audienceId || sourceConfig.key,
      };
    }
    // UNION and source is CONNECTION
    if (
      sourceConfig.dataType === dataTypesEnum.UNION &&
      sourceConfig.dataSource.type === sourceTypesEnum.CONNECTION
    ) {
      backSourcesConfig.unioned.push({
        source: mapBaseSourceConfigToDataSource(sourceConfig),
        columnsMapping: [],
      });
      sourceConfigurationIdsToSourceIds = {
        ...sourceConfigurationIdsToSourceIds,
        [sourceConfig.key]: sourceConfig.key,
      };
    }
    // JOIN and source is AUDIENCE
    if (
      sourceConfig.dataType === dataTypesEnum.JOIN &&
      sourceConfig.dataSource.type === sourceTypesEnum.AUDIENCE
    ) {
      backSourcesConfig.joined.push({
        source: mapBaseSourceConfigToDataSource(sourceConfig),
        condition: { type: JoinedAudienceSourceConditionType.regular, columns: [] },
        aggregatedColumns: [],
      });
      sourceConfigurationIdsToSourceIds = {
        ...sourceConfigurationIdsToSourceIds,
        [sourceConfig.key]: sourceConfig.dataSource.audienceId || sourceConfig.key,
      };
    }
    // JOIN and source is CONNECTION
    if (
      sourceConfig.dataType === dataTypesEnum.JOIN &&
      sourceConfig.dataSource.type === sourceTypesEnum.CONNECTION
    ) {
      backSourcesConfig.joined.push({
        source: mapBaseSourceConfigToDataSource(sourceConfig),
        condition: { type: JoinedAudienceSourceConditionType.regular, columns: [] },
        aggregatedColumns: [],
      });
      sourceConfigurationIdsToSourceIds = {
        ...sourceConfigurationIdsToSourceIds,
        [sourceConfig.key]: sourceConfig.key,
      };
    }
    // UNION and source is SYNC
    if (
      sourceConfig.dataType === dataTypesEnum.UNION &&
      sourceConfig.dataSource.type === sourceTypesEnum.SYNC
    ) {
      backSourcesConfig.unioned.push({
        source: mapBaseSourceConfigToDataSource(sourceConfig),
        columnsMapping: [],
      });
      sourceConfigurationIdsToSourceIds = {
        ...sourceConfigurationIdsToSourceIds,
        [sourceConfig.key]: sourceConfig.dataSource.syncId || sourceConfig.key,
      };
    }
    // UNION and source is SYNC
    if (
      sourceConfig.dataType === dataTypesEnum.JOIN &&
      sourceConfig.dataSource.type === sourceTypesEnum.SYNC
    ) {
      backSourcesConfig.joined.push({
        source: mapBaseSourceConfigToDataSource(sourceConfig),
        condition: { type: JoinedAudienceSourceConditionType.regular, columns: [] },
        aggregatedColumns: [],
      });
      sourceConfigurationIdsToSourceIds = {
        ...sourceConfigurationIdsToSourceIds,
        [sourceConfig.key]: sourceConfig.dataSource.syncId || sourceConfig.key,
      };
    }
    // WEBHOOK
    if (
      sourceConfig.dataType === dataTypesEnum.UNION &&
      sourceConfig.dataSource.type === sourceTypesEnum.WEBHOOK
    ) {
      backSourcesConfig.unioned.push({
        source: mapBaseSourceConfigToDataSource(sourceConfig),
        columnsMapping: [],
      });
      sourceConfigurationIdsToSourceIds = {
        ...sourceConfigurationIdsToSourceIds,
        [sourceConfig.key]: sourceConfig.key,
      };
    }
    if (
      sourceConfig.dataType === dataTypesEnum.JOIN &&
      sourceConfig.dataSource.type === sourceTypesEnum.WEBHOOK
    ) {
      backSourcesConfig.joined.push({
        source: mapBaseSourceConfigToDataSource(sourceConfig),
        condition: { type: JoinedAudienceSourceConditionType.regular, columns: [] },
        aggregatedColumns: [],
      });
      sourceConfigurationIdsToSourceIds = {
        ...sourceConfigurationIdsToSourceIds,
        [sourceConfig.key]: sourceConfig.key,
      };
    }
  });
  return { ...backSourcesConfig, sourceConfigurationIdsToSourceIds };
};
const mappingSelectedColumnsProperty = (selectedColumns: Column[]): SourceColumn[] =>
  selectedColumns.map((column) => {
    switch (column.shapeType) {
      case columnShapeTypeEnum.STATIC:
        const staticSourceColumn: StaticSourceColumn = {
          retrievalType: SourceColumnRetrievalType.static,
          id: column.id,
          name: column.name,
          type: mappingColumnTypePropertyToBack(column.type),
          castTo: column.castTo && mappingColumnTypePropertyToBack(column.castTo),
          value: column.value,
        };
        return staticSourceColumn;

      case columnShapeTypeEnum.DEFAULT:
        const valueSourceColumn: ValueSourceColumn = {
          retrievalType: SourceColumnRetrievalType.value,
          id: column.id,
          name: column.name,
          externalName: column.externalName,
          castTo: column.castTo && mappingColumnTypePropertyToBack(column.castTo, column.format),
          type: mappingColumnTypePropertyToBack(column.type),
        };
        return valueSourceColumn;
    }
  });
const mappingSelectedColumnsToSyncColumnsProperty = (
  selectedColumns: Column[]
): SyncDataSourceColumn[] =>
  selectedColumns.map((column) => {
    switch (column.shapeType) {
      case columnShapeTypeEnum.STATIC:
        throw new Error('Static column is not supported in sync data source');

      case columnShapeTypeEnum.DEFAULT:
        return {
          id: column.id,
          name: column.name,
          type: mappingColumnTypePropertyToBack(column.type),
          fieldId: column.externalName,
        };
      default:
        return caseNever(column);
    }
  });

export const mappingSourcePreparationToBack = (
  frontPreparations: SourcePreparation[]
): BackSourcePreparation[] => {
  return frontPreparations.map((preparation) => {
    if (preparation.type === PreparationActionType.custom_function) {
      return {
        type: PreparationActionType.custom_function,
        function: {
          name: preparation.function.name,
          expectedType: mappingColumnTypePropertyToBack(preparation.function.expectedType),
        },
        column: preparation.column,
      };
    }
    return preparation;
  });
};
