import { PreparationActionType, PreparationStep } from '@features/audiences/types';
import { caseNever } from '@utils/case-never';

export const getPreparationsTargetAudienceColumnsIds = (
  preparations: PreparationStep[]
): string[] => {
  const audiencePrepColumn: string[] = [];
  preparations.forEach((preparation) => {
    const preparationType = preparation.configuration.type;
    switch (preparationType) {
      case PreparationActionType.copy_columns:
      case PreparationActionType.test_string_column_values_with_regex:
      case PreparationActionType.format_timestamps_as_strings:
      case PreparationActionType.check_emails_validity:
      case PreparationActionType.extract_json_by_path:
      case PreparationActionType.hash_values:
      case PreparationActionType.combine_values:
      case PreparationActionType.custom_sql:
      case PreparationActionType.generate_current_timestamp_columns:
      case PreparationActionType.concat_values_as_string:
      case PreparationActionType.conditional_statement_sql:
        preparation.configuration.columns.forEach((x) => {
          audiencePrepColumn.push(x.targetColumnId);
        });
        break;
      case PreparationActionType.split_string_column_values_to_array_columns:
        preparation.configuration.columns.splitStringColumnValuesList.forEach((x) =>
          audiencePrepColumn.push(x.targetColumnId)
        );
        break;
      case PreparationActionType.delete_columns:
      case PreparationActionType.format_as_boolean:
      case PreparationActionType.keep_columns:
      case PreparationActionType.rename_columns:
      case PreparationActionType.format_as_string:
      case PreparationActionType.custom_function:
      case PreparationActionType.replace_string_column_values_with_null:
      case PreparationActionType.trim_string_column_values:
      case PreparationActionType.format_phone_numbers:
      case PreparationActionType.change_strings_cases:
      case PreparationActionType.round_numbers:
      case PreparationActionType.truncate_timestamps:
      case PreparationActionType.filter_by_flags:
      case PreparationActionType.find_replace_in_strings:
        break;
      default:
        caseNever(preparationType);
    }
  });
  return audiencePrepColumn;
};
