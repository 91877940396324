import { AudienceData, DeployedAudience } from '@features/audiences/types/DeployedAudience';
import { Connection, ConnectorType } from '@features/connections/types';
import { ActiveSync, Sync, SyncExecution, SyncsTableRecord, DeployedSync } from '../types';
import {
  GetDestinationEntitiesInput,
  GetDestinationEntitiesOutput,
} from '../types/DestinationEntity';
import { DestinationSchema, GetDestinationSchemaInput } from '../types/DestinationSchema';
import {
  GetDestinationTriggersInput,
  GetDestinationTriggersOutput,
} from '../types/DestinationTriggers';
import { IDatabaseDestinationSettings } from '../types/IDestinationSettings';
import {
  AlterDatabaseSyncTableInput,
  CreateDatabaseSyncTableInput,
  ValidateDatabaseSyncTableInput,
} from '../types/DatabaseSyncTableCommandsInputs';

import { GetApiConnectionEntitySchemaInput } from '../types/ApiDestinationSchema';
import { DestinationEnum, IDestination } from '../types/Destination';

export interface ApiSyncsPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  syncs: Sync[];
}
export interface ApiGetSyncsTableListPayload {
  syncsTableRecords: SyncsTableRecord[];
  error: boolean;
  errorDetails: string | undefined;
  loaded: boolean;
}

export interface ActiveSyncPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  activeSync: ActiveSync;
}

export interface GetDestinationTriggersPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  destinationTriggers?: GetDestinationTriggersOutput;
}
export interface CreateDatabaseSyncTablePayload {
  loaded: boolean;
  isFetching?: boolean;
  errorDetails?: string | undefined;
  errors: object[];
  success: boolean;
}
export interface AlterDatabaseSyncTablePayload {
  loaded: boolean;
  isFetching?: boolean;
  errorDetails?: string | undefined;
  errors: object[];
  success: boolean;
  modified: boolean;
}
export interface ValidateDatabaseSyncTablePayload {
  loaded: boolean;
  isFetching?: boolean;
  errorDetails?: string | undefined;
  errors: object[];
  success: boolean;
}
export interface PerformDatabaseSyncTableOperationsPayload {
  loaded: boolean;
  isFetching?: boolean;
  errorDetails?: string | undefined;
  errors?: object[];
  success: boolean;
  processFinished: boolean;
}

export interface ReducerAllSyncsActions {
  type: string;
  payload: ApiSyncsPayload;
}
export interface ReducerGetSyncsTableListActions {
  type: string;
  payload: { errorDetails?: string; syncsTableRecords: SyncsTableRecord[] };
}

export interface GetSourceRecordsObject {
  type: string;
  payload: { connection: Connection; pointer: string };
}

export interface APISyncPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  sync?: Sync;
}
export interface APIDeployedSyncPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  deployedSync?: DeployedSync;
}

export interface APIDeleteSyncPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  syncId: string;
}
export interface APIActivationSyncPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  status: boolean;
  syncId: string;
}
export interface APIRequestSyncExecutionPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  executionId?: string;
  syncId?: string;
}

export interface ReducerSyncActions {
  type: string;
  payload: APISyncPayload & {
    connectionsDetails: { [conId: string]: Connection };
    deployedAudiencesDetails: { [audId: string]: DeployedAudience };
  };
}
export interface ReducerDeleteSyncAction {
  type: string;
  payload: APIDeleteSyncPayload;
}
export interface ReducerActivationSyncAction {
  type: string;
  payload: APIDeleteSyncPayload;
}
export interface ReducerInitSyncEditionScreenAction {
  type: string;
  payload: APISyncPayload;
}
export interface ReducerExecuteSyncAction {
  type: string;
  payload: APIRequestSyncExecutionPayload;
}
export interface ReducerActiveSyncActions {
  type: string;
  payload: ActiveSyncPayload;
}

export interface ReducerGetDestinationTriggersActions {
  type: string;
  payload: GetDestinationTriggersPayload;
}

export interface ReducerCreateDatabaseSyncTableIActions {
  type: string;
  payload: CreateDatabaseSyncTablePayload;
}

export interface ReducerAlterDatabaseSyncTableIActions {
  type: string;
  payload: AlterDatabaseSyncTablePayload;
}
export interface ReducerValidateDatabaseSyncTableIActions {
  type: string;
  payload: ValidateDatabaseSyncTablePayload;
}

export interface ReducerPerformDatabaseSyncTableOperationsIActions {
  type: string;
  payload: PerformDatabaseSyncTableOperationsPayload;
}

export interface SyncAudienceDataReducerActions {
  type: string;
  payload: ApiGetSyncAudienceDataPayload;
}
export interface ApiGetSyncAudienceDataPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  audienceData: AudienceData;
}

export type APIGetSyncs = (
  accountId: string,
  abortSignal?: AbortSignal
) => Promise<ApiSyncsPayload>;
export type APIGetSyncsTableList = (
  accountId: string,
  abortSignal?: AbortSignal
) => Promise<ApiGetSyncsTableListPayload>;
export type APIGetSync = (syncId: string) => Promise<APISyncPayload>;
export type APIGetDeployedSync = (syncId: string) => Promise<APIDeployedSyncPayload>;
export type APISaveSync = (
  sync: ActiveSync,
  accountId: string,
  dataWarehouseId: string
) => Promise<APISyncPayload>;
export type APIUpdateSync = (
  sync: ActiveSync,
  accountId: string,
  dataWarehouseId: string
) => Promise<APISyncPayload>;

export type APIGetDestinationSchema = (
  getDestinationSchemaInput: GetDestinationSchemaInput
) => Promise<DestinationSchema>;
export type APIGetAPIDestinationSchema = (
  getApiDestinationSchemaInput: GetApiConnectionEntitySchemaInput
) => Promise<DestinationSchema>;
export type APIGetDestinationEntities = (
  getDestinationEntitiesInput: GetDestinationEntitiesInput
) => Promise<GetDestinationEntitiesOutput>;
export type APIGetDestinationTriggers = (
  getDestinationTriggersInput: GetDestinationTriggersInput
) => Promise<GetDestinationTriggersPayload>;
export type APICreateDatabaseSyncTable = (
  createDatabaseSyncTableInput: CreateDatabaseSyncTableInput
) => Promise<CreateDatabaseSyncTablePayload>;
export type APIAlterDatabaseSyncTable = (
  alterDatabaseSyncTableInput: AlterDatabaseSyncTableInput
) => Promise<AlterDatabaseSyncTablePayload>;
export type APIValidateDatabaseSyncTable = (
  input: ValidateDatabaseSyncTableInput
) => Promise<ValidateDatabaseSyncTablePayload>;

export type APIDeleteSync = (id: string) => Promise<APIDeleteSyncPayload>;
export type APIEnableSync = (id: string) => Promise<APIActivationSyncPayload>;
export type APIDisableSync = (id: string) => Promise<APIActivationSyncPayload>;
export type APIRequestSyncExecution = (
  syncExecution: SyncExecution
) => Promise<APIRequestSyncExecutionPayload>;

export interface GetSyncsSaga {
  type: string;
  payload: { abortSignal?: AbortSignal };
}
export interface GetSyncsTableListSaga {
  type: string;
  payload: { abortSignal?: AbortSignal };
}
export interface GetSingleSyncSaga {
  type: string;
  payload: { key: string };
}
export interface SaveSyncSaga {
  type: string;
  payload: { activeSync: ActiveSync };
}

export interface GetDestinationSchemaSaga {
  type: string;
  payload: DestinationSchemaPayload;
}
export interface DeleteSyncSaga {
  type: string;
  payload: DeleteSagaPayload;
}
export type DeleteSagaPayload = {
  id: string;
};
export interface EnableSyncSaga {
  type: string;
  payload: ActivationSyncSagaPayload;
}
export interface DisableSyncSaga {
  type: string;
  payload: ActivationSyncSagaPayload;
}

export type ActivationSyncSagaPayload = {
  id: string;
};
export interface GetSyncToEditSaga {
  syncId: string | null;
}
export interface InitSyncsListScreenSaga {
  type: string;
  payload: InitSyncEditionScreenSagaPayload;
}
export type InitSyncsListScreenSagaPayload = {
  abortSignal?: AbortSignal;
};
export interface InitSyncCreationScreenSaga {
  type: string;
  payload: InitSyncCreationScreenSagaPayload;
}
export type InitSyncCreationScreenSagaPayload = {
  abortSignal?: AbortSignal;
};

export interface InitSyncEditionScreenSaga {
  type: string;
  payload: InitSyncEditionScreenSagaPayload;
}
export type InitSyncEditionScreenSagaPayload = { syncId: string | null; abortSignal?: AbortSignal };

export interface RequestSyncExecutionSaga {
  type: string;
  payload: RequestSyncExecutionPayload;
}
export type RequestSyncExecutionPayload = {
  syncExecutionValue: SyncExecution;
};
export interface GetDestinationEntitiesSaga {
  type: string;
  payload: DestinationEntitiesPayload;
}
export interface GetDestinationTriggersSaga {
  type: string;
  payload: DestinationTriggersPayload;
}
export interface CreateDatabaseSyncTableSaga {
  type: string;
  payload: CreateDatabaseSyncTableSagaPayload;
}
export interface AlterDatabaseSyncTableSaga {
  type: string;
  payload: AlterDatabaseSyncTableSagaPayload;
}
export interface ValidateDatabaseSyncTableSaga {
  type: string;
  payload: ValidateDatabaseSyncTableSagaPayload;
}

export interface PerformDatabaseSyncTableOperationsSaga {
  type: string;
  payload: PerformDatabaseSyncTableOperationsSagaPayload;
}
export type DestinationEntitiesPayload = {
  connectionId: string;
  destinationType: DestinationEnum;
};
export type DestinationTriggersPayload = {
  connectionId: string;
  connectorType: ConnectorType;
};
export type DestinationSchemaPayload = {
  destination: IDestination;
};

export type CreateDatabaseSyncTableSagaPayload = {
  audienceId: string;
  connectionId: string;
  destinationSettings: IDatabaseDestinationSettings;
};
export type AlterDatabaseSyncTableSagaPayload = {
  audienceId: string;
  connectionId: string;
  destinationSettings: IDatabaseDestinationSettings;
};
export type ValidateDatabaseSyncTableSagaPayload = {
  audienceId: string;
  connectionId: string;
  destinationSettings: IDatabaseDestinationSettings;
};

export type PerformDatabaseSyncTableOperationsSagaPayload = {
  audienceId: string;
  connectionId: string;
  destinationSettings: IDatabaseDestinationSettings;
  operation: 'create' | 'alter';
  operationConfirmed?: boolean;
  hasChanges?: boolean;
};
export enum RequestSyncExecutionTypeNames {
  syncExecutionRequestAccepted = 'SyncExecutionRequestAccepted',
  SyncExecutionSyncNotFound = 'SyncExecutionSyncNotFound',
  SyncExecutionSyncNotDeployed = 'SyncExecutionSyncNotDeployed',
  SyncExecutionSyncDisabled = 'SyncExecutionSyncDisabled',
}
