import { CreateSyncCommand } from '@features/syncs/ducks/types/CreateSyncCommand';
import { UpdateSyncCommand } from '@features/syncs/ducks/types/UpdateSyncCommand';
import { Sync } from '@features/syncs/types';
import { SyncModel } from '@features/syncs/types/backTypes/SyncModel';
import { syncToBack } from '../syncToBack';

export const getUpdateSyncCommand = (
  sync: Sync,
  accountId: string,
  dataWarehouseId: string
): UpdateSyncCommand => {
  // Mapping front type to the back type
  const backSyncType: SyncModel = syncToBack(sync, accountId, dataWarehouseId); // Back type

  // Prepare UpdateSyncCommand
  const updateSyncCommand: UpdateSyncCommand = {
    id: backSyncType.id,
    version: backSyncType.version,
    name: backSyncType.name,
    specification: backSyncType.specification,
  };
  return updateSyncCommand;
};
