import { AudienceDeduplicationKey } from '../../../../../../audiences/types';
import { BackAudienceDeduplicationKeys } from '../../../../../ducks/api/audienceBackTypes/audienceSpecification';
import { MASTER_ID_COLUMN_NAME } from '../../../../../helpers/constants/constants';

export const mappingDeduplicationKeysToBack = (
  frontDeduplicationSettings: AudienceDeduplicationKey[]
): BackAudienceDeduplicationKeys => {
  // Get deduplicationSettings from the backend type

  // just adapting having a master id deduplication keys
  if (
    frontDeduplicationSettings.length &&
    frontDeduplicationSettings[0].columnIds.includes(MASTER_ID_COLUMN_NAME)
  ) {
    return 'masterid';
  }
  return frontDeduplicationSettings.map((x) => ({
    columnIds: x.columnIds,
  }));
};
