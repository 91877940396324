import { getApplicationConfiguration } from '@data/app';
import { AccountCapabilities, AccountDetails } from '@features/account/types';

export const prepareFeatures = (capabilities: AccountCapabilities): string[] => {
  const features: string[] = [];
  Object.entries(capabilities).forEach(([key, value]) => {
    if (value) {
      features.push(key);
    }
  });
  return features;
};

export function getVisibleFeatures(accountDetails?: AccountDetails) {
  const organizationName = accountDetails?.partnerId ?? undefined;
  let visibleFeatures = accountDetails?.capabilities
    ? prepareFeatures(accountDetails?.capabilities)
    : [...getApplicationConfiguration(organizationName).visibleFeatures];

  if (!accountDetails?.billingReportUrl) {
    visibleFeatures = visibleFeatures.filter((feature) => feature !== 'billing');
  }
  if (!accountDetails?.customerKnowledgeDashboardUrl) {
    visibleFeatures = visibleFeatures.filter((feature) => feature !== 'dashboard');
  }
  return [...visibleFeatures];
}

export function getAccessibleFeatures(accountDetails?: AccountDetails) {
  const organizationName = accountDetails?.partnerId ?? undefined;
  let accessibleFeatures = accountDetails?.capabilities
    ? prepareFeatures(accountDetails?.capabilities)
    : [...getApplicationConfiguration(organizationName).accessibleFeatures];

  if (!accountDetails?.billingReportUrl) {
    accessibleFeatures = accessibleFeatures.filter((feature) => feature !== 'billing');
  }
  if (!accountDetails?.customerKnowledgeDashboardUrl) {
    accessibleFeatures = accessibleFeatures.filter((feature) => feature !== 'dashboard');
  }
  return [...accessibleFeatures];
}
