import {
  AggregateMappingRow,
  AudienceDataSource,
  columnShapeTypeEnum,
  dataTypesEnum,
  DefaultAudience,
  DefaultColumn,
  DefaultSourceConfiguration,
  MappingRow,
  mappingRowTypesEnum,
  SqlSourceConfiguration,
} from '@features/audiences/types';
import { JoinedAudienceSourceConditionType } from '@features/audiences/ducks/api/audienceBackTypes/audienceSpecification';
import { caseNever } from '@utils/case-never';
import { AudienceColumn } from '@features/audiences/types/AudienceColumn';
import { mapDeployedAudienceColumnToDefaultColumn } from '../ducks/api/mappingAudienceTypes/toFrontType/mapDeployedAudenceShape';
import { DeployedAudience } from '@features/audiences/types/DeployedAudience';
import { uniq } from 'lodash';
import assert from 'assert';

export function getUsedAudienceSourceColumnsIdsInDefaultAudience(
  source: DefaultSourceConfiguration,
  audienceMappingRows: MappingRow[]
): string[] {
  switch (source.dataType) {
    case dataTypesEnum.UNION:
      return source.selectedColumns.map((x) => x.id);
    case dataTypesEnum.JOIN: {
      const columnsFromAggregations = audienceMappingRows
        .filter(
          (mappingRow): mappingRow is AggregateMappingRow =>
            mappingRow.rowType === mappingRowTypesEnum.AGGREGATE_ROW
        )
        .flatMap((mappingRow) => [
          mappingRow.mappedColumns[source.key].aggregate?.sourceColumnId,
          mappingRow.mappedColumns[source.key].aggregate?.filterByColumns.sourceFilterColumnId,
          mappingRow.mappedColumns[source.key].aggregate?.filterByOtherDateColumn
            .sourceFilterDateColumnId,
          mappingRow.mappedColumns[source.key].aggregate?.sort.sourceColumnId,
        ])
        .filter((value): value is string => Boolean(value));

      switch (source.condition.type) {
        case JoinedAudienceSourceConditionType.regular:
          columnsFromAggregations.push(...source.condition.columns.map((x) => x.sourceColumnId));
          break;
        case JoinedAudienceSourceConditionType.source_id_list:
          columnsFromAggregations.push(source.condition.sourceColumnId);
          break;
        default:
          return caseNever(source.condition);
      }

      return uniq(columnsFromAggregations);
    }
    default:
      return caseNever(source);
  }
}

export function getSelectedColumnsForAudienceAsSourceInDefaultAudience(
  source: DefaultSourceConfiguration,
  audience: DefaultAudience,
  deployedAudienceAsSourceColumns: AudienceColumn[]
) {
  const usedColumnsIds = getUsedAudienceSourceColumnsIdsInDefaultAudience(
    source,
    audience.mappingRows
  );

  return usedColumnsIds.reduce((acc: DefaultColumn[], columnId) => {
    const foundColumnInDeployedAudience = deployedAudienceAsSourceColumns.find(
      ({ id }) => id === columnId
    );

    if (!foundColumnInDeployedAudience) {
      console.warn(
        `Column ${columnId} of the Dataset source was not found in the deployed audience`
      );
      return acc;
    }

    acc.push(mapDeployedAudienceColumnToDefaultColumn(foundColumnInDeployedAudience));
    return acc;
  }, []);
}

export function updateAudienceAsSourceColumns(
  source:
    | DefaultSourceConfiguration<AudienceDataSource>
    | SqlSourceConfiguration<AudienceDataSource>,
  deployedAudience: DeployedAudience
) {
  const deployedAudienceColumns = deployedAudience.dataShape.columns;
  const modifiedAtColumnId = deployedAudience.dataShape.systemColumns.modifiedAt;
  const modifiedAtColumn = deployedAudienceColumns.find(({ id }) => id === modifiedAtColumnId);
  const isModifiedAtColumnInTheSelectedColumns = source.selectedColumns.find(
    ({ id }) => id === modifiedAtColumn?.id
  );

  if (modifiedAtColumn && !isModifiedAtColumnInTheSelectedColumns) {
    source.selectedColumns = [
      ...source.selectedColumns,
      {
        externalName: modifiedAtColumn.name,
        id: modifiedAtColumn.id,
        isNullable: !!modifiedAtColumn.defaultValue,
        name: modifiedAtColumn.name,
        shapeType: columnShapeTypeEnum.DEFAULT,
        type: modifiedAtColumn.type,
      },
    ];
  }

  if (modifiedAtColumn && !source.dataSource.updatedAtColumnId) {
    source.dataSource.updatedAtColumnId = modifiedAtColumn.id;
  }

  return source;
}
