export enum DataWarehouseCreationProgress {
  default = 'default',
  databaseCreationStarted = 'databaseCreationStarted',
  databaseCreationEnded = 'databaseCreationEnded',
  dataWarehouseParsingStarted = 'dataWarehouseParsingStarted',
  dataWarehouseParsingEnded = 'dataWarehouseParsingEnded',
  dataWarehouseCreationStarted = 'dataWarehouseCreationStarted',
  dataWarehouseCreationEnded = 'dataWarehouseCreationEnded',
  done = 'done',
}
export const dataWarehouseCreationProgressDetails: {
  [progressStep in DataWarehouseCreationProgress]: { progressValue: number; progressTitle: string };
} = {
  default: { progressValue: 0, progressTitle: 'Start' },
  databaseCreationStarted: { progressValue: 0, progressTitle: 'DB' },
  databaseCreationEnded: { progressValue: 40, progressTitle: 'Created' },
  dataWarehouseParsingStarted: { progressValue: 50, progressTitle: 'Parsing' },
  dataWarehouseParsingEnded: { progressValue: 60, progressTitle: 'parsed' },
  dataWarehouseCreationStarted: { progressValue: 80, progressTitle: 'DWH' },
  dataWarehouseCreationEnded: { progressValue: 80, progressTitle: 'created' },
  done: { progressValue: 100, progressTitle: 'Done' },
};
