import { createSlice } from '@reduxjs/toolkit';
import type { RedirectionReducerActions } from './types';

export interface RedirectionsState {
  isRedirecting?: boolean;
}

export const INITIAL_THEME_STATE = {
  isRedirecting: false,
} as RedirectionsState;

const redirectionsSlice = createSlice({
  name: 'redirections',

  initialState: INITIAL_THEME_STATE,
  reducers: {
    //Redirect source catalog reducers
    redirectToSourceCatalog: (state) => {
      state.isRedirecting = true;
    },
    redirectToSourceCatalogSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    
    //Redicect monitoring reducers
    redirectToMonitoring: (state) => {
      state.isRedirecting = true;
    },
    redirectToMonitoringSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    
    // Redicect account resources executions monitoring reducers
    redirectToAccountResourcesExecutionsMonitoring: (state) => {
      state.isRedirecting = true;
    },
    redirectToAccountResourcesExecutionsMonitoringSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },

    // Redirect connection reducers
    redirectToConnection: (state) => {
      state.isRedirecting = true;
    },
    redirectToConnectionSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },

    // Redirect new syncs reducers
    redirectToNewSyncs: (state) => {
      state.isRedirecting = true;
    },
    redirectToNewSyncsSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    redirectToNewSyncBuilder: (state) => {
      state.isRedirecting = true;
    },
    redirectToNewSyncBuilderSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },

    // Redirect dataset reducers
    redirectToDatasets: (state) => {
      state.isRedirecting = true;
    },
    redirectToDatasetsSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    redirectToDatasetBuilder: (state) => {
      state.isRedirecting = true;
    },
    redirectToDatasetBuilderSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    // Redirect audience reducers

    redirectToAudiences: (state) => {
      state.isRedirecting = true;
    },
    redirectToAudiencesSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },

    redirectToAudiencesCreator: (state) => {
      state.isRedirecting = true;
    },
    redirectToAudiencesCreatorSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },

    redirectToPrepareAudience: (state) => {
      state.isRedirecting = true;
    },
    redirectToPrepareAudienceSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },

    redirectToAudiencesEditor: (state) => {
      state.isRedirecting = true;
    },
    redirectToAudiencesEditorSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },

    redirectToSqlAudienceEditor: (state) => {
      state.isRedirecting = true;
    },
    redirectToSqlAudienceEditorSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },

    redirectToSqlAudienceView: (state) => {
      state.isRedirecting = true;
    },

    redirectToSqlAudienceViewSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },

    // Redirect syncs reducers
    redirectToSyncs: (state) => {
      state.isRedirecting = true;
    },
    redirectToSyncsSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    redirectToSyncsCreator: (state) => {
      state.isRedirecting = true;
    },
    redirectToSyncsCreatorSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },

    redirectToSyncsEditor: (state) => {
      state.isRedirecting = true;
    },
    redirectToSyncsEditorSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    // Account management
    redirectToAccountManagement: (state) => {
      state.isRedirecting = true;
    },
    redirectToAccountManagementSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    // Destination
    redirectToDestination: (state) => {
      state.isRedirecting = true;
    },
    redirectToDestinationSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    // Auth logout
    redirectToLogout: (state) => {
      state.isRedirecting = true;
    },
    redirectToLogoutSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    // Auth account selector
    redirectToAccountSelector: (state) => {
      state.isRedirecting = true;
    },
    redirectToAccountSelectorSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    // Settings account
    redirectToAccountSettings: (state) => {
      state.isRedirecting = true;
    },
    redirectToAccountSettingsSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    // Settings profile
    redirectToProfile: (state) => {
      state.isRedirecting = true;
    },
    redirectToProfileSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    // Settings plan
    redirectToPlan: (state) => {
      state.isRedirecting = true;
    },
    redirectToPlanSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    // Auth account selector
    redirectToDataWarehouseSettings: (state) => {
      state.isRedirecting = true;
    },
    redirectToDataWarehouseSettingsSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    redirectToBilling: (state) => {
      state.isRedirecting = true;
    },
    redirectToBillingSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    redirectToDashboard: (state) => {
      state.isRedirecting = true;
    },
    redirectToDashboardSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
    // Other links
    redirectToLink: (state) => {
      state.isRedirecting = true;
    },
    redirectToLinkSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
    },
  },
});

export default redirectionsSlice;
