import {
  ApiGetAudiencePayload,
  ApiGetDeployedAudiencePayload,
  UpdateAudienceAsSourceDataSaga,
} from '@features/audiences/ducks/types';
import { call } from 'redux-saga/effects';
import { getAudience, getDeployedAudience } from '@features/audiences/ducks/api/api';
import {
  buildTypesEnum,
  dataTypesEnum,
  DefaultSourceConfiguration,
  isAudienceSource,
  sourceTypesEnum,
} from '@features/audiences/types';
import { caseNever } from '@utils/case-never';
import { setMappingRowOfTheJoinCondition } from '../api/mappingAudienceTypes/toFrontType/mappingColumns/mapJoinByColumnsToMappingRows';
import { mapDeployedAudienceColumnsToDefaultColumns } from '../api/mappingAudienceTypes/toFrontType/mapDeployedAudenceShape';
import {
  getSelectedColumnsForAudienceAsSourceInDefaultAudience,
  updateAudienceAsSourceColumns,
} from '@features/audiences/helpers/audienceAsSource';
import { AudienceUnion } from '@features/audiences/types/Audience/Audience';
// used to update all audience as source names, as they are not returned by the API
export const updateNamePropertyOfAudiencesAsSource = (audiences: AudienceUnion[]) => {
  for (const audience of audiences) {
    for (const source of audience.sources) {
      if (source.dataSource.type !== sourceTypesEnum.AUDIENCE) {
        continue;
      }
      const audienceId = source.dataSource.audienceId;
      let foundAudience = audiences.find((audience) => audience.key === audienceId);

      if (!foundAudience) {
        continue;
      }
      source.dataSource.name = foundAudience.longName;
      source.dataSource.identifier = foundAudience.name;
    }
  }
  return audiences;
};

export function* updateAudienceAsSourceData({ payload }: UpdateAudienceAsSourceDataSaga) {
  // TODO @@@@koralex refactor it to make it simpler
  const { audience, abortSignal } = payload;

  for (const source of audience.sources) {
    if (!isAudienceSource(source)) {
      continue;
    }

    const audienceAsSourceId = source.dataSource.audienceId;
    const audienceAsSourceResult: ApiGetAudiencePayload = yield call(
      getAudience,
      audienceAsSourceId
    );
    const audienceAsSource = audienceAsSourceResult.audience;
    if (!audienceAsSource) {
      continue;
    }

    source.dataSource.name = audienceAsSource.longName;
    source.dataSource.identifier = audienceAsSource.name;
    source.deduplicationSettings = {
      deduplicationKeys:
        audienceAsSource.deduplicationSettings.deduplicationKeys[0].columnIds || [],
    };
    source.dataSource.category = audienceAsSource.category;

    const deployedAudienceResult: ApiGetDeployedAudiencePayload = yield call(
      getDeployedAudience,
      source.dataSource.audienceId,
      abortSignal
    );

    if (!deployedAudienceResult.deployedAudience) {
      // TODO @@@@koralex should we throw an error?
      continue;
    }

    switch (audience.buildType) {
      case buildTypesEnum.DATA: {
        switch (audienceAsSource.buildType) {
          case buildTypesEnum.BUSINESS:
          case buildTypesEnum.DATA: {
            source.selectedColumns = mapDeployedAudienceColumnsToDefaultColumns(
              deployedAudienceResult.deployedAudience.dataShape.columns
            );
            break;
          }
          default:
            return caseNever(audienceAsSource);
        }

        updateAudienceAsSourceColumns(source, deployedAudienceResult.deployedAudience);

        break;
      }
      case buildTypesEnum.BUSINESS: {
        switch (audienceAsSource.buildType) {
          case buildTypesEnum.BUSINESS:
          case buildTypesEnum.DATA: {
            const defaultAudienceSource = source as DefaultSourceConfiguration;

            source.selectedColumns = getSelectedColumnsForAudienceAsSourceInDefaultAudience(
              defaultAudienceSource,
              audience,
              deployedAudienceResult.deployedAudience!.dataShape.columns
            );

            if (defaultAudienceSource.dataType === dataTypesEnum.JOIN) {
              audience.mappingRows = setMappingRowOfTheJoinCondition(
                audience.sources,
                defaultAudienceSource,
                audience.mappingRows
              );
            }

            break;
          }
          default:
            return caseNever(audienceAsSource);
        }

        updateAudienceAsSourceColumns(source, deployedAudienceResult.deployedAudience);

        audience.mappingRows = audience.mappingRows.map((mappingRow) => {
          const mappedColumn = mappingRow.mappedColumns[source.key];
          const column = source.selectedColumns.find(({ id }) => id === mappedColumn.columnId);

          return {
            ...mappingRow,
            mappedColumns: {
              ...mappingRow.mappedColumns,
              [source.key]: {
                ...mappedColumn,
                columnName: column ? column.name : mappedColumn.columnName,
                columnType: column ? column.type : mappedColumn.columnType,
              },
            },
          };
        });
        break;
      }
      default:
        return caseNever(audience);
    }
  }

  return audience;
}
