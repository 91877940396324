import {
  ColumnAggregation,
  ColumnAggregationTypeBack,
  DatePeriodFilterSourceColumnType,
} from '@features/audiences/ducks/api/audienceBackTypes/columnAggregations';
import {
  AggregateDetails,
  AggregationType,
  ColumnAggregationSortDirection,
  ColumnAggregationType,
} from '@features/audiences/types';
import { mappingDatePeriodProperty } from './mappingDatePeriodProperty';
import { mappingOperatorProperty } from './mappingOperatorProperty';
import getBackAggregateSort from '@features/audiences/helpers/getBackAggregateSort';

export const mappingAggregateProperty = (aggregation: ColumnAggregation): AggregateDetails => {
  const filterByColumns = aggregation.columnFilter?.sourceColumnId
    ? {
        filterByColumnsStatus: true,
        filterOperator: mappingOperatorProperty(aggregation.columnFilter?.strategy),
        filterOperatorValue: aggregation.columnFilter.strategy.value,
        sourceFilterColumnId: aggregation.columnFilter.sourceColumnId,
      }
    : { filterByColumnsStatus: false };
  const filterByOtherDateColumn =
    aggregation.datePeriodFilter?.sourceColumn.type === DatePeriodFilterSourceColumnType.data
      ? {
          filterByOtherDateColumnStatus: true,
          sourceFilterDateColumnId: aggregation.datePeriodFilter?.sourceColumn.columnId,
        }
      : { filterByOtherDateColumnStatus: false };
  const aggregateBackSort = getBackAggregateSort(aggregation);
  const sort = aggregateBackSort
    ? {
        sortColumnsStatus: true,
        sourceColumnId: aggregateBackSort[0].sourceColumnId,
        direction: aggregateBackSort[0].direction,
      }
    : {
        sortColumnsStatus: false,
      };
  switch (aggregation.type) {
    case ColumnAggregationTypeBack.first_value:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: aggregation.ignoreNull,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.first,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.array:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: aggregation.ignoreNull,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.list,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.count_records:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: false,
        function: ColumnAggregationType.countall,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.count_values:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: false,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.count,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.some:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: false,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.any,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.sum:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: false,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.sum,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.every:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: false,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.every,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.min:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: false,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.min,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.max:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: false,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.max,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.average:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: false,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.average,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.average_difference:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: false,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.average_difference,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    case ColumnAggregationTypeBack.most_frequent_value:
      return {
        filterByColumns,
        filterByOtherDateColumn,
        sort,
        type: AggregationType.column,
        excludeNullValues: false,
        sourceColumnId: aggregation.sourceColumnId,
        function: ColumnAggregationType.most_frequent_value,
        datePeriod: mappingDatePeriodProperty(aggregation.datePeriodFilter),
      };
    default:
      throw new Error(`Aggregation type not found ${aggregation}`);
  }
};
