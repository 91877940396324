import { ConnectorType } from '@features/connections/types';
import { columnTypesEnum } from '@features/objects/types';
import { ApiDestinationSchema } from './ApiDestinationSchema';

export type IPostgresDestinationSchemaSettings = {
  type: ConnectorType.postgres;
  schemaName: string;
  tableName: string;
};

export type IBigQueryDestinationSchemaSettings = {
  type: ConnectorType.bigQuery;
  datasetId: string;
  tableId: string;
  location: string;
};

export type IMySQLDestinationSchemaSettings = {
  type: ConnectorType.mysql;
  schemaName: string;
  tableName: string;
};

export type IDatabaseDestinationSchemaSettings =
  | IPostgresDestinationSchemaSettings
  | IBigQueryDestinationSchemaSettings
  | IMySQLDestinationSchemaSettings;

export type IDestinationSchemaSettings = IDatabaseDestinationSchemaSettings;

// INPUT
export type GetDestinationSchemaInput = {
  accountId: string;
  connectionId: string;
  destinationSchemaSettings: IDestinationSchemaSettings;
};
// OUTPUT Front type

export enum DestinationSchemaType {
  API_SCHEMA = 'apiSchema',
  DB_SCHEMA = 'DBSchema',
}

export type DatabaseFrontColumnDescriptor = {
  name: string;
  supportedTypes: columnTypesEnum[];
};
export interface DatabaseDestinationSchema {
  type: DestinationSchemaType.DB_SCHEMA;
  columnDescriptors: DatabaseFrontColumnDescriptor[];
}

export type DestinationSchema = DatabaseDestinationSchema | ApiDestinationSchema;
