import { Account } from '@features/account/types';

export const accountDefaultConfiguration: Account = {
  name: 'Octolis',
  dbConfig: {
    connection: `connection2`,
    trigram: `xyz`,
  },
  accessibleFeatures: [],
  visibleFeatures: [],
  pureReverseETL: true,
};
