import { DataApiPreparation } from '@features/audiences/types';
import {
  BackDataApiPreparation,
  PreparationActionType,
} from '../../audienceBackTypes/backColumnPreparationStep';
import { mappingColumnTypePropertyToFront } from './mappingColumnTypePropertyToFront';

export const mappingDataApiPreparations = (
  backDataApiPreparation: BackDataApiPreparation[] | undefined
): DataApiPreparation[] => {
  const backSourcePreparations: DataApiPreparation[] = (backDataApiPreparation || []).map(
    (preparation) => {
      if (preparation.type === PreparationActionType.custom_function) {
        return {
          type: PreparationActionType.custom_function,
          function: {
            name: preparation.function.name,
            expectedType: mappingColumnTypePropertyToFront(preparation.function.expectedType),
          },
          column: preparation.column,
        };
      }
      return preparation;
    }
  );

  return backSourcePreparations;
};
