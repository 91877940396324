import { ColumnAggregationType } from '@features/audiences/types';

const canFrontAggregateBeSorted = (aggregateType: ColumnAggregationType) => {
  return (
    aggregateType === ColumnAggregationType.first ||
    aggregateType === ColumnAggregationType.list ||
    aggregateType === ColumnAggregationType.average_difference
  );
};

export default canFrontAggregateBeSorted;
