import {
  PreparationStep,
  PreparationStepType,
} from '@features/audiences/ducks/api/audienceBackTypes/preparationStep';
import { mappingColumnTypePropertyToBack } from '@features/audiences/ducks/api/mappingAudienceTypes/toBackType/mappingAudienceSpecificationProperty/mappingColumnTypePropertyToBack';
import { PreparationActionType } from '@features/audiences/types';
import { generateId } from '@utils/helpers';
import { PreparationStepConfiguration } from '@features/audiences/types/Preparation';
import { caseNever } from '@utils/case-never';

const mappingPreparationStepsToBack = (
  frontPreparations: PreparationStepConfiguration[]
): PreparationStep[] => {
  return frontPreparations.map((frontPreparation) => {
    const frontPreparationType = frontPreparation.type;
    switch (frontPreparationType) {
      case PreparationActionType.generate_current_timestamp_columns:
        return {
          type: PreparationStepType.generate_current_timestamp_columns,
          columns: frontPreparation.columns.map((col) => {
            return {
              targetColumnId: col.targetColumnId,
              targetColumnName: col.targetColumnName,
            };
          }),
        };
      case PreparationActionType.format_as_string:
        return {
          type: PreparationStepType.format_as_string,
          columns: frontPreparation.columns.map((col) => col.originalColumnId),
        };
      case PreparationActionType.filter_by_flags:
        return {
          type: PreparationStepType.filter_by_flags,
          columns: frontPreparation.columns.map((col) => {
            return {
              flagColumnId: col.flagColumnId,
              expectedValue: col.expectedValue ? col.expectedValue : false,
            };
          }),
        };
      case PreparationActionType.format_as_boolean:
        return {
          type: PreparationStepType.format_as_boolean,
          columns: frontPreparation.columns.map((col) => col.originalColumnId),
          onError: frontPreparation.onError,
        };
      case PreparationActionType.combine_values:
        return {
          type: PreparationStepType.combine_values,
          columns: frontPreparation.columns.map((columnsConfig) => {
            return {
              expectedType: mappingColumnTypePropertyToBack(columnsConfig.expectedType),
              targetColumnId: columnsConfig.targetColumnId,
              targetColumnName: columnsConfig.targetColumnName,
              originalColumnIds: columnsConfig.originalColumnIds,
            };
          }),
        };
      case PreparationActionType.concat_values_as_string:
        return {
          type: PreparationStepType.concat_values_as_string,
          columns: frontPreparation.columns.map((col) => {
            return {
              targetColumnId: col.targetColumnId,
              targetColumnName: col.targetColumnName,
              delimiter: col.delimiter,
              originalColumnIds: col.originalColumnIds,
            };
          }),
        };
      case PreparationActionType.hash_values:
        return {
          type: PreparationStepType.hash_values,
          algorithm: frontPreparation.algorithm,
          lowercase: frontPreparation.lowercase,
          columns: frontPreparation.columns.map((col) => {
            return {
              targetColumnId: col.targetColumnId,
              targetColumnName: col.targetColumnName,
              originalColumnIds: col.originalColumnIds,
            };
          }),
        };
      case PreparationActionType.custom_sql:
        return {
          type: PreparationStepType.custom_sql,
          columns: frontPreparation.columns.map((col) => {
            return {
              targetColumnId: col.targetColumnId,
              targetColumnName: col.targetColumnName,
              sqlExpression: col.sqlExpression,
              expectedType: mappingColumnTypePropertyToBack(col.expectedType),
            };
          }),
        };
      case PreparationActionType.custom_function:
        return {
          type: PreparationStepType.custom_function,
          columns: frontPreparation.columns,
          function: {
            name: frontPreparation.function.name,
            expectedType: mappingColumnTypePropertyToBack(frontPreparation.function.expectedType),
          },
        };
      case PreparationActionType.conditional_statement_sql:
        return {
          type: PreparationStepType.conditional_statement_sql,
          columns: frontPreparation.columns.map((col) => {
            return {
              targetColumnId: col.targetColumnId,
              conditionSqlExpression: col.conditionSqlExpression,
              onTrueSqlExpression: col.onTrueSqlExpression,
              onFalseSqlExpression: col.onFalseSqlExpression,
              expectedType: mappingColumnTypePropertyToBack(col.expectedType),
              targetColumnName: col.targetColumnName,
            };
          }),
        };
      case PreparationActionType.copy_columns:
        return {
          type: PreparationStepType.copy_columns,
          columns: frontPreparation.columns.map((col) => {
            return {
              originalColumnId: col.originalColumnId,
              targetColumnId: col.targetColumnId,
              targetColumnName: col.targetColumnName,
            };
          }),
        };
      case PreparationActionType.keep_columns:
        return {
          type: PreparationStepType.keep_columns,
          columns: frontPreparation.columns.map((col) => col.originalColumnId),
        };
      case PreparationActionType.delete_columns:
        return {
          type: PreparationStepType.delete_columns,
          columns: frontPreparation.columns.map((col) => col.originalColumnId),
        };
      case PreparationActionType.rename_columns:
        return {
          type: PreparationStepType.rename_columns,
          columns: frontPreparation.columns.map((col) => {
            return {
              originalColumnId: col.originalColumnId,
              targetColumnName: col.targetColumnName,
            };
          }),
        };
      case PreparationActionType.change_strings_cases:
        return {
          type: PreparationStepType.change_strings_cases,
          columns: frontPreparation.columns.map((col) => {
            return {
              columnId: col.columnId,
              stringCase: col.stringCase,
            };
          }),
        };
      case PreparationActionType.find_replace_in_strings:
        return {
          type: PreparationStepType.find_replace_in_strings,
          columns: frontPreparation.columns.map((col) => {
            return {
              columnId: col.columnId,
              isCaseSensitive: col.isCaseSensitive || false,
              find: col.find,
              replace: col.replace,
            };
          }),
        };
      case PreparationActionType.replace_string_column_values_with_null:
        return {
          type: PreparationStepType.replace_string_column_values_with_null,
          columns: frontPreparation.columns,
        };
      case PreparationActionType.split_string_column_values_to_array_columns:
        return {
          type: PreparationStepType.split_string_column_values_to_array_columns,
          columns: frontPreparation.columns.splitStringColumnValuesList.map((c) => ({
            delimiter: frontPreparation.columns.delimiter,
            originalColumnId: c.originalColumnId,
            targetColumnId: c.targetColumnId,
            targetColumnName: c.targetColumnName,
          })),
        };
      case PreparationActionType.test_string_column_values_with_regex:
        return {
          type: PreparationStepType.test_string_column_values_with_regex,
          columns: frontPreparation.columns,
        };
      case PreparationActionType.trim_string_column_values:
        return {
          type: PreparationStepType.trim_string_column_values,
          columns: frontPreparation.columns,
        };
      case PreparationActionType.format_timestamps_as_strings:
        return {
          type: PreparationStepType.format_timestamps_as_strings,
          columns: frontPreparation.columns,
        };
      case PreparationActionType.round_numbers:
        return {
          type: PreparationStepType.round_numbers,
          columns: frontPreparation.columns.map((columnsConfig) => ({
            columnId: columnsConfig.columnId,
            decimals: columnsConfig.decimals,
          })),
        };
      case PreparationActionType.format_phone_numbers:
        return {
          type: PreparationStepType.format_phone_numbers,
          columns: frontPreparation.columns,
          prefix: frontPreparation.prefix,
          output: frontPreparation.output,
        };

      case PreparationActionType.extract_json_by_path:
        return {
          type: PreparationStepType.extract_json_by_path,
          columns: frontPreparation.columns.map((col) => {
            return {
              originalColumnId: col.originalColumnId,
              path: col.path,
              targetColumnId: col.targetColumnId,
              targetColumnName: col.targetColumnName,
              outputType: col.outputType
                ? mappingColumnTypePropertyToBack(col.outputType)
                : undefined,
            };
          }),
        };

      case PreparationActionType.check_emails_validity:
        return {
          type: PreparationStepType.check_emails_validity,
          columns: frontPreparation.columns.map((col) => {
            return {
              originalColumnId: col.originalColumnId,
              targetColumnId: col.targetColumnId,
              targetColumnName: col.targetColumnName,
            };
          }),
        };
      default:
        throw new Error(`preparation ${frontPreparationType} type doesn't exist `);
    }
  });
};

export default mappingPreparationStepsToBack;
