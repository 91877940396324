export type SyncExecution = {
  syncId: string;
  description: string;
  recordsScope: ISyncRecordsScope;
};

export type ISyncRecordsScope =
  | IAllSyncRecordsScope
  | IUnprocessedSyncRecordsScope
  | ISyncRecordsInTimePeriodScope;

export enum SyncRecordsScope {
  allRecords = 'allRecords',
  unprocessedRecords = 'unprocessedRecords',
  recordsInTimePeriod = 'recordsInTimePeriod',
}

export type IAllSyncRecordsScope = {
  type: SyncRecordsScope.allRecords;
};
export type IUnprocessedSyncRecordsScope = {
  type: SyncRecordsScope.unprocessedRecords;
};
export type ISyncRecordsInTimePeriodScope = {
  type: SyncRecordsScope.recordsInTimePeriod;
  filter: IRecordsScopeFilter;
};

export type IRecordsScopeFilter =
  | ICreatedRecordsScopeFilter
  | IUpdatedRecordsScopeFilter
  | ICreatedAndUpdatedRecordsScopeFilter;

export enum RecordsScopeFilter {
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  createdOrUpdatedAt = 'createdOrUpdatedAt',
}

export type ICreatedRecordsScopeFilter = Readonly<{
  type: RecordsScopeFilter.createdAt;
  createdAt: IRecordsScopeDateFilter;
}>;
export type IUpdatedRecordsScopeFilter = Readonly<{
  type: RecordsScopeFilter.updatedAt;
  updatedAt: IRecordsScopeDateFilter;
}>;
export type ICreatedAndUpdatedRecordsScopeFilter = Readonly<{
  type: RecordsScopeFilter.createdOrUpdatedAt;
  createdAt: IRecordsScopeDateFilter;
  updatedAt: IRecordsScopeDateFilter;
}>;

export enum RecordsScopeDateFilter {
  before = 'before',
  after = 'after',
  range = 'range',
}

export type IRecordsScopeDateFilter =
  | IRecordsScopeAfterDateFilter
  | IRecordsScopeBeforeDateFilter
  | IRecordsScopeDateRangeFilter;

export type IRecordsScopeAfterDateFilter = Readonly<{
  type: RecordsScopeDateFilter.after;
  after: Date;
}>;
export type IRecordsScopeBeforeDateFilter = Readonly<{
  type: RecordsScopeDateFilter.before;
  before: Date;
}>;
export type IRecordsScopeDateRangeFilter = Readonly<{
  type: RecordsScopeDateFilter.range;
  after: Date;
  before: Date;
}>;
