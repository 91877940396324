import { columnTypesEnum } from '@features/objects/types';
import { AggregateDetails, Column } from '../types';
import { IDataValue } from './AudienceColumn';

export type MappingRow =
  | DefaultMappingRow
  | MasterIdMappingRow
  | ModifiedAtMappingRow
  | AggregateMappingRow
  | SourceIdMappingRow;

export type DefaultMappingRow = {
  key: string;
  rowType: mappingRowTypesEnum.DEFAULT;
  mappedColumns: MappedColumns;
  audienceColumnName: string;
  audienceColumnType: columnTypesEnum;
  businessColumn?: Column;
  primaryKey?: boolean;
  updatedAtKey?: boolean;
  canDelete?: boolean;
  defaultValue?: IDataValue;
};
export type MasterIdMappingRow = {
  key: string;
  rowType: mappingRowTypesEnum.MASTER_ID_ROW;
  mappedColumns: MappedColumns;
  audienceColumnName: string;
  audienceColumnType: columnTypesEnum.SYSTEM_ID;
  businessColumn?: Column;
};
export type ModifiedAtMappingRow = {
  key: string;
  rowType: mappingRowTypesEnum.MODIFIED_AT_ROW;
  mappedColumns: MappedColumns;
  audienceColumnName: string;
  audienceColumnType: columnTypesEnum;
  businessColumn?: Column;
  updatedAtKey: true;
};
export type AggregateMappingRow = {
  key: string;
  rowType: mappingRowTypesEnum.AGGREGATE_ROW;
  mappedColumns: MappedColumns;
  audienceColumnName: string;
  audienceColumnType: columnTypesEnum;
  businessColumn?: Column;
  canDelete: boolean;
};

export type SourceIdMappingRow = {
  key: string;
  rowType: mappingRowTypesEnum.SOURCE_ID_LIST;
  mappedColumns: MappedColumns;
  audienceColumnName: string;
  audienceColumnType: columnTypesEnum;
  businessColumn?: Column;
  sourceId: string;
  canDelete: boolean;
};

export type MappedColumns = {
  [sourceKey: string]: MappedColumn;
};
export type MappedColumn = {
  columnId: string;
  columnName: string;
  columnType?: columnTypesEnum;
  aggregate?: AggregateDetails;
  primaryKey?: boolean;
  updatedAtKey?: boolean;
  columnDisplay?: columnDisplayEnum;
};

export enum mappingRowTypesEnum {
  DEFAULT = 'default',
  MASTER_ID_ROW = 'masterId',
  MODIFIED_AT_ROW = 'modifiedAt',
  AGGREGATE_ROW = 'aggregateRow',
  SOURCE_ID_LIST = 'sourceIdList',
}
export enum columnDisplayEnum {
  HIDDEN = 'hidden',
  DISABLED = 'disabled',
  LONG_LINE = 'longLine',
  SELECT = 'select',
}

// Mapping types for the Form values
export type MappingJoinColumnsRowsForm = RegularJoinConditionForm | SourceIdListJoinConditionForm;
export type RegularJoinConditionForm = {
  [rowKey: string]: {
    sourceColumnId: string | undefined;
    audienceColumnId: string | undefined;
  };
};
export type SourceIdListJoinConditionForm = {
  sourceColumnId: string | undefined;
  sourceIdList: string | undefined;
};
export enum betweenColumnsDisplayEnum {
  SHORT_LINES = 'shortLines',
  SHORT_LINES_WITH_ARROW = 'shortLinesWithArrow',
}
