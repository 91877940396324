import apolloClientInstance from '@apolloClientInstance';
import { GET_DATAWAREHOUSES, GET_DATAWAREHOUSES_CREDENTIALS } from './graphql/queries';
import * as apiTypes from './types';
import { CREATE_DATAWAREHOUSE } from '@features/dataWarehouse/ducks/graphql/mutations';
import { apolloQuery } from '@utils/apollo-query';
import {
  GetDataWarehousesCredentials,
  GetDataWarehousesCredentialsVariables,
  IPostgresDataWarehouseCredentials,
} from './types/getDataWarehousesCredentials';
import { ApiPayload } from '@features/types';

const getDatawarehouses: apiTypes.APIGetDatawarehouses = async (accountId) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance();

  const res = await apolloClient.query({
    query: GET_DATAWAREHOUSES,
    variables: {
      accountId,
    },
  });

  const dataWarehouses: string[] = res.data.dataWarehouses.map((x: { id: string }) => x.id);

  if (!res.data.dataWarehouses.length) {
    error = true;
    errorDetails = 'No datawarehouse found.';
  }

  return {
    error,
    dataWarehouses,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const createDataWarehouse: apiTypes.APICreateDataWarehouse = async (dataWarehouse) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: CREATE_DATAWAREHOUSE,
    variables: {
      command: dataWarehouse,
    },
  });
  if (!res.data.createPostgresDataWarehouse) {
    error = true;
    errorDetails = 'No datawarehouse found.';
  }
  return {
    error,
    dataWarehouse: res.data.createPostgresDataWarehouse,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getDatawarehousesCredentials = async (
  accountId: string
): Promise<ApiPayload<{ credentials: IPostgresDataWarehouseCredentials[] }>> => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const { data } = await apolloQuery<
    GetDataWarehousesCredentials,
    GetDataWarehousesCredentialsVariables
  >({
    query: GET_DATAWAREHOUSES_CREDENTIALS,
    variables: { accountId },
  });

  const credentials = data.dataWarehouses.map((item) => item.readOnlyCredentials);

  if (!data.dataWarehouses.length) {
    error = true;
    errorDetails = 'No datawarehouse found.';
  }

  return {
    error,
    credentials,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getDatawarehouses, createDataWarehouse, getDatawarehousesCredentials };
