import { ModifiedAtNullValuesStrategy, ModifiedAtOnlyChangeStrategy } from '../../types';

// Important: Not allowing names of columns, sources or audiences to be longer than 26 characters
export const MAX_COLUMN_NAME_LENGTH = 26;

export const MAX_CONNECTION_DATA_SOURCE_NAME_LENGTH = 26;

export const MAX_AUDIENCE_NAME_LENGTH = 26;

// Options
export const onlyChangeOptions: {
  value: ModifiedAtOnlyChangeStrategy;
  label: string;
  disabled?: boolean;
}[] = [
  {
    value: ModifiedAtOnlyChangeStrategy.silent_update,
    label: `Update the 📅 column only and don't pass the record to the Dataset`,
  },
  {
    value: ModifiedAtOnlyChangeStrategy.full_update,
    label: `Update the 📅 column only and pass the record to the Dataset.`,
  },
  {
    value: ModifiedAtOnlyChangeStrategy.ignore,
    label: `Don't update the record.`,
  },
];
export const nullValuesOptions: {
  value: ModifiedAtNullValuesStrategy;
  label: string;
  disabled?: boolean;
}[] = [
  {
    value: ModifiedAtNullValuesStrategy.set_current_time,
    label: 'Set its value to the current time',
  },
  {
    value: ModifiedAtNullValuesStrategy.filter_out,
    label: 'Filter out the record',
  },
];

export const DEFAULT_AUDIENCE_PREVIEW_SAMPLE_PAGE_SIZE = 5;
export const DEFAULT_AUDIENCE_DATA_PAGE_SIZE = 5;
export const CREATED_AT_COLUMN_NAME: string = 'created_at';
export const UPDATED_AT_COLUMN_NAME: string = 'updated_at';
export const MASTER_ID_COLUMN_NAME: string = '__masterid__';
export const MODIFIED_AT_COLUMN_NAME: string = '__modified_at__';
