import { SourceDescription } from '@features/audiences/types';
import { call } from 'redux-saga/effects';
import { draftSource } from '../api/api';
import {
  DraftSourceOutput,
  DraftSourceStatus,
  RequestDraftSourceInput,
  RequestDraftSourceInputTypes,
} from '../api/types';
import { DraftParentAudienceSaga } from '../types';
import { draftSourceSagaForDefaultAudience } from './draftSource';

export function* draftParentAudience({ payload }: DraftParentAudienceSaga) {
  const { parentAudience, abortSignal } = payload;
  try {
    if (parentAudience.draftSourceId) {
      const describeSourceProcessOutput: DraftSourceOutput = yield call(
        draftSource,
        parentAudience.draftSourceId,
        abortSignal
      );
      if (describeSourceProcessOutput.status === DraftSourceStatus.ok) {
        return {
          ...parentAudience,
          draftSourceId: parentAudience.draftSourceId,
          sourceDescription: describeSourceProcessOutput.sourceDescription,
        };
      }

      if (describeSourceProcessOutput.status === DraftSourceStatus.error) {
        return describeSourceProcessOutput.error;
      }
    }
    if (parentAudience && parentAudience.audienceId && parentAudience.join) {
      const draftSourceInput: RequestDraftSourceInput = {
        type: RequestDraftSourceInputTypes.audience,
        audienceId: parentAudience.audienceId,
      };
      const describeSourceOutput:
        | {
            draftSourceId: string;
            sourceDescription: SourceDescription;
          }
        | string = yield draftSourceSagaForDefaultAudience({
        type: 'draftSource',
        payload: {
          draftSourceInput,
          abortSignal,
        },
      });
      if (typeof describeSourceOutput === 'string') {
        return describeSourceOutput;
      }
      return {
        ...parentAudience,
        draftSourceId: describeSourceOutput.draftSourceId,
        sourceDescription: describeSourceOutput.sourceDescription,
      };
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      return err.message;
    }
  }
}
