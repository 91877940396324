import { MappingRow, mappingRowTypesEnum } from '@features/audiences/types';
import {
  DataValueType,
  FunctionArgumentType,
  IDataValue,
  IFunctionArgument,
} from '@features/audiences/types/AudienceColumn';
import { caseNever } from '@utils/case-never';
import {
  AudienceColumn,
  BackDataValueType,
  BackFunctionArgumentType,
  IBackDataValue,
  IBackFunctionArgument,
} from '../../../../audienceBackTypes/audienceColumn';
import { mappingColumnTypePropertyToBack } from '../mappingColumnTypePropertyToBack';

export const mappingAudienceColumnsProperty = (mappingRows: MappingRow[]): AudienceColumn[] => {
  const columns: AudienceColumn[] = [];
  mappingRows.forEach((row) => {
    // Mapping audience columns
    if (row.rowType === mappingRowTypesEnum.DEFAULT) {
      columns.push({
        id: row.key,
        name: row.audienceColumnName,
        type: mappingColumnTypePropertyToBack(row.audienceColumnType),
        defaultValue: row.defaultValue && mappingDefaultAudienceColumnValue(row.defaultValue),
      });
    }
  });
  return columns;
};

const mappingDefaultAudienceColumnValue = (
  defaultValue: IDataValue
): IBackDataValue | undefined => {
  const defaultValueType = defaultValue.type;
  switch (defaultValueType) {
    case DataValueType.static:
      return { type: BackDataValueType.static, value: defaultValue.value };
    case DataValueType.sequence:
      return { type: BackDataValueType.sequence, sequence: defaultValue.sequence };
    case DataValueType.function:
      return {
        type: BackDataValueType.function,
        args: mapDataValueArgsProperty(defaultValue.args),
        name: defaultValue.name,
        outputType: mappingColumnTypePropertyToBack(defaultValue.outputType),
      };
    case DataValueType.special:
      return { type: BackDataValueType.special, value: defaultValue.value };
    default:
      caseNever(defaultValueType);
  }
};

const mapDataValueArgsProperty = (args: IFunctionArgument[]): IBackFunctionArgument[] => {
  const backArgs: IBackFunctionArgument[] = [];
  args.forEach((elem) => {
    const type = elem.type;
    switch (type) {
      case FunctionArgumentType.value:
        backArgs.push({
          type: BackFunctionArgumentType.value,
          value: elem.value,
          dataType: mappingColumnTypePropertyToBack(elem.dataType),
        });
        break;
      case FunctionArgumentType.sequence:
        backArgs.push({
          type: BackFunctionArgumentType.sequence,
          outputType: mappingColumnTypePropertyToBack(elem.outputType),
          sequence: elem.sequence,
        });
        break;
      default:
        caseNever(type);
    }
  });
  return backArgs;
};
