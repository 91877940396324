import {
  ActiveSourceConfigurationPayload,
  ActiveSQLSourceConfigurationPayload,
  ApiGetAudiencePayload,
  DescribeSourceReducerAction,
  DraftAudienceSourceProcessOutput,
  DraftAudienceSourceProcessSaga,
  DraftAudienceSourceSaga,
  DraftConnectionSourceProcessForDefaultOutput,
  DraftConnectionSourceProcessForSqlOutput,
  DraftConnectionSourceProcessSaga,
  DraftSourceForDefaultAudienceSaga,
  DraftSourceSaga,
  DraftSyncSourceProcessOutput,
  DraftSyncSourceProcessSaga,
  DraftSyncSourceSaga,
  SetAudienceAsSourceDefaultConfigurationSaga,
  SetSyncAsSourceDefaultConfigurationSaga,
} from '@features/audiences/ducks/types';
import {
  ActiveBaseSourceConfiguration,
  ActiveDefaultAudience,
  ActiveSourceConfiguration,
  ActiveSourceDescription,
  ActiveSQLSourceConfiguration,
  buildTypesEnum,
  Column,
  columnShapeTypeEnum,
  ColumnWithNoID,
  sftpModifiedAtColumnSource,
  sourceTypesEnum,
} from '@features/audiences/types';
import { all, call, put, select } from 'redux-saga/effects';
import {
  activeAccountIdStateSelector,
  activeAudienceSelector,
  activeDataWarehouseIdStateSelector,
  activeSourceConfigurationAudienceSelector,
  activeSqlSourceConfigurationAudienceSelector,
} from '@redux/selectors';
import { generateId, sleep } from '@utils/helpers';
import {
  BackColumn,
  DraftAudienceSourceOutput,
  DraftSourceOutput,
  DraftSourceStatus,
  DraftSyncSourceOutput,
  RequestDraftSourceInput,
  RequestDraftSourceInputTypes,
  RequestDraftSourceOutput,
} from '@features/audiences/ducks/api/types';
import {
  draftAudienceSource,
  draftSource,
  draftSyncSource,
  getAudience,
  requestDraftSource,
} from '@features/audiences/ducks/api/api';
import { mappingDraftSourceBackColumnToFront } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontAudienceHelpers/mappingBackColumnToColumn';
import audiencesSlice from '@features/audiences/ducks/audiencesSlice';
import { toSnakeCase } from '@utils/to-snake-case';
import { ActiveAudience } from '@features/audiences/types/ActiveAudience/ActiveAudience';
import { caseNever } from '@utils/case-never';
import { ConnectorType } from '@features/connections/types';
import { getColumnNameForComparison, shortenColumnName } from '@features/audiences/utils/columns';
import { mappingColumnTypePropertyToFront } from '../api/mappingAudienceTypes/toFrontType/mappingColumnTypePropertyToFront';

function* draftConnectionSourceProcess({ payload }: DraftConnectionSourceProcessSaga) {
  const { draftSourceId, abortSignal } = payload;
  let describeSourceIsExecuted = false;

  while (!describeSourceIsExecuted) {
    yield sleep(500, abortSignal);
    const describeSourceProcessOutput: DraftSourceOutput = yield call(
      draftSource,
      draftSourceId,
      abortSignal
    );

    if (describeSourceProcessOutput.status === DraftSourceStatus.error) {
      describeSourceIsExecuted = true;
      return {
        status: describeSourceProcessOutput.status,
        error: describeSourceProcessOutput.error,
      };
    }
    if (describeSourceProcessOutput.status === DraftSourceStatus.ok) {
      describeSourceIsExecuted = true;

      return {
        sourceDescription: describeSourceProcessOutput.sourceDescription,
        status: describeSourceProcessOutput.status,
      };
    }
  }
}

function* draftConnectionSourceForDefault({ payload }: DraftConnectionSourceProcessSaga) {
  const { draftSourceId, abortSignal } = payload;
  const activeAudience: ActiveDefaultAudience = yield select(activeAudienceSelector);
  const activeSourceConfiguration: ActiveSourceConfiguration = yield select(
    activeSourceConfigurationAudienceSelector
  );

  const describeSourceProcessOutput: DraftSourceOutput = yield call(draftConnectionSourceProcess, {
    payload: {
      draftSourceId,
      abortSignal,
    },
  });

  if (describeSourceProcessOutput.status === DraftSourceStatus.error) {
    return {
      status: describeSourceProcessOutput.status,
      errorDetails: describeSourceProcessOutput.error,
    };
  }
  if (describeSourceProcessOutput.status === DraftSourceStatus.ok) {
    const sourceColumnsWithIds: Column[] = getSourceColumnsWithIdsByColumnName(
      describeSourceProcessOutput.sourceDescription.shape,
      activeSourceConfiguration
    );
    const newShapeDetails = compareDraftSourceColumns(
      sourceColumnsWithIds,
      activeSourceConfiguration.selectedColumns
    );
    const updatedAtColumnId: string | undefined = sourceColumnsWithIds.find(
      (c) => c.name === describeSourceProcessOutput.sourceDescription.updatedAtColumnName
    )?.id;
    const sourceDescription: ActiveSourceDescription = {
      shape: sourceColumnsWithIds,
      counter: describeSourceProcessOutput.sourceDescription.counter,
      sample: {
        size: describeSourceProcessOutput.sourceDescription.sample.length,
        page: 1,
        pageSize: 6,
        records: describeSourceProcessOutput.sourceDescription.sample,
      },
      primaryKeys: describeSourceProcessOutput.sourceDescription.primaryKeys
        ? describeSourceProcessOutput.sourceDescription.primaryKeys
        : [],
      updatedAtColumnId: updatedAtColumnId ?? null,
      newShapeDetails,
    };

    return {
      sourceDescription,
      newShapeDetails,
      status: describeSourceProcessOutput.status,
    };
  }
}

function* draftConnectionSourceForSQL({ payload }: DraftConnectionSourceProcessSaga) {
  const { draftSourceId, abortSignal } = payload;
  const activeSourceConfiguration: ActiveSQLSourceConfiguration = yield select(
    activeSqlSourceConfigurationAudienceSelector
  );

  const describeSourceProcessOutput: DraftSourceOutput = yield call(draftConnectionSourceProcess, {
    payload: {
      draftSourceId,
      abortSignal,
    },
  });

  if (describeSourceProcessOutput.status === DraftSourceStatus.error) {
    return {
      status: describeSourceProcessOutput.status,
      errorDetails: describeSourceProcessOutput.error,
    };
  }
  if (describeSourceProcessOutput.status === DraftSourceStatus.ok) {
    const sourceColumnsWithIds: Column[] = getSourceColumnsWithIdsByColumnName(
      describeSourceProcessOutput.sourceDescription.shape,
      activeSourceConfiguration
    );
    const newShapeDetails = compareDraftSourceColumns(
      sourceColumnsWithIds,
      activeSourceConfiguration.selectedColumns
    );
    const updatedAtColumnId: string | undefined = sourceColumnsWithIds.find(
      (c) => c.name === describeSourceProcessOutput.sourceDescription.updatedAtColumnName
    )?.id;
    const sourceDescription: ActiveSourceDescription = {
      shape: sourceColumnsWithIds,
      counter: describeSourceProcessOutput.sourceDescription.counter,
      sample: {
        size: describeSourceProcessOutput.sourceDescription.sample.length,
        page: 1,
        pageSize: 6,
        records: describeSourceProcessOutput.sourceDescription.sample,
      },
      primaryKeys: describeSourceProcessOutput.sourceDescription.primaryKeys
        ? describeSourceProcessOutput.sourceDescription.primaryKeys
        : [],
      updatedAtColumnId: updatedAtColumnId ?? null,
      newShapeDetails,
    };

    return {
      sourceDescription,
      status: describeSourceProcessOutput.status,
    };
  }
}

function* draftAudienceSourceProcess({ payload }: DraftAudienceSourceProcessSaga) {
  const { audienceId, draftSourceId, abortSignal } = payload;

  let describeSourceIsExecuted = false;
  while (!describeSourceIsExecuted) {
    yield sleep(500, abortSignal);
    const describeAudienceSourceProcessOutput: DraftAudienceSourceOutput = yield call(
      // TODO @@@@koralex we need to align the type this function returns
      // TODO @@@@koralex missing primaryKeys, updatedAtColumnName
      draftAudienceSource,
      draftSourceId,
      audienceId,
      abortSignal
    );
    if (describeAudienceSourceProcessOutput.status === DraftSourceStatus.error) {
      describeSourceIsExecuted = true;
      return {
        status: describeAudienceSourceProcessOutput.status,
        error: describeAudienceSourceProcessOutput.error,
      };
    }
    if (describeAudienceSourceProcessOutput.status === DraftSourceStatus.ok) {
      describeSourceIsExecuted = true;

      return describeAudienceSourceProcessOutput;
    }
  }
}

function* draftAudienceSourceSaga({ payload }: DraftAudienceSourceSaga) {
  const { audienceId, draftSourceId, abortSignal, audienceType } = payload;
  const describeAudienceSourceProcessOutput: DraftAudienceSourceOutput = yield call(
    draftAudienceSourceProcess,
    {
      payload: {
        audienceId,
        draftSourceId,
        abortSignal,
      },
    }
  );

  if (describeAudienceSourceProcessOutput.status === DraftSourceStatus.error) {
    return {
      status: describeAudienceSourceProcessOutput.status,
      errorDetails: describeAudienceSourceProcessOutput.error,
    };
  }
  if (describeAudienceSourceProcessOutput.status === DraftSourceStatus.ok) {
    let activeSourceConfiguration:
      | ActiveSourceConfiguration
      | ActiveSQLSourceConfiguration
      | undefined;

    switch (audienceType) {
      case buildTypesEnum.BUSINESS:
        activeSourceConfiguration = yield select(activeSourceConfigurationAudienceSelector);
        break;
      case buildTypesEnum.DATA:
        activeSourceConfiguration = yield select(activeSqlSourceConfigurationAudienceSelector);
        break;
      default:
        return caseNever(audienceType);
    }

    if (!activeSourceConfiguration) {
      return {
        status: DraftSourceStatus.error,
        errorDetails: 'Active source configuration not found',
      };
    }

    const { deployedAudience, sourceDescription, deduplicationKeys } =
      describeAudienceSourceProcessOutput;
    const audienceSourceColumns: Column[] = deployedAudience.dataShape.columns.map((col) => ({
      id: col.id,
      type: col.type,
      shapeType: columnShapeTypeEnum.DEFAULT,
      externalName: col.name,
      name: toSnakeCase(col.name),
      isNullable: false,
    }));
    const newShapeDetails = compareDraftSourceColumns(
      audienceSourceColumns,
      activeSourceConfiguration.selectedColumns
    );
    const newSourceDescriptionValues: ActiveSourceDescription = {
      shape: audienceSourceColumns,
      counter: sourceDescription.counter,
      sample: {
        size: sourceDescription.sample.length,
        page: 1,
        pageSize: 6,
        records: sourceDescription.sample,
      },
      primaryKeys: sourceDescription.primaryKeys ? sourceDescription.primaryKeys : [],
      updatedAtColumnId: deployedAudience.dataShape.systemColumns.modifiedAt,
      newShapeDetails,
    };
    return {
      deployedAudience,
      deduplicationKeys,
      sourceDescription: newSourceDescriptionValues,
      status: describeAudienceSourceProcessOutput.status,
    };
  }
}
function* draftSyncSourceProcess({ payload }: DraftSyncSourceProcessSaga) {
  const { syncId, draftSourceId, abortSignal } = payload;

  let describeSourceIsExecuted = false;
  while (!describeSourceIsExecuted) {
    yield sleep(500, abortSignal);
    const describeSyncSourceProcessOutput: DraftSyncSourceOutput = yield call(
      // TODO @@@@koralex we need to align the type this function returns
      // TODO @@@@koralex missing primaryKeys, updatedAtColumnName
      draftSyncSource,
      draftSourceId,
      syncId,
      abortSignal
    );
    if (describeSyncSourceProcessOutput.status === DraftSourceStatus.error) {
      describeSourceIsExecuted = true;
      return {
        status: describeSyncSourceProcessOutput.status,
        error: describeSyncSourceProcessOutput.error,
      };
    }
    if (describeSyncSourceProcessOutput.status === DraftSourceStatus.ok) {
      describeSourceIsExecuted = true;

      return describeSyncSourceProcessOutput;
    }
  }
}

function* draftSyncSourceSaga({ payload }: DraftSyncSourceSaga) {
  const { syncId, draftSourceId, abortSignal, audienceType } = payload;
  const describeSyncSourceProcessOutput: DraftSyncSourceOutput = yield call(
    draftSyncSourceProcess,
    {
      payload: {
        syncId,
        draftSourceId,
        abortSignal,
      },
    }
  );

  if (describeSyncSourceProcessOutput.status === DraftSourceStatus.error) {
    return {
      status: describeSyncSourceProcessOutput.status,
      errorDetails: describeSyncSourceProcessOutput.error,
    };
  }
  if (describeSyncSourceProcessOutput.status === DraftSourceStatus.ok) {
    let activeSourceConfiguration:
      | ActiveSourceConfiguration
      | ActiveSQLSourceConfiguration
      | undefined;

    switch (audienceType) {
      case buildTypesEnum.BUSINESS:
        activeSourceConfiguration = yield select(activeSourceConfigurationAudienceSelector);
        break;
      case buildTypesEnum.DATA:
        activeSourceConfiguration = yield select(activeSqlSourceConfigurationAudienceSelector);
        break;
      default:
        return caseNever(audienceType);
    }

    if (!activeSourceConfiguration) {
      return {
        status: DraftSourceStatus.error,
        errorDetails: 'Active source configuration not found',
      };
    }
    const syncAudiencePayload: ApiGetAudiencePayload = yield call(
      getAudience,
      describeSyncSourceProcessOutput.syncAudienceId
    );
    const { sourceDescription } = describeSyncSourceProcessOutput;

    const syncSourceColumns: Column[] = sourceDescription.shape.map((draftSourceCol) => {
      const draftSourceColumnName = shortenColumnName(toSnakeCase(draftSourceCol.name));

      const selectedSourceColumnExist = activeSourceConfiguration!.selectedColumns.find((col) => {
        const name1 = getColumnNameForComparison(col);
        const name2 = getColumnNameForComparison({
          shapeType: columnShapeTypeEnum.DEFAULT,
          name: draftSourceColumnName,
          externalName: draftSourceCol.name,
        });
        return name1 === name2;
      });

      return {
        ...draftSourceCol,
        id: selectedSourceColumnExist ? selectedSourceColumnExist.id : generateId(),
        type: mappingColumnTypePropertyToFront(draftSourceCol.type),
        shapeType: columnShapeTypeEnum.DEFAULT,
        externalName: draftSourceCol.name,
        name: selectedSourceColumnExist ? selectedSourceColumnExist.name : draftSourceColumnName,
        isNullable: draftSourceCol.isNullable,
      };
    });
    const backUpdatedAtColumn = sourceDescription.shape.find((col) => col.name === 'updated_at');
    const newShapeDetails = compareDraftSourceColumns(
      syncSourceColumns,
      activeSourceConfiguration.selectedColumns
    );
    const newSourceDescriptionValues: ActiveSourceDescription = {
      shape: [...syncSourceColumns],
      counter: sourceDescription.counter,
      sample: {
        size: sourceDescription.sample.length,
        page: 1,
        pageSize: 6,
        records: sourceDescription.sample,
      },
      primaryKeys: sourceDescription.primaryKeys ? sourceDescription.primaryKeys : [],
      updatedAtColumnId: backUpdatedAtColumn?.id ?? '',
      newShapeDetails,
    };
    const deduplicationKeys: string[] = [];
    return {
      deduplicationKeys,
      sourceDescription: newSourceDescriptionValues,
      syncAudienceCategory: syncAudiencePayload.audience?.category,
      status: describeSyncSourceProcessOutput.status,
    };
  }
}

export function* draftSourceSaga({ payload, type }: DraftSourceSaga) {
  const { draftSourceInput, isEditForm, abortSignal, force } = payload;

  switch (payload.audienceType) {
    case buildTypesEnum.BUSINESS: {
      yield call(draftSourceSagaForDefaultAudience, {
        type,
        payload: {
          draftSourceInput,
          abortSignal,
          isEditForm,
          force,
        },
      });
      break;
    }
    case buildTypesEnum.DATA: {
      yield call(draftSourceSagaForSqlAudience, {
        type,
        payload: {
          draftSourceInput,
          abortSignal,
          isEditForm,
          force,
        },
      });
      break;
    }
    default:
      return caseNever(payload.audienceType);
  }
}

export function* draftSourceSagaForDefaultAudience({ payload }: DraftSourceForDefaultAudienceSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);
  const activeAudience: ActiveAudience = yield select(activeAudienceSelector);
  const activeSourceConfiguration: ActiveSourceConfiguration = yield select(
    activeSourceConfigurationAudienceSelector
  );
  try {
    const { draftSourceInput, abortSignal, isEditForm, force } = payload;
    const describeSourceOutput: RequestDraftSourceOutput = yield call(
      requestDraftSource,
      accountId,
      dataWarehouseId,
      draftSourceInput,
      abortSignal,
      force
    );
    const draftSourceId = describeSourceOutput.draftSourceId;
    yield put({
      type: audiencesSlice.actions.updateRequestDraftSourceStatus.type,
      payload: {
        status: DraftSourceStatus.pending,
      },
    });
    switch (draftSourceInput.type) {
      case RequestDraftSourceInputTypes.connection:
        if (activeSourceConfiguration.dataSource.type === sourceTypesEnum.CONNECTION) {
          const draftConnectionSourceProcessOutput: DraftConnectionSourceProcessForDefaultOutput =
            yield draftConnectionSourceForDefault({ payload: { draftSourceId, abortSignal } });
          if (draftConnectionSourceProcessOutput.status === DraftSourceStatus.ok) {
            let newSourceConfigurationValues: ActiveSourceConfiguration;
            if (isEditForm) {
              newSourceConfigurationValues = {
                ...activeSourceConfiguration,
                dataSource: {
                  ...activeSourceConfiguration.dataSource,
                  draftSourceId,
                  sourceDescription: draftConnectionSourceProcessOutput.sourceDescription,
                },
              };
            } else {
              const updatedAtColumn =
                draftConnectionSourceProcessOutput.sourceDescription.shape.find(
                  ({ name }) =>
                    draftSourceInput.sourceSettings.type === ConnectorType.sftp &&
                    draftSourceInput.sourceSettings.modifiedAtColumn?.type ===
                      sftpModifiedAtColumnSource.fileName &&
                    name === draftSourceInput.sourceSettings.modifiedAtColumn.columnName
                );
              newSourceConfigurationValues = {
                ...activeSourceConfiguration,
                selectedColumns: updatedAtColumn ? [updatedAtColumn] : [],
                deduplicationSettings: {
                  deduplicationKeys: [],
                },
                dataSource: {
                  ...activeSourceConfiguration.dataSource,
                  draftSourceId,
                  sourceDescription: draftConnectionSourceProcessOutput.sourceDescription,
                  updatedAtColumnId: updatedAtColumn ? updatedAtColumn.id : undefined,
                  category: undefined,
                },
              };
            }

            yield all([
              put<DescribeSourceReducerAction>({
                type: audiencesSlice.actions.draftSourceSuccess.type,
                payload: {
                  status: draftConnectionSourceProcessOutput.status,
                },
              }),
              put<ActiveSourceConfigurationPayload>({
                type: audiencesSlice.actions.updateActiveSourceConfiguration.type,
                payload: {
                  sourceConfiguration: newSourceConfigurationValues,
                },
              }),
            ]);
            return {
              draftSourceId,
              sourceDescription: draftConnectionSourceProcessOutput.sourceDescription,
            };
          }
          if (draftConnectionSourceProcessOutput.status === DraftSourceStatus.error) {
            yield put({
              type: audiencesSlice.actions.draftSourceFailed.type,
              payload: { errorDetails: draftConnectionSourceProcessOutput.errorDetails },
            });
            return draftConnectionSourceProcessOutput.errorDetails;
          }
        }
        break;
      case RequestDraftSourceInputTypes.audience:
        const draftAudienceSourceProcessOutput: DraftAudienceSourceProcessOutput =
          yield draftAudienceSourceSaga({
            payload: {
              draftSourceId,
              abortSignal,
              audienceId: draftSourceInput.audienceId,
              audienceType: buildTypesEnum.BUSINESS,
            },
          });
        if (draftAudienceSourceProcessOutput.status === DraftSourceStatus.ok) {
          const partialDefaultSourceConfiguration: Pick<
            ActiveBaseSourceConfiguration,
            'selectedColumns' | 'dataSource' | 'deduplicationSettings'
          > = setAudienceAsSourceDefaultConfiguration({
            payload: {
              isEditForm,
              draftSourceId,
              deployedAudience: draftAudienceSourceProcessOutput.deployedAudience,
              deduplicationKeys:
                draftAudienceSourceProcessOutput.deduplicationKeys[0]?.columnIds || [],
              sourceDescription: draftAudienceSourceProcessOutput.sourceDescription,
              partialActiveSourceConfiguration: {
                dataSource: activeSourceConfiguration.dataSource,
                selectedColumns: activeSourceConfiguration.selectedColumns,
              },
            },
          });

          const sourceConfigurationNewValues: ActiveSourceConfiguration = {
            ...activeSourceConfiguration,
            dataSource: partialDefaultSourceConfiguration.dataSource,
            selectedColumns: partialDefaultSourceConfiguration.selectedColumns,
            deduplicationSettings: partialDefaultSourceConfiguration.deduplicationSettings,
          };
          yield put<DescribeSourceReducerAction>({
            type: audiencesSlice.actions.draftSourceSuccess.type,
            payload: {
              status: draftAudienceSourceProcessOutput.status,
            },
          });

          yield put<ActiveSourceConfigurationPayload>({
            type: audiencesSlice.actions.updateActiveSourceConfiguration.type,
            payload: {
              sourceConfiguration: sourceConfigurationNewValues,
            },
          });
          return {
            draftSourceId,
            sourceDescription: draftAudienceSourceProcessOutput.sourceDescription,
          };
        }
        if (draftAudienceSourceProcessOutput.status === DraftSourceStatus.error) {
          yield put({
            type: audiencesSlice.actions.draftSourceFailed.type,
            payload: { errorDetails: draftAudienceSourceProcessOutput.errorDetails },
          });
          return draftAudienceSourceProcessOutput.errorDetails;
        }
        break;
      case RequestDraftSourceInputTypes.sync:
        const draftSyncSourceProcessOutput: DraftSyncSourceProcessOutput =
          yield draftSyncSourceSaga({
            payload: {
              draftSourceId,
              abortSignal,
              syncId: draftSourceInput.syncId,
              audienceType: buildTypesEnum.BUSINESS,
            },
          });
        if (draftSyncSourceProcessOutput.status === DraftSourceStatus.ok) {
          const partialDefaultSourceConfiguration: Pick<
            ActiveBaseSourceConfiguration,
            'selectedColumns' | 'dataSource' | 'deduplicationSettings'
          > = yield setSyncAsSourceDefaultConfigurationSaga({
            payload: {
              isEditForm,
              draftSourceId,
              sourceDescription: draftSyncSourceProcessOutput.sourceDescription,
              deduplicationKeys: draftSyncSourceProcessOutput.deduplicationKeys[0]?.columnIds || [],
              syncAudienceCategory: draftSyncSourceProcessOutput.syncAudienceCategory,
              partialActiveSourceConfiguration: {
                dataSource: activeSourceConfiguration.dataSource,
                selectedColumns: activeSourceConfiguration.selectedColumns,
              },
            },
          });

          const sourceConfigurationNewValues: ActiveSourceConfiguration = {
            ...activeSourceConfiguration,

            deduplicationSettings: partialDefaultSourceConfiguration.deduplicationSettings,
            dataSource: partialDefaultSourceConfiguration.dataSource,
            selectedColumns: partialDefaultSourceConfiguration.selectedColumns,
          };
          yield put<DescribeSourceReducerAction>({
            type: audiencesSlice.actions.draftSourceSuccess.type,
            payload: {
              status: draftSyncSourceProcessOutput.status,
            },
          });

          yield put<ActiveSourceConfigurationPayload>({
            type: audiencesSlice.actions.updateActiveSourceConfiguration.type,
            payload: {
              sourceConfiguration: sourceConfigurationNewValues,
            },
          });
          return {
            draftSourceId,
            sourceDescription: draftSyncSourceProcessOutput.sourceDescription,
          };
        }
        if (draftSyncSourceProcessOutput.status === DraftSourceStatus.error) {
          yield put({
            type: audiencesSlice.actions.draftSourceFailed.type,
            payload: { errorDetails: draftSyncSourceProcessOutput.errorDetails },
          });
          return draftSyncSourceProcessOutput.errorDetails;
        }
        break;
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.draftSourceFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
      return err.message;
    }
  }
}

export function* draftSourceSagaForSqlAudience({ payload }: DraftSourceForDefaultAudienceSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);
  const activeSourceConfiguration: ActiveSQLSourceConfiguration = yield select(
    activeSqlSourceConfigurationAudienceSelector
  );
  try {
    const { draftSourceInput, abortSignal, isEditForm, force } = payload;
    const describeSourceOutput: RequestDraftSourceOutput = yield call(
      requestDraftSource,
      accountId,
      dataWarehouseId,
      draftSourceInput,
      abortSignal,
      force
    );
    const draftSourceId = describeSourceOutput.draftSourceId;
    yield put({
      type: audiencesSlice.actions.updateRequestDraftSourceStatus.type,
      payload: {
        status: DraftSourceStatus.pending,
      },
    });

    switch (draftSourceInput.type) {
      case RequestDraftSourceInputTypes.connection:
        if (activeSourceConfiguration.dataSource.type === sourceTypesEnum.CONNECTION) {
          const draftConnectionSourceProcessOutput: DraftConnectionSourceProcessForSqlOutput =
            yield draftConnectionSourceForSQL({ payload: { draftSourceId, abortSignal } });

          if (draftConnectionSourceProcessOutput.status === DraftSourceStatus.ok) {
            let newSourceConfigurationValues: ActiveSQLSourceConfiguration;
            if (isEditForm) {
              newSourceConfigurationValues = {
                ...activeSourceConfiguration,
                dataSource: {
                  ...activeSourceConfiguration.dataSource,
                  draftSourceId,
                  sourceDescription: draftConnectionSourceProcessOutput.sourceDescription,
                },
              };
            } else {
              const updatedAtColumn =
                draftConnectionSourceProcessOutput.sourceDescription.shape.find(
                  ({ name }) =>
                    draftSourceInput.sourceSettings.type === ConnectorType.sftp &&
                    draftSourceInput.sourceSettings.modifiedAtColumn?.type ===
                      sftpModifiedAtColumnSource.fileName &&
                    name === draftSourceInput.sourceSettings.modifiedAtColumn.columnName
                );
              newSourceConfigurationValues = {
                ...activeSourceConfiguration,
                selectedColumns: updatedAtColumn ? [updatedAtColumn] : [],
                deduplicationSettings: {
                  deduplicationKeys: [],
                },
                dataSource: {
                  ...activeSourceConfiguration.dataSource,
                  draftSourceId,
                  sourceDescription: draftConnectionSourceProcessOutput.sourceDescription,
                  updatedAtColumnId: updatedAtColumn ? updatedAtColumn.id : undefined,
                  category: undefined,
                },
              };
            }
            yield put<DescribeSourceReducerAction>({
              type: audiencesSlice.actions.draftSourceSuccess.type,
              payload: {
                status: draftConnectionSourceProcessOutput.status,
              },
            });
            yield put<ActiveSQLSourceConfigurationPayload>({
              type: audiencesSlice.actions.updateActiveSQLSourceConfiguration.type,
              payload: {
                sourceConfiguration: newSourceConfigurationValues,
              },
            });
          } else if (draftConnectionSourceProcessOutput.status === DraftSourceStatus.error) {
            yield put({
              type: audiencesSlice.actions.draftSourceFailed.type,
              payload: { errorDetails: draftConnectionSourceProcessOutput.errorDetails },
            });
          }
        }
        break;
      case RequestDraftSourceInputTypes.audience:
        const draftAudienceSourceProcessOutput: DraftAudienceSourceProcessOutput =
          yield draftAudienceSourceSaga({
            payload: {
              draftSourceId,
              abortSignal,
              audienceId: draftSourceInput.audienceId,
              audienceType: buildTypesEnum.DATA,
            },
          });
        if (draftAudienceSourceProcessOutput.status === DraftSourceStatus.ok) {
          const partialSqlSourceConfiguration: Pick<
            ActiveBaseSourceConfiguration,
            'selectedColumns' | 'dataSource' | 'deduplicationSettings'
          > = setAudienceAsSourceDefaultConfiguration({
            payload: {
              isEditForm,
              draftSourceId,
              deployedAudience: draftAudienceSourceProcessOutput.deployedAudience,
              deduplicationKeys:
                draftAudienceSourceProcessOutput.deduplicationKeys[0]?.columnIds || [],
              sourceDescription: draftAudienceSourceProcessOutput.sourceDescription,
              partialActiveSourceConfiguration: {
                dataSource: activeSourceConfiguration.dataSource,
                selectedColumns: activeSourceConfiguration.selectedColumns,
              },
            },
          });

          const sourceConfigurationNewValues: ActiveSQLSourceConfiguration = {
            ...activeSourceConfiguration,
            dataSource: partialSqlSourceConfiguration.dataSource,
            selectedColumns: partialSqlSourceConfiguration.selectedColumns,
            deduplicationSettings: partialSqlSourceConfiguration.deduplicationSettings,
          };
          yield put<DescribeSourceReducerAction>({
            type: audiencesSlice.actions.draftSourceSuccess.type,
            payload: {
              status: draftAudienceSourceProcessOutput.status,
            },
          });

          yield put<ActiveSQLSourceConfigurationPayload>({
            type: audiencesSlice.actions.updateActiveSQLSourceConfiguration.type,
            payload: {
              sourceConfiguration: sourceConfigurationNewValues,
            },
          });
        } else if (draftAudienceSourceProcessOutput.status === DraftSourceStatus.error) {
          yield put({
            type: audiencesSlice.actions.draftSourceFailed.type,
            payload: { errorDetails: draftAudienceSourceProcessOutput.errorDetails },
          });
        }
        break;
      case RequestDraftSourceInputTypes.sync:
        const draftSyncSourceProcessOutput: DraftSyncSourceProcessOutput =
          yield draftSyncSourceSaga({
            payload: {
              draftSourceId,
              abortSignal,
              syncId: draftSourceInput.syncId,
              audienceType: buildTypesEnum.DATA,
            },
          });

        if (draftSyncSourceProcessOutput.status === DraftSourceStatus.ok) {
          const partialDefaultSourceConfiguration: Pick<
            ActiveBaseSourceConfiguration,
            'selectedColumns' | 'dataSource' | 'deduplicationSettings'
          > = yield setSyncAsSourceDefaultConfigurationSaga({
            payload: {
              isEditForm,
              draftSourceId,
              deduplicationKeys: draftSyncSourceProcessOutput.deduplicationKeys[0]?.columnIds || [],
              syncAudienceCategory: draftSyncSourceProcessOutput.syncAudienceCategory,
              sourceDescription: draftSyncSourceProcessOutput.sourceDescription,
              partialActiveSourceConfiguration: {
                dataSource: activeSourceConfiguration.dataSource,
                selectedColumns: activeSourceConfiguration.selectedColumns,
              },
            },
          });

          const sourceConfigurationNewValues: ActiveSQLSourceConfiguration = {
            ...activeSourceConfiguration,
            dataSource: { ...partialDefaultSourceConfiguration.dataSource },
            selectedColumns: partialDefaultSourceConfiguration.selectedColumns,
            deduplicationSettings: partialDefaultSourceConfiguration.deduplicationSettings,
          };
          yield put<DescribeSourceReducerAction>({
            type: audiencesSlice.actions.draftSourceSuccess.type,
            payload: {
              status: draftSyncSourceProcessOutput.status,
            },
          });

          yield put<ActiveSQLSourceConfigurationPayload>({
            type: audiencesSlice.actions.updateActiveSQLSourceConfiguration.type,
            payload: {
              sourceConfiguration: sourceConfigurationNewValues,
            },
          });
          return {
            draftSourceId,
            sourceDescription: draftSyncSourceProcessOutput.sourceDescription,
          };
        }
        if (draftSyncSourceProcessOutput.status === DraftSourceStatus.error) {
          yield put({
            type: audiencesSlice.actions.draftSourceFailed.type,
            payload: { errorDetails: draftSyncSourceProcessOutput.errorDetails },
          });
          return draftSyncSourceProcessOutput.errorDetails;
        }

        break;
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.draftSourceFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function setAudienceAsSourceDefaultConfiguration({
  payload,
}: SetAudienceAsSourceDefaultConfigurationSaga) {
  const {
    deduplicationKeys,
    deployedAudience,
    sourceDescription,
    partialActiveSourceConfiguration,
    draftSourceId,
    isEditForm,
  } = payload;
  const updatedAtColumnId: string = deployedAudience.dataShape.systemColumns.modifiedAt;
  const updatedAtColumn: Column | undefined = sourceDescription.shape.find(
    (col) => col.id === updatedAtColumnId
  );
  const primaryKeysColumns: Column[] = sourceDescription.shape.filter((x) =>
    deduplicationKeys.includes(x.id)
  );
  const selectedColumns: Column[] = isEditForm
    ? partialActiveSourceConfiguration.selectedColumns
    : updatedAtColumn
    ? [...primaryKeysColumns, updatedAtColumn]
    : primaryKeysColumns;

  return {
    selectedColumns,
    deduplicationSettings: {
      deduplicationKeys,
    },
    dataSource: {
      ...partialActiveSourceConfiguration.dataSource,
      sourceDescription,
      draftSourceId,
      updatedAtColumnId,
      category: deployedAudience.businessCategory,
      name: deployedAudience.name,
      identifier: deployedAudience.identifier,
    },
  };
}

function getSourceColumnsWithIdsByColumnName(
  shape: BackColumn[],
  activeSourceConfiguration: ActiveSQLSourceConfiguration | ActiveSourceConfiguration
) {
  const draftSourceColumnsWithoutIds: ColumnWithNoID[] = shape.map(
    mappingDraftSourceBackColumnToFront
  );
  const sourceColumnsWithIds: Column[] = draftSourceColumnsWithoutIds.map((draftSourceCol) => {
    const selectedSourceColumnExist = activeSourceConfiguration.selectedColumns.find((col) => {
      const columnName = getColumnNameForComparison(col);
      const draftSourceColumnName = getColumnNameForComparison(draftSourceCol);
      return columnName === draftSourceColumnName;
    });
    return {
      ...draftSourceCol,
      id: selectedSourceColumnExist ? selectedSourceColumnExist.id : generateId(),
      name: selectedSourceColumnExist ? selectedSourceColumnExist.name : draftSourceCol.name,
    };
  });
  return sourceColumnsWithIds;
}

function compareDraftSourceColumns(draftSourceColumns: Column[], selectedSourceColumns: Column[]) {
  let newShapeDetails: {
    deletedColumns: Column[];
    newColumnsTypes: Column[];
  } = { deletedColumns: [], newColumnsTypes: [] };
  selectedSourceColumns.forEach((col) => {
    const foundColumn = draftSourceColumns.find((draftSourceCol) => col.id === draftSourceCol.id);
    if (!foundColumn && col.shapeType !== columnShapeTypeEnum.STATIC) {
      // deleted column
      newShapeDetails.deletedColumns.push(col);
    }
    if (foundColumn && col.type !== foundColumn.type) {
      // new column type
      newShapeDetails.newColumnsTypes.push(foundColumn);
    }
  });
  return newShapeDetails;
}

function setSyncAsSourceDefaultConfigurationSaga({
  payload,
}: SetSyncAsSourceDefaultConfigurationSaga) {
  const {
    sourceDescription,
    partialActiveSourceConfiguration,
    draftSourceId,
    syncAudienceCategory,
  } = payload;
  const masterIdColumn = sourceDescription.shape.find((col) => col.name === 'masterid');
  const updatedAtColumn = sourceDescription.shape.find((col) => col.name === 'updated_at');
  return {
    selectedColumns: sourceDescription.shape,
    deduplicationSettings: {
      deduplicationKeys: [masterIdColumn?.id],
    },
    audienceDeduplicationColumns: [masterIdColumn],
    columns: [],
    dataSource: {
      ...partialActiveSourceConfiguration.dataSource,
      sourceDescription,
      draftSourceId,
      category: syncAudienceCategory,
      updatedAtColumnId: updatedAtColumn?.id,
      masterIdColumnId: masterIdColumn?.id,
      columns: [...sourceDescription.shape.filter((col) => col.name !== 'masterid')],
    },
  };
}
