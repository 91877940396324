export enum SqlAudienceQueryDescriptorStatus {
    ok = 'ok',
    error = 'error',
    pending = 'pending',
}

export enum SqlAudienceQueryDescriptorState {
    constructingSources = 'constructingSources',
    sourcesConstructed = 'sourcesConstructed',
    describingQuery = 'describingQuery',
    queryDescribed = 'queryDescribed',
    sourceConstructionFailed = 'sourceConstructionFailed',
    queryDescriptionFailed = 'queryDescriptionFailed',
}