import {
  Column,
  columnShapeTypeEnum,
  dataTypesEnum,
  retrievalModeEnum,
  UnionActiveSourceConfiguration,
} from '../../../../types';
import { UnionedAudienceSource } from '../../audienceBackTypes/audienceSpecification';
import { DataSourceType } from '../../audienceBackTypes/source';
import { mappingSelectedColumnsProperty } from './mappingSelectedColumnsProperty';
import { mappingSourcePreparationToFront } from './mappingJoinedSources';
import {
  mapSelectedEntity,
  mapSelectedEntityOfAudienceAsSource,
  mapSelectedEntityOfSyncAsSource,
} from './mappingSelectedEntity';
import {
  mapBackendAudienceDatasourceToActiveDataSource,
  mapBackendConnectionDatasourceToActiveDataSource,
  mapBackendSyncDatasourceToActiveDataSource,
  mapBackendWebhookDatasourceToActiveDataSource,
} from './mapDatasource';
import { mappingColumnTypePropertyToFront } from './mappingColumnTypePropertyToFront';
import { mapSelectedColumnsPropertyForWebhookSource } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mappingSelectedColumnsPropertyForWebhookSource';

// Mapping unioned sources back type to sources configuration front type
export const mappingUnionedSources = (
  unionedSourcesBackType: UnionedAudienceSource[]
): UnionActiveSourceConfiguration[] => {
  const unionedSourcesConfiguration: UnionActiveSourceConfiguration[] = [];

  // Add sources columns to the audience mapping for the front type
  unionedSourcesBackType.forEach((backSourceConfig, index) => {
    // const sourceConfigurationKey: string = generateId();

    if (backSourceConfig.source.type === DataSourceType.connection) {
      // Prepare dataSource and source configuration for connection source types
      const dataSource = mapBackendConnectionDatasourceToActiveDataSource(backSourceConfig.source);
      const sourceConfiguration: UnionActiveSourceConfiguration = {
        dataSource,
        dataType: dataTypesEnum.UNION,
        key: backSourceConfig.source.id,
        combinationLevel: index,
        selectedColumns: mappingSelectedColumnsProperty(backSourceConfig.source.columns), // @@@Todo: we should maybe add isNullable and shapeType property for the source columns ( shapeType = 'default' | 'static' )
        retrievalMode: retrievalModeEnum.INCREMENTAL_MODE, // @@@Note: property exists only in front we will fake it for now
        deduplicationSettings: {
          deduplicationKeys: backSourceConfig.source.deduplicationSettings.key.columnIds,
        },
        preparations: mappingSourcePreparationToFront(backSourceConfig.source.preparations),
        selectedEntity: mapSelectedEntity(backSourceConfig.source.sourceSettings),
      };
      unionedSourcesConfiguration.push(sourceConfiguration);
    } else if (backSourceConfig.source.type === DataSourceType.audience) {
      // Prepare dataSource and source configuration for audience as source types
      const dataSource = mapBackendAudienceDatasourceToActiveDataSource(backSourceConfig.source);
      const sourceConfiguration: UnionActiveSourceConfiguration = {
        dataSource,
        key: backSourceConfig.source.audienceId,
        dataType: dataTypesEnum.UNION,
        combinationLevel: index,
        selectedColumns: backSourceConfig.columnsMapping.reduce((result: Column[], elem) => {
          result.push({ id: elem.sourceColumnId } as Column);
          return result;
        }, []),
        retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
        // @@@Todo: we should get audience as source primary keys
        deduplicationSettings: {
          deduplicationKeys: [],
        },
        preparations: [],
        selectedEntity: mapSelectedEntityOfAudienceAsSource(backSourceConfig.source),
      };

      unionedSourcesConfiguration.push(sourceConfiguration);
    } else if (backSourceConfig.source.type === DataSourceType.sync) {
      // TODO @@@@koralex [review and fix?]
      const dataSource = mapBackendSyncDatasourceToActiveDataSource(backSourceConfig.source);
      const sourceConfiguration: UnionActiveSourceConfiguration = {
        dataSource,
        key: backSourceConfig.source.syncId,
        dataType: dataTypesEnum.UNION,
        combinationLevel: index,
        selectedColumns: [
          ...backSourceConfig.source.columns.map(
            (col): Column => ({
              id: col.id,
              name: col.name,
              externalName: col.fieldId,
              type: mappingColumnTypePropertyToFront(col.type),
              shapeType: columnShapeTypeEnum.DEFAULT,
              isNullable: true, // TODO @@@@koralex [incorrect]
            })
          ),
        ],
        // TODO @@@@koralex [should it be defined for sync sources?]
        retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
        deduplicationSettings: {
          deduplicationKeys: [backSourceConfig.source.masterIdColumnId],
        },
        preparations: [],
        selectedEntity: mapSelectedEntityOfSyncAsSource(backSourceConfig.source),
      };

      unionedSourcesConfiguration.push(sourceConfiguration);
    } else if (backSourceConfig.source.type === DataSourceType.webhook) {
      const dataSource = mapBackendWebhookDatasourceToActiveDataSource(backSourceConfig.source);
      const sourceConfiguration: UnionActiveSourceConfiguration = {
        dataSource,
        key: backSourceConfig.source.id,
        dataType: dataTypesEnum.UNION,
        combinationLevel: index,
        selectedColumns: mapSelectedColumnsPropertyForWebhookSource(
          backSourceConfig.source.columns
        ),
        retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
        preparations: mappingSourcePreparationToFront(backSourceConfig.source.preparations),
      };
      unionedSourcesConfiguration.push(sourceConfiguration);
    }
  });
  return unionedSourcesConfiguration;
};
