import { SyncRecordsScope } from './backTypes/ISyncRecordsScope';
import { Dayjs } from 'dayjs';
import { RecordsScopeFilter } from 'src/features/syncs/types/backTypes/ISyncRecordsScopeFilter';

export enum SyncScheduleTypes {
  REAL_TIME = 'realTime',
  SCHEDULED = 'scheduled',
  MANUAL = 'manual',
}
export type SyncScheduleManual = {
  type: SyncScheduleTypes.MANUAL;
};

export type IFrontSyncRecordsScope = {
  recordsScope: SyncRecordsScope;
  dateFilterType?: RecordsScopeFilter;
  fromMoment?: Dayjs;
  toMoment?: Dayjs;
};

export type SyncScheduleInterval = {
  type: SyncScheduleTypes.SCHEDULED;
  cron: string;
} & IFrontSyncRecordsScope;
export type SyncScheduleRealTime = {
  type: SyncScheduleTypes.REAL_TIME;
  isThrottled?: boolean;
};
export type SyncSchedule = SyncScheduleManual | SyncScheduleInterval | SyncScheduleRealTime;
