import {
  GetDestinationSchemaOutput,
  GetDestinationSchemaTypename,
} from '@features/syncs/types/backTypes/GetDestinationSchemaOutput';
import {
  DatabaseDestinationSchema,
  DestinationSchemaType,
} from '@features/syncs/types/DestinationSchema';
import { mappingColumnTypePropertyToFront } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mappingColumnTypePropertyToFront';

export const mapDestinationSchemaToFront = (
  backDestinationSchema: GetDestinationSchemaOutput
): DatabaseDestinationSchema => {
  switch (backDestinationSchema.__typename) {
    case GetDestinationSchemaTypename.DatabaseDestinationSchema:
      return {
        type: DestinationSchemaType.DB_SCHEMA,
        columnDescriptors: backDestinationSchema.columnDescriptors.map(
          ({ supportedTypes, ...rest }) => ({
            ...rest,
            supportedTypes: supportedTypes.map((type) => mappingColumnTypePropertyToFront(type)),
          })
        ),
      };

    case GetDestinationSchemaTypename.GetDestinationSchemaConnectionDestinationTypeIsNotSupported:
      throw new Error('Destination type connection type not supported');

    case GetDestinationSchemaTypename.GetDestinationSchemaConnectionNotFound:
      throw new Error('Destination type connection not found');

    default:
      throw new Error('Unexpected type');
  }
};
