import { FilterOperatorTypes, OctoRecordsFilter } from '@features/filter/types';

export interface SyncSegmentation {
  dataFilter: OctoRecordsFilter;
  recordsLeavingStrategy?: RecordsLeavingSegmentStrategy;
}

export enum RecordsLeavingSegmentStrategy {
  delete = 'delete',
  ignore = 'ignore',
}

export const segmentationAllowedOperators = [
  FilterOperatorTypes.is,
  FilterOperatorTypes.isNot,
  FilterOperatorTypes.isEmpty,
  FilterOperatorTypes.isNotEmpty,
  FilterOperatorTypes.regex,
  FilterOperatorTypes.contains,
  FilterOperatorTypes.is,
];
