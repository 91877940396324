import { columnTypesEnum } from '@features/objects/types';
import { DataType, IColumnType } from '../../../audienceBackTypes/columnType';

export const mappingColumnTypePropertyToBack = (
  columnType: columnTypesEnum,
  format?: string
): IColumnType => {
  switch (columnType) {
    case columnTypesEnum.STRING:
      return { dataType: DataType.String };
    case columnTypesEnum.TIMESTAMP:
      return { format, dataType: DataType.Timestamp };
    case columnTypesEnum.BOOLEAN:
      return { dataType: DataType.Boolean };
    case columnTypesEnum.INTEGER:
      return { dataType: DataType.Integer };
    case columnTypesEnum.FLOAT:
      return { dataType: DataType.Float };
    case columnTypesEnum.JSON:
      return { dataType: DataType.Json };
    case columnTypesEnum.JSON_ARRAY:
      return { dataType: DataType.Array, itemType: { dataType: DataType.Json } };
    case columnTypesEnum.TIMESTAMP_ARRAY:
      return { dataType: DataType.Array, itemType: { format, dataType: DataType.Timestamp } };
    case columnTypesEnum.INTEGER_ARRAY:
      return { dataType: DataType.Array, itemType: { dataType: DataType.Integer } };
    case columnTypesEnum.FLOAT_ARRAY:
      return { dataType: DataType.Array, itemType: { dataType: DataType.Float } };
    case columnTypesEnum.STRING_ARRAY:
      return { dataType: DataType.Array, itemType: { dataType: DataType.String } };
    case columnTypesEnum.BOOLEAN_ARRAY:
      return { dataType: DataType.Array, itemType: { dataType: DataType.Boolean } };
    case columnTypesEnum.SYSTEM_ID_ARRAY:
      return { dataType: DataType.Array, itemType: { dataType: DataType.SystemId } };
    case columnTypesEnum.SYSTEM_ID:
      return { dataType: DataType.SystemId };
    default:
      throw new Error(`column type: ${columnType} doesn't exist in the mapping`);
  }
};
