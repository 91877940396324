import { mappingColumnTypePropertyToBack } from '@features/audiences/ducks/api/mappingAudienceTypes/toBackType/mappingAudienceSpecificationProperty/mappingColumnTypePropertyToBack';
import { ActiveConnectionSettings, ConnectorType } from '@features/connections/types';
import { caseNever } from '@utils/case-never';
import { IConnectionSettingsV2 } from '../backendTypes/ConnectionReadModel';

export const toBackendConnectionSettings = (
  settings?: ActiveConnectionSettings
): IConnectionSettingsV2 | undefined => {
  if (settings === undefined) {
    return undefined;
  }

  switch (settings.type) {
    case ConnectorType.bigQuery:
    case ConnectorType.mysql:
    case ConnectorType.sftp:
    case ConnectorType.snowflake:
      return {
        ...settings,
      };

    case ConnectorType.postgres: {
      const { ssl, ...restOfSettings } = settings;
      return { ...restOfSettings, ssl: ssl ? true : false };
    }

    case ConnectorType.braze: {
      const { type, ...restOfSettings } = settings;
      return {
        ...restOfSettings,
        customAttributes:
          settings.customAttributes?.map((ca) => ({
            ...ca,
            type: mappingColumnTypePropertyToBack(ca.type),
          })) ?? [],
      };
    }

    case ConnectorType.googleSpreadhsheets:
    case ConnectorType.splio:
    case ConnectorType.sendinblue:
    case ConnectorType.sendinblueTracker:
    case ConnectorType.brevo:
    case ConnectorType.shopify:
    case ConnectorType.mailchimp:
    case ConnectorType.selligent:
    case ConnectorType.facebookAds: {
      const { type, ...restOfSettings } = settings;

      return { ...restOfSettings };
    }
    
    default:
      return caseNever(settings);
  }
};
