import { columnTypesEnum } from '@features/objects/types';
import { Dayjs } from 'dayjs';
// interfaces
export interface OctoRecordsFilter {
  key: string;
  combinationOperator: combinationOperator;
  groupFilters: FilterGroupType[];
}
export interface OctoRecordsQuickFilter {
  key: string;
  name: string;
  condition: ConditionType;
  object?: string;
}

// types
export type FilterGroupType = {
  id: string;
  type: string; // filter types are defined
  combinationOperator: combinationOperator;
  conditions: ConditionType[];
};
export type ConditionType = {
  id: string;
  fieldId: string;
  fieldName: string;
  fieldType: columnTypesEnum;
  fieldCategory?: string;
  operator?: OperatorDetails;
  quickFilterName?: string;
};
// TODO REFACTOR THIS TO UNION TYPE HANDLING EACH OPERATOR CASE
export type OperatorDetails = {
  type: FilterOperatorTypes; // operator are defined
  value?: any;
  isCaseSensitive?: boolean; // For regex operator
};
export type ConditionStyle = 'left' | 'middle';
export type ConditionForm = {
  operator: FilterOperatorTypes;
  value: string | number;
  values: string[];
  datePicker: Dayjs;
  rangePicker: Dayjs[];
  dateList: any[];
  saveAsQuickFilter: boolean;
  quickFilterName: string;
};
export type combinationOperator = 'or' | 'and';
// Specific input types for some operator other will be depending of selected schema column type
// None used for boolean types
export type operator = { value: FilterOperatorTypes; input?: OperatorInputTypes };
export enum FilterOperatorTypes {
  isInListOfDates = 'is in list of dates',
  isNotInListOfDates = 'is not in list of dates',
  isInListOfStrings = 'is in list of strings',
  isNotInListOfStrings = 'is not in list of strings',
  isInListOfIntegers = 'is in list of integer numbers',
  isNotInListOfIntegers = 'is not in list of integer numbers',
  isInListOfFloats = 'is in list of floats',
  isNotInListOfFloats = 'is not in list of floats',
  isInListOfBooleans = 'is in list of booleans',
  isNotInListOfBooleans = 'is not in list of booleans',
  isInListOfSystemIds = 'is in list of system-ids',
  isNotInListOfSystemIds = 'is not in list of system-ids',
  is = 'is',
  isNot = 'is not',
  regex = 'regex',
  contains = 'contains',
  isNotEmpty = 'is not empty',
  isEmpty = 'is empty',
  /**
  isNotBetween = 'is not between',
  isBetween = 'is between',
  isInLessThanXDays = 'is in less than X days',
  isInMoreThanXDays = 'is in more than X days',
  isLessThanXDaysAgo = 'is less than X days ago',
  isMoreThanXDaysAgo = 'is more than X days ago',
  isNeitherTrueNorFalse = 'is neither true nor false',
  greaterThan = 'greater than',
  lowerThan = 'lower than',
   */
}

// Constants and enums
export enum OperatorInputTypes {
  DATE_PICKER = 'datePicker',
  RANGE_PICKER = 'rangePicker',
  DATE_LIST = 'dateList',
  STRING = 'string',
  STRING_LIST = 'stringList',
  INTEGER = 'integer',
  INTEGER_LIST = 'integerList',
  FLOAT = 'float',
  FLOAT_LIST = 'floatList',
  BOOLEAN = 'boolean',
  BOOLEAN_LIST = 'booleanList',
  SYSTEM_ID = 'systemId',
  SYSTEM_ID_LIST = 'systemIdList',
  NONE = 'none',
}
export enum InputNames {
  DATE_LIST = 'dateList',
  DATE_PICKER = 'datePicker',
  RANGE_PICKER = 'rangePicker',
  VALUE = 'value',
  VALUES = 'values',
}

export enum OPERATORS_CATEGORY_TYPES {
  EMAIL = 'email',
  PHONE = 'phone',
}

export enum CONDITION_STYLE {
  LEFT = 'left',
  MIDDLE = 'middle',
}
export const COMBINATION_OPERATOR = {
  and: 'and',
  or: 'or',
};
export const DELETE_ACTIONS = {
  delete: 'delete',
  delete_quick_filter: 'delete_quick_filter',
};
