import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../locales/en';
import fr from '../locales/fr';

let translate: any;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources: {
        en,
        fr,
      },
      lng: 'en',
      fallbackLng: 'en',

      debug: false,

      // @@@Amine declaring the namespace gives an error that the key is missing
      // ns: ['translations'],
      // defaultNS: 'translations',

      interpolation: {
        escapeValue: false,
      },

      react: {
        useSuspense: true, // Enable Suspense
        bindI18n: 'languageChanged loaded',
      },
    },
    (err, t) => {
      translate = t;
    }
  );

export { translate as t };

export default i18n;
