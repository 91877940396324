export enum EncryptionType {
    none = 'none',
    openpgp = 'openpgp',
}

export enum OpenPgpSecretType {
    password = 'password',
    key = 'key',
}

export type NoneDecryption = {
    type: EncryptionType.none;
};
export type OpenPgpKeyDecryptionSettings = {
    type: OpenPgpSecretType.key;
    privateKey: string;
    publicKey?: string;
    passphrase?: string;
};
export type OpenPgpPasswordDecryptionSettings = {
    type: OpenPgpSecretType.password;
    password: string;
};
export type OpenPgpDecryptionSettings =
    | OpenPgpKeyDecryptionSettings
    | OpenPgpPasswordDecryptionSettings;

export type OpenPgpDecryption = {
    type: EncryptionType.openpgp;
    outputFormat: OpenPgpDecryptionOutputFormatType;
    inputFormat: OpenPgpDecryptionInputFormatType;
    settings: OpenPgpDecryptionSettings;
};
export type IDecryption = NoneDecryption | OpenPgpDecryption;

export enum OpenPgpDecryptionOutputFormatType {
    binary = 'binary',
    utf8 = 'utf8',
}
export enum OpenPgpDecryptionInputFormatType {
    binary = 'binary',
    armored = 'armored',
}