import { ApiConnectionEntity, ApiConnectionEntityTypeOutput } from '@features/connections/types/ApiConnectionEntity';

export enum GetDestinationEntitiesInputTypes {
  connection = 'connection',
}

export enum GetDestinationEntitiesOutputTypes {
  api = 'api',
  fs = 'fs',
  database = 'database',
}

export type GetDestinationEntitiesInput = {
  accountId: string;
  settings: {
    type: GetDestinationEntitiesInputTypes.connection;
    connectionId: string;
  };
};

export enum DestinationEntitiesTypes {
  apiEntity = 'apiEntity',
  file = 'file',
  folder = 'folder',
  table = 'table',
  schema = 'schema',
}

export enum FileSystemEntities {
  file = 'file',
  folder = 'folder',
}
export type FileEntity = {
  key: string;
  type: FileSystemEntities.file;
  name: string;
};

export type FolderEntity = {
  key: string;
  type: FileSystemEntities.folder;
  name: string;
  children: FileSystemEntity[];
};

export type FileSystemEntity = FileEntity | FolderEntity;

export type GetDestinationConnectionFileSystemEntitiesOutput = {
  type: GetDestinationEntitiesOutputTypes.fs;
  entities: FileSystemEntity[];
};

export type IDestinationTableEntity = {
  type: DestinationEntitiesTypes.table;

  key: string;
  name: string;
  meta: any;
};

export type IDestinationSchemaEntity = {
  type: DestinationEntitiesTypes.schema;

  key: string;
  name: string;
  meta: any;
  tables: IDestinationTableEntity[];
};
export type GetDestinationDBEntitiesOutput = {
  type: GetDestinationEntitiesOutputTypes.database;
  entities: IDestinationSchemaEntity[];
};

export type DestinationEntity = ApiConnectionEntity | FileSystemEntity;

export type GetDestinationApiEntitiesOutput = {
  type: GetDestinationEntitiesOutputTypes.api;
} & ApiConnectionEntityTypeOutput;

export type GetDestinationEntitiesOutput =
  | GetDestinationConnectionFileSystemEntitiesOutput
  | GetDestinationDBEntitiesOutput
  | GetDestinationApiEntitiesOutput;
