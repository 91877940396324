// TODO: Splitting into a module / directory

import { AudienceColumnId } from './audienceColumn';
import { SourceColumn } from './source';

export enum ColumnAggregationTypeBack {
  first_value = 'first_value',
  array = 'array',
  sum = 'sum',
  count_values = 'count_values',
  count_records = 'count_records',
  every = 'every',
  some = 'some',

  max = 'max',
  min = 'min',
  average = 'average',
  average_difference = 'average_difference',
  most_frequent_value = 'most_frequent_value',
}

export type ColumnAggregation =
  | FirstValueColumnAggregation
  | ArrayColumnAggregation
  | EveryColumnAggregation
  | SomeColumnAggregation
  | CountRecordsColumnAggregation
  | CountValuesColumnAggregation
  | MaxColumnAggregation
  | MinColumnAggregation
  | AverageColumnAggregation
  | AverageDifferenceColumnAggregation
  | MostFrequentValueColumnAggregation
  | SumColumnAggregation;

export type FirstValueColumnAggregation = Readonly<{
  // TODO @@@@koralex it could be used with all columns types
  type: ColumnAggregationTypeBack.first_value;
  sourceColumnId: SourceColumn['id'];
  sort?: ColumnAggregationSortOrder[];
  ignoreNull: boolean;
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;

export type ArrayColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.array;
  sourceColumnId: SourceColumn['id'];
  sort?: ColumnAggregationSortOrder[];
  distinct: boolean;
  ignoreNull: boolean;
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;

// TODO @@@@koralex we need to have a filter by a different
// TODO @@@@koralex rename agg column definition
export type SumColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.sum;
  sourceColumnId: SourceColumn['id'];
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;

export type CountValuesColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.count_values;
  sourceColumnId: SourceColumn['id'];
  distinct: boolean;
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;

export type CountRecordsColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.count_records;
  distinct: boolean;
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;

export type EveryColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.every;
  sourceColumnId: SourceColumn['id'];
  value: EveryColumnAggregationValue;
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;
export type SomeColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.some;
  sourceColumnId: SourceColumn['id'];
  value: SomeColumnAggregationValue;
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;
export type AverageColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.average;
  sourceColumnId: SourceColumn['id'];
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;
export type AverageDifferenceColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.average_difference;
  sourceColumnId: SourceColumn['id'];
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
  sort?: ColumnAggregationSortOrder[];
  ranks?: number[]; // Specifying ranks to choose
}>;
export type MostFrequentValueColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.most_frequent_value;
  sourceColumnId: SourceColumn['id'];
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;

export type MaxColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.max;
  sourceColumnId: SourceColumn['id'];
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;
export type MinColumnAggregation = Readonly<{
  type: ColumnAggregationTypeBack.min;
  sourceColumnId: SourceColumn['id'];
  datePeriodFilter?: DatePeriodFilter; // TODO @@@@koralex maybe have it explicit instead of optional
  columnFilter?: ColumnFilter;
}>;
export enum EveryColumnAggregationValue {
  is_true = 'is_true',
  is_false = 'is_false',
  is_null = 'is_null',
}

export enum SomeColumnAggregationValue {
  is_true = 'is_true',
  is_false = 'is_false',
  is_null = 'is_null',
}

export enum ColumnAggregationSortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type ColumnAggregationSortOrder = Readonly<{
  sourceColumnId: SourceColumn['id'];
  direction: ColumnAggregationSortDirection;
}>;

export enum ColumnFilteringStrategyType {
  match_value = 'match_value',
  mismatch_value = 'mismatch_value',

  match_regex = 'match_regex',
  mismatch_regex = 'mismatch_regex',
}

export type MatchValueColumnFilteringStrategy = Readonly<{
  type: ColumnFilteringStrategyType.match_value;
  value: string;
}>;

export type MismatchValueColumnFilteringStrategy = Readonly<{
  type: ColumnFilteringStrategyType.mismatch_value;
  value: string;
}>;

export type MatchRegexColumnFilteringStrategy = Readonly<{
  type: ColumnFilteringStrategyType.match_regex;
  value: string;
}>;

export type MismatchRegexColumnFilteringStrategy = Readonly<{
  type: ColumnFilteringStrategyType.mismatch_regex;
  value: string;
}>;

export type ColumnFilteringStrategy =
  | MatchValueColumnFilteringStrategy
  | MismatchValueColumnFilteringStrategy
  | MatchRegexColumnFilteringStrategy
  | MismatchRegexColumnFilteringStrategy;

export type ColumnFilter<Strategy extends ColumnFilteringStrategy = ColumnFilteringStrategy> =
  Readonly<{
    sourceColumnId: AudienceColumnId;
    strategy: Strategy;
  }>;

// **** date filtering

export enum DatePeriodFilterSourceColumnType {
  data = 'data',
  modified_at = 'modified_at',
}
export type DatePeriodFilterSourceDataColumn = Readonly<{
  type: DatePeriodFilterSourceColumnType.data;
  columnId: SourceColumn['id'];
}>;

export type DatePeriodFilterSourceModifiedAtColumn = Readonly<{
  type: DatePeriodFilterSourceColumnType.modified_at;
}>;

export type DatePeriodFilterSourceColumn =
  | DatePeriodFilterSourceDataColumn
  | DatePeriodFilterSourceModifiedAtColumn;

export type DatePeriodFilter = Readonly<{
  dateRange: DateRange;
  sourceColumn: DatePeriodFilterSourceColumn;
}>;

export type DateRange = Readonly<{
  start: DateBoundary;
  end: DateBoundary;
}>;

export enum DateBoundaryType {
  relative = 'relative',
  absolute = 'absolute',
  today = 'today',
}

export type DateBoundary = RelativeDate | AbsoluteDate | Today;
export type RelativeDateComponent = number | 'first' | 'last';

export type RelativeDate = Readonly<{
  // 'relative' without any year, month, nor day, is equivalent to Today
  type: DateBoundaryType.relative;
  year?: number; // 0 (This year), 1 (Next), -2 (Two year prior)
  month?: RelativeDateComponent; // 1 month ago / 2 month into the future / First month or last month of the year
  day?: RelativeDateComponent; // First / Last day of the month
}>;

export type AbsoluteDate = Readonly<{
  type: DateBoundaryType.absolute;
  year: number;
  month: number;
  day: number;
}>;
// TODO do we really need an explicit "today" value? relative with no year, month or day modifier implicitly means today, so...
export type Today = Readonly<{
  type: DateBoundaryType.today;
}>;
