import { getConnectionsSaga } from '@features/connections/ducks/sagas';
import { getDeployedAudiencesSaga } from '@features/audiences/ducks/sagas/allSagas';
import { all, call, put, select } from 'redux-saga/effects';
import { getAudienceDataForSyncSaga, getDestinationSchemaSaga, getSyncsSaga, getSyncToEditSaga } from './sagas';
import syncsSlice from '../syncsSlice';
import { Sync } from '@features/syncs/types';
import {
  InitSyncCreationScreenSaga,
  InitSyncEditionScreenSaga,
  InitSyncsListScreenSaga,
} from '../types';
import { DEFAULT_AUDIENCE_DATA_PAGE_SIZE } from '@features/audiences/helpers/constants/constants';
import { Connection } from '@features/connections/types';
import { connectionsSelector } from '@redux/selectors';

export function* initSyncCreationScreenSaga({ payload }: InitSyncCreationScreenSaga) {
  const { abortSignal } = payload;
  yield all([
    put({
      type: syncsSlice.actions.resetDestinationSchema.type,
    }),
    put({
      type: syncsSlice.actions.setEmptyActiveSync.type,
    }),
    getConnectionsSaga(),
    getDeployedAudiencesSaga({ type: 'getDeployedAudiences', payload: { abortSignal } }),
    getSyncsSaga({ type: 'getSyncs', payload: { abortSignal } }),
  ]);
}

export function* initSyncEditionScreenSaga({ payload }: InitSyncEditionScreenSaga) {
  const { syncId, abortSignal } = payload;
  const syncResponse: { sync?: Sync; error?: boolean; errorDetails?: string } = yield call(
    getSyncToEditSaga,
    { syncId }
  );
  const sync = syncResponse.sync;

  if (sync) {
    yield all([
      put({
        type: syncsSlice.actions.resetDestinationSchema.type,
      }),
      put({
        type: syncsSlice.actions.setEmptyActiveSync.type,
      }),
      getDestinationSchemaSaga({
        type: syncsSlice.actions.getDestinationSchema.type,
        payload: {
          destination: sync.destination,
        },
      }),
      getAudienceDataForSyncSaga({
        type: 'getAudienceDataForSync',
        payload: {
          audienceId: sync.audienceId,
          paging: { page: 1, pageSize: DEFAULT_AUDIENCE_DATA_PAGE_SIZE },
        },
      }),
      getConnectionsSaga(),
      getDeployedAudiencesSaga({ type: 'getDeployedAudiences', payload: { abortSignal } }),
      getSyncsSaga({ type: 'getSyncs', payload: { abortSignal } }),
    ]);

    const connections: Connection[] = yield select(connectionsSelector);

    const destinationConnection = connections.find(({id}) => id === sync?.destinationConnectionId);

    yield put({
      type: syncsSlice.actions.initSyncEditionScreenSuccess.type,
      payload: {
        sync: {
          ...sync,
          destinationConnectionType: destinationConnection?.settings.type
        }
      },
    });
  } else {
    yield put({
      type: syncsSlice.actions.initSyncEditionScreenFailed.type,
      payload: syncResponse,
    });
  }
}
export function* initSyncsListScreenSaga({ payload }: InitSyncsListScreenSaga) {
  const { abortSignal } = payload;
}
