import { Space } from 'antd';
import classnames from 'classnames';
import styles from './screenSpinner.module.css';

const ScreenSpinner = () => {
  return (
    <Space className={styles.ScreenSpinContainer} direction="vertical">
      <div className={styles['breeding-rhombus-spinner']}>
        <div className={classnames(styles.rhombus, styles['child-1'])} />
        <div className={classnames(styles.rhombus, styles['child-2'])} />
        <div className={classnames(styles.rhombus, styles['child-3'])} />
        <div className={classnames(styles.rhombus, styles['child-4'])} />
        <div className={classnames(styles.rhombus, styles['child-5'])} />
        <div className={classnames(styles.rhombus, styles['child-6'])} />
        <div className={classnames(styles.rhombus, styles['child-7'])} />
        <div className={classnames(styles.rhombus, styles['child-8'])} />
        <div className={classnames(styles.rhombus, styles.big)} />
      </div>
    </Space>
  );
};
export default ScreenSpinner;
