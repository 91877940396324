import { OrganizationByDomain, OrganizationType } from '@features/organizations/types';
import { caseNever } from '@utils/case-never';

export const getOrganizationAppContextName = (organization: OrganizationByDomain) => {
  if (organization) {
    switch (organization?.type) {
      case OrganizationType.partner:
        return organization.partnerId;
      case OrganizationType.octolis:
        return OrganizationType.octolis;
      case OrganizationType.customer:
        return organization.partnerId ? organization.partnerId : OrganizationType.octolis;
      default:
        caseNever(organization);
    }
  }
  return undefined;
};
