import { ScheduleType } from '@contracts/schedule';

export type IAudienceSourceExecutionTriggerSpecification =
  | IRealTimeAudienceSourceExecutionTriggerSpecification
  | IManualAudienceSourceExecutionTriggerSpecification
  | IScheduledSyncExecutionTriggerSpecification;

export enum AudienceSourceTriggerType {
  realTime = 'realTime',
  manual = 'manual',
  scheduled = 'scheduled',
}

export type IRealTimeAudienceSourceExecutionTriggerSpecification = {
  type: AudienceSourceTriggerType.realTime;
};
export type IManualAudienceSourceExecutionTriggerSpecification = {
  type: AudienceSourceTriggerType.manual;
};
export type IScheduledSyncExecutionTriggerSpecification = {
  type: AudienceSourceTriggerType.scheduled;
  recordsScope: IAudienceSourceRecordsScope;
  schedule: CronSchedule;
};
export enum AudienceSourceRecordsScopeType {
  allRecords = 'allRecords',
  unprocessedRecords = 'unprocessedRecords',
}

export type IAudienceSourceRecordsScope =
  | IAllAudienceSourceRecordsScope
  | IUnprocessedAudienceSourceRecordsScope;

export type IAllAudienceSourceRecordsScope = {
  type: AudienceSourceRecordsScopeType.allRecords;
};
export type IUnprocessedAudienceSourceRecordsScope = {
  type: AudienceSourceRecordsScopeType.unprocessedRecords;
};

export type CronSchedule = {
  type: ScheduleType.cron;
  value: string;
  timezone?: string;
  startDate?: string | number;
  endDate?: string | number;
};
