import { ActiveParentAudience } from '@features/audiences/types/ActiveAudience/ActiveParentAudience';
import {
  ParentAudience,
  ParentAudienceCondition,
  ParentAudienceConditionType,
  ParentAudienceJoin,
} from '@features/audiences/ducks/api/audienceBackTypes/ParentAudience';

export const mappingParentAudienceToBack = (
  activeParentAudience: ActiveParentAudience | null
): ParentAudience | null => {
  if (
    activeParentAudience &&
    activeParentAudience.audienceId &&
    activeParentAudience.join &&
    activeParentAudience.join.parentColumn &&
    activeParentAudience.join.outputColumnName &&
    activeParentAudience.join.childAllMatchesColumnId &&
    activeParentAudience.join.childMatchColumnId &&
    activeParentAudience.conditions.length
  ) {
    const backConditions: ParentAudienceCondition[] = [];
    activeParentAudience.conditions.forEach((condition) => {
      switch (condition.type) {
        case ParentAudienceConditionType.regular:
          const newColumns = condition.columns.map((col) => {
            return {
              childColumnId: col.childColumnId,
              parentColumnId: col.parentColumnId,
            };
          });
          backConditions.push({
            type: ParentAudienceConditionType.regular,
            columns: newColumns,
          });
          break;
        case ParentAudienceConditionType.source_id_list:
          backConditions.push({
            type: ParentAudienceConditionType.source_id_list,
            childColumnId: condition.childColumnId,
            parentSourceId: condition.parentSourceId,
            parentSourceIdColumnId: condition.parentSourceIdColumnId,
          });
          break;
      }
    });
    const backJoin: ParentAudienceJoin = {
      parentColumn: activeParentAudience.join.parentColumn,
      outputColumnName: activeParentAudience.join.outputColumnName,
      childAllMatchesColumnId: activeParentAudience.join.childAllMatchesColumnId,
      childMatchColumnId: activeParentAudience.join.childMatchColumnId,
    };
    return {
      audienceId: activeParentAudience.audienceId,
      join: backJoin,
      conditions: backConditions,
      draftSourceId: activeParentAudience.draftSourceId,
    };
  }
  return null;
};
