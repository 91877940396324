import sortAudienceColumns from '@features/audiences/helpers/sortAudienceColumns';
import { AudienceColumn } from '@features/audiences/types/AudienceColumn';
import { AudiencePreviewShapes } from '@features/audiences/types/AudiencePreview/AudiencePreview';
import {
  PreparationStep,
  PreparationStepConfiguration,
} from '@features/audiences/types/Preparation';
import { AudienceSpecificationType } from '../../audienceBackTypes/audienceSpecification';
import { BackAudiencePreviewShapes } from '../../types';
import { mappingDefaultAudienceColumnValueToFront } from './mappingColumns/mappingDefaultAudienceColumnValueToFront';
import { mappingColumnTypePropertyToFront } from './mappingColumnTypePropertyToFront';
import mappingPreparationStepsToFront from './mappingPreparationStepsToFront';

// Mapping audiencePreviewShapes properties used to get prep steps details
export const mappingAudiencePreviewShapesToFront = (
  shapes: BackAudiencePreviewShapes
): AudiencePreviewShapes => {
  switch (shapes.audienceType) {
    case AudienceSpecificationType.default:
      const steps = shapes.preparationSteps.steps.reduce(
        (result: { step: PreparationStepConfiguration; inputShape: AudienceColumn[] }[], elem) => {
          const stepsWithFrontType: PreparationStep[] = mappingPreparationStepsToFront([elem.step]);
          const preparationStep: PreparationStep | null = stepsWithFrontType.length
            ? stepsWithFrontType[0]
            : null;

          const inputShape: AudienceColumn[] = elem.inputShape.map((c) => {
            const column: AudienceColumn = {
              id: c.id,
              name: c.name,
              type: mappingColumnTypePropertyToFront(c.type),
              defaultValue:
                c.defaultValue && mappingDefaultAudienceColumnValueToFront(c.defaultValue),
            };
            return column;
          });
          preparationStep && result.push({ inputShape, step: preparationStep.configuration });
          return result;
        },
        []
      );
      const outputShapeToFront = shapes.preparationSteps.outputShape.map((c) => {
        const column: AudienceColumn = {
          id: c.id,
          name: c.name,
          type: mappingColumnTypePropertyToFront(c.type),
          defaultValue: c.defaultValue && mappingDefaultAudienceColumnValueToFront(c.defaultValue),
        };
        return column;
      });
      const audiencePreviewShapes: AudiencePreviewShapes = {
        audienceType: AudienceSpecificationType.default,
        preparationSteps: {
          steps,
          outputShape: sortAudienceColumns(outputShapeToFront),
        },
      };
      return audiencePreviewShapes;
    case AudienceSpecificationType.sql:
      return {
        audienceType: AudienceSpecificationType.sql,
      };
  }
};
