import { PreparationActionType } from '@features/audiences/types/PreparationActionType';
import { columnTypesEnum } from '@features/objects/types';
import { AudienceConfigurationEnum } from '@features/audiences/types/AudienceConfiguration';

// Preparation STEPS TYPES / ENUM / FORM TYPES
export enum PreparationStepCategoriesEnum {
  DATA_PREPARATION = 'dataPreparation',
  BUSINESS_PREPARATION = 'businessPreparation',
  SCORING = 'scoring',
}

export type PreparationStepConfiguration =
  | GenerateCurrentTimestampColumnsPreparationAction
  | FilterByFlagsPreparationAction
  | FormatAsStringPreparationAction
  | FormatAsBooleanPreparationAction
  | CombineValuesPreparationAction
  | ConcatValuesAsStringPreparationAction
  | HashValuesPreparationAction
  | CustomSqlPreparationAction
  | CustomFunctionPreparationAction
  | ConditionalStatementSqlPreparationAction
  | CopyColumnsPreparationAction
  | KeepColumnsPreparationAction
  | DeleteColumnsPreparationAction
  | RenameColumnsPreparationAction
  | ChangeStringsCasesPreparationAction
  | FindReplaceInStringsPreparationAction
  | ReplaceStringColumnValuesWithNullPreparationAction
  | SplitStringColumnValuesToArrayColumnsPreparationAction
  | TestStringColumnValuesWithRegexPreparationAction
  | TrimStringColumnValuesPreparationAction
  | RoundNumbersPreparationAction
  | FormatTimestampsAsStringsPreparationAction
  | FormatPhoneNumbersPreparationAction
  | ExtractJSONByPathPreparationAction
  | CheckEmailsValidityPreparationAction
  | TruncateTimestampsPreparationAction;

export type PreparationStep = {
  key: string;
  title: string;
  category: PreparationStepCategoriesEnum;
  configuration: PreparationStepConfiguration;
};
export type ActivePreparationStep = {
  key: string;
  title?: string;
  category?: PreparationStepCategoriesEnum;
  configuration?: PreparationStepConfiguration;
};
// GenerateCurrentTimestampColumnsPreparationAction
export type GenerateCurrentTimestampColumnsPreparationAction = {
  type: PreparationActionType.generate_current_timestamp_columns;
  columns: {
    targetColumnId: string; // Audience column id
    targetColumnName: string; // Audience column name
  }[];
};
// FilterByFlagsPreparationAction
export type FilterByFlagsPreparationAction = {
  type: PreparationActionType.filter_by_flags;
  columns: {
    key: string;
    flagColumnId: string; // Audience column id
    expectedValue: boolean | null;
  }[];
};
// FormatAsStringPreparationAction
export type FormatAsStringPreparationAction = {
  type: PreparationActionType.format_as_string;
  columns: {
    key: string;
    originalColumnId: string; // Audience column id
  }[];
};
// FormatAsBooleanPreparationAction
export type FormatAsBooleanPreparationAction = {
  type: PreparationActionType.format_as_boolean;
  columns: {
    key: string;
    originalColumnId: string; // Audience column id
  }[];
  onError: FormattingPreparationActionErrorHandlingStrategy;
};

export enum FormattingPreparationActionErrorHandlingStrategy {
  throwError = 'throw-error',
  returnNull = 'return-null',
}

// CombineValuesPreparationAction
export type CombineValuesPreparationAction = {
  type: PreparationActionType.combine_values;
  columns: {
    key: string;
    originalColumnIds: string[];
    expectedType: columnTypesEnum;
    targetColumnId: string; // Audience column id
    targetColumnName: string; // Audience column name
  }[];
};
// ConcatValuesAsStringPreparationAction
export type ConcatValuesAsStringPreparationAction = {
  type: PreparationActionType.concat_values_as_string;
  columns: {
    key: string;
    originalColumnIds: string[];
    delimiter: string;
    targetColumnId: string; // Audience column id
    targetColumnName: string; // Audience column name
  }[];
};

export enum PreparingHashAlgorithm {
  md5 = 'md5',
  sha256 = 'sha256',
}

// HashValuesPreparationAction
export type HashValuesPreparationAction = {
  type: PreparationActionType.hash_values;

  algorithm?: PreparingHashAlgorithm;

  lowercase?: boolean;
  columns: {
    key: string;
    originalColumnIds: string[]; // Audience column ids
    targetColumnId: string; // Audience column id
    targetColumnName: string; // Audience column name
  }[];
};
// CustomSqlPreparationAction
export type CustomSqlPreparationAction = {
  type: PreparationActionType.custom_sql;
  columns: {
    key: string;
    sqlExpression: string;
    expectedType: columnTypesEnum;
    targetColumnId: string; // Audience column id
    targetColumnName: string; // Audience column name
  }[];
};
// CustomFunctionPreparationAction
export type CustomFunctionPreparationAction = {
  type: PreparationActionType.custom_function;

  function: {
    name: string;
    expectedType: columnTypesEnum;
  };

  columns: string[]; // Audience column ids
  isStrict?: boolean; // Determines if changing the type is permitted
};
// ConditionalStatementSqlPreparationAction
export type ConditionalStatementSqlPreparationAction = {
  type: PreparationActionType.conditional_statement_sql;
  columns: {
    conditionSqlExpression: string;
    onTrueSqlExpression: string;
    onFalseSqlExpression: string;
    expectedType: columnTypesEnum;
    targetColumnId: string; // Audience column id
    targetColumnName: string; // Audience column name
  }[];
};
// CopyColumnsPreparationAction
export type CopyColumnsPreparationAction = {
  type: PreparationActionType.copy_columns;
  columns: {
    key: string;
    originalColumnId: string;
    targetColumnId: string;
    targetColumnName: string;
  }[];
};
// KeepColumnsPreparationAction
export type KeepColumnsPreparationAction = {
  type: PreparationActionType.keep_columns;
  columns: {
    key: string;
    originalColumnId: string; // Audience column id
  }[];
};
// DeleteColumnsPreparationAction
export type DeleteColumnsPreparationAction = {
  type: PreparationActionType.delete_columns;
  columns: {
    key: string;
    originalColumnId: string; // Audience column id
  }[];
};
// RenameColumnsPreparationAction
export type RenameColumnsPreparationAction = {
  type: PreparationActionType.rename_columns;
  columns: {
    key: string;
    originalColumnId: string; // Audience column id
    targetColumnName: string; // Audience column name
  }[];
};
// ChangeStringsCasesPreparationAction
export type ChangeStringsCasesPreparationAction = {
  type: PreparationActionType.change_strings_cases;
  columns: {
    key: string;
    columnId: string; // Audience column id
    stringCase: StringCaseChangeType;
  }[];
};

export enum StringCaseChangeType {
  lowercase = 'lowercase',
  uppercase = 'uppercase',
  initcase = 'initcase',
  initcase_first_only = 'initcase_first_only',
}

// FindReplaceInStringsPreparationAction
export type FindReplaceInStringsPreparationAction = {
  type: PreparationActionType.find_replace_in_strings;
  columns: {
    key: string;
    columnId: string; // Audience column id
    find: string;
    replace: string;
    isCaseSensitive: boolean;
  }[];
};
// ReplaceStringColumnValuesWithNullPreparationAction
export type ReplaceStringColumnValuesWithNullPreparationAction = {
  type: PreparationActionType.replace_string_column_values_with_null;
  columns: string[];
};
// SplitStringColumnValuesToArrayColumnsPreparationAction
export type SplitStringColumnValuesToArrayColumnsPreparationAction = {
  type: PreparationActionType.split_string_column_values_to_array_columns;
  columns: {
    delimiter: string;
    splitStringColumnValuesList: {
      key: string;
      originalColumnId: string;
      targetColumnId: string;
      targetColumnName: string;
    }[];
  };
};
// TestStringColumnValuesWithRegexPreparationAction
export type TestStringColumnValuesWithRegexPreparationAction = {
  type: PreparationActionType.test_string_column_values_with_regex;

  columns: {
    key: string;
    originalColumnId: string; // Audience column id
    regex: string;
    targetColumnId: string; // Audience column id
    targetColumnName: string; // Audience column name
  }[];
};
// Round numbers
export type RoundNumbersPreparationAction = {
  type: PreparationActionType.round_numbers;

  columns: {
    key: string;
    columnId: string; // Audience column id
    decimals: number;
  }[];
};
// format timestamps as strings
export type IComplexTimestampFormat = Readonly<{
  date?: string;
  time?: string;
  delimiter?: string;
}>;
export type IFullTimestampFormat = string | IComplexTimestampFormat;

export type FormatTimestampsAsStringsPreparationAction = {
  type: PreparationActionType.format_timestamps_as_strings;

  columns: {
    key: string;
    originalColumnId: string; // Audience column id
    format: IFullTimestampFormat;
    targetColumnId: string;
    targetColumnName: string;
  }[];
};
// format phone numbers
export type PhoneFormattingOutputPrefix = '+' | '00';
export enum PhoneFormattingInvalidOutput {
  null_value = 'null_value',
  raw = 'raw',
}
export type FormatPhoneNumbersPreparationAction = {
  type: PreparationActionType.format_phone_numbers;
  prefix: PhoneFormattingOutputPrefix;
  output: PhoneFormattingInvalidOutput;
  columns: string[];
};
// TrimStringColumnValuesPreparationAction
export type TrimStringColumnValuesPreparationAction = {
  type: PreparationActionType.trim_string_column_values;
  columns: string[]; // Audience column ids
};

// TrimStringColumnValuesPreparationAction
export type ExtractJSONByPathPreparationAction = {
  type: PreparationActionType.extract_json_by_path;
  columns: {
    key: string;
    originalColumnId: string;
    path: string;
    outputType?: columnTypesEnum;
    targetColumnId: string; // Audience column id
    targetColumnName: string; // Audience column name
  }[];
};
// TruncateTimestampsPreparationAction
export enum TimestampPart {
  year = 'year',
  month = 'month',
  day = 'day',
  hour = 'hour',
  minute = 'minute',
  second = 'second',
}
export type TruncateTimestampsPreparationAction = Readonly<{
  type: PreparationActionType.truncate_timestamps;

  columns: {
    columnId: string;
    part: TimestampPart;
  }[];
}>;

// Check email validity
export type CheckEmailsValidityPreparationAction = {
  type: PreparationActionType.check_emails_validity;
  columns: {
    key: string;
    originalColumnId: string;
    targetColumnId: string; // Audience column id
    targetColumnName: string; // Audience column name
  }[];
};
// MoveAppliedPreparationTypes
export enum MoveAppliedPreparationTypes {
  up = 'up',
  down = 'down',
}

export type AppliedStepPreparation = {
  key: string;
  type: AudienceConfigurationEnum.preparation;
  title: string;
  preparationKey: string;
  preparationCategory: PreparationStepCategoriesEnum;
};
// PreparationAction used in components
export type PreparationAction = {
  actionType: AudienceConfigurationEnum.preparation;
  type: PreparationActionType;
  name: string;
  category: PreparationStepCategoriesEnum;
  actionBox?: JSX.Element;
};
