import {
  AudienceDataSource,
  ConnectionDataSource,
  DataSource,
  SyncDataSource,
  WebhookDataSource,
} from '@features/audiences/ducks/api/audienceBackTypes/source';
import {
  ActiveAudienceDataSource,
  ActiveConnectionDataSource,
  ActiveSyncDataSource,
  ActiveWebhookDataSource,
  sourceTypesEnum,
} from '@features/audiences/types';
import { mappingSourceSettingsProperty } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mappingSourceSettingsProperty';
import { Connection } from '@features/connections/types';
import { BusinessCategoryEnum } from '@features/objects/types';

type IMapBackendDatasourceToActiveDataSourceReturnType<Datasource extends DataSource = DataSource> =
  Datasource extends AudienceDataSource
    ? ActiveAudienceDataSource
    : Datasource extends ConnectionDataSource
    ? ActiveConnectionDataSource
    : Datasource extends SyncDataSource
    ? ActiveSyncDataSource
    : Datasource extends WebhookDataSource
    ? ActiveWebhookDataSource
    : never;

type IMapBackendDatasourceToActiveDataSource<InputType extends DataSource = DataSource> = (
  source: InputType
) => IMapBackendDatasourceToActiveDataSourceReturnType<InputType>;

export const mapBackendAudienceDatasourceToActiveDataSource: IMapBackendDatasourceToActiveDataSource<
  AudienceDataSource
> = (source) => ({
  type: sourceTypesEnum.AUDIENCE,
  key: source.audienceId,
  audienceId: source.audienceId,
  // @@@Todo: we should get business category,name,updatedAtColumnId for audience as source case
  category: {} as BusinessCategoryEnum,
  name: '',
  updatedAtColumnId: '',
  draftSourceId: source.draftSourceId,
  executionTrigger: source.executionTrigger,
});

export const mapBackendConnectionDatasourceToActiveDataSource: IMapBackendDatasourceToActiveDataSource<
  ConnectionDataSource
> = (source) => ({
  key: source.id,
  type: sourceTypesEnum.CONNECTION,
  name: source.name,
  category: source.businessCategory,
  sourceSettings: mappingSourceSettingsProperty(source.sourceSettings),
  connection: { id: source.connectionId } as Connection, // @@@Todo: check if selecting only connection id can make an issue in the front.
  updatedAtColumnId: source.modifiedAtColumnId,
  draftSourceId: source.draftSourceId,
  modifiedAtOptions: source.modifiedAtOptions,
  modifiedAtFormat: source.modifiedAtFormat,
  schedule: source.schedule,
});

export const mapBackendSyncDatasourceToActiveDataSource: IMapBackendDatasourceToActiveDataSource<
  SyncDataSource
> = (source) => ({
  type: sourceTypesEnum.SYNC,
  key: source.syncId,
  syncId: source.syncId,
  updatedAtColumnId: source.modifiedAtColumnId,
  masterIdColumnId: source.masterIdColumnId,
  category: {} as BusinessCategoryEnum,
  name: '',
  columns: source.columns,
  draftSourceId: source.draftSourceId,
});

export const mapBackendWebhookDatasourceToActiveDataSource: IMapBackendDatasourceToActiveDataSource<
  WebhookDataSource
> = (source) => ({
  type: sourceTypesEnum.WEBHOOK,
  name: `webhook: ${source.webhookId}`,
  id: source.id,
  webhookId: source.webhookId,
  updatedAtColumnId: source.modifiedAtColumnId,
  columns: source.columns,
  draftSourceId: source.draftSourceId,
  filterExpression: source.filterExpression,
  keyColumnIds: source.keyColumnIds,
});
