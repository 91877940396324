import {
  AggregatedAudienceColumn,
  JoinedAudienceSource,
  JoinedAudienceSourceColumnConditionType,
  JoinedAudienceSourceCondition,
  JoinedAudienceSourceConditionType,
  RegularJoinedAudienceSourceConditionColumn,
  SourceToAudienceColumnMapping,
  UnionedAudienceSource,
} from '@features/audiences/ducks/api/audienceBackTypes/audienceSpecification';
import {
  ActiveDefaultAudience,
  ActiveSourceJoinCondition,
  AggregateDetails,
  JoinActiveSourceConfiguration,
  MappingRow,
  mappingRowTypesEnum,
} from '@features/audiences/types';
import { caseNever } from '@utils/case-never';
import { SourceConfigurationIdsToSourceIds } from '../mappingSourcesProperty';
import mappingAggregateProperty from './mappingAggregateProperty';
import { isJoinSourceConfigurationType } from '@features/audiences/types/typesPredicates';
import getSourceIdFromSourceConfig from '../../../helpers/getSourceIdFromSourceConfig';

export const mappingSourcesMappedColumns = (
  sources: {
    unioned: UnionedAudienceSource[];
    joined: JoinedAudienceSource[];
  },
  audience: ActiveDefaultAudience,
  sourceConfigurationIdsToSourceIds: SourceConfigurationIdsToSourceIds
): {
  unioned: UnionedAudienceSource[];
  joined: JoinedAudienceSource[];
} => {
  const newSourcesValues: {
    unioned: UnionedAudienceSource[];
    joined: JoinedAudienceSource[];
  } = {
    unioned: sources.unioned.map((sourceConfig) => {
      const sourceId: string = getSourceIdFromSourceConfig(sourceConfig);
      return {
        source: sourceConfig.source,
        columnsMapping: unionedColumns(
          sourceId,
          audience.mappingRows,
          sourceConfigurationIdsToSourceIds
        ),
      };
    }),
    joined: sources.joined.reduce((result: JoinedAudienceSource[], sourceConfig) => {
      const sourceId: string = getSourceIdFromSourceConfig(sourceConfig);
      // TODO @@@@koralex [potential issues when use the same audience as source, unioned or joined]
      const activeSource = audience.sources.find(
        (source): source is JoinActiveSourceConfiguration =>
          sourceConfigurationIdsToSourceIds[source.key] === sourceId &&
          isJoinSourceConfigurationType(source)
      );
      if (!!activeSource) {
        result.push({
          source: sourceConfig.source,
          condition: joinedColumns(activeSource.condition),
          aggregatedColumns: aggregatedColumns(
            sourceId,
            audience.mappingRows,
            sourceConfigurationIdsToSourceIds
          ),
        });
      }
      return result;
    }, []),
  };

  return newSourcesValues;
};

const unionedColumns = (
  activeSourceId: string,
  mappingRows: MappingRow[],
  sourceConfigurationIdsToSourceIds: SourceConfigurationIdsToSourceIds
): SourceToAudienceColumnMapping[] => {
  return mappingRows.reduce((result: SourceToAudienceColumnMapping[], row) => {
    if (row.rowType === mappingRowTypesEnum.DEFAULT) {
      Object.keys(row.mappedColumns).forEach((sourceConfigId) => {
        const sourceId: string = sourceConfigurationIdsToSourceIds[sourceConfigId];
        if (!!row.mappedColumns[sourceConfigId].columnId && activeSourceId === sourceId) {
          result.push({
            audienceColumnId: row.key,
            sourceColumnId: row.mappedColumns[sourceConfigId].columnId,
          });
        }
      });
    }
    return result;
  }, []);
};

const joinedColumns = (joinCondition: ActiveSourceJoinCondition): JoinedAudienceSourceCondition => {
  switch (joinCondition.type) {
    case JoinedAudienceSourceConditionType.regular:
      return {
        type: JoinedAudienceSourceConditionType.regular,
        columns: joinCondition.columns.reduce(
          (result: RegularJoinedAudienceSourceConditionColumn[], elem) => {
            switch (elem.type) {
              case JoinedAudienceSourceColumnConditionType.data:
                if (elem.audienceColumnId && elem.sourceColumnId) {
                  result.push({
                    type: JoinedAudienceSourceColumnConditionType.data,
                    sourceColumnId: elem.sourceColumnId,
                    audienceColumnId: elem.audienceColumnId,
                  });
                } else {
                  console.warn('Missing column ids for the data join type', elem);
                }
                break;
              case JoinedAudienceSourceColumnConditionType.master_id:
                if (elem.sourceColumnId) {
                  result.push({
                    type: JoinedAudienceSourceColumnConditionType.master_id,
                    sourceColumnId: elem.sourceColumnId,
                  });
                } else {
                  console.warn('Missing source column id for the master id join type', elem);
                }
                break;
              default:
                return caseNever(elem);
            }

            return result;
          },
          []
        ),
      };
    case JoinedAudienceSourceConditionType.source_id_list:
      if (
        joinCondition.sourceColumnId &&
        joinCondition.sourceId &&
        joinCondition.sourceIdColumnId
      ) {
        return {
          type: JoinedAudienceSourceConditionType.source_id_list,
          sourceColumnId: joinCondition.sourceColumnId,
          sourceId: joinCondition.sourceId,
          sourceIdColumnId: joinCondition.sourceIdColumnId,
        };
      }
      throw new Error('Missing properties in source id join condition!');

    default:
      return caseNever(joinCondition);
  }
};

const aggregatedColumns = (
  activeSourceId: string,
  mappingRows: MappingRow[],
  sourceConfigurationIdsToSourceIds: SourceConfigurationIdsToSourceIds
): AggregatedAudienceColumn[] => {
  return mappingRows.reduce((result: AggregatedAudienceColumn[], row) => {
    if (row.rowType === mappingRowTypesEnum.AGGREGATE_ROW) {
      Object.keys(row.mappedColumns).forEach((sourceConfigId) => {
        const sourceId: string = sourceConfigurationIdsToSourceIds[sourceConfigId];
        if (
          activeSourceId === sourceId &&
          row.mappedColumns[sourceConfigId] &&
          row.mappedColumns[sourceConfigId].aggregate
        ) {
          result.push({
            targetColumnId: row.key,
            targetColumnName: row.audienceColumnName,
            aggregation: mappingAggregateProperty(
              row.mappedColumns[sourceConfigId].aggregate as AggregateDetails
            ),
          });
        }
      });
    }
    return result;
  }, []);
};
