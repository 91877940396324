import { call, put } from 'redux-saga/effects';
import audiencesSlice from '../../../audiences/ducks/audiencesSlice';
import {
  ApiListBusinessTermsPayload,
  BusinessTermsFailedReducerActions,
  BusinessTermsSuccessReducerActions,
  ListBusinessTermsSaga,
} from '../../../audiences/ducks/api/types/ListBusinessTerms';
import { listBusinessTerms } from '../../../audiences/ducks/api/api';

export function* listBusinessTermsSaga({ payload }: ListBusinessTermsSaga) {
  try {
    const { abortSignal } = payload;

    const businessTermsPayload: ApiListBusinessTermsPayload = yield call(
      listBusinessTerms,
      abortSignal
    );

    yield put<BusinessTermsSuccessReducerActions>({
      type: audiencesSlice.actions.listBusinessTermsSuccess.type,
      payload: {
        businessTerms: businessTermsPayload.businessTerms,
      },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put<BusinessTermsFailedReducerActions>({
        type: audiencesSlice.actions.listBusinessTermsFailed.type,
        payload: { errorDetails: err.message },
      });
    }
  }
}
