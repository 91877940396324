// Schedule
export enum ScheduleType {
  none = 'none',
  cron = 'cron',
}
export type Schedule = NoSchedule | CronSchedule;
export type StrictlySchedule = Exclude<Schedule, NoSchedule>;

export type NoSchedule = {
  type: ScheduleType.none;
};

export type CronSchedule = {
  type: ScheduleType.cron;
  value: string;
  timezone?: string;
  startDate?: string | number;
  endDate?: string | number;
};
