import { createSlice } from '@reduxjs/toolkit';
import assert from 'assert';

import {
  ReducerActiveSyncActions,
  ReducerAllSyncsActions,
  ReducerAlterDatabaseSyncTableIActions,
  ReducerCreateDatabaseSyncTableIActions,
  ReducerDeleteSyncAction,
  ReducerExecuteSyncAction,
  ReducerGetDestinationTriggersActions,
  ReducerGetSyncsTableListActions,
  ReducerInitSyncEditionScreenAction,
  ReducerPerformDatabaseSyncTableOperationsIActions,
  ReducerSyncActions,
  ReducerValidateDatabaseSyncTableIActions,
  SyncAudienceDataReducerActions,
} from './types';
import { generateId, upsertInArray } from '@utils/helpers';
import {
  ActiveSync,
  Sync,
  SyncRecordsEachTimeStrategy,
  SyncScheduleTypes,
  SyncsTableRecord,
} from '../types';
import { DraftSourceStatus } from '@features/audiences/ducks/api/types';
import { Column, SourceDescription } from '@features/audiences/types';
import { DestinationSchema, DestinationSchemaType } from '../types/DestinationSchema';
import { GetDestinationEntitiesOutput } from '../types/DestinationEntity';
import { mappingSyncToTableRecordType } from './api/mappingSyncTypes/mappingSyncToTableRecordType';
import { AudienceData, DeployedAudience } from '@features/audiences/types/DeployedAudience';
import { Connection } from '@features/connections/types';
import { GetDestinationTriggersOutput } from '../types/DestinationTriggers';
import { RecordsLeavingSegmentStrategy } from '../types/SyncSegmentation';

export interface SyncsState {
  loaded: boolean;
  shouldBeClosed: boolean;
  isFetching?: boolean;
  isFetchingSyncs?: boolean;
  isFetchingSyncAudienceData?: boolean;
  isFetchingDestinationSchema: boolean;
  isFetchingDestinationEntities: boolean;
  currentDatabaseTableOperation?: 'create' | 'alter' | 'validate';
  isValidatingDBSync?: boolean;
  isInitSyncEditorScreenRequesting: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  creationOrAlteringDBTableError?: string;
  dbTableValidationErrors?: object[];
  dbTableValidated?: boolean;
  dbSyncOperationsFinished?: boolean;
  DBSyncSuccessfullyCreated?: boolean;
  DBSyncSuccessfullyAltered?: boolean;
  syncs: Sync[];
  syncsTableRecords: SyncsTableRecord[];
  sync?: Sync;
  activeSync: ActiveSync;
  showExecutionModalAfterSaveDetails?: {
    syncId: string;
    syncName: string;
  };
  syncExecutionDetails: {
    syncId?: string;
    executionId?: string;
    isRequestingSyncExecution?: boolean;
  };
  destinationSchema: DestinationSchema;
  destinationEntities?: GetDestinationEntitiesOutput;
  destinationTriggers?: GetDestinationTriggersOutput;
  selectedAudienceData?: AudienceData;
  describeSourceProcessState: {
    describeSourceProcessId?: string;
    status?: DraftSourceStatus;
  };
  integrationShape?: Column[];
  audienceDescription?: SourceDescription;
}

const initialDestinationSchema: DestinationSchema = {
  type: DestinationSchemaType.API_SCHEMA,
  fields: [],
  id: generateId(),
  label: '',
};
const initialDestinationTriggers: GetDestinationTriggersOutput = {
  __typename: 'DestinationTriggers',
  triggers: [],
};
export const initialActiveSync: ActiveSync = {
  key: generateId(),
  mapping: [],
  syncSegmentation: {
    recordsLeavingStrategy: RecordsLeavingSegmentStrategy.ignore,
    dataFilter: {
      key: '',
      groupFilters: [],
      combinationOperator: 'and',
    },
  },
  syncEachTimeStrategy: SyncRecordsEachTimeStrategy.eachTimeIsUpdated,
  active: true,
};
export const INITIAL_SYNCS_STATE: SyncsState = {
  loaded: false,
  shouldBeClosed: true,
  isFetching: false,
  isFetchingSyncs: false,
  isFetchingSyncAudienceData: false,
  isFetchingDestinationSchema: false,
  isFetchingDestinationEntities: false,
  isInitSyncEditorScreenRequesting: false,
  syncExecutionDetails: { isRequestingSyncExecution: false },
  showExecutionModalAfterSaveDetails: undefined,
  syncs: [],
  syncsTableRecords: [],
  activeSync: initialActiveSync,
  destinationEntities: undefined,
  destinationTriggers: initialDestinationTriggers,
  destinationSchema: initialDestinationSchema,
  describeSourceProcessState: {},
};

const syncsSlice = createSlice({
  name: 'syncs',
  initialState: INITIAL_SYNCS_STATE,
  reducers: {
    getSyncs: (state) => {
      state.isFetching = true;
      state.isFetchingSyncs = true;
      state.syncs = [];
    },
    getSyncsSuccess: (state, { payload }: ReducerAllSyncsActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.isFetchingSyncs = false;
      state.syncs = payload.syncs;
    },
    getSyncsFailed: (state, { payload }: ReducerAllSyncsActions) => {
      state.isFetching = false;
      state.isFetchingSyncs = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    getSyncsTableList: (state) => {
      state.isFetching = true;
      state.isFetchingSyncs = true;
    },
    getSyncsTableListSuccess: (state, { payload }: ReducerGetSyncsTableListActions) => {
      const { syncsTableRecords } = payload;
      state.loaded = true;
      state.isFetching = false;
      state.isFetchingSyncs = false;
      state.syncsTableRecords = syncsTableRecords;
    },
    getSyncsTableListFailed: (state, { payload }: ReducerGetSyncsTableListActions) => {
      state.isFetching = false;
      state.isFetchingSyncs = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveSync: (state) => {
      state.isFetching = true;
    },
    saveSyncSuccess: (state, { payload }: ReducerSyncActions) => {
      const { deployedAudiencesDetails, connectionsDetails } = payload;

      assert(payload.sync, 'Sync to save is not defined');

      const syncs = upsertInArray(payload.sync, state.syncs, (sync) => sync.key);

      if (payload.sync) {
        const audience: DeployedAudience | undefined = deployedAudiencesDetails[
          payload.sync.audienceId
        ]
          ? deployedAudiencesDetails[payload.sync.audienceId]
          : undefined;

        const connection: Connection | undefined = connectionsDetails[
          payload.sync.destinationConnectionId
        ]
          ? connectionsDetails[payload.sync.destinationConnectionId]
          : undefined;

        const syncTableRecord = mappingSyncToTableRecordType(payload.sync, audience, connection);

        state.syncsTableRecords = upsertInArray(
          syncTableRecord,
          state.syncsTableRecords,
          (sync) => sync.key
        );

        state.showExecutionModalAfterSaveDetails =
          payload.sync?.schedule.type === SyncScheduleTypes.REAL_TIME ||
          payload.sync?.schedule.type === SyncScheduleTypes.SCHEDULED
            ? { syncId: payload.sync.key, syncName: payload.sync.name }
            : undefined;
      }

      state.loaded = true;
      state.isFetching = false;
      state.syncs = syncs;
      state.activeSync = { ...initialActiveSync, key: generateId() };
    },
    saveSyncFailed: (state, { payload }: ReducerSyncActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    updateSync: (state) => {
      state.isFetching = true;
    },
    updateSyncSuccess: (state, { payload }: ReducerSyncActions) => {
      assert(payload.sync, 'Sync to update is not defined');
      const { deployedAudiencesDetails, connectionsDetails } = payload;
      const syncs = upsertInArray(payload.sync, state.syncs, (sync) => sync.key);

      if (payload.sync) {
        const audience: DeployedAudience | undefined = deployedAudiencesDetails[
          payload.sync.audienceId
        ]
          ? deployedAudiencesDetails[payload.sync.audienceId]
          : undefined;

        const connection: Connection | undefined = connectionsDetails[
          payload.sync.destinationConnectionId
        ]
          ? connectionsDetails[payload.sync.destinationConnectionId]
          : undefined;

        const syncTableRecord = mappingSyncToTableRecordType(payload.sync, audience, connection);

        state.syncsTableRecords = upsertInArray(
          syncTableRecord,
          state.syncsTableRecords,
          (sync) => sync.key
        );
      }

      state.loaded = true;
      state.isFetching = false;
      state.syncs = syncs;
      state.activeSync = { ...initialActiveSync, key: generateId() };
    },
    updateSyncFailed: (state, { payload }: ReducerSyncActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    deleteSync: (state) => {
      state.isFetching = true;
    },
    deleteSyncSuccess: (state, { payload }: ReducerDeleteSyncAction) => {
      const syncsNewValues = state.syncs.filter((elem) => elem.key !== payload.syncId);
      const syncsTableRecordsNewValues = state.syncsTableRecords.filter(
        (elem) => elem.key !== payload.syncId
      );
      state.loaded = true;
      state.isFetching = false;
      state.syncs = syncsNewValues;
      state.syncsTableRecords = syncsTableRecordsNewValues;
    },
    deleteSyncFailed: (state, { payload }: ReducerDeleteSyncAction) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    enableSync: (state) => {
      state.isFetching = true;
    },
    enableSyncSuccess: (state, { payload }: ReducerDeleteSyncAction) => {
      const syncsNewValues = state.syncs.reduce((result: Sync[], elem) => {
        elem.key === payload.syncId ? result.push({ ...elem, active: true }) : result.push(elem);
        return result;
      }, []);
      const syncsTableRecordsNewValues = state.syncsTableRecords.reduce(
        (result: SyncsTableRecord[], elem) => {
          elem.key === payload.syncId ? result.push({ ...elem, status: true }) : result.push(elem);
          return result;
        },
        []
      );
      state.loaded = true;
      state.isFetching = false;
      state.syncs = syncsNewValues;
      state.syncsTableRecords = syncsTableRecordsNewValues;
    },
    enableSyncFailed: (state, { payload }: ReducerDeleteSyncAction) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    disableSync: (state) => {
      state.isFetching = true;
    },
    disableSyncSuccess: (state, { payload }: ReducerDeleteSyncAction) => {
      const syncsNewValues = state.syncs.reduce((result: Sync[], elem) => {
        elem.key === payload.syncId ? result.push({ ...elem, active: false }) : result.push(elem);
        return result;
      }, []);
      const syncsTableRecordsNewValues = state.syncsTableRecords.reduce(
        (result: SyncsTableRecord[], elem) => {
          elem.key === payload.syncId ? result.push({ ...elem, status: false }) : result.push(elem);
          return result;
        },
        []
      );
      state.loaded = true;
      state.isFetching = false;
      state.syncs = syncsNewValues;
      state.syncsTableRecords = syncsTableRecordsNewValues;
    },
    disableSyncFailed: (state, { payload }: ReducerDeleteSyncAction) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    initSyncEditionScreen: (state) => {
      state.isInitSyncEditorScreenRequesting = true;
      state.loaded = false;
    },
    initSyncEditionScreenSuccess: (state, { payload }: ReducerInitSyncEditionScreenAction) => {
      state.loaded = true;
      state.isInitSyncEditorScreenRequesting = false;
      if (payload.sync) {
        state.activeSync = payload.sync;
      }
    },
    initSyncEditionScreenFailed: (state, { payload }: ReducerInitSyncEditionScreenAction) => {
      state.isInitSyncEditorScreenRequesting = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    requestSyncExecution: (state) => {
      state.syncExecutionDetails.isRequestingSyncExecution = true;
      state.error = false;
      state.errorDetails = '';
    },
    requestSyncExecutionSuccess: (state, { payload }: ReducerExecuteSyncAction) => {
      state.syncExecutionDetails = {
        syncId: payload.syncId,
        executionId: payload.executionId,
        isRequestingSyncExecution: false,
      };
    },
    requestSyncExecutionFailed: (state, { payload }: ReducerExecuteSyncAction) => {
      state.syncExecutionDetails = {
        syncId: payload.syncId,
        executionId: undefined,
        isRequestingSyncExecution: false,
      };
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    updateActiveSync: (state, { payload }: ReducerActiveSyncActions) => {
      state.activeSync = payload.activeSync;
    },
    setEmptyActiveSync: (state) => {
      state.activeSync = { ...initialActiveSync, key: generateId() };
    },
    setEmptyShowExecutionModalDetails: (state) => {
      state.showExecutionModalAfterSaveDetails = undefined;
    },

    resetDestinationSchema: (state) => {
      state.destinationSchema = initialDestinationSchema;
    },

    resetDestinationEntities: (state) => {
      state.destinationEntities = undefined;
    },

    getDestinationSchema: (state) => {
      state.isFetchingDestinationSchema = true;
    },
    getDestinationSchemaSuccess: (state, { payload }) => {
      state.loaded = true;
      state.isFetchingDestinationSchema = false;
      state.destinationSchema = payload.destinationSchema;
    },
    getDestinationSchemaFailed: (state, { payload }) => {
      state.isFetchingDestinationSchema = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getDestinationEntities: (state) => {
      state.isFetchingDestinationEntities = true;
      state.destinationEntities = undefined;
    },
    getDestinationEntitiesSuccess: (state, { payload }) => {
      state.loaded = true;
      state.isFetchingDestinationEntities = false;
      state.destinationEntities = payload.destinationEntities;
    },
    getDestinationEntitiesFailed: (state, { payload }) => {
      state.isFetchingDestinationEntities = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getDestinationTriggers: (state) => {
      state.isFetching = true;
    },
    getDestinationTriggersSuccess: (state, { payload }: ReducerGetDestinationTriggersActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.destinationTriggers = payload.destinationTriggers;
    },
    getDestinationTriggersFailed: (state, { payload }: ReducerGetDestinationTriggersActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    createDatabaseSyncTable: (state) => {
      state.isFetching = true;
      state.currentDatabaseTableOperation = 'create';
      state.DBSyncSuccessfullyCreated = false;
      state.DBSyncSuccessfullyAltered = false;
      state.creationOrAlteringDBTableError = undefined;
    },
    createDatabaseSyncTableSuccess: (
      state,
      { payload }: ReducerCreateDatabaseSyncTableIActions
    ) => {
      state.loaded = true;
      state.currentDatabaseTableOperation = undefined;
      state.isFetching = false;
      state.DBSyncSuccessfullyCreated = payload.success;
    },
    createDatabaseSyncTableFailed: (state, { payload }: ReducerCreateDatabaseSyncTableIActions) => {
      state.isFetching = false;
      state.error = true;
      state.currentDatabaseTableOperation = undefined;
      state.creationOrAlteringDBTableError = payload.errorDetails;
    },

    alterDatabaseSyncTable: (state) => {
      state.isFetching = true;
      state.currentDatabaseTableOperation = 'alter';
      state.DBSyncSuccessfullyAltered = false;
      state.DBSyncSuccessfullyCreated = false;
      state.creationOrAlteringDBTableError = undefined;
    },
    alterDatabaseSyncTableSuccess: (state, { payload }: ReducerAlterDatabaseSyncTableIActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.currentDatabaseTableOperation = undefined;
      state.DBSyncSuccessfullyAltered = payload.success;
    },
    alterDatabaseSyncTableFailed: (state, { payload }: ReducerAlterDatabaseSyncTableIActions) => {
      state.isFetching = false;
      state.error = true;
      state.currentDatabaseTableOperation = undefined;
      state.creationOrAlteringDBTableError = payload.errorDetails;
    },

    validateDatabaseSyncTable: (state) => {
      state.isFetching = true;
      state.dbTableValidationErrors = undefined;
      state.dbTableValidated = false;
      state.isValidatingDBSync = true;
      state.currentDatabaseTableOperation = 'validate';
    },
    validateDatabaseSyncTableSuccess: (
      state,
      { payload }: ReducerValidateDatabaseSyncTableIActions
    ) => {
      state.loaded = true;
      state.isFetching = false;
      state.dbTableValidated = true;
      state.currentDatabaseTableOperation = undefined;
    },
    validateDatabaseSyncTableFailed: (
      state,
      { payload }: ReducerValidateDatabaseSyncTableIActions
    ) => {
      state.isFetching = false;
      state.error = true;
      state.dbTableValidationErrors = payload.errors;
      state.dbTableValidated = true;
      state.currentDatabaseTableOperation = undefined;
    },

    performDatabaseSyncTableOperations: (state) => {
      state.isFetching = true;
      state.dbSyncOperationsFinished = undefined;
    },
    performDatabaseSyncTableOperationsSuccess: (
      state,
      { payload }: ReducerPerformDatabaseSyncTableOperationsIActions
    ) => {
      state.loaded = true;
      state.isFetching = false;
      state.dbSyncOperationsFinished = payload.processFinished;
    },
    performDatabaseSyncTableOperationsFailed: (
      state,
      { payload }: ReducerPerformDatabaseSyncTableOperationsIActions
    ) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
      state.dbSyncOperationsFinished = payload.processFinished;
    },

    updateDatabaseTableOperationsState: (state) => {
      state.dbTableValidated = false;
      state.dbSyncOperationsFinished = undefined;
      state.dbTableValidationErrors = [];
    },

    resetDatabaseTableValidationErrors: (state) => {
      state.dbTableValidationErrors = [];
    },

    getAudienceDataForSync: (state) => {
      state.isFetchingSyncAudienceData = true;
    },
    getAudienceDataForSyncSuccess: (state, { payload }: SyncAudienceDataReducerActions) => {
      state.loaded = true;
      state.isFetchingSyncAudienceData = false;
      state.selectedAudienceData = payload.audienceData;
    },
    getAudienceDataForSyncFailed: (state, { payload }: SyncAudienceDataReducerActions) => {
      state.isFetchingSyncAudienceData = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    setActiveSyncIntegrationObject: (state) => {
      state.activeSync = {
        ...state.activeSync,
        // integrationObject: payload.object,
      };
    },
    // init sync screens
    initSyncCreationScreen: () => {},
  },
});

export default syncsSlice;
