import { gql } from '@apollo/client';
const GET_WEBHOOKS = gql`
  query ($accountId: String!) {
    webhooks(accountId: $accountId) {
      id
      version
      createdAt
      updatedAt
      accountId
      dataWarehouseId
      name
      description
      ipAddressesWhitelist {
        ... on IpAddress {
          type
          value
        }
        ... on IpAddressRange {
          type
          from
          to
        }
      }
      methodsWhitelist
      headersWhitelist {
        name
        values
      }
    }
  }
`;
const GET_WEBHOOK = gql`
  query ($id: String!) {
    webhook(id: $id) {
      id
      version
      createdAt
      updatedAt
      accountId
      dataWarehouseId
      name
      description
      ipAddressesWhitelist {
        ... on IpAddress {
          type
          value
        }
        ... on IpAddressRange {
          type
          from
          to
        }
      }
      methodsWhitelist
      headersWhitelist {
        name
        values
      }
    }
  }
`;

export { GET_WEBHOOKS, GET_WEBHOOK };
