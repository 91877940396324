import { User } from '@features/users/types';

export const usersList: User[] = [
  {
    id: 'admin',
    email: 'admin@octolis.com',
    firstName: 'admin',
    lastName: 'admin',
    lastLogin: Date.now(),
    access: [
      {
        organization: 'octolis',
        userProfile: 'admin',
        active: true,
      },
    ],
  },
  {
    id: 'marketing',
    email: 'marketing@octolis.com',
    firstName: 'marketing',
    lastName: 'marketing',
    lastLogin: Date.now(),

    access: [
      {
        organization: 'octolis',
        userProfile: 'marketing',
        active: true,
      },
    ],
  },
  {
    id: 'data',
    email: 'data@octolis.com',
    firstName: 'data',
    lastName: 'data',
    lastLogin: Date.now(),

    access: [
      {
        organization: 'octolis',
        userProfile: 'data',
        active: true,
      },
    ],
  },
];
