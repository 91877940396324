import * as apiTypes from './types';
import apolloClientInstance from '@apolloClientInstance';
import { GET_WEBHOOK, GET_WEBHOOKS } from '../../webhooks/ducks/graphql/queries';
import {
  CREATE_WEBHOOK,
  DELETE_WEBHOOK,
  UPDATE_WEBHOOK,
} from '../../webhooks/ducks/graphql/mutations';
import { Webhook, WebhookCommand } from '../../webhooks/ducks/backTypes/types';
import { mappingWebhookToBack } from '../../webhooks/ducks/backTypes/mappingToBack';

const getWebhooks: apiTypes.APIGetWebhooks = async (accountId, abortSignal?) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.query<{ webhooks: Webhook[] }>({
    query: GET_WEBHOOKS,
    variables: {
      accountId,
    },
  });
  const webhooks = res.data.webhooks;

  if (res.data.webhooks) {
    error = true;
    errorDetails = 'No webhooks found.';
  }
  return {
    error,
    webhooks,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getWebhook: apiTypes.APIGetWebhook = async (id: string) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.query<{ webhook: Webhook }>({
    query: GET_WEBHOOK,
    variables: {
      id,
    },
  });

  const webhook = res.data.webhook;

  if (!res.data.webhook) {
    error = true;
    errorDetails = 'No webhook found.';
  }
  return {
    error,
    webhook,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const createWebhook: apiTypes.APISaveWebhook = async (
  activeWebhook,
  accountId,
  dataWarehouseId
) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const createWebhookCommand: WebhookCommand = mappingWebhookToBack(
    activeWebhook,
    accountId,
    dataWarehouseId
  );
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: CREATE_WEBHOOK,
    variables: {
      command: createWebhookCommand,
    },
  });

  const webhook = res.data.createWebhook;

  if (!webhook) {
    error = true;
    errorDetails = "couldn't create webhook";
  }
  return {
    error,
    webhook,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const updateWebhook: apiTypes.APIUpdateWebhook = async (activeWebhook) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const apolloClient = await apolloClientInstance();
  const updateWebhookCommand = {
    ...mappingWebhookToBack(activeWebhook, undefined, undefined),
    id: activeWebhook.id,
  };

  const res = await apolloClient.mutate({
    mutation: UPDATE_WEBHOOK,
    variables: {
      command: updateWebhookCommand,
    },
  });
  const webhook = res.data?.updateWebhook;

  if (!webhook) {
    error = true;
    errorDetails = "couldn't update webhook";
  }
  return {
    error,
    webhook,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const deleteWebhook: apiTypes.APIDeleteWebhook = async (activeWebhook) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: DELETE_WEBHOOK,
    variables: {
      command: {
        id: activeWebhook.id,
      },
    },
  });
  const webhook = res.data?.webhook;
  if (!webhook) {
    error = true;
    errorDetails = "couldn't delete webhook";
  }
  return {
    webhook,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getWebhooks, getWebhook, createWebhook, updateWebhook, deleteWebhook };
