import resources from './resources';
import accountResourcesExecutionMonitoring from './account-resources-executions-monitoring';

const common = {
  true: 'vrai',
  false: 'faux',
  apply: 'Appliquer',
};

const sourceCatalogScreen = {
  sourceCatalog: 'Catalogue des sources',
  catalog: 'Catalogue',

  'thousand-separator': 'Séparateur de milliers',
  'decimal-separator': 'Séparateur décimal',
  timezone: 'Timezone',
  format: 'Format',
  'empty-date-custom-format': 'Veuillez écrire un format personnalisé!',
  'write-date-custom-format': 'Veuillez écrire un format personnalisé',
  'select-date-format': 'Veuillez sélectionner le format de la colonne!',
  'select-timezone': 'Veuillez sélectionner un fuseau horaire!',
  unit: 'Unité',
  seconds: 'Secondes',
  milliseconds: 'Millisecondes',
  'rounding-operation': "Opération d'arrondissement",
  round: 'round',
  floor: 'floor',
  ceil: 'ceil',
};

const monitoringScreen = {
  logDetails: 'Log Details',
  loading: 'Chargement ...',
  filters: 'Filtres',
  objectType: "Type d'objet",
  date: 'Date',
  created: 'Créé',
  started: 'Commencé',
  ended: 'Terminé',
  status: 'Statut',
  timing: 'Le timing',
  trigger: 'Trigger',
  executionTime: "Temps d'exécution",
  executionId: "Id d'exécution",
  at: 'at',
};
const sourcesScreen = {
  sourcesDescription:
    'Les sources sont des emplacements en dehors du datahub à partir desquels vous obtenez des données.',
  addASource: 'Ajouter une source',

  // source keys
  name: 'Nom',
  connector: 'Connecteur',
  pointer: 'Pointeur',
  records: 'Registres',
  lastRun: 'Last run',
  status: 'Statut',
  schedule: 'Planning',
  actions: 'Actions',
  // source actions
  create: 'Créer',
  view: 'Voir',
  edit: 'Éditer',
  open: 'Ouvrir',
  log: 'Log',
  active: 'Active',
  broken: 'Interrompu',
  // form
  editTheSource: 'Editer une source',
  viewTheSource: 'Voir une source',
  createTheSource: 'Créer une source',
  chooseConnector: 'Choisir un connecteur',
  configuration: 'Configuration',
  sourcePointer: 'Pointeur de la source',
  globalSettings: 'Paramètres globaux',
  usageInFlows: 'Utilisation dans les flux',
  sourceUsedInFlows: 'Cette source est actuellement utilisée dans les flux suivants',
  connectorRequiredMessage: 'Veuillez sélectionner un connecteur.',
  pointerRequiredMessage: 'Veuillez sélectionner un pointeur.',
  nameRequiredMessage: 'Veuillez sélectionner un nom.',
  statusRequiredMessage: 'Veuillez sélectionner un statut.',
  creationFlowRequiredMessage:
    'Veuillez choisir si vous souhaitez créer un flux pour cette source.',

  creatFlowLabel: 'Créer un flux pour alimenter un objet avec les données de cette source',
  confirmDeletion: 'Êtes-vous sûr? La source sera supprimée.',
  confirmCancel: 'Êtes-vous sûr? Vos modifications ne seront pas enregistrées.',
  save: 'Sauvegarder',
  next: 'Suivant',
  choose: 'Choisir',
  previous: 'Retour',
  cancel: 'Annuler',
  delete: 'Supprimer',
  yes: 'Oui',
  no: 'Non',
  reset: 'Réinitialiser',
  confirmReset:
    'Êtes-vous sûr de vouloir réinitialiser cette source? Toutes ses données existantes vont être effacées.',
};
const menuHeader = {
  controlCenter: 'Centre de contrôle',
  settings: 'Paramètres',
};
const menuLeft = {
  connections: 'Connections',
  sources: 'Sources',
  allSources: 'Toutes les sources',
  flows: 'Flux',
  allFlows: 'Tous les flux',
  sourceFlows: 'Flux de source',
  destinationsFlows: 'Flux de destination',
  internalFlows: 'Flux internes',
  recipes: 'Recipes',
  allRecipes: 'Toutes les recipes',
  objects: 'Objets',
  allObjects: 'Tous les objets',
  contacts: 'Contacts',
  orders: 'Ordres',
  products: 'Produits',
  destinations: 'Destinations',
  allDestinations: 'Toutes les destinations',
  syncs: 'Syncs',
  settings: 'Settings',
};
const syncsScreen = {
  //screens
  syncsDescription: 'Les Syncs associent une source vers une destination',
  addNewSync: 'Ajouter une sync',
  createANewSync: 'Créer une nouvelle sync',
  //form
  whatDatasetDoSync: 'Quel dataset voulez-vous synchroniser?',
  existingDatasetRecordsPolicy:
    'Que doit faire la sync avec les données déjà présentes dans le dataset?',
  ignoreExistingDatasetRecordsPolicy: 'Les ignorer',
  includeExistingDatasetRecordsPolicy: 'Les inclure',
  sendARawSource: 'Envoyer une Source',
  sendASegment: 'Envoyer un Segment',
  addSource: 'Ajouter une Source',
  createANewSource: 'Créer un nouveau source ',
  createANewSegment: 'Créer un nouveau segment',
  addDestination: 'Ajouter une Destination',
  originTypeLabel: 'Quelles données voulez-vous synchroniser?',
  chooseYourSource: 'Choisissez une Source',
  chooseYourSegment: 'Choisissez un Segment',
  chooseYourOrigin: 'Choisissez une Origine',
  source: 'Source',
  segment: 'Segment',
  origin: 'Origine',
  settings: 'Paramètres',
  destination: 'Destination',
  whereSyncDataQuestion: 'Vers où voulez-vous synchroniser les données ?',
  whichFieldToSyncQuestion: 'Quels champs doivent être synchronisés ?',
  whenDoYouWantToSync: 'When do you want to sync the Dataset?',
  nameAndActivateThisSync: 'Name and activate this sync ',
  weWillCastText: 'Nous allons convertir ',
  stringAsText: " en chaîne de caractères telle qu'elle est actuellement stockée en tant que ",
  weCannotSendText: 'Nous ne pouvons pas envoyer ',
  asAText: ' comme un ',
  updateMappingText: ', veuillez mettre à jour le Mapping.',
  sourceColumnsTitle: 'Source colonnes (CDP)',
  destinationFieldTitle: 'Champ de destination',
  datasetColumnTitle: 'Champ de Dataset',
  refreshFieldsTitle: 'Actualiser les champs de destination',
  addMappingText: 'Ajouter un Mapping',
  addStaticValueText: 'Ajouter une valeur statique',
  syncNameLabel: 'Nom de la Sync',

  syncNameInputRequiredMessage: 'Veuillez entrer un nom',
  activeLabel: 'Activer la Sync',
  viewTheSync: 'Voir la sync',
  editTheSync: 'Editer la sync',
  originSettings: "Paramètres d'Origin",
  destinationSettings: 'Paramètres de Destination',
  mappingSettings: 'Paramètre de Mapping',
  originStepFailed: "Echec du paramétrage d'Origin, accès à cet écran refusé",
  mappingStaticValidValue: 'Veuillez entrer une valeur valide !',
  mappingOriginEmptyValue: "Veuillez sélectionner une colonne d'origin !",
  mappingTargetEmptyValue: 'Veuillez sélectionner une colonne de destination !',
  mappingInvalidAlert: 'Veuillez entrer un ',
  whatKindOfSync: 'What kind of sync would you like us to perform?',
  chooseAction: 'Choose action',

  whatShouldWedoRecordLeaves: 'What should we do when a record leaves the Dataset?',
  syncTypeRequiredRuleMessage: 'Please choose the sync type',
  recordsActionTypeRequiredRuleMessage: 'Please choose records action',
  afterCreation: 'Sync only when a record is created in the Dataset',
  upsert: 'Sync when a record is created or updated (upsert) in Dataset',
  syncSegmentLeaveignore: 'Do nothing',
  syncSegmentLeavedelete: 'Delete it in the destination',
  mappingTargetAlreadyUsed:
    'Ce nom de champ de destination est déjà utilisé. Veuillez en choisir un autre.',
  mappingTargetAlreadyExists:
    'Ce nom de champ de destination existe déjà dans les options. Veuillez en choisir un autre ou utiliser une option existante.',
};
const segmentScreen = {
  createANewSegment: 'Ajouter un  segment',
  editSegment: 'Edit segment',
  segmenCombineMultiple: 'Les segments servent à combiner plusieurs sources',
  selectSourceOrCreateOne: 'Select a source or create one',
  saveAndSync: 'Sauvegarder & Sync',
  whatDataToSegment: 'Quelles données souhaitez-vous segmenter?',
  addSource: 'Ajouter Source',

  chooseYourBaseData: 'Choose your base data',
  addData: 'Add data',
  updateCombinedSource: 'Update combined source',
  resetSources: 'Reset sources',
  selectSourceOrCreate: 'Select a source or create one',
  segmentNameRequiredMessage: 'Please enter the segment name',
  segmentName: 'Enter the segment name',
  howToSegmentData: 'Comment souhaitez-vous segmenter vos données ?',
  easy: 'Mode facile',
  sql: ' Utiliser SQL',
  filterData: 'Filtrer les données',
  //form
  combineSources: 'Combiner les sources',
  explore: 'Explorer',
  combinationSettings: 'Paramètres de combinaison',
  columnSelection: 'Sélection de colonne',
  whatSourceLikeToCombine: 'Avec quelle source souhaitez-vous combiner',
  connection: 'Connection',
  file: 'File',
  recordsInTotal: 'données au total',
  recordsInSample: 'données en échantillon',
  howShouldCombineNewSource: "Comment combiner cette nouvelle source avec l'autre ?",
  ofRecordsFrom: ' des data de ',
  matchOnesFrom: ' correspondent à ceux de ',
  whatRecordsShouldWeKeep:
    'Quels enregistrements devons-nous conserver pour cette nouvelle source ?',
  severalRecordsOfCombinedSource:
    ' Plusieurs enregistrements de source combinée correspondent à chaque enregistrement de ',
  requiredCombinationTypeMessage: 'Veuillez choisir le mode pour combinée',
  whatShouldWeDo: ', Que devrions nous faire?',
  chooseColumn: 'Choisissez la colonne',
  chooseFormula: 'Choisissez la formule',
  pleaseChooseTheFormula: 'Veuillez choisir la formule!',
  requiredColumnRuleMessage: 'Veuillez choisir la colonne!',

  clickToReduce: 'Cliquez pour fermer les statistiques',
  clickToExpand: 'Cliquez pour voir les statistiques',
  statistics: 'Statistiques',
  sampleValues: 'Les valeurs',
  //formula
  sum: 'Sum',
  max: 'Max',
  min: 'Min',
  avg: 'Avg',
  first: 'First',
  last: 'Last',
  // segments table
  sourceName: 'Source',
  combinedSources: 'Combined sources',
};
const scheduleContainer = {
  trigger: 'Trigger',
  regular: 'Interval régulier',
  manual: 'Manuel',
  triggerRequiredRuleMessage: 'Le Trigger est requis !',
  scheduleEvery: 'Planifié tous les',
  scheduleRequiredRuleMessage: 'Une fréquence est requise !',
  intervalRequiredRuleMessage: 'Un interval est requis !',
  startingDateRequiredRuleMessage: `Une date de début est requise !`,
  startingDateValidator: `Expression CRON invalide`,
  startingAtLabel: 'À',
  startingFromLabel: 'À partir de',
  hour: 'Heure',
  day: 'Jour',
  month: 'Mois',
  cronExpressionLabel: 'Expression CRON',
  cronExpressionRequiredRuleMessage: 'Veuillez entrer une expression CRON !',
};
const audiencesScreen = {
  newDataset: 'New Dataset',
  confirmDatasetDeletion: 'Êtes-vous sûr? Cette Dataset sera supprimée.',
};

const recipeScreen = {
  pleaseEnterRecipeName: 'Please enter a name for the recipe!',
  availableDataOrigins: 'Available data origins',
  availableDataTargets: 'Available data targets',
  useModel: 'Use model',
  context: 'Context',
  testQuery: 'Test query',
  queryRunSuccess: 'Query run with Success',
  queryFailed: 'Query failed',
  runQuery: 'Run query',
  compiledSql: 'Compiled SQL',
  results: 'results',
  logs: 'Logs',
  pleaseExecuteQuery: 'Please execute query',
  youCanSaveRecipe: 'You can save your recipe now',
  queryMustBeTested: 'Query must be tested with success',
};
const connectionsScreen = {
  connectionsListDescription:
    'Add Sources and Destinations to Octolis to get started and do more with your data.',
  addMoreLabel: '+ Add more',
  connectionsDeleteErrors: {
    connectionUsedByResource:
      'Cette connexion est utilisée par une source ou une sync. Elle ne peut pas être supprimée.',
    unexpectedError:
      'Une erreur est survenue. Veuillez essayer de nouveau. Si le problème persiste, merci de contacter le support.',
  },
};

const settingsScreen = {
  'settings-account': 'Mon compte',
  'settings-profile': 'Profil',
  'settings-plan': 'Plan',
  'settings-sync-to-analytics': 'Synchroniser avec Analytics',
  logout: 'Se déconnecter',

  'settings-account.description': 'Description',
  'settings-account-database': 'Base de données',
  'settings-account-name': 'Nom du compte',
  'settings-account-database-description':
    "Octolis fonctionne par-dessus une base de données (Postgres). Voici les informations d'identification de votre base de données (read replica) si vous souhaitez utiliser un outil de BI pour l'analyse.",
  'settings-account-db-host': 'Hôte',
  'settings-account-db-user': 'Utilisateur',
  'settings-account-db-database': 'Base de données',
  'settings-account-db-port': 'Port',
  'settings-password': 'Mot de passe',
  'settings-account-db-ssl': 'SSL',
  'settings-account-db-ssl-true': 'OUI',
  'settings-account-db-ssl-false': 'NON',

  'settings-profile-myProfile': 'Mon profil',
  'settings-profile-role': 'Rôle',
  'settings-profile-login': 'Identifiant de connexion',
  'settings-profile-reset-password': 'Réinitialiser',
  'settings-profile-reset-password-succeeded-title': 'Réinitialisation du mot de passe réussie',
  'settings-profile-reset-password-succeeded':
    "Nous avons reçu votre demande de réinitialisation de mot de passe. Veuillez consulter votre courrier électronique pour obtenir d'autres instructions.",
  'settings-profile-reset-password-failed-title':
    'Échec de la demande de réinitialisation du mot de passe',
  'settings-profile-reset-password-failed':
    "Nous sommes désolés, mais il y a eu un problème dans le traitement de votre demande de réinitialisation de mot de passe. Veuillez réessayer plus tard ou contacter le service d'assistance pour obtenir de l'aide.",

  'settings-plan-subscription': 'Abonnement',
  'settings-plan-essential': 'Essential',
  'settings-plan-professional': 'Professional',
  'settings-plan-custom': 'Custom',
};

const datasetsScreen = {
  mappedSourcesCountTooltip:
    'Nombre de sources mappées / Nombre de sources avec la même entité sémantique',
  mappedSourceColumnsCountTooltip: 'Nombre de colonnes mappées / Nombre de toutes les colonnes',
};

const fr = {
  translation: {
    ...common,
    ...menuLeft,
    ...menuHeader,
    ...sourcesScreen,
    ...syncsScreen,
    ...scheduleContainer,
    ...segmentScreen,
    ...connectionsScreen,
    ...recipeScreen,
    ...audiencesScreen,
    ...monitoringScreen,
    ...sourceCatalogScreen,
    ...settingsScreen,
    ...datasetsScreen,
    ...resources,
    ...accountResourcesExecutionMonitoring,
  },
};
export default fr;
