import {
  columnDisplayEnum,
  DefaultSourceConfiguration,
  JoinSourceConfiguration,
  MappedColumn,
  MappingRow,
  mappingRowTypesEnum,
  RegularJoinConditionColumn,
} from '@features/audiences/types';
import {
  JoinedAudienceSourceColumnConditionType,
  JoinedAudienceSourceConditionType,
} from '@features/audiences/ducks/api/audienceBackTypes/audienceSpecification';
import getSourceIDConditionAudienceColumnsName from '@features/audiences/helpers/getSourceIDConditionAudienceColumnsName';
import mappingHelpers from '@features/audiences/helpers/mappingSourcesHelpers/mappingHelpers';
import { caseNever } from '@utils/case-never';

export const MappingRowsMatchingJoins = {
  MatchesJoinCondition(condition: RegularJoinConditionColumn) {
    return (mappingRow: MappingRow) =>
      MappingRowsMatchingJoins.matchesJoinCondition(mappingRow, condition);
  },

  matchesJoinCondition(mappingRow: MappingRow, condition: RegularJoinConditionColumn) {
    switch (condition.type) {
      case JoinedAudienceSourceColumnConditionType.data:
        return mappingRow.key === condition.audienceColumnId;

      case JoinedAudienceSourceColumnConditionType.master_id:
        return mappingRow.rowType === mappingRowTypesEnum.MASTER_ID_ROW;

      default:
        return caseNever(condition);
    }
  },
};

export function setMappingRowOfTheJoinCondition(
  sources: DefaultSourceConfiguration[],
  source: JoinSourceConfiguration,
  mappingRows: MappingRow[]
) {
  switch (source.condition.type) {
    case JoinedAudienceSourceConditionType.regular:
      return source.condition.columns.reduce((result, joinCondition) => {
        const foundIndex = mappingRows.findIndex(
          MappingRowsMatchingJoins.MatchesJoinCondition(joinCondition)
        );

        if (foundIndex < 0) {
          return mappingRows;
        }

        const row = mappingRows[foundIndex];
        const column = source.selectedColumns.find(
          (selectedColumn) => selectedColumn.id === joinCondition.sourceColumnId
        );

        if (column) {
          result = result.slice();
          result.splice(foundIndex, 1, {
            ...row,
            mappedColumns: {
              ...row.mappedColumns,
              [source.key]: {
                columnId: column.id,
                columnName: column.name,
                columnType: column.type,
              },
            },
          });
        }

        return result;
      }, mappingRows);

    case JoinedAudienceSourceConditionType.source_id_list:
      const joinConditionSourceId = source.condition.sourceId;
      const joinConditionSourceIdColumnId = source.condition.sourceIdColumnId;
      const joinConditionsSourceColumnId = source.condition.sourceColumnId;
      const joinConditionSource = sources.find((s) => s.key === joinConditionSourceId);
      const sourceIdColumn = joinConditionSource?.selectedColumns.find(
        (col) => col.id === joinConditionSourceIdColumnId
      );
      const sourceColumn = source.selectedColumns.find(
        (col) => col.id === joinConditionsSourceColumnId
      );

      const mappingRowName = getSourceIDConditionAudienceColumnsName(
        sources,
        joinConditionSourceId,
        joinConditionSourceIdColumnId
      );
      if (sourceColumn && sourceIdColumn) {
        const hiddenColumns = mappingHelpers.hideMappedColumnsHandler(
          sources,
          source.combinationLevel
        );
        const mappedColumnData: MappedColumn = {
          columnId: sourceColumn.id,
          columnName: sourceColumn.name,
          columnType: sourceColumn.type,
          columnDisplay: columnDisplayEnum.DISABLED,
        };
        const mappingRow: MappingRow = {
          key: sourceIdColumn.id,
          mappedColumns: {
            ...hiddenColumns,
            [source.key]: mappedColumnData,
          },
          rowType: mappingRowTypesEnum.SOURCE_ID_LIST,
          audienceColumnName: mappingRowName,
          audienceColumnType: sourceColumn.type,
          sourceId: source.dataSource.key,
          canDelete: false,
        };
        return [...mappingRows, mappingRow];
      }
      return [];
    default:
      throw new Error('join condition type doesnt exist');
  }
}
