import { useEffect } from "react";
import { useApm } from "./ApmProvider";
import { useAuth0 } from "./Auth0Provider";

export const ConnectApmToAuth0 = ({children}: Readonly<{children: React.ReactNode}>) => {
  const {user} = useAuth0();
  const {setUser} = useApm();

  useEffect(() => {
    if (user?.sub) {
      setUser({ id: user.sub });
    }
  }, [user, setUser]);

  return (
    <>{children}</>
  );
};
