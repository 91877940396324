import {
  ColumnFilteringStrategy,
  ColumnFilteringStrategyType,
} from '@features/audiences/ducks/api/audienceBackTypes/columnAggregations';
import { operator, FilterOperatorTypes, OperatorInputTypes } from '@features/filter/types';

// This function Should return  filter operator type
export const mappingOperatorProperty = (strategy?: ColumnFilteringStrategy): operator => {
  const strategyType: { [keyValue in ColumnFilteringStrategyType]: FilterOperatorTypes } = {
    match_value: FilterOperatorTypes.is,
    mismatch_value: FilterOperatorTypes.isNot,
    match_regex: FilterOperatorTypes.regex,
    mismatch_regex: FilterOperatorTypes.is, // We don't have this property in front 'mismatch_regex
  };
  return {
    value: strategy?.type ? strategyType[strategy.type] : strategyType['match_value'],
    input: OperatorInputTypes.STRING,
  };
};
