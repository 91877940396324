export enum WebhookMethod {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
}
export enum IpAddressWhitelistEntryType {
  ipAddress = 'ipAddress',
  ipAddressRange = 'ipAddressRange',
}

export type ActiveHeadersValue = { key: string; value?: string };
export interface HeadersWhitelistConfig {
  key: string;
  name?: string;
  values: ActiveHeadersValue[];
}

export type ActiveIpAddress = {
  type: IpAddressWhitelistEntryType.ipAddress;
  key: string;
  value?: string;
};

export type ActiveIpAddressRange = {
  type: IpAddressWhitelistEntryType.ipAddressRange;
  key: string;
  from?: string;
  to?: string;
};

export type ActiveIpAddressWhitelistEntry = ActiveIpAddress | ActiveIpAddressRange;
export type ActiveWebhook = {
  id: string;
  name?: string;
  description?: string;
  ipAddressesWhitelist?: ActiveIpAddressWhitelistEntry[];
  methodsWhitelist: WebhookMethod[];
  headersWhitelist?: HeadersWhitelistConfig[];
};
