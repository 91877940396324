import { columnTypesEnum } from '@features/objects/types';
import { Column } from '@features/audiences/types';

export const allowedDataTypesByOriginType: { [type in columnTypesEnum]: columnTypesEnum[] } = {
  string: [
    columnTypesEnum.STRING,
    columnTypesEnum.INTEGER,
    columnTypesEnum.FLOAT,
    columnTypesEnum.BOOLEAN,
    columnTypesEnum.TIMESTAMP,
    columnTypesEnum.JSON,
    columnTypesEnum.SYSTEM_ID,
    columnTypesEnum.STRING_ARRAY,
  ],
  float: [columnTypesEnum.FLOAT, columnTypesEnum.STRING],
  integer: [
    columnTypesEnum.STRING,
    columnTypesEnum.INTEGER,
    columnTypesEnum.FLOAT,
    columnTypesEnum.BOOLEAN,
  ],
  boolean: [columnTypesEnum.BOOLEAN, columnTypesEnum.STRING, columnTypesEnum.INTEGER],
  timestamp: [columnTypesEnum.TIMESTAMP, columnTypesEnum.STRING],
  json: [columnTypesEnum.JSON, columnTypesEnum.STRING],
  'system-id': [columnTypesEnum.SYSTEM_ID, columnTypesEnum.STRING],
  'boolean[]': [columnTypesEnum.BOOLEAN_ARRAY, columnTypesEnum.JSON],
  'float[]': [columnTypesEnum.FLOAT_ARRAY, columnTypesEnum.STRING_ARRAY, columnTypesEnum.JSON],
  'integer[]': [columnTypesEnum.STRING_ARRAY, columnTypesEnum.INTEGER_ARRAY, columnTypesEnum.JSON],
  'string[]': [columnTypesEnum.STRING_ARRAY, columnTypesEnum.JSON],
  'timestamp[]': [columnTypesEnum.TIMESTAMP_ARRAY, columnTypesEnum.JSON],
  'system-id[]': [columnTypesEnum.SYSTEM_ID_ARRAY, columnTypesEnum.JSON],
  'json[]': [columnTypesEnum.JSON_ARRAY, columnTypesEnum.JSON],
};
export const canValueBeCastedAs = (
  initialValueType: columnTypesEnum,
  newValueType: columnTypesEnum
): Boolean => {
  switch (initialValueType) {
    case columnTypesEnum.BOOLEAN:
      return allowedDataTypesByOriginType[columnTypesEnum.BOOLEAN].includes(newValueType);

    case columnTypesEnum.BOOLEAN_ARRAY:
      return allowedDataTypesByOriginType[columnTypesEnum.BOOLEAN_ARRAY].includes(newValueType);

    case columnTypesEnum.FLOAT:
      return allowedDataTypesByOriginType[columnTypesEnum.FLOAT].includes(newValueType);

    case columnTypesEnum.FLOAT_ARRAY:
      return allowedDataTypesByOriginType[columnTypesEnum.FLOAT_ARRAY].includes(newValueType);

    case columnTypesEnum.INTEGER:
      return allowedDataTypesByOriginType[columnTypesEnum.INTEGER].includes(newValueType);

    case columnTypesEnum.INTEGER_ARRAY:
      return allowedDataTypesByOriginType[columnTypesEnum.INTEGER_ARRAY].includes(newValueType);

    case columnTypesEnum.STRING:
      return allowedDataTypesByOriginType[columnTypesEnum.STRING].includes(newValueType);

    case columnTypesEnum.STRING_ARRAY:
      return allowedDataTypesByOriginType[columnTypesEnum.STRING_ARRAY].includes(newValueType);

    case columnTypesEnum.JSON:
      return allowedDataTypesByOriginType[columnTypesEnum.JSON].includes(newValueType);

    case columnTypesEnum.TIMESTAMP:
      return allowedDataTypesByOriginType[columnTypesEnum.TIMESTAMP].includes(newValueType);

    case columnTypesEnum.TIMESTAMP_ARRAY:
      return allowedDataTypesByOriginType[columnTypesEnum.TIMESTAMP_ARRAY].includes(newValueType);

    case columnTypesEnum.SYSTEM_ID:
      return allowedDataTypesByOriginType[columnTypesEnum.SYSTEM_ID].includes(newValueType);

    case columnTypesEnum.SYSTEM_ID_ARRAY:
      return allowedDataTypesByOriginType[columnTypesEnum.SYSTEM_ID_ARRAY].includes(newValueType);

    default:
      return false;
  }
};

export function getSourceColumnType(column: Column) {
  return column.castTo ?? column.type;
}
