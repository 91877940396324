import { ApolloError } from '@apollo/client';

type ErrorConstraints =
  | {
      reference: string;
    }
  | {
      type: string;
      data: {
        action: {
          type: string;
          columns: [
            {
              originalColumnId: string;
              targetColumnId: string;
              targetColumnName: string;
            }
          ];
        };
        missingColumns: string[];
      };
    };
type ErrorResponseMessage = {
  property: string;
  children?: ErrorResponseMessage[];
  constraints?: ErrorConstraints;
  target?: any;
};
type ErrorResponse = {
  message: ErrorResponseMessage[];
  statusCode: number;
  error: string;
};
const isErrorResponse = (obj: unknown): obj is ErrorResponse => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'message' in obj &&
    'statusCode' in obj &&
    'error' in obj
  );
};

const parseErrorResponse = (error: ApolloError) => {
  const errorResponse = error.graphQLErrors[0].extensions.originalError;
  if (isErrorResponse(errorResponse)) {
    const errorResponseMessages = errorResponse.message.map((message) => ({
      property: message.property,
      constraints: message.constraints,
      children: message.children,
    }));
    const statusCode = errorResponse.statusCode;
    switch (statusCode) {
      case 400:
        return `Error details: ${JSON.stringify(errorResponseMessages, null, 2)}`;
      default:
        return 'Server is not responding';
    }
  }
  return "Can't parse the error message";
};

export default parseErrorResponse;
