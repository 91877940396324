import React, { useContext, useEffect, useState } from 'react';
import {
  createAuth0Client,
  Auth0Client,
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  PopupConfigOptions,
  RedirectLoginOptions,
  User,
} from '@auth0/auth0-spa-js';
import { history } from '@redux/store';
import { OrganizationsState } from '@features/organizations/ducks/organizationsSlice';
import { connect } from 'react-redux';
import { ReducerStates } from '@redux/reducers';
import { getFrontendTestingSignInRequest } from '@features/frontend-e2e-testing/frontend-testing';
import { FrontendTestingAuth0Provider } from '@features/frontend-e2e-testing/FrontendTestingAuth0Provider';

const DEFAULT_REDIRECT_CALLBACK = (appState: any) =>
  history.push(appState?.returnTo || window.location.pathname);

export interface ContextValue {
  isAuthenticated?: boolean;
  user?: User;
  loading?: boolean;
  popupOpen?: boolean;
  loginWithPopup?: () => void;
  handleRedirectCallback?: () => void;
  getIdTokenClaims?: (...p: any) => void;
  loginWithRedirect?: (options?: RedirectLoginOptions<any>) => Promise<void> | undefined;
  getTokensWithPopup?: (
    options?: GetTokenWithPopupOptions,
    config?: PopupConfigOptions
  ) => Promise<string> | undefined;
  logout?: (options?: LogoutOptions) => void | Promise<void>;
}

export interface Auth0ProviderProps extends Auth0ClientOptions {
  onRedirectCallback?: (appState: any) => void;
  children: React.ReactNode;
}

export const Auth0Context = React.createContext<ContextValue>({});
export const useAuth0 = () => useContext(Auth0Context);

let _initOptions: Auth0ClientOptions;
let _client: Auth0Client;

const getAuth0Client = async (initOptions?: Auth0ClientOptions) => {
  if (_client) {
    return _client;
  }

  try {
    _client = await createAuth0Client(initOptions || _initOptions);
    return _client;
  } catch (e: any) {
    throw new Error(`getAuth0Client Error ${e}`);
  }
};

export const getTokenSilently = async (options?: GetTokenSilentlyOptions) => {
  if (!_client) {
    throw new Error('Client is not defined');
  }
  return await _client.getTokenSilently(options);
};

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderProps) => {
  const customInitOptions: Auth0ClientOptions = {
    ...initOptions,
    authorizationParams: {
      ...initOptions.authorizationParams,
      redirect_uri: `${window.location.origin}/oauth/octolis`,
    },
  };
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<User | undefined>();
  const [auth0Client, setAuth0Client] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      _initOptions = customInitOptions;
      const client: Auth0Client = await getAuth0Client(customInitOptions);
      setAuth0Client(client);

      if (window.location.pathname === '/oauth/octolis') {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await client.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await client.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client?.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const user = await auth0Client?.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const getTokensWithPopup = async (
    options?: GetTokenWithPopupOptions,
    config?: PopupConfigOptions
  ): Promise<string> => {
    const token = await auth0Client?.getTokenWithPopup(options, config);
    if (!token) {
      throw new Error('Token is undefined');
    }
    return token;
  };

  const logout = (options?: LogoutOptions) => auth0Client?.logout(options);

  const getIdTokenClaims = () => auth0Client?.getIdTokenClaims();

  const loginWithRedirect = (options?: RedirectLoginOptions<any>) => {
    return auth0Client?.loginWithRedirect(options);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getTokensWithPopup,
        logout,
        getIdTokenClaims,
        loginWithRedirect: loginWithRedirect,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

const ConnectedAuth0Container = ({
  organizationState,
  children,
}: {
  children?: React.ReactNode;
  organizationState: OrganizationsState;
}) => {
  if (getFrontendTestingSignInRequest()) {
    return <FrontendTestingAuth0Provider>{children}</FrontendTestingAuth0Provider>;
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      useRefreshTokens={true}
      cacheLocation="memory"
      authorizationParams={{
        organization: organizationState.organizationByDomain?.auth0OrganizationId,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
export const Auth0ContainerProvider = connect(
  (state: ReducerStates) => ({
    organizationState: state.organizations,
  }),
  {}
)(ConnectedAuth0Container);
