import {
  DEFAULT_AUDIENCE_PREVIEW_SAMPLE_PAGE_SIZE,
  MASTER_ID_COLUMN_NAME,
  MODIFIED_AT_COLUMN_NAME,
} from '@features/audiences/helpers/constants/constants';
import sortAudienceColumns from '@features/audiences/helpers/sortAudienceColumns';
import {
  ActiveParentAudience,
  buildTypesEnum,
  CounterTypes,
  mappingRowTypesEnum,
  RecordsSample,
} from '@features/audiences/types';
import { ActiveAudience } from '@features/audiences/types/ActiveAudience/ActiveAudience';
import { AudienceColumn } from '@features/audiences/types/AudienceColumn';
import { AudiencePreviewStatus } from '@features/audiences/types/AudienceExecution/AudienceExecution';
import {
  AudiencePreview,
  AudiencePreviewShapes,
} from '@features/audiences/types/AudiencePreview/AudiencePreview';
import {
  activeAccountIdStateSelector,
  activeDataWarehouseIdStateSelector,
  audiencePreviewSelector,
} from '@redux/selectors';
import { sleep } from '@utils/helpers';
import { call, put, select } from 'redux-saga/effects';
import { getAudiencePreviewSample, previewAudience, requestPreviewAudience } from '../api/api';
import { getPreparationsTargetAudienceColumnsIds } from '../../helpers/getPreparationTargetAudienceColumnId';
import { mappingAudiencePreviewShapesToFront } from '../api/mappingAudienceTypes/toFrontType/mappingAudiencePreviewShapesToFront';
import { mappingSqlAudienceShapeToFront } from '../api/mappingAudienceTypes/toFrontType/mappingAudienceShapeToFront';
import { PreviewAudienceOutput, RequestPreviewAudienceOutput } from '../api/types';
import audiencesSlice from '../audiencesSlice';
import {
  GetAudiencePreviewSampleReducerActions,
  GetAudiencePreviewSampleSaga,
  PrepareAudienceShapeSaga,
  PreviewAudienceSaga,
  PreviewAudienceShapePayload,
  PreviewAudienceSuccessReducerActions,
  ReducerPreviewAudienceFailedAction,
  UpdateActiveAudienceColumnsReducerActions,
  UpdateAudiencePreviewRequestedIdReducerActions,
  UpdateAudiencePreviewTraceparentReducerActions,
  UpdatePreviewAudienceSampleReducerActions,
  UpdatePreviewAudienceShapeReducerActions,
} from '../types';
import { draftParentAudience } from './draftParentAudience';
import { getDeletedPrepAudienceColumns } from '@features/audiences/helpers/getDeletedPrepAudienceColumns';
import { AudienceSpecificationType } from '../api/audienceBackTypes/audienceSpecification';
import { ApolloError } from '@apollo/client';
import parseErrorResponse from '@features/audiences/helpers/parseErrorResponse';
import newTraceparent from '@utils/createTraceparent';

export function* previewAudienceSaga({ payload }: PreviewAudienceSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);

  const { audience, samplePaging, abortSignal } = payload;
  try {
    // Update audience with parent audience properties
    const checkParentAudienceResult: {
      activeAudience: ActiveAudience;
      checkParentAudienceError: string | undefined;
    } = yield call(checkParentAudienceSaga, {
      audience,
    });
    const { activeAudience, checkParentAudienceError } = checkParentAudienceResult;

    // In case check parent saga returns error
    if (checkParentAudienceError) {
      yield put<ReducerPreviewAudienceFailedAction>({
        type: audiencesSlice.actions.previewAudienceFailed.type,
        payload: {
          errorTitle: 'Parent dataset error',
          errorDetails: checkParentAudienceError,
        },
      });
      return;
    }
    // Request preview audience

    const traceparent = newTraceparent();
    yield put<UpdateAudiencePreviewTraceparentReducerActions>({
      type: audiencesSlice.actions.updateAudiencePreviewTraceparent.type,
      payload: {
        traceparent,
      },
    });
    const requestPreviewAudienceOutput: RequestPreviewAudienceOutput = yield call(
      requestPreviewAudience,
      activeAudience,
      accountId,
      dataWarehouseId,
      traceparent,
      abortSignal
    );
    // Update preview Id
    yield put<UpdateAudiencePreviewRequestedIdReducerActions>({
      type: audiencesSlice.actions.updateAudiencePreviewRequestedId.type,
      payload: {
        previewId: requestPreviewAudienceOutput.audiencePreviewId,
      },
    });

    let previewAudiencePayload: PreviewAudienceOutput | undefined = undefined;
    let previewAudienceShapeUpdated = false;
    let previewAudienceSampleUpdated = false;
    let isPreviewAudiencePending = true;
    // Check previewAudience data and status each 500ms
    while (isPreviewAudiencePending) {
      yield sleep(500, abortSignal);
      previewAudiencePayload = yield call(
        previewAudience,
        requestPreviewAudienceOutput.audiencePreviewId,
        samplePaging,
        abortSignal
      );
      isPreviewAudiencePending = previewAudiencePayload?.status === AudiencePreviewStatus.pending;
      // Update audience shapes if preview still pending but audience shape data is available
      if (
        isPreviewAudiencePending &&
        !previewAudienceShapeUpdated &&
        previewAudiencePayload?.shape &&
        previewAudiencePayload?.shapes
      ) {
        const audienceShapePayload: PreviewAudienceShapePayload | undefined = yield call(
          prepareAudienceShapeSaga,
          {
            previewAudiencePayload,
            audience: activeAudience,
          }
        );
        if (audienceShapePayload) {
          yield put<UpdateActiveAudienceColumnsReducerActions>({
            type: audiencesSlice.actions.updateActiveAudienceColumns.type,
            payload: {
              activeAudienceColumns: audienceShapePayload.activeAudienceColumnsSettings,
            },
          });
          yield put<UpdatePreviewAudienceShapeReducerActions>({
            type: audiencesSlice.actions.updatePreviewAudienceShape.type,
            payload: {
              shape: audienceShapePayload.shape,
              shapeDetails: audienceShapePayload.shapeDetails,
              shapes: audienceShapePayload.shapes,
            },
          });
          previewAudienceShapeUpdated = true;
        }
      }
    }
    // In case pending is done and audience shape is not updated yet
    if (
      !isPreviewAudiencePending &&
      !previewAudienceShapeUpdated &&
      previewAudiencePayload?.shape &&
      previewAudiencePayload?.shapes
    ) {
      const audienceShapePayload: PreviewAudienceShapePayload | undefined = yield call(
        prepareAudienceShapeSaga,
        {
          previewAudiencePayload,
          audience: activeAudience,
        }
      );
      if (audienceShapePayload) {
        yield put<UpdateActiveAudienceColumnsReducerActions>({
          type: audiencesSlice.actions.updateActiveAudienceColumns.type,
          payload: {
            activeAudienceColumns: audienceShapePayload.activeAudienceColumnsSettings,
          },
        });
        yield put<UpdatePreviewAudienceShapeReducerActions>({
          type: audiencesSlice.actions.updatePreviewAudienceShape.type,
          payload: {
            shape: audienceShapePayload.shape,
            shapeDetails: audienceShapePayload.shapeDetails,
            shapes: audienceShapePayload.shapes,
          },
        });
      }
      previewAudienceShapeUpdated = true;
    }
    // Update preview audience sample and counter
    if (
      previewAudiencePayload?.status === AudiencePreviewStatus.ok &&
      previewAudiencePayload.sample
    ) {
      yield put<UpdatePreviewAudienceSampleReducerActions>({
        type: audiencesSlice.actions.updatePreviewAudienceSample.type,
        payload: {
          sample: mappingSampleProperty(previewAudiencePayload),
          counter: previewAudiencePayload.counter ?? { type: CounterTypes.none },
        },
      });
      previewAudienceSampleUpdated = true;
    }
    // In case shape & sample updated
    if (previewAudienceShapeUpdated && previewAudienceSampleUpdated) {
      yield put<PreviewAudienceSuccessReducerActions>({
        type: audiencesSlice.actions.previewAudienceSuccess.type,
      });
      const previewAudience: AudiencePreview = yield select(audiencePreviewSelector);
      return { previewAudience };
    }
    // In case error
    if (previewAudiencePayload?.status === AudiencePreviewStatus.error) {
      yield put({
        type: audiencesSlice.actions.cleanPreviewAudienceState.type,
        payload: { audiencePreviewId: requestPreviewAudienceOutput.audiencePreviewId },
      });
      yield put<ReducerPreviewAudienceFailedAction>({
        type: audiencesSlice.actions.previewAudienceFailed.type,
        payload: {
          errorTitle: previewAudiencePayload.error?.name ?? 'Preview dataset error',
          errorDetails: JSON.stringify(
            previewAudiencePayload.error,
            Object.getOwnPropertyNames(previewAudiencePayload.error)
          ),
        },
      });
      return {
        error: previewAudiencePayload.error?.message,
      };
    }
  } catch (err: unknown) {
    if (err instanceof ApolloError) {
      try {
        const errorDetails = parseErrorResponse(err);
        const errorTitle =
          typeof err.graphQLErrors[0].extensions?.code === 'string'
            ? err.graphQLErrors[0].extensions?.code
            : 'Error happened';
        yield put<ReducerPreviewAudienceFailedAction>({
          type: audiencesSlice.actions.previewAudienceFailed.type,
          payload: { errorTitle, errorDetails },
        });
      } catch (e) {
        console.log(e);
      }
      return {
        error: err.message,
      };
    }
    if (err instanceof Error) {
      console.error(err);
    }
  }
}

function* checkParentAudienceSaga({ audience }: { audience: ActiveAudience }) {
  let activeAudience: ActiveAudience = audience;
  let checkParentAudienceError: string | undefined = undefined;
  switch (audience.buildType) {
    case buildTypesEnum.BUSINESS: {
      if (audience.parent) {
        const parentAudienceOutput: ActiveParentAudience | string = yield draftParentAudience({
          payload: { parentAudience: audience.parent },
        });
        if (typeof parentAudienceOutput === 'string') {
          checkParentAudienceError = parentAudienceOutput; // Error message
        } else {
          activeAudience = {
            ...audience,
            parent: parentAudienceOutput,
          };
          yield put({
            type: audiencesSlice.actions.updateActiveAudience.type,
            payload: { activeAudience },
          });
        }
      }
      break;
    }
    case buildTypesEnum.DATA:
      break;
    case undefined:
      throw new Error('Failed checkParentAudienceSaga Build type is undefined');
    default:
      throw new Error(`Type ${audience} of audience is not supported`);
  }
  return checkParentAudienceError
    ? { checkParentAudienceError, activeAudience }
    : { activeAudience };
}
function prepareAudienceShapeSaga({ audience, previewAudiencePayload }: PrepareAudienceShapeSaga) {
  const audiencePreviewShapes: AudiencePreviewShapes = mappingAudiencePreviewShapesToFront(
    previewAudiencePayload.shapes!
  );
  switch (audience.buildType) {
    case buildTypesEnum.BUSINESS:
      const allColumns =
        audiencePreviewShapes.audienceType === AudienceSpecificationType.default
          ? audiencePreviewShapes.preparationSteps.outputShape
          : [];
      const defaultMappingRowsIds = audience.mappingRows.reduce((result: string[], row) => {
        if (row.rowType === mappingRowTypesEnum.DEFAULT) {
          result.push(row.key);
        }
        return result;
      }, []);
      const baseColumns = allColumns.filter((col) => defaultMappingRowsIds.includes(col.id));
      const masterIdColumn = allColumns.find((col) => col.name === MASTER_ID_COLUMN_NAME);
      const modifiedAtColumn = allColumns.find((col) => col.name === MODIFIED_AT_COLUMN_NAME);
      const otherSystemColumns = allColumns.filter(
        (col) =>
          col.name.startsWith('__') &&
          col.name !== MASTER_ID_COLUMN_NAME &&
          col.name !== MODIFIED_AT_COLUMN_NAME
      );
      const parentColumn = allColumns.find(
        (col) => col.id === audience.parent?.join?.childMatchColumnId
      );
      const aggsMappingRowsIds = audience.mappingRows.reduce((result: string[], row) => {
        if (row.rowType === mappingRowTypesEnum.AGGREGATE_ROW) {
          result.push(row.key);
        }
        return result;
      }, []);
      const aggsColumns = allColumns.filter((col) => aggsMappingRowsIds.includes(col.id));
      const preparationStepsAudienceColumnsIds = getPreparationsTargetAudienceColumnsIds(
        audience.preparationSteps
      );

      const prepColumns = allColumns.filter((col) =>
        preparationStepsAudienceColumnsIds.includes(col.id)
      );
      const deletedColumnsByPrep = getDeletedPrepAudienceColumns(
        audience.preparationSteps,
        audience.mappingRows
      );
      // This columns list will contain deleted columns from preps for display purposes in the settings screen.
      let activeAudienceColumnsSettings: AudienceColumn[] = [];
      // Order is important!
      masterIdColumn && activeAudienceColumnsSettings.push(masterIdColumn);
      modifiedAtColumn && activeAudienceColumnsSettings.push(modifiedAtColumn);
      activeAudienceColumnsSettings = [...activeAudienceColumnsSettings, ...baseColumns];
      parentColumn && activeAudienceColumnsSettings.push(parentColumn);
      activeAudienceColumnsSettings = [
        ...activeAudienceColumnsSettings,
        ...aggsColumns,
        ...prepColumns,
        ...deletedColumnsByPrep,
        ...otherSystemColumns,
      ];

      return {
        activeAudienceColumnsSettings,
        shape: sortAudienceColumns(allColumns),
        shapeDetails: {
          preparationStepsAudienceColumnsIds,
          deletedPreparationStepAudienceColumns: deletedColumnsByPrep,
          masterIdColumnId: masterIdColumn?.id ?? '',
          modifiedAtColumnId: modifiedAtColumn?.id ?? '',
        },
        shapes: audiencePreviewShapes,
      };
    case buildTypesEnum.DATA:
      const sqlAudienceShape: AudienceColumn[] = mappingSqlAudienceShapeToFront(
        previewAudiencePayload.shape?.columns || []
      );
      const audienceColumns = sortAudienceColumns(sqlAudienceShape);
      const masterIdColumnId = sqlAudienceShape.find(
        (col) => col.name === MASTER_ID_COLUMN_NAME
      )?.id;
      const modifiedAtColumnId = sqlAudienceShape.find(
        (col) => col.name === MODIFIED_AT_COLUMN_NAME
      )?.id;
      return {
        shapes: audiencePreviewShapes,
        shape: audienceColumns,
        shapeDetails: {
          masterIdColumnId: masterIdColumnId ?? '',
          modifiedAtColumnId: modifiedAtColumnId ?? '',
          preparationStepsAudienceColumnsIds: [],
          deletedPreparationStepAudienceColumns: [],
        },
        activeAudienceColumnsSettings: [],
      };
    default:
      return undefined;
  }
}
function mappingSampleProperty(previewAudiencePayload: PreviewAudienceOutput): RecordsSample {
  return Array.isArray(previewAudiencePayload.sample)
    ? {
        size: previewAudiencePayload.sample.length,
        page: 1,
        pageSize: DEFAULT_AUDIENCE_PREVIEW_SAMPLE_PAGE_SIZE,
        records: previewAudiencePayload.sample,
      }
    : {
        size: 0,
        page: 1,
        pageSize: DEFAULT_AUDIENCE_PREVIEW_SAMPLE_PAGE_SIZE,
        records: [],
        ...previewAudiencePayload.sample,
      };
}
export function* getAudiencePreviewSampleSaga({
  payload: { audiencePreviewId, paging },
}: GetAudiencePreviewSampleSaga) {
  try {
    const sample: RecordsSample = yield call(getAudiencePreviewSample, audiencePreviewId, paging);
    const payload: GetAudiencePreviewSampleReducerActions['payload'] = {
      sample: {
        ...paging,
        ...sample,
      },
    };
    yield put({ payload, type: audiencesSlice.actions.getAudiencePreviewSampleSuccess.type });
  } catch (error) {
    yield put({
      type: audiencesSlice.actions.getAudiencePreviewSampleFailed.type,
    });
  }
}
