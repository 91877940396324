import { OperationType } from '@features/profiles/types';

export const allRights: OperationType[] = [
  OperationType.create,
  OperationType.read,
  OperationType.update,
  OperationType.delete,
];

export const canUserRead = (rights: OperationType[]) => {
  return rights.includes(
    OperationType.read || OperationType.create || OperationType.update || OperationType.delete
  );
};
export const canUserCreate = (rights: OperationType[]) => {
  return rights.includes(OperationType.create || OperationType.update || OperationType.delete);
};
export const canUserEdit = (rights: OperationType[]) => {
  return rights.includes(OperationType.update || OperationType.delete);
};
export const canUserDelete = (rights: OperationType[]) => {
  return rights.includes(OperationType.delete);
};
