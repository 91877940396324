// Importing type
import * as apiTypes from '../types';
import { Sync, SyncsTableRecord, DeployedSync } from '../../types';
import {
  GET_API_DESTINATION_SCHEMA,
  GET_DEPLOYED_SYNC,
  GET_DESTINATION_ENTITIES,
  GET_DESTINATION_SCHEMA,
  GET_DESTINATION_TRIGGERS,
  GET_SYNC,
  GET_SYNCS,
  GET_SYNCS_TABLE_LIST,
} from '@features/syncs/ducks/api/graphql/queries';
import {
  ALTER_DATABASE_SYNC_TABLE,
  CREATE_DATABASE_SYNC_TABLE,
  CREATE_SYNC,
  DELETE_SYNC,
  DISABLE_SYNC,
  ENABLE_SYNC,
  REQUEST_EXECUTE_SYNC,
  UPDATE_SYNC,
  VALIDATE_DATABASE_SYNC_TABLE,
} from '@features/syncs/ducks/api/graphql/mutations';
import { SyncReadModel } from '@features/syncs/types/backTypes/SyncModel';
import apolloClientInstance from '@apolloClientInstance';
import {
  mapApiDestinationSchemaToFront,
  mapDestinationSchemaToFront,
  syncToFront,
} from './mappingSyncTypes';
import {
  GetDestinationEntitiesInput,
  GetDestinationEntitiesOutput,
} from '@features/syncs/types/DestinationEntity';
import { GetDestinationSchemaInput } from '@features/syncs/types/DestinationSchema';
import { getCreateSyncCommand } from './mappingSyncTypes/actions/getCreateSyncCommand';
import { CreateSyncCommand } from '../types/CreateSyncCommand';
import { UpdateSyncCommand } from '../types/UpdateSyncCommand';
import { getUpdateSyncCommand } from './mappingSyncTypes/actions/getUpdateSyncCommand';
import {
  GetDestinationTriggersInput,
  GetDestinationTriggersOutput,
} from '@features/syncs/types/DestinationTriggers';
import {
  AlterDatabaseSyncTableInput,
  CreateDatabaseSyncTableInput,
  ValidateDatabaseSyncTableInput,
} from '../../types/DatabaseSyncTableCommandsInputs';
import { sleep } from '@utils/helpers';
import { Connector } from '@features/connectors/types';
import { connectors } from '@data/connectors';
import { mappingSyncToTableRecordType } from './mappingSyncTypes/mappingSyncToTableRecordType';
import { ValidateDatabaseSyncTablePayload } from '../types';
import { GET_AUDIENCE_DATA_V2 } from '@features/audiences/ducks/api/graphql/queries';
import { mappingAudienceDataShape } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontAudienceHelpers/mappingAudienceDataShape';
import { AudienceColumn } from '@features/audiences/types/AudienceColumn';

const getSyncs: apiTypes.APIGetSyncs = async (accountId: string, abortSignal?) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  let syncs: Sync[] = [];

  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.query<{
    syncs: SyncReadModel[];
  }>({
    query: GET_SYNCS,
    variables: {
      accountId,
    },
  });

  if (res.data.syncs) {
    syncs = res.data.syncs.map((backSync) => syncToFront(backSync));
  }
  if (!syncs.length) {
    error = true;
    errorDetails = 'No Syncs Found';
  }
  return {
    syncs,
    error,
    errorDetails,
    loaded: true,
  };
};
const getSyncsTableList: apiTypes.APIGetSyncsTableList = async (
  accountId: string,
  abortSignal?: AbortSignal
) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  let syncsTableRecords: SyncsTableRecord[] = [];
  let audiences: { id: string; name: string }[] = [];
  let connections: { id: string; name: string; connector: Connector }[] = [];

  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.query({
    query: GET_SYNCS_TABLE_LIST,
    variables: {
      accountId,
    },
  });

  connections = (res.data.connections || []).map((con: any) => ({
    id: con.id,
    name: con.name,
    connector: connectors.find((connector) => connector.connectorType === con.settings.type),
  }));
  audiences = res.data.audiences;
  if (res.data.syncs) {
    syncsTableRecords = res.data.syncs.map((backSync: SyncReadModel) => {
      let sync = syncToFront(backSync);
      const audience = audiences.find((aud) => aud.id === sync.audienceId);
      const connection = connections.find((con) => con.id === sync.destinationConnectionId);
      return mappingSyncToTableRecordType(sync, audience, connection);
    });
  }
  if (!syncsTableRecords.length) {
    error = true;
    errorDetails = 'No Syncs table list Found';
  }
  return {
    syncsTableRecords,
    error,
    errorDetails,
    loaded: true,
  };
};

const getSync: apiTypes.APIGetSync = async (syncId) => {
  let sync: Sync | undefined = undefined;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.query({
    query: GET_SYNC,
    variables: {
      id: syncId,
    },
  });
  if (res.data.sync) {
    let audienceColumns: AudienceColumn[] = [];
    const resultAudienceData = await apolloClient.query({
      query: GET_AUDIENCE_DATA_V2,
      variables: {
        query: {
          audienceId: res.data.sync.specification.audienceId,
        },
      },
    });
    if (resultAudienceData.data.audienceDataV2) {
      audienceColumns = mappingAudienceDataShape(
        resultAudienceData.data.audienceDataV2.shape
      ).columns;
    }
    sync = syncToFront(res.data.sync, audienceColumns);
    return {
      sync,
      loaded: true,
    };
  }

  if (!sync) {
    error = true;
    errorDetails = 'Sync not found.';
  }
  return {
    sync,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getDeployedSync: apiTypes.APIGetDeployedSync = async (syncId) => {
  let deployedSync: DeployedSync | undefined = undefined;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.query({
    query: GET_DEPLOYED_SYNC,
    variables: {
      syncId,
    },
  });
  if (res.data.deployedSync) {
    return {
      deployedSync: res.data.deployedSync,
      loaded: true,
    };
  } else {
    error = true;
    errorDetails = 'Unable to get deployed sync';
  }

  return {
    deployedSync,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const saveSync: apiTypes.APISaveSync = async (activeSync, accountId, dataWarehouseId) => {
  const createSyncCommand: CreateSyncCommand = getCreateSyncCommand(
    activeSync as unknown as Sync,
    accountId,
    dataWarehouseId
  );
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: CREATE_SYNC,
    variables: {
      command: createSyncCommand,
    },
  });
  if (res.data.createSync) {
    const sync: Sync = syncToFront(res.data.createSync);
    return {
      sync,
      loaded: true,
    };
  }
  return {
    error: true,
    errorDetails: 'Impossible to save sync.',
    loaded: true,
  };
};
const updateSync: apiTypes.APIUpdateSync = async (activeSync, accountId, dataWarehouseId) => {
  const updateSyncCommand: UpdateSyncCommand = getUpdateSyncCommand(
    activeSync as unknown as Sync,
    accountId,
    dataWarehouseId
  );
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: UPDATE_SYNC,
    variables: {
      command: updateSyncCommand,
    },
  });
  if (res.data.updateSync) {
    const sync: Sync = syncToFront(res.data.updateSync);
    return {
      sync,
      loaded: true,
    };
  }
  return {
    error: true,
    errorDetails: 'Impossible to update sync.',
    loaded: true,
  };
};
const deleteSync: apiTypes.APIDeleteSync = async (id) => {
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: DELETE_SYNC,
    variables: {
      command: { id },
    },
  });
  if (res.data.deleteSync) {
    return {
      loaded: true,
      syncId: id,
    };
  }
  return {
    error: true,
    errorDetails: 'Impossible to delete sync.',
    loaded: true,
    syncId: id,
  };
};
const enableSync: apiTypes.APIEnableSync = async (id) => {
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: ENABLE_SYNC,
    variables: {
      command: { id },
    },
  });
  if (res.data.enableSync) {
    return {
      syncId: id,
      loaded: true,
      status: true,
    };
  }
  return {
    syncId: id,
    status: false,
    error: true,
    errorDetails: 'Impossible to enable sync.',
    loaded: true,
  };
};
const disableSync: apiTypes.APIDisableSync = async (id) => {
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: DISABLE_SYNC,
    variables: {
      command: { id },
    },
  });
  if (res.data.disableSync) {
    return {
      syncId: id,
      loaded: true,
      status: true,
    };
  }
  return {
    syncId: id,
    status: false,
    error: true,
    errorDetails: 'Impossible to disable sync.',
    loaded: true,
  };
};

const requestSyncExecution: apiTypes.APIRequestSyncExecution = async (syncExecution) => {
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: REQUEST_EXECUTE_SYNC,
    variables: {
      command: syncExecution,
    },
  });
  if (
    res.data.requestSyncExecution &&
    res.data.requestSyncExecution.__typename ===
      apiTypes.RequestSyncExecutionTypeNames.syncExecutionRequestAccepted &&
    res.data.requestSyncExecution.executionId
  ) {
    return {
      loaded: true,
      status: true,
      executionId: res.data.requestSyncExecution.executionId,
      syncId: syncExecution.syncId,
    };
  }
  return {
    status: false,
    error: true,
    errorDetails: 'Execute sync failed.',
    loaded: true,
  };
};
const getDestinationSchema: apiTypes.APIGetDestinationSchema = async (
  query: GetDestinationSchemaInput
) => {
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.query({
    query: GET_DESTINATION_SCHEMA,
    variables: {
      query,
    },
  });
  if (res.data.getDestinationSchema) {
    const frontDestinationSchema = mapDestinationSchemaToFront(res.data.getDestinationSchema);
    return frontDestinationSchema;
  } else {
    throw new Error('No Destination Schema found.');
  }
};
const getDestinationEntities: apiTypes.APIGetDestinationEntities = async (
  getDestinationEntitiesInput: GetDestinationEntitiesInput
) => {
  const { accountId, settings } = getDestinationEntitiesInput;
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.query({
    query: GET_DESTINATION_ENTITIES,
    variables: {
      accountId,
      settings,
    },
  });
  if (res.data.getDestinationEntities) {
    const destinationEntities: GetDestinationEntitiesOutput = res.data.getDestinationEntities;
    return destinationEntities;
  } else {
    throw new Error('Unexpected error');
  }
};
const getApiDestinationSchema: apiTypes.APIGetAPIDestinationSchema = async (query) => {
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.query({
    query: GET_API_DESTINATION_SCHEMA,
    variables: {
      query,
    },
  });
  if (res.data.getApiConnectionEntityDestinationSchema) {
    const frontApiDestinationSchema = mapApiDestinationSchemaToFront(
      res.data.getApiConnectionEntityDestinationSchema
    );
    return frontApiDestinationSchema;
  } else {
    throw new Error('No api destination schema found.');
  }
};
const getDestinationTriggers: apiTypes.APIGetDestinationTriggers = async (
  getDestinationTriggersInput: GetDestinationTriggersInput
) => {
  const { query } = getDestinationTriggersInput;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  let destinationTriggers: GetDestinationTriggersOutput | undefined = undefined;

  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.query({
    query: GET_DESTINATION_TRIGGERS,
    variables: {
      query,
    },
  });
  if (res.data.getDestinationTriggers) {
    destinationTriggers = res.data.getDestinationTriggers;
  } else {
    error = true;
    errorDetails = 'No Destination Triggers found.';
  }

  return {
    error,
    destinationTriggers,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const createDatabaseSyncTable: apiTypes.APICreateDatabaseSyncTable = async (
  createDatabaseSyncTableInput: CreateDatabaseSyncTableInput
) => {
  await sleep(1000);
  const { command } = createDatabaseSyncTableInput;
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate<{
    createDatabaseSyncTable: {
      success: boolean;
      errors: object[];
    };
  }>({
    mutation: CREATE_DATABASE_SYNC_TABLE,
    variables: {
      command,
    },
  });
  return {
    success: res.data ? res.data.createDatabaseSyncTable.success : false,
    errors: res.data ? res.data.createDatabaseSyncTable.errors : [],
    loaded: true,
  };
};
const alterDatabaseSyncTable: apiTypes.APIAlterDatabaseSyncTable = async (
  alterDatabaseSyncTableInput: AlterDatabaseSyncTableInput
) => {
  await sleep(3000);
  const { command } = alterDatabaseSyncTableInput;

  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate<{
    alterDatabaseSyncTable: {
      success: boolean;
      modified: boolean;
      errors: object[];
    };
  }>({
    mutation: ALTER_DATABASE_SYNC_TABLE,
    variables: {
      command,
    },
  });
  const result = res.data?.alterDatabaseSyncTable;
  return {
    success: result?.success ?? false,
    errors: result?.errors ?? [],
    modified: result?.modified ?? false,
    errorDetails: result?.errors ? JSON.stringify(result.errors[0]) : 'Alter table failed',
    loaded: true,
  };
};

const validateDatabaseSyncTable: apiTypes.APIValidateDatabaseSyncTable = async ({
  command,
}: ValidateDatabaseSyncTableInput): Promise<ValidateDatabaseSyncTablePayload> => {
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate<{
    validateDatabaseSyncTable: {
      success: boolean;
      errors: object[];
    };
  }>({
    mutation: VALIDATE_DATABASE_SYNC_TABLE,
    variables: {
      command,
    },
  });
  const validateDatabaseSyncTableResult = res.data?.validateDatabaseSyncTable;

  return {
    success: validateDatabaseSyncTableResult?.success ?? false,
    errors: validateDatabaseSyncTableResult?.errors ?? [],
    errorDetails: !validateDatabaseSyncTableResult ? 'Unexpected error happened' : undefined,
    loaded: true,
  };
};
export {
  getSyncs,
  getSyncsTableList,
  getSync,
  getDeployedSync,
  saveSync,
  updateSync,
  deleteSync,
  enableSync,
  disableSync,
  getDestinationSchema,
  getDestinationEntities,
  getApiDestinationSchema,
  getDestinationTriggers,
  requestSyncExecution,
  createDatabaseSyncTable,
  alterDatabaseSyncTable,
  validateDatabaseSyncTable,
};
