import { AudienceDeduplicationKey } from '@features/audiences/types';
import { generateId } from '@utils/helpers';
import { AudienceReadModel } from '../../audienceBackTypes/audience';
import { MASTER_ID_COLUMN_NAME } from '@features/audiences/helpers/constants/constants';

export const mappingDeduplicationKeysToFront = (
  audienceFromBack: AudienceReadModel
): AudienceDeduplicationKey[] => {
  // Get deduplicationSettings from the backend type

  const backDeduplicationSettingsKeys = audienceFromBack.specification.deduplicationSettings.keys;

  // just adapting having a master id deduplication keys
  if (typeof backDeduplicationSettingsKeys === 'string') {
    return [
      {
        key: generateId(),
        columnIds: [MASTER_ID_COLUMN_NAME],
      },
    ];
  }
  return backDeduplicationSettingsKeys.map((x) => ({
    columnIds: x.columnIds,
    key: generateId(),
  }));
};
