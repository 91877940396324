import { createSlice } from '@reduxjs/toolkit';

import { User } from '@features/users/types';
import {
  DeletedUserReducerActions,
  UserReducerActions,
  UsersReducerActions,
} from '@features/users/ducks/types';

export enum UserActionKey {
  GetUsers = 'get-users',
  GetUser = 'get-user',
  SaveUser = 'save-user',
  DeleteUser = 'delete-user',
  ResetPassword = 'reset-password',
}

export interface UsersState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  user: User;
  users: User[];
  latestActionKey?: UserActionKey;
}

export const INITIAL_THEME_STATE: UsersState = {
  loaded: false,
  isFetching: false,
  users: [],
  user: {
    id: '',
    email: '',
    firstName: '',
    lastLogin: '',
    access: [],
    lastName: '',
  },
};

const usersSlice = createSlice({
  name: 'users',

  initialState: INITIAL_THEME_STATE,
  reducers: {
    getUsers: (state) => {
      state.isFetching = true;
      state.latestActionKey = UserActionKey.GetUsers;
    },
    getUsersSuccess: (state, { payload }: UsersReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.users = payload.users;
      state.latestActionKey = UserActionKey.GetUsers;
    },
    getUsersFailed: (state, { payload }: UsersReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
      state.latestActionKey = UserActionKey.GetUsers;
    },

    getUser: (state) => {
      state.isFetching = true;
      state.latestActionKey = UserActionKey.GetUser;
    },
    getUserSuccess: (state, { payload }: UserReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.user = payload.user;
      state.latestActionKey = UserActionKey.GetUser;
    },
    getUserFailed: (state, { payload }: UserReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
      state.latestActionKey = UserActionKey.GetUser;
    },

    saveUser: (state) => {
      state.isFetching = true;
      state.latestActionKey = UserActionKey.SaveUser;
    },
    saveUserSuccess: (state, { payload }: UserReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.latestActionKey = UserActionKey.SaveUser;
      const userExists = state.users.find((u) => u.id === payload.user.id);
      if (userExists) {
        state.users = state.users.map((user) => {
          if (user.id === userExists.id) return payload.user;
          return user;
        });
      } else state.users.push(payload.user);
    },
    saveUserFailed: (state, { payload }: UserReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
      state.latestActionKey = UserActionKey.SaveUser;
    },

    deleteUser: (state) => {
      state.isFetching = true;
      state.latestActionKey = UserActionKey.DeleteUser;
    },
    deleteUserSuccess: (state, { payload }: DeletedUserReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.users = state.users.filter((u) => u.id !== payload.userId);
      state.latestActionKey = UserActionKey.DeleteUser;
    },
    deleteUserFailed: (state, { payload }: DeletedUserReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
      state.latestActionKey = UserActionKey.DeleteUser;
    },

    requestPasswordReset: (state) => {
      state.isFetching = true;
      state.error = false;
      state.errorDetails = undefined;
      state.latestActionKey = UserActionKey.ResetPassword;
    },
    requestPasswordResetSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    requestPasswordResetFailed: (state, { payload }) => {
      state.isFetching = false;
      state.error = true;
    },

    resetLoaded: (state) => {
      state.loaded = false;
      state.latestActionKey = undefined;
    },
    resetError: (state) => {
      state.error = false;
      state.errorDetails = undefined;
      state.latestActionKey = undefined;
    },
  },
});

export default usersSlice;
