import { IColumnType } from '@features/audiences/ducks/api/audienceBackTypes/columnType';
import { mappingColumnTypePropertyToFront } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/mappingColumnTypePropertyToFront';
import {
  ConnectionSettings,
  ConnectorType,
  defaultBrevoConnectionRateLimitTier,
} from '@features/connections/types';
import { generateId } from '@utils/helpers';
import { IConnectionSettingsV2 } from '../backendTypes/ConnectionReadModel';

export const toFrontendConnectionSettings = (
  settings?: IConnectionSettingsV2
): ConnectionSettings | undefined => {
  if (settings === undefined) {
    return undefined;
  }
  if (settings.type === ConnectorType.brevo && !settings.rateLimitTier) {
    return {
      ...settings,
      rateLimitTier: defaultBrevoConnectionRateLimitTier,
    } as ConnectionSettings;
  }

  if (settings.type !== ConnectorType.braze) {
    return settings as ConnectionSettings;
  }
  return {
    ...settings,
    customAttributes:
      (settings.customAttributes as unknown as Array<any>)?.map((ca) => ({
        ...ca,
        type: mappingColumnTypePropertyToFront(ca.type as unknown as IColumnType),
        id: generateId(),
      })) ?? [],
  } as ConnectionSettings;
};
