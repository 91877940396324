import { takeLatest } from 'redux-saga/effects';
import {
  alterDatabaseSyncTableSaga,
  createDatabaseSyncTableSaga,
  validateDatabaseSyncTableSaga,
  deleteSyncSaga,
  disableSyncSaga,
  enableSyncSaga,
  getAudienceDataForSyncSaga,
  getDestinationEntitiesSaga,
  getDestinationSchemaSaga,
  getDestinationTriggersSaga,
  getSyncsSaga,
  getSyncsTableListSaga,
  requestSyncExecutionSaga,
  saveSyncSaga,
  updateSyncSaga,
  performDatabaseSyncTableOperationsSaga,
} from './sagas';
import syncsSlice from '../syncsSlice';
import { initSyncCreationScreenSaga, initSyncEditionScreenSaga } from './inits';

export const syncsSagas = [
  takeLatest(syncsSlice.actions.getSyncs.type, getSyncsSaga),
  takeLatest(syncsSlice.actions.getSyncsTableList.type, getSyncsTableListSaga),
  takeLatest(syncsSlice.actions.saveSync.type, saveSyncSaga),
  takeLatest(syncsSlice.actions.updateSync.type, updateSyncSaga),
  takeLatest(syncsSlice.actions.deleteSync.type, deleteSyncSaga),
  takeLatest(syncsSlice.actions.enableSync.type, enableSyncSaga),
  takeLatest(syncsSlice.actions.disableSync.type, disableSyncSaga),
  takeLatest(syncsSlice.actions.requestSyncExecution.type, requestSyncExecutionSaga),
  takeLatest(syncsSlice.actions.getDestinationSchema.type, getDestinationSchemaSaga),
  takeLatest(syncsSlice.actions.getDestinationEntities.type, getDestinationEntitiesSaga),
  takeLatest(syncsSlice.actions.getDestinationTriggers.type, getDestinationTriggersSaga),
  takeLatest(syncsSlice.actions.getAudienceDataForSync.type, getAudienceDataForSyncSaga),
  takeLatest(syncsSlice.actions.createDatabaseSyncTable.type, createDatabaseSyncTableSaga),
  takeLatest(syncsSlice.actions.alterDatabaseSyncTable.type, alterDatabaseSyncTableSaga),
  takeLatest(syncsSlice.actions.validateDatabaseSyncTable.type, validateDatabaseSyncTableSaga),
  takeLatest(syncsSlice.actions.performDatabaseSyncTableOperations.type, performDatabaseSyncTableOperationsSaga),
  takeLatest(syncsSlice.actions.initSyncCreationScreen.type, initSyncCreationScreenSaga),
  takeLatest(syncsSlice.actions.initSyncEditionScreen.type, initSyncEditionScreenSaga),
];
