import React, { CSSProperties } from 'react';
import { NotFoundType } from './types';
const NotFoundContainerStyle: CSSProperties = { height: '100%', textAlign: 'center', padding: 40 };
const messagesByErrorType: { [typeKey in NotFoundType]: React.ReactNode } = {
  default: <h2>Page not found!</h2>,
  datawarehouseNotFound: (
    <h2>Your account datawarehouse was not found, please reach out to your account manager.</h2>
  ),
  inaccessibleFeature: (
    <h2>
      This feature isn't accessible in your app or your profile doesn't have access to this feature
      please contact your consultant for more information !
    </h2>
  ),
  hiddenFeature: <h2>Your app doesn't have this feature yet!</h2>,
  underConstruction: <h2>Under construction!</h2>,
  appScreenNotfound: <h2>Page not found!</h2>,
  authScreenNotfound: <h2>Page not found!</h2>,
  organizationNotFound: <h2>Organization not found!</h2>,
};
interface INotFoundProps {
  type: NotFoundType;
  content?: React.ReactNode;
  children?: React.ReactNode;
}
const NotFound = ({ children, content, type }: INotFoundProps) => {
  return (
    <div style={NotFoundContainerStyle}>
      {children
        ? children
        : content
        ? content
        : messagesByErrorType[type]
        ? messagesByErrorType[type]
        : messagesByErrorType[NotFoundType.default]}
    </div>
  );
};

export default NotFound;
