import { gql } from '@apollo/client';
export const CREATE_DATAWAREHOUSE = gql`
  mutation ($command: CreatePostgresDataWarehouseCommand!) {
    createPostgresDataWarehouse(command: $command) {
      __typename
      ... on DataWarehouse {
        id
      }
    }
  }
`;
