import { Route, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { layoutRoutesEnum } from '@routes/types';

export function isEnabledFrontendTesting(): boolean {
  return process.env.REACT_APP_TESTING_ONLY_ENABLE_FRONTEND_E2E_TESTING === 'true';
}

const FRONTEND_TESTING_REQUEST = 'octolis-frontend-testing-request';

export function saveFrontendTestingRequest(request: IFrontendTestingSignInRequest) {
  if (isEnabledFrontendTesting()) {
    localStorage.setItem(FRONTEND_TESTING_REQUEST, JSON.stringify(request));
  }
}

export function hasFrontendTestingSignInRequest() {
  return !!getFrontendTestingSignInRequest();
}

export function removeFrontendTestingSignInRequest() {
  localStorage.removeItem(FRONTEND_TESTING_REQUEST);
}

export function getFrontendTestingSignInRequest(): IFrontendTestingSignInRequest | null {
  let request: IFrontendTestingSignInRequest | null = null;

  if (!request && isEnabledFrontendTesting()) {
    const value = localStorage.getItem(FRONTEND_TESTING_REQUEST);
    request = value ? (JSON.parse(value) as IFrontendTestingSignInRequest) : null;
  }

  return request;
}

export type IFrontendTestingSignInRequest = {
  accountId: string;
  dataWarehouseId: string;
  accessToken: string;
  user: {
    sub: string;
    organization: any;
  };
};

export function FrontendTestingSignInPage() {
  const matchParamHook = useParams<{ payload: string }>();

  useEffect(() => {
    if (isEnabledFrontendTesting()) {
      const payload = matchParamHook.payload;

      try {
        const request: IFrontendTestingSignInRequest =
          payload && JSON.parse(decodeURIComponent(payload));
        saveFrontendTestingRequest(request);
        window.location.href = `${layoutRoutesEnum.APP}/${request.accountId}/connections`;
      } catch (error) {
        console.log('Error while parsing frontend testing sign in request, PAYLOAD: ', payload);
        console.error('Error while parsing frontend testing sign in request, ERROR', error);
        throw error;
      }
    }
  }, []);

  return isEnabledFrontendTesting() ? <div>signing in...</div> : <div>not allowed</div>;
}

export function FrontendTestingRoute() {
  return isEnabledFrontendTesting() ? (
    <Route path="/testing/sign-in/:payload" Component={FrontendTestingSignInPage} />
  ) : null;
}
