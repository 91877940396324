import { ConnectorType } from '@features/connections/types';

export interface Connector {
  key: string;
  title: string;
  description: string;
  connectorType: ConnectorType;
  category: ConnectorCategoriesEnum;
  logo: string;
  expandedLogo: string;
  canBe: ConnectorExternalType[];
}
export enum ConnectorExternalType {
  destination = 'destination',
  source = 'source',
}

export enum ConnectorCategoriesEnum {
  DB = 'db',
  FS = 'fs',
  API = 'api',
}
