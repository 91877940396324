import { removeDiacritics } from './remove-diacritics';

// Heavily inspired from https://github.com/blakeembrey/change-case

const DEFAULT_SPLIT_REGEXP = [/([a-z0-9])([A-Z])/g, /([A-Z])([A-Z][a-z])/g];

// Remove all non-word characters.
const DEFAULT_STRIP_REGEXP = /[^A-Z0-9]+/gi;

function replace(inValue: string, expressions: RegExp | RegExp[], withValue: string) {
  return Array.isArray(expressions)
    ? expressions.reduce((value, expression) => value.replace(expression, withValue), inValue)
    : inValue.replace(expressions, withValue);
}

export function toSnakeCase(value: string): string {
  const result = replace(
    replace(removeDiacritics(value), DEFAULT_SPLIT_REGEXP, '$1\0$2'),
    DEFAULT_STRIP_REGEXP,
    '\0'
  );

  let start = 0;
  let end = result.length;

  // Trim the delimiter from around the output string.
  while (result.charAt(start) === '\0') start++;
  while (result.charAt(end - 1) === '\0') end--;

  // Transform each token independently.
  return result
    .slice(start, end)
    .split('\0')
    .map((s: string) => s.toLowerCase())
    .join('_');
}
