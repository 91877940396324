import { operator } from '@features/filter/types';
import { SourceColumn } from '@features/audiences/ducks/api/audienceBackTypes/source';

export interface AggregateDetails {
  // AggregateDetails used for aggs columns (JOIN case)
  type: AggregationType;
  sourceColumnId?: string;
  function?: ColumnAggregationType;
  datePeriod?: DatePeriodTypes;
  sort: {
    sortColumnsStatus: boolean;
    sourceColumnId?: string;
    direction?: ColumnAggregationSortDirection;
  };
  filterByColumns: {
    filterByColumnsStatus: boolean;
    sourceFilterColumnId?: string;
    filterOperator?: operator;
    filterOperatorValue?: string | Date | number | string[] | Date[] | number[] | boolean | unknown;
  };
  filterByOtherDateColumn: {
    filterByOtherDateColumnStatus: boolean;
    sourceFilterDateColumnId?: string;
  };
  excludeNullValues: boolean;
}
export enum AggregationType {
  allColumns = 'allcolumns',
  column = 'column',
}

export enum ColumnAggregationSortDirection {
  asc = 'asc',
  desc = 'desc',
}
export enum ColumnAggregationType {
  list = 'list', // Available in backend as 'array'
  first = 'first', // Available in backend as 'first_value'
  last = 'last',
  count = 'count', // Available in backend as 'count_values'
  countall = 'countall', // Available in backend as 'count_records'

  max = 'max',
  min = 'min',
  average = 'average',
  average_difference = 'average_difference',
  most_frequent_value = 'most_frequent_value',
  sum = 'sum', // Available in backend as 'sum'
  any = 'any', // Available in backend as 'some'
  every = 'every', // Available in backend as 'every'
}

export enum DatePeriodTypes {
  all_time = 'alltime',
  thirty_days = '30days',
  sixty_days = '60days',
  ninety_days = '90days',
  one_twenty_days = '120days',
  one_fifty_days = '150days',
  one_eighty_days = '180days',
  three_sixty_five_days = '365days',
  this_year = 'thisyear',
  last_year = 'lastyear',
}
