// Importing type
import * as apiTypes from './types';
import { sleep } from '@utils/helpers';
import { Organization, OrganizationByDomain } from '@features/organizations/types';
import { standardApi } from '@utils/fetch';

const getOrganizations: apiTypes.APIGetOrganizations = async (app: string) => {
  await sleep(1000);

  let organizations: Organization[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  organizations = [
    {
      key: 'sendinblue',
      details: {
        name: 'sendinblue',
        short_name: 'SNB',
      },
      theme: 'sendinblue-default',
    },
    {
      key: 'splio',
      details: {
        name: 'Splio',
        short_name: 'SPL',
      },
      theme: 'splio-black',
    },
  ];
  if (!organizations) {
    error = true;
    errorDetails = 'No organizations found.';
  }

  return {
    error,
    organizations,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getOrganization: apiTypes.APIGetOrganization = async (organizationName: string) => {
  await sleep(1000);

  let organization: Organization;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (organizationName === 'sendinblue') {
    organization = {
      key: 'sendinblue',
      details: {
        name: 'Sendinblue',
        short_name: 'SNB',
      },
      theme: 'sendinblue-default',
    };
  } else if (organizationName === 'octolis') {
    organization = {
      key: 'octolis',
      details: {
        name: 'octolis',
        short_name: 'OCT',
      },
      theme: 'octolis-default',
    };
  } else {
    organization = {
      key: 'splio',
      details: {
        name: 'Splio',
        short_name: 'SPL',
      },
      theme: 'splio-black',
    };
  }

  if (!organization) {
    error = true;
    errorDetails = 'No organizations found.';
  }

  return {
    error,
    organization,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getOrganizationByDomain: apiTypes.APIGetOrganizationByDomain = async (domain: string) => {
  let organizationByDomain:
    | {
        organization: OrganizationByDomain;
        auth0OrganizationId: string;
      }
    | undefined = undefined;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  try {
    const result = await standardApi<{
      organization: OrganizationByDomain;
      auth0OrganizationId: string;
    }>(`${process.env.REACT_APP_GRAPHQL_DOMAINS_URI}/${domain}`);

    organizationByDomain = result;
  } catch (err: unknown) {
    if (err instanceof Error) {
      error = true;
      errorDetails = err.message;
    }
  }
  return {
    error,
    organizationByDomain,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getOrganizations, getOrganization, getOrganizationByDomain };
