import { Column, ColumnWithNoID } from '../Column';
import { SourceDeduplicationSettings } from '../SourceDeduplicationSettings';
import { SourcePreparation } from '../ColumnPreparation';
import { AudienceDataSource, ConnectionDataSource, DataSource, SyncDataSource } from './DataSource';
import { sourceTypesEnum, dataTypesEnum, retrievalModeEnum } from '../enums';
import {
  JoinedAudienceSourceColumnConditionType,
  JoinedAudienceSourceConditionType,
} from '../../ducks/api/audienceBackTypes/audienceSpecification';

export type DefaultSourceConfiguration<DatasourceType extends DataSource = DataSource> =
  | JoinSourceConfiguration<DatasourceType>
  | UnionSourceConfiguration<DatasourceType>;

export type SourceConfigurationBase<DatasourceType extends DataSource = DataSource> = {
  key: string;
  dataSource: DatasourceType;
  selectedColumns: Column[];
  preparations: SourcePreparation[];
  retrievalMode: retrievalModeEnum;
  deduplicationSettings: SourceDeduplicationSettings;
};

export type UnionSourceConfiguration<DatasourceType extends DataSource = DataSource> =
  SourceConfigurationBase<DatasourceType> & {
    dataType: dataTypesEnum.UNION;
    combinationLevel: number;
  };
export type JoinSourceConfiguration<DatasourceType extends DataSource = DataSource> =
  SourceConfigurationBase<DatasourceType> & {
    dataType: dataTypesEnum.JOIN;
    combinationLevel: number;
    condition: SourceJoinCondition;
  };
export type SourceJoinCondition =
  | RegularJoinedAudienceSourceCondition
  | SourceIdListJoinedAudienceSourceCondition;

export type RegularJoinConditionColumnBase<Type = JoinedAudienceSourceColumnConditionType> = {
  key: string;
  type: Type;
  sourceColumnId: string;
};

export type RegularJoinConditionOnDataColumns =
  RegularJoinConditionColumnBase<JoinedAudienceSourceColumnConditionType.data> & {
    audienceColumnId: string;
  };

export type RegularJoinConditionOnMasterIdColumns =
  RegularJoinConditionColumnBase<JoinedAudienceSourceColumnConditionType.master_id>;

export type RegularJoinConditionColumn =
  | RegularJoinConditionOnDataColumns
  | RegularJoinConditionOnMasterIdColumns;
export type RegularJoinedAudienceSourceCondition = {
  type: JoinedAudienceSourceConditionType.regular;
  columns: RegularJoinConditionColumn[];
};

export type SourceIdListJoinedAudienceSourceCondition = {
  type: JoinedAudienceSourceConditionType.source_id_list;
  sourceId: string;
  sourceColumnId: string;
  sourceIdColumnId: string;
};

export type SqlSourceConfiguration<DatasourceType extends DataSource = DataSource> =
  SourceConfigurationBase<DatasourceType> & {
    columns: ColumnWithNoID[];
  };

export const isConnectionSource = (
  source: DefaultSourceConfiguration | SqlSourceConfiguration
): source is
  | DefaultSourceConfiguration<ConnectionDataSource>
  | SqlSourceConfiguration<ConnectionDataSource> =>
  source.dataSource.type === sourceTypesEnum.CONNECTION;
export const isAudienceSource = (
  source: DefaultSourceConfiguration | SqlSourceConfiguration
): source is
  | DefaultSourceConfiguration<AudienceDataSource>
  | SqlSourceConfiguration<AudienceDataSource> =>
  source.dataSource.type === sourceTypesEnum.AUDIENCE;

export const isSyncSource = (
  source: DefaultSourceConfiguration | SqlSourceConfiguration
): source is
  | DefaultSourceConfiguration<SyncDataSource>
  | SqlSourceConfiguration<SyncDataSource> =>
  source.dataSource.type === sourceTypesEnum.SYNC;
